import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {fetchData} from "../../../../../hook/axios.hook";
import {STATUS_COLORS} from "../../../../../utilities/colorConstants";
import ReactTable from "../../../../conteiners/ReactTable";
import {TextInput} from "../../../../Inputs";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {getProjectDocumentLoading} from "../../../ProjectSlice";
import {COF_DOCUMENTS_COLUMNS, PROJECT_DOC_TYPES} from "../constants";

import ShowDocBtn from "./ShowDocBtn";

const CoFDocForm = ({coId, cof = null}) => {
  const [currentCof, setCurrentCof] = useState(cof);
  const listsLoading = useSelector(getProjectDocumentLoading);
  const columns = useColumns(COF_DOCUMENTS_COLUMNS);

  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    if (!coId && !cof) return;

    if (!cof)
      fetchData(`/cos/f/get/${coId}`, "get")
        .then(res => setCurrentCof(res))
        .catch(() => {});
  }, []);

  useEffect(() => {
    setFieldValue("cofNumber", currentCof?.cofNumber);
  }, [currentCof]);

  const tableData = useMemo(
    () =>
      currentCof?.cofId
        ? [
            {
              id: currentCof?.cofId,
              statusCircle: STATUS_COLORS.GREY,
              number: 1,
              coNumber: currentCof?.cofNumber,
              projectManager: currentCof?.projectManager,
              coManager: currentCof?.coManager?.name,
              note: currentCof?.note,
              cofSum: currentCof?.cofSum,
            },
          ]
        : [],
    [currentCof],
  );

  return (
    <div className="w100">
      <div className="fc nowrap jcsb mb">
        <TextInput name={"cofNumber"} label={"КП(f) №"} readOnly />
        <ShowDocBtn
          type={PROJECT_DOC_TYPES.cof}
          files={currentCof?.docsArray || []}
          disabled={currentCof?.docsArray?.length < 1}
        />
      </div>
      <ReactTable
        defaultData={tableData}
        columns={columns}
        className={"project_table mb"}
        selectable={false}
        loading={listsLoading}
        style={{height: "4em"}}
      />
    </div>
  );
};
export default CoFDocForm;
