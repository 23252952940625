import {useEffect, useMemo, useRef, useState} from "react";
import accounting from "accounting";
import {useField} from "formik";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

const NumberInput = ({
  label,
  width = "67%",
  height,
  className = "",

  question = null,
  autolabel,
  readOnly,
  labelStyles,
  labelWrap,
  placeholder,
  inputClassName,
  questionItemClassName = "",
  handleInputChange = () => {},
  noFormat = false,
  disabled = false,
  arrows = false,
  minusValues = false,
  setref = null,
  maxNumberLength,
  onBlur = () => {},
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState("");

  const inputRef = useRef(null);

  const setInputRef = ref => {
    if (ref) {
      inputRef.current = ref;
      if (setref) setref(ref);
    }
  };

  const {setValue: setRawValue} = helpers;

  useEffect(() => {
    if (field.value === "") {
      setValue("");
    } else noFormat ? setValue(field.value) : setValue(accounting.formatNumber(field.value, options));
  }, [field.value]);

  const handleFocus = () => {
    inputRef?.current.select();
  };

  const options = useMemo(() => {
    return {
      // symbol : "€",
      decimal: ".",
      thousand: "'",
      precision: 2,
      format: "%s%v",
    };
  }, []);

  const handleChange = e => {
    const value = e.target.value;
    if (maxNumberLength && value.length > maxNumberLength) return;
    const formattedValue = (
      !minusValues
        ? value.replace(/[^0-9.,]/g, "")
        : value
        ? value[0]?.replace(/[^0-9.,-]/g, "") + value.slice(1).replace(/[^0-9.,]/g, "")
        : ""
    ).replaceAll(",", ".");

    handleInputChange(e);
    setValue(formattedValue);
  };

  return (
    <div className={`project_input ${labelWrap ? "" : "labelWrap"}`}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative", height: height}}>
        <div className={className}>
          <input
            readOnly={readOnly}
            autoComplete="off"
            value={value ?? ""}
            onBlur={e => {
              setRawValue(value);
              onBlur(e);
            }}
            onChange={handleChange}
            ref={setInputRef}
            onFocus={() => {
              !readOnly && handleFocus();
            }}
            // type="number"
            className={`${inputClassName} ${!arrows ? "inputNoArrows" : ""}`}
            placeholder={placeholder}
            style={{height: height}}
            disabled={disabled}
          />
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} className={questionItemClassName} /> : null}
    </div>
  );
};

NumberInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  props: PropTypes.array,
  className: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  autolabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  noFormat: PropTypes.bool,
};

export default NumberInput;
