import React from "react";
import {t} from "i18next";

import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import {TextAreaInput, TextInput} from "../../../Inputs";

const DtCtPart = ({isOrderCompleate}) => {
  return (
    <div className="border w100 padding0_5 margin0_5" style={{border: "1px solid #A3B9D9", padding: "1em"}}>
      <div className="fdr gap jcsb">
        <div className="fdr aic gap " style={{marginRight: "2em"}}>
          <TextInput name="dt" label={t("Dt")} autolabel style={{width: "30em"}} readOnly={true} />
        </div>

        <div className="fdr aic gap" style={{marginLeft: "2em"}}>
          <TextInput name="kt" label={t("Kt")} autolabel style={{width: "30em"}} readOnly={true} />
        </div>
      </div>
      <div className="fdr aic gap">
        <ToolTip title="Dt account name" className="fGrow">
          <TextAreaInput name="debitAccountName" height="2.5em" placeholder={t("Dt account name")} disabled />
        </ToolTip>
        <ToolTip title="Kt account name" className="fGrow">
          <TextAreaInput name="creditAccountName" height="2.5em" placeholder={t("Kt account name")} disabled />
        </ToolTip>
      </div>
      <Divider />
      <div className="fdr aic gap">
        <ToolTip title="Payment purpose" className="fGrow2">
          <TextAreaInput name="paymentPurpose" height="4.5em" placeholder={t("Payment purpose")} readOnly={true} />
        </ToolTip>
        <ToolTip title="Note (for the whole directive)" className="fGrow">
          <TextAreaInput name="compleateNote" height="4.5em" placeholder={t("Add note")} readOnly={isOrderCompleate} />
        </ToolTip>
      </div>
    </div>
  );
};

export default DtCtPart;
