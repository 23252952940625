import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../../utilities/dateFormat";
import {calcStatusColorViaPlannedDate} from "../../../../../utilities/DateStatusCircle";
import {makeTableNameFromPersona} from "../../../../../utilities/makeNameFromPersona";
import {
  deselectCurrentRequest,
  getAllStatusRequest,
  getLoadingRequest,
  getRequest,
  getRequestInfo,
} from "../../../RequestsSlice";
import {getExpandedDocs, profExpanded} from "../functions/expandDataForTable";

const useRequests = filters => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const allStatusRequests = useSelector(getAllStatusRequest);
  const currentRequest = useSelector(getRequestInfo);
  const requestLoading = useSelector(getLoadingRequest);

  useEffect(() => {
    if (currentRequest) {
      dispatch(getRequest(currentRequest.requestId));
    }
  }, []);

  // useEffect(() => {
  //   dispatch(fetchMixedRequests({window: REQ_WINDOW_TYPES.INPROGRESS}));
  // }, []);

  const onRequestSelect = id => {
    if (!id || id === currentRequest?.requestId) {
      dispatch(deselectCurrentRequest());
      return;
    }

    dispatch(getRequest(id));
  };

  const tableData = useMemo(() => {
    return allStatusRequests?.map(
      (
        {
          requestId,
          status,
          brand,
          brand: {factoryContacts},
          managerFea,
          createdAt,
          requestDates,
          cos,
          proforms,
          transportDocs,
          ...rest
        },
        index,
      ) => {
        const statusName = status?.[i18n.language === "en" ? "engName" : "ukrName"] || "";
        const lastDate = requestDates.reduce((prev, current) => {
          return prev.dateId > current.dateId ? prev : current;
        });
        const closerBringTo = cos.reduce((prev, current) => {
          const prevDate = prev.bringTo;
          const currentDate = current.bringTo;
          return prevDate > currentDate;
        });
        const unickManagersCO = Array.from(
          new Map(
            cos.map(obj => [
              obj.managerCo.userId,
              makeTableNameFromPersona({persona: obj.managerCo?.persona, language: i18n.language}),
            ]),
          ).values(),
        );

        const color = calcStatusColorViaPlannedDate(lastDate.plannedDate, lastDate.createdAt, lastDate.actualDate);

        return {
          ...rest,
          number: index + 1,
          prof: profExpanded(proforms),
          requestStatus: statusName,
          managerCo: unickManagersCO.join(", "),
          managerFea: makeTableNameFromPersona({persona: managerFea?.persona, language: i18n.language}) || "",
          factory: brand?.name || (factoryContacts && factoryContacts[0]?.name) || "",
          id: requestId,
          createdAt: dateFormat(createdAt),
          updatedAt: dateFormat(closerBringTo),
          noname: "",
          statusCircle: color,
          country: cos[0]?.project?.country?.shortName,
          bringTo: dateFormat(closerBringTo?.bringTo),
          packL: getExpandedDocs(transportDocs, 1),
          invoice: getExpandedDocs(transportDocs, 2),
        };
      },
    );
  }, [allStatusRequests]);

  //form initial values

  return {tableData, onRequestSelect, requestLoading};
};

export default useRequests;
