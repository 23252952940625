import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getBrands} from "../../../../selectors/skuSelectors";
import {TextAreaInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useSkuCatalogsService from "../../SkuCatalogsService";

const MainCharacteristics = () => {
  const {t} = useTranslation();
  const {brandList, colorsList, materialsList, productGroups, productTypes} = useSkuCatalogsService();

  const {setFieldValue} = useFormikContext();
  const brands = useSelector(getBrands);

  return (
    <div className="fcCol w100 gap0_5 aistr pt5">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "1em",
          position: "relative",
        }}
        className="autolabel sku-modal-form__mainChar"
      >
        <TextInput label="SKU*" name="article" type="text" labelStyles={{width: "7em"}} width="8em" />
        <div
          style={{
            position: "relative",
            bottom: "0",
            top: "0em",
            left: "-13em",
            right: "0",
            textAlign: "right",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <TextInput name="brandPrefix" type="text" width="3.5em" readOnly />
          <div> _ </div>
        </div>

        <Select
          label="Factory"
          name="brandId"
          options={brandList}
          width={"11em"}
          makeEffect={e => {
            setFieldValue("brandPrefix", brands?.find(item => item.brandId === e?.value)?.prefix || "");
          }}
          required
        />
        <Select label="Group" name="productGroupId" options={productGroups} width={"12em"} required />
        <Select label="Type" name="productTypeId" options={productTypes} width="12em" required />
        <Select label={t("Finishing*")} name="materialId" options={materialsList} width="10em" />
        <Select label={t("Color*")} name="colorId" options={colorsList} width="10em" />
      </div>
      <div className="fc jcsb autolabel">
        <div className="fc">
          <TextInput label="UnKit#" name="skuUnkit" type="text" readOnly={true} />
          <Select label={t("UnKit type")} id="unKitType" name="unKitType" type="text" options={[]} readOnly={true} />
        </div>
        <div className="fc">
          <TextInput label={t("Series")} name="productSeries" />
          <TextInput label={t("Batch")} name="batch" />
        </div>
      </div>
      <div className="fc jcsb autolabel">
        <TextAreaInput label={t("Nomenclature")} id="name" name="name" width="30%" />
        <TextAreaInput label="NameSKU(eng)" id="nameEng" name="nameEng" width="30%" />
        <TextAreaInput label={t("Set description")} id="description" name="complectDesc" width="30%" />
      </div>
    </div>
  );
};

export default MainCharacteristics;
