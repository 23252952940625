export const SKUS_TABLE_COLUMNS = Object.freeze([
  {
    header: () => "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Brand w*",
    accessorKey: "brandName",
  },
  {
    header: "Nomenclature",
    accessorKey: "skuName",
  },
  {
    header: "Characteristics",
    accessorKey: "characteristics",
    size: 300,
  },
  {
    header: "VWC",
    accessorKey: "ovh",
  },
  // {
  //   header: "Image",
  //   accessorKey: "image1",
  //   // cell: imgcellRender,
  // },
  // {
  //   header: "Image",
  //   accessorKey: "image2",
  //   // cell: imgcellRender,
  // },
  {
    header: "Set description",
    accessorKey: "complectDesc",
    toggled: true,
  },
  {
    header: "Quantity",
    accessorKey: "amount",
  },
  {
    header: "Price pcs",
    accessorKey: "price",
    numberFormat: true,
  },
  {
    header: "Sum €",
    accessorKey: "paymentSumm",
    numberFormat: true,
  },
  {
    header: "Discount %",
    accessorKey: "discount",
    numberFormat: true,
  },
  {
    header: "Total €",
    accessorKey: "totalPaymentSum",
    numberFormat: true,
  },
  {
    header: "Total discount %",
    accessorKey: "totalDiscount",
    numberFormat: true,
  },
  {
    header: "Payment €",
    accessorKey: "payable",
    numberFormat: true,
  },
]);

export const DOCS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Document date",
    accessorKey: "dateFrom",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Load date",
    accessorKey: "docUploadDate",
    size: 100,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 100,
    isSortable: true,
  },
]);

export const ORDER_ACCEPTANCE_YES_COLUMNS = Object.freeze([
  {
    header: () => "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
  },
  {
    header: "Brand w*",
    accessorKey: "brandName",
  },
  {
    header: "Nomenclature",
    accessorKey: "skuName",
  },
  {
    header: "Characteristics",
    accessorKey: "characteristics",
    size: 300,
  },
  {
    header: "VWC",
    accessorKey: "ovh",
  },

  {
    header: "Set description",
    accessorKey: "complectDesc",
    toggled: true,
  },
  {
    header: "Measurement units",
    accessorKey: "measurementUnit",
  },
  {
    header: "Quantity",
    accessorKey: "amount",
  },
  {
    header: "Price pcs",
    accessorKey: "price",
    numberFormat: true,
  },
  {
    header: "Sum €",
    accessorKey: "sum",
    numberFormat: true,
  },
]);

export const ORDER_ACCEPTANCE_NOT_COLUMNS = Object.freeze([
  {
    header: () => "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
  },
  {
    header: "Brand w*",
    accessorKey: "brandName",
  },
  {
    header: "Nomenclature",
    accessorKey: "skuName",
  },
  {
    header: "Characteristics",
    accessorKey: "characteristics",
    size: 300,
  },
  {
    header: "VWC",
    accessorKey: "ovh",
  },

  {
    header: "Set description",
    accessorKey: "complectDesc",
    toggled: true,
  },
  {
    header: "Measurement units",
    accessorKey: "measurementUnit",
  },
  {
    header: "Quantity",
    accessorKey: "amount",
  },
  {
    header: "Price pcs",
    accessorKey: "price",
    numberFormat: true,
  },
  {
    header: "Sum €",
    accessorKey: "sum",
    numberFormat: true,
  },
]);
