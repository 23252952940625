export const PROJECT_VIEW_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
    maxSize: 25,
  },
  {
    header: "Date from",
    accessorKey: "dateFrom",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Date To",
    accessorKey: "dateTo",
    size: 60,
    maxSize: 60,
  },
  {
    header: "CO №",
    accessorKey: "coNumber",
    maxSize: 80,
  },
  {
    header: "CO description",
    accessorKey: "coDescription",
    size: 300,
  },
  {
    header: "CO Sum",
    accessorKey: "coSum",
    maxSize: 70,
  },
  {
    header: "Sum (n)",
    accessorKey: "sumN",
    maxSize: 70,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumF",
    maxSize: 70,
  },
  {
    header: "(%%)",
    accessorKey: "percentage",
    maxSize: 70,
  },
  {
    header: "Sum (+/-)",
    accessorKey: "approxSum",
    maxSize: 70,
  },
  {
    header: "Date To",
    accessorKey: "dateToS",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Manager",
    accessorKey: "managerP",
    maxSize: 90,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCO",
    maxSize: 90,
  },
]);

export const ORDER_VIEW_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Order number",
    accessorKey: "orderNumber",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "brandName",
    // size: 100,
  },
  {
    header: "Group",
    accessorKey: "group",
    // size: 100,
  },
  {
    header: "Take To",
    accessorKey: "takeTo",
    // size: 100,
  },
  {
    header: "Order sum",
    accessorKey: "orderSum",
    // size: 100,
  },
  {
    header: "Status*",
    accessorKey: "status",
    // size: 100,
  },
]);
