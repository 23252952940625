import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";

import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import {useModal} from "../../Modal/ModalProvider";
import {addNewCashdeskDirective} from "../FinBlockSlice";
import {fetchAllDirectives, fetchOperationTypes} from "../FinSettings/FinSettingsSlice";

import CashdeskDirectiveOrder from "./cashdeskDirectiveOrderModals/CashdeskDirectiveOrder";
import {CASHDESK_DIRECTIVE_TYPES} from "./CashdeskDirective";

const useCashdeskDirective = () => {
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const {openModal} = useModal();
  const {filters} = useFiltersContext();

  const {t} = useTranslation();

  const [currentDirectiveSubTypeId, setCurrentDirectiveSubTypeId] = useState(null);

  const [currentPayer, setCurrentPayer] = useState(null);
  const [currentReceiver, setCurrentReceiver] = useState(null);

  const isPayerLead = values.payerType === "Lead";
  const isReceiverLead = values.receiverType === "Lead";

  // const [isOrderCreateModalActive, setOrderCreateModalActive] = useState(false);
  // const [isOrderViewModalActive, setOrderViewModalActive] = useState(false);

  const directiveType = values?.directiveType;

  useEffect(() => {
    setCurrentDirectiveSubTypeId(null);
    setCurrentPayer(null);
    setCurrentReceiver(null);
    setValues(prev => ({...prev, payerName: "", payerType: "", receiverName: "", receiverType: ""}));
  }, [directiveType]);

  useEffect(() => {
    dispatch(fetchOperationTypes({}));
  }, []);

  const openOrderCreateModal = orderId => {
    openModal(<CashdeskDirectiveOrder />, {
      label: t("Cashdesk order"),
      style: {width: "90%", height: "85%"},
      className: "largeModal",
      orderId,
    });
  };

  const onAddNewDirectiveBtnClick = () => {
    dispatch(
      addNewCashdeskDirective({
        isInflow: directiveType === CASHDESK_DIRECTIVE_TYPES.ADVENT,
        operationTypeId: currentDirectiveSubTypeId,
        payer: {[isPayerLead ? "leadId" : "clientId"]: currentPayer},
        recipient: {[isReceiverLead ? "leadId" : "clientId"]: currentReceiver},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        openOrderCreateModal(resp?.payload?.directive?.orders?.[0]?.orderId);
        dispatch(fetchAllDirectives({...checkEmptyFilterFields(filters), isCashDesk: true}));
        setCurrentDirectiveSubTypeId(null);
        setCurrentPayer(null);
        setCurrentReceiver(null);
        setValues(prev => ({
          ...prev,
          directiveSubTypeId: "",
          ...(directiveType === CASHDESK_DIRECTIVE_TYPES.ADVENT ? {payerType: ""} : {}),
          payerName: "",
          receiverName: "",
        }));
      }
    });
  };

  return {
    onAddNewDirectiveBtnClick,
    directiveType,
    currentDirectiveSubTypeId,
    setCurrentDirectiveSubTypeId,
    currentPayer,
    setCurrentPayer,
    currentReceiver,
    setCurrentReceiver,
  };
};

export default useCashdeskDirective;
