import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {apiUrl} from "../../../../../api/baseURL";

const useKpFinCollumnsService = toggledColl => {
  const {t} = useTranslation();

  const kpFinCollumns = useMemo(() => {
    const imgcellRender = value => {
      var imgs = value.getValue();
      if (imgs) {
        return (
          <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <div key={imgs} style={{position: "relative"}}>
              <img src={apiUrl + "/" + imgs} alt={imgs} style={{width: "5em", height: "5em", marginRight: "2px"}} />
            </div>
          </div>
        );
      }
    };
    return [
      {
        header: () => "№",
        accessorKey: "number",
        size: 15,
      },
      {
        header: () => "SKU",
        accessorKey: "article",
        // toggled: true,
        size: 100,
      },
      {
        header: () => [t("Brand w*")],
        accessorKey: "brandName",
      },
      {
        header: () => [t("Nomenclature")],
        accessorKey: "skuName",
      },
      {
        header: () => [t("Characteristics")],
        accessorKey: "characteristics",
        size: 200,
        // toggled: true,
      },
      {
        header: () => [t("VWC")],
        accessorKey: "ovh",
      },
      {
        header: () => [t("Image")],
        accessorKey: "image1",
        cell: imgcellRender,
      },
      {
        header: () => [t("Image")],
        accessorKey: "image2",
        cell: imgcellRender,
      },
      {
        header: () => [t("Set description")],
        accessorKey: "complectDesc",
        // toggled: true,
      },
      {
        header: () => [t("Quantity")],
        accessorKey: "amount",
        size: 60,
      },
      {
        header: () => [t("Measur units")],
        accessorKey: "measurUnits",
        size: 60,
      },
      {
        header: () => [t("Price pcs")],
        accessorKey: "price",
        numberFormat: true,
        size: 100,
      },
      {
        header: () => [t("Sum €")],
        accessorKey: "summ",
        numberFormat: true,
        size: 100,
      },
      {
        header: () => [t("Discount %")],
        accessorKey: "discount",
        size: 70,
        // toggled: true,
      },
      {
        header: () => [t("Total €")],
        accessorKey: "totalSumm",
        numberFormat: true,
      },
      {
        header: () => [t("Total discount %")],
        accessorKey: "topDiscount",
        size: 70,
        // toggled: true,
      },
      {
        header: () => [t("Payment €")],
        accessorKey: "paymentSumm",
        numberFormat: true,
      },
    ];
  }, []);

  const collumns = useMemo(() => {
    if (toggledColl?.length > 0) {
      const withDisabledColls = kpFinCollumns.map(coll => {
        if (toggledColl.includes(coll.accessorKey)) return {...coll, toggled: true};
        return coll;
      });
      return withDisabledColls;
    } else {
      return kpFinCollumns;
    }
  }, []);

  return {collumns};
};

export default useKpFinCollumnsService;
