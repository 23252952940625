import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
// import Flatpickr from "flatpickr";
import flatpickr from "flatpickr";
import {useField, useFormikContext} from "formik";
import moment from "moment";
import PropTypes from "prop-types";

import calendarIcon from "../../images/icons/calendar.png";
import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";
import {Ukrainian} from "./utils/ukrLocale";

import "flatpickr/dist/flatpickr.min.css";

export const SimpleDateInput = ({label, ...props}) => {
  const [field, meta] = useField(props);

  return (
    <div className="project_input" style={{position: "relative"}}>
      <div className="datePicker_withText">
        <label htmlFor={props.name} style={{width: "auto", marginRight: "5px"}}>
          {label}
        </label>
        <input {...props} {...field} type="date" style={{width: "9em"}} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" />
        {!props.readOnly && <img src={calendarIcon} alt="" draggable="false" />}
      </div>
      {meta.touched && meta.error ? <div className="error ">{meta.error}</div> : null}
    </div>
  );
};

export const DateInput = ({
  label,
  className = "",
  generalClassName = "",
  question,
  labelWrap,
  labelW,
  flatpikrConfig,
  noFormik = false,
  setNoFormikValue = null,
  noFormikValue,
  disabled = false,
  generalStyle = {},
  required,
  style = {},
  fromCurrentDate = false,
  onDateChange = () => {},
  ...props
}) => {
  const [field, meta] = useField(props);
  const inputref = useRef();
  const [date, setDateValue] = useState("");
  const {t, i18n} = useTranslation();
  const [flatpickrInstance, setInstanse] = useState(null);

  const {tooltipShown, onMouseEnter, onMouseLeave} = useInputsTooltip(field);

  const {setFieldValue, values} = useFormikContext();

  useEffect(() => {
    setInstanse(
      flatpickr(inputref.current, {
        dateFormat: "d.m.Y",
        clickOpens: false,
        weekNumbers: true,
        locale: i18n.language === "en" ? "en" : Ukrainian,
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("in picker", typeof selectedDates[0]);
          if (noFormik && setNoFormikValue) {
            setNoFormikValue(selectedDates[0].toJSON());
            return;
          }
          setFieldValue(props.name, selectedDates[0].toJSON());
        },
        ...flatpikrConfig,
        ...(fromCurrentDate ? {minDate: moment().format("DD-MM-YYYY")} : {}),
      }),
    );

    return () => {
      // Очистка экземпляра Flatpickr при размонтировании компонента
      setInstanse(null);
    };
  }, []);

  useEffect(() => {
    const newValue = noFormik ? noFormikValue : field?.value;

    if (typeof newValue === "string" && newValue !== "" && flatpickrInstance) {
      var d = flatpickr.formatDate(new Date(newValue), "d.m.Y");
      setDateValue(d);
      onDateChange(newValue);
      if (d) flatpickrInstance.setDate(new Date(newValue), false);
    } else if ((!newValue || newValue === "") && flatpickrInstance) {
      setDateValue("");
      if (field?.value && field?.value !== "") {
        onDateChange("");
      }
      flatpickrInstance.setDate("", false);
    }
  }, [field.value, flatpickrInstance, noFormikValue]);

  const inp = useMemo(() => {
    return (
      <input
        className="calendar"
        ref={inputref}
        // {...props}
        // {...field}
        value={date}
        type="text"
        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
        style={{width: "9em", cursor: "auto", ...generalStyle}}
        readOnly={true} // Отключение возможности ввода текста, так как Flatpickr будет отвечать за выбор даты
        onMouseEnter={question ? onMouseEnter : null}
        onMouseLeave={question ? onMouseLeave : null}
      />
    );
  }, [date]);

  const labelText = label
    ? label?.[label?.length - 1] === ":"
      ? required
        ? label + "*"
        : label
      : (required ? label + "*" : label) + ":"
    : "";

  return (
    <div
      className={`project_input ${labelWrap ? "" : "labelWrap"} ${generalClassName}`}
      style={{position: "relative", ...style}}
    >
      <div className={`${className} datePicker_withText`}>
        <label htmlFor={props.name} style={{marginRight: "5px", width: labelW || "auto"}} className={"autolabel"}>
          {labelText}
        </label>
        {inp}
        {!props.readOnly && (
          <img
            className="datePicker_img"
            src={calendarIcon}
            style={{cursor: disabled ? "auto" : "pointer"}}
            alt=""
            onClick={() => {
              if (disabled) return;
              flatpickrInstance && flatpickrInstance.open();
            }}
          />
        )}
        {question ? (
          <div className="question-date">
            <QuestionItem title={question} tooltipShown={tooltipShown} />
          </div>
        ) : null}
      </div>
      {meta.touched && meta.error ? <div className="error error_dateInp">{meta.error}</div> : null}
    </div>
  );
};

DateInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  props: PropTypes.array,
};

export default DateInput;
