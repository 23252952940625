import React from "react";
import {useTranslation} from "react-i18next";

import {InputWithMeasureUnits, NumberInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import useSkuCatalogsService from "../SkuCatalogsService";

import PalletSelect from "./PalletSelect";

const useVWCharInputsService = (width = "", name, allDisabled) => {
  const {
    measurementUnitsList,
    measurementObjectsList,
    lengthMeasurementQuantites,
    areaMeasurementQuantites,
    volumeMeasurementQuantites,
    weightMeasurementQuantites,
    palletsList,
  } = useSkuCatalogsService();

  const {t} = useTranslation();

  const dimension = (
    <InputWithMeasureUnits
      name={"overallSizeUnitId"}
      measureUnitsList={lengthMeasurementQuantites}
      coef={"dimensionCoef"}
      label={t(name)}
    >
      <NumberInput label={t("Length")} name="length" width={width} autolabel readOnly={allDisabled} />
      <NumberInput label={t("Width")} name="width" width={width} autolabel readOnly={allDisabled} />
      <NumberInput label={t("Height")} name="height" width={width} autolabel readOnly={allDisabled} />
    </InputWithMeasureUnits>
  );

  const area = (
    <InputWithMeasureUnits
      name="areaUnitId"
      label={t("Area")}
      measureUnitsList={areaMeasurementQuantites}
      coef={"areaCoef"}
    >
      <NumberInput name="area" width={width} readOnly={allDisabled} />
    </InputWithMeasureUnits>
  );

  const volume = (
    <InputWithMeasureUnits
      name="volumeUnitId"
      label={t("Volume")}
      measureUnitsList={volumeMeasurementQuantites}
      coef={"volumeCoef"}
    >
      <NumberInput name="volume" width={width} readOnly={allDisabled} />
    </InputWithMeasureUnits>
  );

  const weight = (
    <InputWithMeasureUnits
      name="weightUnitId"
      label={t("Weight")}
      measureUnitsList={weightMeasurementQuantites}
      coef={"volumeWeightCoef"}
    >
      <NumberInput name="weight" width={width} readOnly={allDisabled} />
    </InputWithMeasureUnits>
  );

  const skuMeasurementUnit = (
    <Select
      label={t("Measurement units for price*")}
      name="unitId"
      options={measurementUnitsList}
      width="5em"
      readOnly={allDisabled}
    />
  );

  const quantity = (
    <div className="fc jcfs autolabel aist" style={{gap: "5px"}}>
      <Select
        label={t("Type") + " SKU"}
        name="objectId"
        width="10em"
        options={measurementObjectsList}
        readOnly={allDisabled}
      />
      <TextInput name="amount" width="5em" readOnly={allDisabled} style={{display: "none"}} />
    </div>
  );

  const pallet = <PalletSelect pallets={palletsList} />;

  return {
    dimension,
    area,
    volume,
    weight,
    skuMeasurementUnit,
    quantity,
    pallet,
  };
};

export default useVWCharInputsService;
