/* eslint-disable react/display-name */

import {useDispatch, useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";
import {t} from "i18next";

import {getCurrentUserRole, getCurrentUserRoutes, setPreviousUserLocation} from "../../slices/UserSlice";

export function withRoleRequired(WrappedComponent) {
  return function (props) {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const role = useSelector(getCurrentUserRole) || null;
    const allowedRoutes = useSelector(getCurrentUserRoutes);

    const location = useLocation();

    const superRoutes = ["main", "settings", "references", "devIT"];
    const currentPath = location?.pathname.replace("/ba3a/", "");
    const nestedPaths = currentPath.split("/");

    const ifAllowed = allowedRoutes?.filter(route => {
      if (currentPath.includes("bos")) {
        return route.routeUrl.includes(nestedPaths[0] + "/bos");
      }
      return route.routeUrl === nestedPaths[0] + "/*";
    });

    if (!token) {
      setTimeout(() => dispatch(setPreviousUserLocation(location?.pathname)), 100);
      return <Navigate to="/login" replace />;
    }
    if (superRoutes.includes(nestedPaths[0])) {
      return <WrappedComponent {...props} />;
    }

    if (role && ifAllowed && ifAllowed?.length < 1) {
      return (
        <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
          <p> {t("You have no access to this page")}</p>
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
}
