import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEmpty, isEqual} from "lodash";

import {
  selectAgents,
  selectAgentTypes,
  selectDesigners,
  selectDesignerTypes,
} from "../../../../../slices/CatalogsSlice";
import {selectAllUsers} from "../../../../../slices/PersonSlice";
import {getCurrentUser} from "../../../../../slices/UserSlice";
import calcStatusColor, {COLOR_TO_REMIND_COLOR, REMIND_COLORS} from "../../../../../utilities/calcStatusColor";
import {STATUS_COLORS} from "../../../../../utilities/colorConstants";
import {dateFormat} from "../../../../../utilities/dateFormat";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../utilities/hooks/toaster";
import makeNameFromPersona, {makeTableNameFromPersona} from "../../../../../utilities/makeNameFromPersona";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addCO,
  clearCOs,
  clearCurrentCO,
  deleteCO,
  fetchAllCOs,
  fetchCO,
  fetchProjectFilters,
  getCOLoading,
  getCurrentProject,
  selectAllCOs,
  selectCurrentCO,
  updateCO,
} from "../../../ProjectSlice";
import {CO_TABLE_COLUMNS} from "../../constants";

const useCoSection = () => {
  const {
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues, setFieldValue, errors, submitForm} = useFormikContext();

  const currentUser = useSelector(getCurrentUser);
  const currentUserId = currentUser?.id;
  const users = useSelector(selectAllUsers);
  const designers = useSelector(selectDesigners);
  const designerTypes = useSelector(selectDesignerTypes);
  const agents = useSelector(selectAgents);
  const agentTypes = useSelector(selectAgentTypes);
  const allCOs = useSelector(selectAllCOs);
  const currentProject = useSelector(getCurrentProject);
  const projectId = currentProject?.projectId;
  const currentCO = useSelector(selectCurrentCO);

  const coLoading = useSelector(getCOLoading);
  const isDoneDisabled = currentCO?.statusId >= 3;

  // const READY_CO_STATUS_ID = 2;

  const [currentCoId, setCurrentCoId] = useState(null);
  const [remindBtnColor, setReminBtnColor] = useState(REMIND_COLORS.BLUE);

  const hasCoChanged = useMemo(() => {
    if (!currentCO) return true;
    let coFromValues = {
      designer: values.designer || "",
      designerPersent: values.designerPersent || "",
      designerType: values.designerType || "",
      agent: values.agent || "",
      agentPersent: values.agentPersent || "",
      agentType: values.agentType || "",
      coDescription: values.coDescription || "",
      isDone: values.isDone,
    };
    let co = {
      designer: currentCO?.designers?.[0]?.designerId || "",
      designerPersent: currentCO?.designers?.[0]?.CoToDesigners?.find(item => item.coId === currentCoId)?.bonus || "",
      designerType:
        currentCO?.designers?.[0]?.CoToDesigners?.find(item => item.coId === currentCoId)?.designerTypeId || "",
      agent: currentCO?.agents?.[0]?.agentId || "",
      agentPersent: currentCO?.agents?.[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.bonus || "",
      agentType: currentCO?.agents?.[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.agentTypeId || "",
      coDescription: currentCO?.note || "",
      isDone: currentCO?.statusId >= 3,
    };

    if ((co.designer && !coFromValues.designer) || (co.agent && !coFromValues.agent)) return true;

    return isEqual(coFromValues, co);
  }, [values, currentCO]);

  // useEffect(() => {
  //   if (!users?.length) {
  //     dispatch(fetchUsers());
  //   }
  //   if (!designers?.length) {
  //     dispatch(fetchDesigners());
  //   }
  // }, [users?.length, designers?.length]);

  // useEffect(() => {
  //   console.log("uef 1");
  //   if (users?.length > 0 && currentUserId && projectId) {
  //     setValues(prev => ({...prev, managerCoId: currentUserId}));
  //   }
  //   if (!projectId) {
  //     setValues(prev => ({...prev, managerCoId: ""}));
  //   }
  // }, [users?.length, currentUserId, projectId]);

  useEffect(() => {
    setCurrentCoId(null);
    dispatch(clearCurrentCO());
    setFieldValue("managerCoId", projectId ? currentUserId : "");

    if (projectId) {
      dispatch(fetchAllCOs({projectId}));
    } else {
      dispatch(clearCOs());
    }

    // if ( currentUserId && projectId) {
    //   setValues(prev => ({...prev, managerCoId: currentUserId}));
    // }
    // if (!projectId) {
    //   setValues(prev => ({...prev, managerCoId: ""}));
    // }
  }, [projectId]);

  useEffect(() => {
    setTimeout(() => {
      setValues(prev => ({
        ...prev,
        managerCoId: currentCO?.managerCoId ?? currentUserId ?? "",
        startDate: currentCO?.startDate ?? "",
        finalDate: currentCO?.finalDate ?? "",
        // isReady: currentCO?.status?.statusId ? currentCO?.status?.statusId === READY_CO_STATUS_ID : "",
        coOffice: currentCO?.managerCo?.orgStructure?.shortName ?? "",
        coNumber: currentCO?.coNumber ?? "",
        coSum: currentCO?.sum ?? "",
        advance: currentCO ? (currentCO?.prepayment / currentCO?.sum) * 100 ?? "" : "",
        planSum: currentCO?.prepayment ?? "",
        factSum: currentCO?.accounts?.[0]?.balance ?? "",
        factSumPersent: currentCO ? (currentCO?.accounts?.[0]?.balance / currentCO?.sum) * 100 ?? "" : "",
        designer: (currentCO?.designers ?? [])?.[0]?.designerId,
        designerPersent:
          (currentCO?.designers ?? [])?.[0]?.CoToDesigners?.find(item => item.coId === currentCoId)?.bonus ?? "",
        designerType: (currentCO?.designers ?? [])?.[0]?.CoToDesigners?.find(item => item.coId === currentCoId)
          ?.designerTypeId,
        agent: (currentCO?.agents ?? [])?.[0]?.agentId,
        agentPersent: (currentCO?.agents ?? [])?.[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.bonus ?? "",
        agentType: (currentCO?.agents ?? [])?.[0]?.CoToAgents?.find(item => item.coId === currentCoId)?.agentTypeId,
        coDescription: currentCO?.note || "",
        isDone: currentCO?.statusId >= 3,
      }));
    }, 100);
  }, [currentCO]);

  useEffect(() => {
    if (values?.isDone) {
      setReminBtnColor(REMIND_COLORS.GREY);
      return;
    }
    if (new Date(values.finalDate) - new Date(values.startDate) < 0) {
      toaster.error("Final date can`t be before start date!");
      setFieldValue("finalDate", "");
    }

    setReminBtnColor(
      REMIND_COLORS[COLOR_TO_REMIND_COLOR[calcStatusColor({startDate: values.startDate, finalDate: values.finalDate})]],
    );
  }, [values.startDate, values.finalDate, values?.isDone]);

  const coManagerOptions =
    users?.map(({persona, userId}) => ({
      //   title: `${makeNameFromPersona({persona, language})} ${language === "en" ? nameEng : nameUkr}`,
      title: `${makeTableNameFromPersona({persona, language})}`,
      value: userId,
    })) ?? [];
  const designerOptions =
    designers?.map(({persona, designerId, bonus}) => ({
      title: makeTableNameFromPersona({persona, language}),
      value: designerId,
      bonus,
    })) ?? [];
  const designerTypeOptions =
    designerTypes?.map(({engName, ukrName, designerTypeId}) => ({
      title: language === "en" ? engName : ukrName,
      value: designerTypeId,
    })) ?? [];
  const coAgentOptions =
    agents?.map(({name, agentId, basicBonus}) => ({title: name, value: agentId, bonus: basicBonus})) ?? [];
  const agentTypeOptions =
    agentTypes?.map(({engName, ukrName, agentTypeId}) => ({
      title: language === "en" ? engName : ukrName,
      value: agentTypeId,
    })) ?? [];

  const coTableColumns = useColumns(CO_TABLE_COLUMNS);
  const coTableData =
    allCOs?.map(
      (
        {
          coId,
          coNumber,
          statusId,
          note,
          sum,
          startDate,
          finalDate,
          bringTo,
          managerCoId,
          project,
          accounts,
          prepayment,
        },
        index,
      ) => ({
        number: ++index,
        id: coId,
        statusCircle:
          statusId >= 3
            ? STATUS_COLORS.GREY
            : bringTo
            ? calcStatusColor({startDate, finalDate: bringTo})
            : REMIND_COLORS.TRANSPARENT,
        startDate: dateFormat(startDate),
        finalDate: dateFormat(finalDate),
        coNumber,
        coDescription: note,
        sum: numberFormat(sum),
        planSum: numberFormat(prepayment) || "",
        factSum: numberFormat(accounts?.[0]?.balance) ?? "",
        persent: Number.parseFloat(sum)
          ? numberFormat((Number.parseFloat(accounts?.[0]?.balance) / Number.parseFloat(sum)) * 100)
          : "",
        factDate: dateFormat(bringTo),
        managerP: makeTableNameFromPersona({persona: project.projectManager.persona, language}),
        managerCO: coManagerOptions?.find(item => item.value === managerCoId)?.title ?? "-",
      }),
    ) ?? [];

  function onCoTableRowSelect(coId) {
    setCurrentCoId(coId);
    if (coId) {
      dispatch(fetchCO({coId}));
    } else {
      dispatch(clearCurrentCO());
      setReminBtnColor(REMIND_COLORS.BLUE);
    }
  }

  function onCreateCoBtnClick() {
    if (!projectId) return;
    const {managerCoId, startDate, finalDate} = values;

    dispatch(addCO({projectId, body: {managerCoId, startDate, finalDate}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("CO created");
        dispatch(fetchCO({coId: resp.payload.co.coId}));
        // setValues(prev => ({
        //   ...prev,
        //   coNumber: resp.payload.co.coNumber,
        //   coOffice: resp.payload.co.managerCo.orgStructure.shortName,
        // }));
        dispatch(fetchAllCOs({projectId}));
        setCurrentCoId(resp.payload.co.coId);
        dispatch(fetchProjectFilters());
      }
    });
  }

  function onSaveCoBtnClick() {
    if (!currentCoId) return;
    submitForm();
    if (!isEmpty(errors)) return;

    const {coDescription, designer, designerPersent, designerType, agent, agentPersent, agentType, isDone} = values;

    dispatch(
      updateCO({
        coId: currentCoId,
        body: {
          isDone,
          note: coDescription,
          ...(designer && {
            designer: {
              designerId: designer,
              designerTypeId: designerType || null,
              bonus: designerPersent || 0,
            },
          }),
          ...(agent && {
            agent: {
              agentId: agent,
              agentTypeId: agentType || null,
              bonus: agentPersent || 0,
            },
          }),
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("CO saved/updated");
        dispatch(fetchAllCOs({projectId}));
        dispatch(fetchProjectFilters());
      }
    });
  }

  function onDeleteCoBtnClick() {
    if (!currentCoId) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteCO({coId: currentCoId})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("CO deleted");
            dispatch(fetchAllCOs({projectId}));
            setCurrentCoId(null);
          }
        });
      },
    });
  }

  function onDesignerSelect(option) {
    if (!option) {
      setValues(prev => ({...prev, designer: "", designerPersent: ""}));
      return;
    }

    const {value, bonus} = option;
    setValues(prev => ({...prev, designer: value, designerPersent: bonus}));
  }
  function onAgentSelect(option) {
    if (!option) {
      setValues(prev => ({...prev, agent: "", agentPersent: ""}));
      return;
    }

    const {value, bonus} = option;
    setValues(prev => ({...prev, agent: value, agentPersent: bonus}));
  }

  return {
    coTableColumns,
    coTableData,
    onCoTableRowSelect,
    currentCoId,
    onCreateCoBtnClick,
    onSaveCoBtnClick,
    onDeleteCoBtnClick,
    coManagerOptions,
    designerOptions,
    designerTypeOptions,
    coAgentOptions,
    agentTypeOptions,
    projectId,
    onDesignerSelect,
    onAgentSelect,
    hasCoChanged,
    remindBtnColor,
    coLoading,
    isDoneDisabled,
  };
};

export default useCoSection;
