import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {Filters} from "../../../conteiners/filters/Filter.hoc";
import {useFiltersContext} from "../../../conteiners/filters/useSessionFiltersContext";
import HiddenContainer from "../../../conteiners/hiddenContainer/HiddenContainer";
import {DateInput, MoneyInput, Select, TextInput} from "../../../Inputs";
import {
  fetchAllBankClientStatements,
  fetchAllBankTransactions,
  fetchBankClientFilters,
  selectBankClientFilters,
} from "../../FinBlockSlice";

const BankClientFilters = ({
  onGetDataBtnClick,
  folderPickerRef,
  handleFolderUpload,
  hasGetDataBtn,
  forBankStatements = false,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(selectBankClientFilters);
  const bankOptions = filters?.banks?.map(bank => ({title: bank, value: bank})) ?? [];
  const currencyOptions =
    filters?.currencies?.map(({currencyId, shortName}) => ({title: shortName, value: currencyId})) ?? [];
  const clientNumberOptions =
    filters?.clients?.map(({clientId, clientNumber}) => ({title: clientNumber, value: clientId})) ?? [];

  const {filters: savedFilters = {}} = useFiltersContext();

  useEffect(() => {
    if (forBankStatements) {
      dispatch(fetchAllBankClientStatements({...savedFilters}));
    } else {
      dispatch(fetchAllBankTransactions({...savedFilters}));
    }
    dispatch(fetchBankClientFilters());
  }, []);

  return (
    <HiddenContainer tooltipMessageShow={"Show filters"} tooltipMessageHide={"Hide filters"} window="BANK_CLIENT">
      <Filters>
        <div className="row aibl" style={{position: "absolute", top: "3.3em", left: 15, width: "50px"}}>
          <TextInput
            label="trId"
            name="transactionUuid"
            placeholder="tr.id"
            width="5.5rem"
            labelStyles={{display: "none"}}
          />
          {hasGetDataBtn ? (
            <>
              <FunctionButton name="Get data" onClick={onGetDataBtnClick} autoWidth />
              <input
                type="file"
                style={{display: "none"}}
                webkitdirectory="true"
                ref={folderPickerRef}
                multiple
                onChange={handleFolderUpload}
              />
            </>
          ) : (
            ""
          )}
        </div>

        <div className="fcCol w100 gap">
          <div className="row w100">
            <Select options={bankOptions} label="Bank" name="counterpartyBank" autolabel />
            <TextInput label="Company" name="company" autolabel disabled />
            {/* <TextInput name="clientNumber" label="Client №" autolabel disabled /> */}
            <Select name={"clientId"} label={t("Client №")} options={clientNumberOptions} disabled />
            <MoneyInput name="operationSum" label="Sum" />
            <Select options={currencyOptions} label="Currency" name="operationCurrencyId" autolabel />
            <DateInput label="From" name="dateStart" />
          </div>
          <div className={`row w100 ${hasGetDataBtn ? "pl7x" : ""}`}>
            <TextInput name={forBankStatements ? "iban" : "accountNumber"} label="Acc. №" autolabel />
            <TextInput name="counterparty" label="Name (P./R.)" autolabel />
            <TextInput name="edrpou" label="EDRPOU / TIN (P./R.)" autolabel />
            <TextInput
              name="paymentPurpose"
              label="Purpose"
              autolabel
              generalClassName="fGrow"
              className="fGrow"
              placeholder={`...${t("payment for")}`}
            />
            <DateInput label="To" name="dateEnd" />
          </div>
        </div>
      </Filters>
    </HiddenContainer>
  );
};

export default BankClientFilters;
