import {toast} from "react-toastify";
import {t} from "i18next";

import {instance} from "../api/apiSetup";
import {checkEmptyFields} from "../utilities/checkEmptyFields";
import toastInstance from "../utilities/ToastManager";

export const fetchData = async (
  url,
  method,
  rawBody = null,
  headers = null,
  withoutError = false,
  params = null,
  noMessage = false,
) => {
  const config = {
    headers,
    params,
  };

  const body = method.toUpperCase() === "POST" ? checkEmptyFields(rawBody) : rawBody;

  var response = await instance[method](url, body, config)
    .then(res => {
      !noMessage && res.data.message && toast.info(t(res.data.message), {autoClose: 1000});
      return res.data;
    })
    .catch(err => {
      if (err.response?.status === 401) {
        toastInstance.showError(t(err.response.data.message), {autoClose: 1000}, err.response?.status);
      } else if (!withoutError) toast.error(t(err.response.data.message));
      throw new Error(err.response.data.message);
      // return err;
    });
  return response;
};

export const fetchList = async (url, method = "get", body = null) => {
  var response = await instance[method](url, body)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      if (err.response?.status === 401) {
        toastInstance.showError(t(err.response.data.message), {autoClose: 1000}, err.response?.status);
      }
      throw new Error(err.response.data.message);
    });
  return response;
};

export const deleteReq = async (url, body = null, headers = null) => {
  var response = await instance
    .delete(url, {headers, data: body})
    .then(res => {
      toast.info(res.data.message, {autoClose: 1000});
      return res.data;
    })
    .catch(err => {
      toast.error(err.response.data.message);
      throw new Error(err.response.data.message);
    });
  return response;
};

export const fetchData_ = async (
  url,
  method,
  rawBody = null,
  settings = {data: null, headers: null, params: null, withoutError: false, noMessage: false},
) => {
  const {headers, params, withoutError, noMessage} = settings;
  const config = {
    headers,
    params,
  };

  const body = method.toUpperCase() === "POST" ? checkEmptyFields(rawBody) : rawBody;

  var response = await instance[method](url, body, config)
    .then(res => {
      !noMessage && res.data.message && toast.info(t(res.data.message), {autoClose: 1000});
      return res.data;
    })
    .catch(err => {
      if (err.response?.status === 401) {
        toastInstance.showError(err.response.data.message, {autoClose: 1000}, err.response?.status);
      } else if (!withoutError) toast.error(t(err.response.data.message));
      throw new Error(err.response.data.message);
      // return err;
    });
  return response;
};
