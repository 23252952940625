export const CASHDESK_DIRECTIVE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 25,
  },
]);

export const DIRECTIVE_TYPE_PART_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 15,
  },
  {
    header: "Directive type",
    accessorKey: "directiveType",
    size: 100,
  },
]);

export const PAYER_TYPE_PART_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 10,
  },
  {
    header: "Payer type",
    accessorKey: "payerType",
    size: 50,
    maxSize: 50,
  },
  {
    header: "Payer ",
    accessorKey: "payer",
    size: 50,
    maxSize: 50,
  },
]);

export const RECEIVER_TYPE_PART_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 10,
  },
  {
    header: "Receiver type",
    accessorKey: "receiverType",
    size: 50,
    maxSize: 50,
  },
  {
    header: "Receiver",
    accessorKey: "receiver",
    size: 50,
    maxSize: 50,
  },
]);
