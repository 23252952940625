import {useState} from "react";
import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ModalForm from "../../../Modal/FormModal";
import AccountAssociationAndTransaction from "../modals/AccountAssociationAndTransaction/AccountAssociationAndTransaction";

const BankClientButtonRow = () => {
  const [isModalActive, setModalActive] = useState(false);

  const onAccountAssociationBtnClick = () => {
    setModalActive(true);
  };
  const onToTheClientBalanceBtnClick = () => {};
  const onConversionBtnClick = () => {};
  const onCheckTransactionsBtnClick = () => {};
  const onConductBtnClick = () => {};
  const onCreateDebtBtnClick = () => {};

  return (
    <div className="row w100">
      <FunctionButton name="Account association" onClick={onAccountAssociationBtnClick} autoWidth />
      <FunctionButton name="To the Client`s balance" onClick={onToTheClientBalanceBtnClick} autoWidth />
      <FunctionButton name="Conversion" onClick={onConversionBtnClick} autoWidth className="noFunctional" />
      <FunctionButton
        name="Check transactions"
        onClick={onCheckTransactionsBtnClick}
        autoWidth
        className="noFunctional"
      />
      <FunctionButton name="Conduct" onClick={onConductBtnClick} autoWidth className="noFunctional" />
      <FunctionButton name="Create debt" onClick={onCreateDebtBtnClick} autoWidth className="noFunctional" />
      <ModalForm
        className="largeModal"
        label={t("Account association")}
        active={isModalActive}
        setModalActive={setModalActive}
        closable
        Component={AccountAssociationAndTransaction}
      />
    </div>
  );
};

export default BankClientButtonRow;
