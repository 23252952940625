import PropTypes from "prop-types";

import CloseBtnIcon from "../../images/icons/closeBtnIcon.png";

import "../../Components/Modal/modal.scss";

const CloseBtn = ({onClick, style = null, width = "7px", height = "7px"}) => {
  return (
    <img
      className="modal_close_btn"
      src={CloseBtnIcon}
      onClick={onClick}
      alt="Close modal"
      style={{...style}}
      width={width}
      height={height}
    />
  );
};

CloseBtn.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

export default CloseBtn;
