import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {dateFormat} from "../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../utilities/formatAccountNumber";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../utilities/hooks/toaster";
import {getSuccess} from "../../../../utilities/toasts";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {
  addNewDirective,
  addNewFinOrder,
  cancelDirective,
  clearCreatedDirective,
  fetchDirective,
  fetchFinOrder,
  selectAllStatuses,
  selectCreatedDirective,
  selectFinOrder,
  selectReadyToCreateDirectiveCO,
  setCreatedDirective,
  setFinOrder,
  updateDirective,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import ProjectDocuments from "../../ProjectSecondVersion/Documents/ProjectDocuments";
import OrderDirectiveView from "../OrderDirectiveView/OrderDirectiveView";
import {ORDER_REGISTER_TABLE_COLUMNS} from "../OrderRegister/constants";

const useMainDirective = directiveId => {
  const {
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {changeCurrentTabName, addNewTabCustom, tabs, setTabs, currentTabIndex, setCurrentTabIndex} = useTabsContext();

  const {values, setValues, setFieldValue} = useFormikContext();

  const {fulfilBy, finOrderTypeId, operationTypeId, areAllFieldsFilledIn} = values;

  const createdDirective = useSelector(selectCreatedDirective);
  const isDirectiveCreated = createdDirective?.directiveId;
  const hasDirectivePayment = Number.parseFloat(createdDirective?.sumFact) > 0;

  const statuses = useSelector(selectAllStatuses);
  const co = useSelector(selectReadyToCreateDirectiveCO) || createdDirective?.co;
  const coId = co?.coId;
  const currentOrder = useSelector(selectFinOrder);

  const [fileArray, setFileArrayUnsaved] = useState([]);
  const setFileArray = useCallback(setFileArrayUnsaved, []);

  const contractTableDataRow = useMemo(() => co?.project.contracts, [coId]);
  const annexTableDataRow = useMemo(() => co?.annexes, [coId]);
  const einfoTableDataRow = useMemo(() => co?.project.einfo, [coId]);

  const ordersTableColumns = useColumns(ORDER_REGISTER_TABLE_COLUMNS);

  useEffect(() => {
    dispatch(setFinOrder(null));
    // dispatch(fetchAllFinStatuses());
    if (directiveId) {
      dispatch(fetchDirective({directiveId})).then(res => {
        dispatch(setCreatedDirective(res.payload));
        changeCurrentTabName(`${t("Directive №")} ${res.payload.directiveNumber}`);
      });
    }
  }, []);

  useEffect(() => {
    if (co?.coId) {
      const {project, account, coNumber, sum} = co;
      const {butget, client} = project;

      setValues(prev => ({
        ...prev,
        areAllFieldsFilledIn: true,
        fulfilBy: new Date().toISOString(),
        directiveType: "",
        coNumber,
        coBalance: account?.balance || "0",
        clientBalance: client?.account?.balance,
        clientProject: butget,
        coSum: sum,

        fact: (sum && (account?.balance / sum) * 100) || "0",
      }));
    }
  }, [co?.coId]);

  useEffect(() => {
    if (createdDirective && (createdDirective?.directiveId === directiveId || directiveId === null)) {
      const directive = createdDirective;

      const {status, orders, recipient, co, sumFact, sumPlan, payer, outsideParty, currencyId} = directive;
      const {project, account, coNumber, sum} = directive.co;
      const {butget} = project;

      const loanTerms = orders ? orders[0]?.loanTerms : {};
      let newDirective = {
        status: language === "en" ? status?.nameEng : status?.nameUkr,

        payerCoBalanceComposed: payer?.balance,
        payerClientNumberComposed: payer?.clientNumber,
        payerClientBalanceComposed: payer?.balance,
        payerCreditForCoComposed: payer?.balance,

        recipientClientBalanceComposed: recipient?.balance,
        recipientCoBalanceComposed: recipient?.balance,
        recipientClientNumberComposed: recipient?.clientNumber,
        recipientCreditForCoComposed: recipient?.balance,

        creditTermComposed: currentOrder?.loanTerms?.termUpTo || loanTerms?.termUpTo,
        perAnnumComposed: currentOrder?.loanTerms?.annualInterestRate || loanTerms?.annualInterestRate,
        repaymentTermIdComposed: currentOrder?.loanTerms?.repaymentTermsId || loanTerms?.repaymentTermsId,
        creditSumComposed: sumPlan ?? "",

        payerNameComposed: outsideParty?.name,
        edrpouoComposed: outsideParty?.edrpouCode,
        payerAccountComposed: outsideParty?.currentAccount,

        sumComposed: currentOrder?.sum || sumPlan || "",
        currencyIdComposed: currentOrder?.currencyId || currencyId || "",
        finOrderTypeId: currentOrder?.type?.typeId ?? "",

        coSumComposed: co?.sum,
        fSumComposed: sumFact ?? "0",
        coSumAdditionalComposed: co?.prepayment ?? "0",

        apSumComposed: sumPlan ?? "0",
        advancePercentageComposed: +co?.sum ? ((sumPlan / co?.sum) * 100).toFixed(2) : 0,

        afSumComposed: sumFact ?? "0",

        fulfilBy: directive?.fulfilBy || new Date().toISOString(),
        directiveType: "",
        coNumber,
        coBalance: account?.balance || "0",
        clientBalance: project?.client?.account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: +sum && +sumPlan !== +sum ? (+sumPlan / +sum) * 100 : "0",
        nSum: sumPlan,

        fact: (+sum && (account?.balance / sum) * 100) || "0",

        operationTypeId: directive?.operationTypeId,
        executorNote: directive?.note,
      };

      setValues(prev => ({...prev, ...newDirective}));
    }
  }, [createdDirective, currentOrder]);

  const ordersTableData =
    createdDirective?.orders?.map(({orderId, orderNumber, createdAt, sum, transactions}, index) => ({
      id: orderId,
      number: ++index,
      dateTo: dateFormat(fulfilBy) ?? "-",
      factDate: dateFormat(createdAt) ?? "-",
      directiveNumber: createdDirective?.directiveNumber,
      orderNumber,
      dt: formatAccountNumber(transactions?.[0]?.debitAccount?.accountNumber) ?? "-",
      kt: formatAccountNumber(transactions?.[0]?.creditAccount?.accountNumber) ?? "-",
      sumF: sum ?? "-",
      paymentPurpose: transactions?.[0]?.paymentPurpose ?? "-",
    })) ?? [];

  const onOrderSelect = id => {
    if (id) dispatch(fetchFinOrder(id));
    else {
      setFieldValue("finOrderTypeId", "");
      dispatch(setFinOrder(null));
    }
  };

  const onRowDoubleClick = order => {
    if (!order) return;
    addNewTabCustom({
      TabComponent: OrderDirectiveView,
      title: `${t("Order number ")} ${order.orderNumber}`,
      tabIdName: `Directive №${createdDirective.directiveId} order№${order.orderNumber}`,
      orderId: order.id,
    });
  };

  const closeDirectiveTab = () => {
    setTabs(prev => {
      return prev.filter(
        (tab, index) =>
          index !== currentTabIndex && !tab.tabIdName?.includes(`Directive №${createdDirective?.directiveId}` + " "),
      );
    });
    setCurrentTabIndex(0);
    dispatch(clearCreatedDirective());
  };

  function onOkBtnClick() {
    if (!fulfilBy || !finOrderTypeId || !operationTypeId || !coId) return;

    dispatch(addNewDirective({coId, operationTypeId, typeId: finOrderTypeId, fulfilBy})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        // changeCurrentTabName(`${t("Directive №")} ${res.payload.directive.directiveNumber}`);
        const newDirective = res.payload.directive;
        setTabs(prev =>
          prev?.map(tab => {
            if (tab?.index === currentTabIndex)
              return {
                ...tab,
                title: `${t("Directive №")} ${newDirective.directiveNumber}`,
                tabIdName: `Directive №${newDirective.directiveId}`,
                directiveId: newDirective.directiveId,
              };
            return tab;
          }),
        );
      }
    });
  }

  function onForImplementationBtnClick() {
    if (!isDirectiveCreated) {
      console.error("No created directive");
      return;
    }

    const {
      executorNote,
      edrpouoComposed,
      payerNameComposed,
      payerAccountComposed,
      sumComposed,
      currencyIdComposed,
      // repaymentTermIdComposed,
      // creditTermComposed,
      // perAnnumComposed,
      creditSumComposed,
    } = values;

    if (sumComposed < 0 || creditSumComposed < 0) {
      toaster.error("Sum can`t be a negative number");
      return;
    }

    dispatch(
      updateDirective({
        directiveId: createdDirective?.directiveId,
        body: {
          ...(sumComposed || creditSumComposed ? {sumPlan: sumComposed?.trim() ? sumComposed : creditSumComposed} : {}),
          statusId: 2,
          ...(executorNote ? {note: executorNote} : {}),
          ...(currencyIdComposed ? {currencyId: currencyIdComposed} : {}),
          ...(payerNameComposed || edrpouoComposed || payerAccountComposed
            ? {
                outsideParty: {
                  partyId: createdDirective?.outsideParty?.partyId,
                  ...(payerNameComposed ? {name: payerNameComposed} : {}),
                  ...(edrpouoComposed ? {edrpouCode: edrpouoComposed} : {}),
                  ...(payerAccountComposed ? {currentAccount: payerAccountComposed} : {}),
                },
              }
            : {}),
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        getSuccess("Created directive has been sent for execution");
        closeDirectiveTab();
      }
    });
  }

  const onCancelBtnClick = () => {
    swalCustom.confirm({
      confirmFunc: () => {
        closeDirectiveTab();
      },
    });
  };

  function onCancelDirectiveBtnClick() {
    if (!createdDirective || hasDirectivePayment) return;

    swalCustom.confirm({
      confirmFunc: () => {
        // const tabOrdersIndex = tabs.findIndex(
        //   tab => tab.tabIdName === `Directive №${createdDirective?.directiveId} orders`,
        // );

        // closeTabs({indexes: [currentTabIndex, tabOrdersIndex], currentTabIndex});

        dispatch(cancelDirective(createdDirective?.directiveId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            closeDirectiveTab();
            toaster.success("Directive canceled");
            dispatch(clearCreatedDirective());
          }
        });
      },
    });
  }

  function onTakeIntoWorkBtnClick() {
    if (!createdDirective || !values.finOrderTypeId) return;

    dispatch(addNewFinOrder({directiveId: createdDirective?.directiveId, body: {typeId: values.finOrderTypeId}})).then(
      resp => {
        if (resp.meta.requestStatus === "fulfilled")
          dispatch(
            fetchFinOrder(resp.payload.directive.orders[resp.payload?.directive.orders.length - 1].orderId),
          ).then(resp => {
            addNewTabCustom({
              TabComponent: OrderDirectiveView,
              title: `${t("Order number ")} ${resp.payload.orderNumber}`,
              tabIdName: `Directive №${createdDirective.directiveId} order№${resp.payload.orderNumber}`,
              orderId: resp.payload.orderId,
            });
          });
      },
    );
  }

  const onDocumentsOpen = () => {
    const title = t("project docs label", {projectNumber: createdDirective?.co?.project?.projectNumber});
    const isExcist = tabs?.find(tab => tab?.title === title);
    if (isExcist) {
      setCurrentTabIndex(isExcist?.index);
      return;
    }
    const cof = {
      ...createdDirective?.co?.cof,
      coManager: createdDirective?.co?.coManager,
      cofSum: createdDirective?.co?.sum,
    };
    addNewTabCustom({
      TabComponent: ProjectDocuments,
      title,
      tabIdName: `Directive №${createdDirective?.directiveId} documents`,
      controlled: true,
      projectId: createdDirective?.co?.projectId,
      cof: cof,
    });
  };

  return {
    fileArray,
    setFileArray,
    onForImplementationBtnClick,
    onCancelBtnClick,
    onOkBtnClick,
    contractTableDataRow,
    annexTableDataRow,
    einfoTableDataRow,
    isDirectiveCreated,
    areAllFieldsFilledIn,
    onCancelDirectiveBtnClick,
    hasDirectivePayment,
    onTakeIntoWorkBtnClick,
    ordersTableColumns,
    ordersTableData,
    currentOrderId: currentOrder?.orderId || null,
    onOrderSelect,
    onRowDoubleClick,
    createdDirective,
    onDocumentsOpen,
  };
};

export default useMainDirective;
