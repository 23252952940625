import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {flexRender, getCoreRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import {debounce} from "lodash";
import PropTypes from "prop-types";

import ArrowDown from "../../images/icons/arrowDown.png";
import ArrowUp from "../../images/icons/arrowUp.png";
import {KEYBOARD_CODES} from "../../utilities/keyboardCodes";
import Spinner from "../spinner/Spinner";

import {getSuccess} from "./../../utilities/toasts";
import CopyCell from "./reactTable/CopyCell";
import IndeterminateCheckbox from "./reactTable/IndeterminateCheckbox";
import ClickableTr from "./ClickableTr";

const ReactTable = ({
  defaultData = [],
  columns,
  onSelect = () => {},
  current,
  className = "",
  onRowDoubleClick = () => {},
  tableClassName = "",
  loading = false,
  selectable = true, /// чи можна обирати один рядок
  setMultiSelect = () => {}, //// засетити масив id обраних чекбоксів
  defaultMultiSelection, //// масив id обраних чекбоксів
  enableMultiSelection = false, /// мультиселект (чекбокси)
  disabledMultiSelection = false,
  disabledRowIds = [], // массив ID строк, которые нужно задизейблить
  lineThroughRowIds = [],
  disabledRowClassName = "react-table-disabled-td",
  enableColumnResizing = false,
  paginatable = false,
  onPaginate = () => {},
  scrollTop = false,
  handleContextMenu = null,
  selectOnRightBtn = false,
  ...props
}) => {
  const [tablefullWidth, setTableFullWidth] = useState(null);

  const data = defaultData ?? [];

  const SORTING_STATES = Object.freeze({ASC: "asc", DESC: "desc"});
  const [sorting, setSorting] = useState([]);

  const [rowSelection, setRowSelection] = useState({});

  //*** Table Row Selection on Tab ***
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [copyCell, setCopyCell] = useState(null);

  const debouncedPagination = debounce(onPaginate, 1000);

  const tableContainerRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (tableContainerRef.current) {
        setTableFullWidth(tableContainerRef.current.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    if (scrollTop) tableContainerRef.current.scrollTop = 0;
  }, [scrollTop]);

  const handleScroll = () => {
    const {scrollTop, clientHeight, scrollHeight} = tableContainerRef.current;
    if (scrollTop + clientHeight > scrollHeight - 5) {
      debouncedPagination();
    }
  };

  const onTabKeyPress = () => {
    let index = selectedIndex !== null && selectedIndex < data.length - 1 ? selectedIndex + 1 : 0;
    let isNotDisabled = false;
    while (!isNotDisabled) {
      if (disabledRowIds?.includes(data[index]?.id)) index++;
      else isNotDisabled = true;
    }
    setSelectedIndex(index);
    onSelect(data[index]?.id);
  };

  function onTableKeyDown(e) {
    const isModifierKey = e.ctrlKey || e.altKey || e.metaKey || e.shiftKey;

    // Не блокируем события для сочетаний клавиш
    if (isModifierKey) {
      return;
    }

    switch (e.code) {
      case KEYBOARD_CODES.TAB:
        e.preventDefault();
        onTabKeyPress();
        break;
    }
  }
  //*** //Table Row Selection on Tab ***

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: enableMultiSelection,
    state: {
      rowSelection,
      sorting,
    },
    defaultColumn: {
      minSize: 40,
      maxSize: 800,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    sortingFns: {
      sortDate: (rowA, rowB, columnId) => {
        const parseDate = dateStr => {
          const [day, month, year] = dateStr.split(".").map(Number);
          return new Date(year, month - 1, day); // месяц в JS начинается с 0
        };

        const dateA = parseDate(rowA.original[columnId]);
        const dateB = parseDate(rowB.original[columnId]);

        return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
      },
      sortNumber: (rowA, rowB, columnId) => {
        const numA = parseFloat(rowA.original[columnId]);
        const numB = parseFloat(rowB.original[columnId]);

        return numA - numB;
      },
    },
    enableColumnResizing: enableColumnResizing,
    columnResizeMode: "onChange",
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    //!Important for redux state management on checkbox change
    const selectedIds = Object.keys(rowSelection).map(id => {
      return table.getRow(id).original.id;
    });

    if (setMultiSelect && enableMultiSelection) {
      setMultiSelect(selectedIds);
    }
  }, [rowSelection]);

  useEffect(() => {
    // if (defaultSelection?.lenght === 0) setRowSelection({});
    if (enableMultiSelection) {
      if (defaultMultiSelection?.length > 0 && data.length > 0) {
        var selection = {};
        data.forEach(row => {
          if (defaultMultiSelection.includes(row.id)) {
            selection[row.number - 1] = true;
          }
        });
        setRowSelection(selection);
      } else if (Object.keys(rowSelection).length) {
        setRowSelection({});
      }
    }
  }, [data.length]); //defaultMultiSelection?.length

  const handleRowClick = row => {
    if (disabledRowIds?.includes(row?.original?.id)) {
      return;
    }
    if (current === row.original.id && !copyCell) {
      onSelect(null);
      setSelectedIndex(null);
    } else {
      onSelect(row.original.id);
      setSelectedIndex(row.index);
    }
  };

  const handleRowDoubleClick = row => {
    onRowDoubleClick(row.original);
  };

  function measureTextWidth(text) {
    var tempSpan = document.createElement("span");
    tempSpan.style.visibility = "hidden";
    tempSpan.textContent = text;

    document.body.appendChild(tempSpan);
    var width = tempSpan.getBoundingClientRect().width;
    document.body.removeChild(tempSpan);

    return width + 10;
  }

  const resizableHeaderStyles = {
    padding: "0 0.5em",
    position: "relative",
    // wordBreak: "break-word",
    // whiteSpace: "normal",
  };
  const getResizeHeaderWidth = id => `calc(var(--header-${id}-size) * 1px)`;
  const getHeaderWidth = header => (header?.getSize() === 150 ? "max-content" : header?.getSize());
  const getCellWidth = size => (size === 150 ? "max-content" : size);

  const tHeadRender = useCallback(() => {
    return table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}>
        {enableMultiSelection && (
          <th
            key={"i0"}
            style={{
              position: "relative",
              width: 25,
            }}
          >
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                disabled: disabledMultiSelection,
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </th>
        )}
        {headerGroup.headers.map(header => (
          <th
            className={`${header.column.columnDef.isSortable ? "sortableHeader" : ""}`}
            key={header.id}
            style={{
              padding: "0 0.5em",
              ...(header.column.getCanResize()
                ? resizableHeaderStyles
                : {maxWidth: header.column.columnDef?.maxSize ? header.column.columnDef?.maxSize : "max-content"}),
              width: header.column.getCanResize() ? getResizeHeaderWidth(header?.id) : getHeaderWidth(header),
            }}
            title={flexRender(header.column.columnDef.header, header.getContext())}
            onClick={header.column.columnDef.isSortable ? header.column.getToggleSortingHandler() : () => {}}
          >
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            {header.column.getIsSorted() === SORTING_STATES.ASC && (
              <img src={ArrowUp} width={10} height={10} style={{marginLeft: "5px"}} />
            )}
            {header.column.getIsSorted() === SORTING_STATES.DESC && (
              <img src={ArrowDown} width={10} height={10} style={{marginLeft: "5px"}} />
            )}
            {header.column.getCanResize() && (
              <div
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
                className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
              />
            )}
          </th>
        ))}
      </tr>
    ));
  }, []);

  const tCGRender = useCallback(() => {
    const cols = table.getHeaderGroups()[0].headers.map(header => (
      <col
        key={header.id}
        style={{
          width: header.getSize() === 150 ? measureTextWidth(header.getContext()) : header.getSize(),
          maxWidth: "max-content",
        }}
      />
    ));
    if (enableMultiSelection) {
      cols.unshift(<col key={"1e"} style={{width: 25}} />);
    }
    return cols;
  }, []);

  ///////////////////////////////////////////////////////////////////////////////////////
  // мультилайновые ячейки [...]

  const expandedCellRefs = [];

  const setref = ref => {
    expandedCellRefs.push(ref);
  };

  const onExpandingCellClick = id => {
    if (id !== null && expandedCellRefs[id].classList.contains("expanded")) {
      expandedCellRefs[id].classList.remove("expanded");
      return;
    }
    expandedCellRefs.forEach(item => item.classList.remove("expanded"));
    if (id !== null) {
      expandedCellRefs[id].classList.add("expanded");
    }
  };

  //////////////////////////////// cell copy expanding

  // const tableRef = useRef();

  const handleCellClick = (e, text, rowId) => {
    if (e.target.tagName !== "SPAN") {
      return;
    }
    if (current !== rowId && selectable) {
      return;
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const tableRect = tableRef.current.getBoundingClientRect();

    setCopyCell({
      text,
      top: rect.top - tableRect.top,
      left: rect.left - tableRect.left,
      width: rect.width,
      height: rect.height,
    });
  };

  const handleClose = () => {
    setCopyCell(null);
  };

  const handleCopy = () => {
    if (copyCell?.text) {
      navigator.clipboard.writeText(copyCell.text).then(() => {
        getSuccess("Text copied to clipboard!");
      });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////

  const columnSizeVars = useCallback(() => {
    const headers = table.getFlatHeaders();
    let colSizes = {};
    const factor =
      tablefullWidth && tablefullWidth > table.getTotalSize()
        ? (tablefullWidth - table.getTotalSize()) / columns?.length
        : 0; //// если ширина контейнера таблицы больше чем получаемая сумма ширин столбцов таблицы то добавляем к каждой по коефициенту дабы добиться ста процентной ширины таблицы

    for (let i = 0; i < headers.length; i++) {
      const header = headers?.[i];
      colSizes[`--header-${header.id}-size`] = header?.getSize() + factor;
      colSizes[`--col-${header.column.id}-size`] = header?.column?.getSize() + factor;
    }
    return colSizes;
  }, [table.getState().columnSizingInfo, table.getState().columnSizing, tablefullWidth]);

  const tbodyRender = () => {
    var expandedCelsCounter = 0;
    return table.getRowModel().rows.map(row => {
      return (
        <ClickableTr
          key={row.original.id}
          onClick={() => {
            if (selectable) {
              handleRowClick(row);
            }
          }}
          onDoubleClick={() => {
            if (onRowDoubleClick) handleRowDoubleClick(row);
          }}
          onContextMenu={e => {
            if (selectable && selectOnRightBtn && current !== row.original.id) {
              handleRowClick(row);
            }

            if (handleContextMenu) {
              handleContextMenu(e, row.original.id);
            }
          }}
          className={`reactTableRow ${disabledRowIds?.includes(row?.original?.id) ? disabledRowClassName : ""} ${
            lineThroughRowIds?.includes(row?.original.id) ? "react-table-linethrough " : ""
          }`}
        >
          {enableMultiSelection && (
            <td
              key={"j0"}
              style={{
                position: "relative",
                width: 20,
                backgroundColor: selectable && row?.original?.id === current ? "#567db8" : "transparent",
                textAlign: "center",
                cursor: selectable ? "pointer" : "default",
              }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: disabledMultiSelection || disabledRowIds?.includes(row?.original?.id),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </td>
          )}
          {row.getVisibleCells().map(cell => {
            const value = cell.getValue();
            const cellvalue = typeof value === "object" ? value?.text : value;
            var expandedCelsCounter_ = expandedCelsCounter;
            if (value?.expand) {
              expandedCelsCounter++;
            }
            return (
              <td
                onClick={e => handleCellClick(e, cell.getValue(), row.original.id)}
                key={cell.id}
                title={cellvalue}
                ref={value?.expand ? setref : null}
                style={{
                  ...(cell.column.getCanResize()
                    ? {width: `var(--col-${cell.column.id}-size) * 1px)`}
                    : {maxWidth: getCellWidth(cell.column.columnDef?.size)}),
                  backgroundColor: selectable && row?.original?.id === current ? "#567db8" : "inherit",
                  color: selectable && row?.original?.id === current ? "white" : "inherit",
                  cursor:
                    selectable && disabledRowIds?.includes(row?.original?.id)
                      ? "not-allowed"
                      : selectable
                      ? "pointer"
                      : "default",
                  position: "relative",
                  ...(cell?.column?.columnDef?.alignStart ? {textAlign: "start"} : {}),
                  ...(cell?.column?.columnDef?.textAlign ? {textAlign: cell?.column?.columnDef?.textAlign} : {}),
                }}
              >
                {value?.expand && (
                  <span
                    className="expand_sign"
                    onClick={e => {
                      e.stopPropagation();
                      onExpandingCellClick(expandedCelsCounter_);
                    }}
                  >
                    {`[...]`}
                  </span>
                )}

                {value?.expand ? (
                  <span className="expand_cell_text">
                    <pre>{cellvalue}</pre>
                  </span>
                ) : (
                  <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                )}
              </td>
            );
          })}
        </ClickableTr>
      );
    });
  };

  const memoizedBody = useMemo(() => tbodyRender(), [defaultData, current, disabledRowIds, defaultMultiSelection]);
  useEffect(() => {
    if (!paginatable) return;
    if (defaultData?.length === 0) return;
    const {clientHeight: containerHeight} = tableContainerRef.current;
    const {clientHeight: tableHeight} = tableRef.current;
    if (containerHeight > tableHeight) {
      debouncedPagination();
    }
  }, [defaultData?.length]);
  return (
    <div
      className={`project_list ${className}`}
      style={props.style}
      onKeyDown={onTableKeyDown}
      tabIndex="0"
      ref={tableContainerRef}
      onScroll={paginatable ? handleScroll : () => {}}
    >
      <table
        ref={tableRef}
        style={enableColumnResizing ? {...columnSizeVars(), width: table.getTotalSize()} : {}}
        className={`project_table autoLayout ${tableClassName} ${enableColumnResizing && "fixedLayout"}`}
      >
        {!enableColumnResizing && <colgroup>{tCGRender()}</colgroup>}
        <thead>{tHeadRender()}</thead>
        <tbody>{memoizedBody}</tbody>
      </table>
      {loading && <Spinner />}
      {copyCell && <CopyCell copyCell={copyCell} handleClose={handleClose} handleCopy={handleCopy} />}
    </div>
  );
};

ReactTable.propTypes = {
  defaultData: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  className: PropTypes.string,
  onRowDoubleClick: PropTypes.func,
  props: PropTypes.array,
};

export default ReactTable;
