import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";

export const PROJECT_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 25,
  },

  {
    header: "Contract witn num",
    accessorKey: "contractNumber",
    size: 60,
    maxSize: 60,
  },

  {
    header: "Project To",
    accessorKey: "dateTo",
    size: 50,
    maxSize: 50,
  },
  {
    header: "Briefly about",
    accessorKey: "brieflyAbout",

    size: 300,
  },

  {
    header: "Project Sum",
    accessorKey: "projectSum",
    maxSize: 60,
    isSortable: true,
  },
]);

export const CO_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 25,
  },

  {
    header: "CO №",
    accessorKey: "coNumber",
    size: 60,
    maxSize: 60,
  },
  {
    header: "CO Annex №",
    accessorKey: "annexNumber",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Take To",
    accessorKey: "takeTo",
    size: 50,
    maxSize: 50,
  },
  {
    header: "Briefly about",
    accessorKey: "brieflyAbout",
    size: 300,
  },

  {
    header: "CO Sum",
    accessorKey: "coSum",
    maxSize: 60,
    isSortable: true,
  },
]);

export const SCREENSHPTS_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 15,
  },

  {
    header: "ScreenShot №",
    accessorKey: "screenShotNumber",
    size: 55,
    maxSize: 55,
  },
  {
    header: "Date S.",
    accessorKey: "creationDate",
    size: 45,
    maxSize: 45,
  },
  {
    header: "Briefly about",
    accessorKey: "brieflyAbout",
    minSize: 300,
  },
]);

export const PROJECT_DOC_TYPES = {
  contract: "CONTRACT",
  annex: "ANNEX",
  screenshot: "SCREENSHOT",
  cof: "COF",
};

export const COF_DOCUMENTS_COLUMNS = Object.freeze([
  {
    header: "Status",
    accessorKey: "statusCircle",
    maxSize: 70,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  // {
  //   header: "№пп",
  //   accessorKey: "number",
  //   maxSize: 50,
  // },

  {
    header: "CO(f) №",
    accessorKey: "coNumber",
    // size: 200,
  },
  {
    header: "Manager",
    accessorKey: "projectManager",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    // size: 60,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    alignStart: true,
    size: 400,
  },
  {
    header: "COf Sum",
    accessorKey: "cofSum",
    isSortable: true,
    // maxSize: 100,
  },
]);
