import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import moment from "moment";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import {fetchData} from "../../../../../hook/axios.hook";
import saveImg from "../../../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../../../utilities/dateFormat";
import {getInfo} from "../../../../../utilities/toasts";
import {useFiltersContext} from "../../../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, MoneyInput, TextInput} from "../../../../Inputs";
import PdfFilePreview from "../../../../Inputs/fileSelect/preview/PdfPreview";
import {Select} from "../../../../Inputs/SelectField";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addProjectContract,
  addProjectContractFile,
  delProjectContract,
  delProjectContractFile,
  fetchProjectContracts,
  getCurrentProject,
  getDocsLoading,
  getProjectContracts,
  getProjectDocumentLoading,
  updateProjectContract,
} from "../../../ProjectSlice";
import {PROJECT_DOC_TYPES, PROJECT_DOCUMENTS_COLUMNS} from "../constants";

import ShowDocBtn from "./ShowDocBtn";

const ProjectDocForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [currentContract, setCurrentContract] = useState(null);
  const [files, setFiles] = useState([]);

  const contracts = useSelector(getProjectContracts),
    docsLoading = useSelector(getDocsLoading),
    currentProject = useSelector(getCurrentProject),
    listsLoading = useSelector(getProjectDocumentLoading);

  const columns = useColumns(PROJECT_DOCUMENTS_COLUMNS);
  const {filters} = useFiltersContext();
  const {values, setValues, setFieldValue} = useFormikContext();
  const {contractId} = values;

  const fetchList = () => {
    let docFilters = {projectId: currentProject.projectId, ...filters};
    dispatch(fetchProjectContracts(docFilters));
  };

  useEffect(() => {
    if (currentProject) fetchList();
  }, [currentProject, filters]);

  useEffect(() => {
    if (!contractId) {
      setCurrentContract(null);
      setValues(prev => ({
        ...prev,
        projectsum: "",
        projectnote: "",
        projectdeadline: "",
      }));
      setFiles([]);
      return;
    }
    fetchData(`/contracts/get/${contractId}`, "get").then(res => {
      setFiles(res.files);
    });
    let current = contracts.find(contr => contr.contractId === contractId);

    setCurrentContract(current);
    if (current)
      setValues(prev => ({
        ...prev,
        projectsum: current.sum,
        projectnote: current.note,
        projectdeadline: current.deadline,
      }));
  }, [contractId]);

  const contractNumberList =
    contracts?.map(contract => ({
      title: contract.contractNumber,
      value: contract.contractId,
    })) || [];

  const tableData =
    contracts?.map((contract, i) => ({
      number: i + 1,
      contractNumber: contract.contractNumber,
      dateTo: contract?.deadline ? dateFormat(contract?.deadline) : "",
      brieflyAbout: contract?.note || "",
      projectSum: contract?.sum || "",
      id: contract.contractId,
    })) || [];

  const onFileAdding = arrFiles => {
    let formdata = new FormData();
    let file = arrFiles[0];
    let filename = translit(file.name);
    formdata.append("file", file, filename);

    dispatch(addProjectContractFile({contractId: currentContract.contractId, formdata})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFiles(res.payload.files);
      }
    });
  };

  const onFileDel = fileId => {
    dispatch(delProjectContractFile(fileId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        fetchData(`/contracts/get/${contractId}`, "get").then(res => {
          setFiles(res.files);
        });
      }
    });
  };

  const onAddButtonClick = () => {
    if (currentProject) {
      setValues(prev => ({...prev, contractId: "", projectsum: "", projectnote: "", projectdeadline: ""}));

      dispatch(addProjectContract(currentProject.projectId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          Swal.fire({
            title: "",
            text: `${t("Contract added", {contractNumber: res.payload.contract.contractNumber})}`,
            icon: "info",
            confirmButtonText: "OK",
          });
          setFieldValue("contractId", res.payload.contract.contractId);
          fetchList();
        }
      });
    } else {
      getInfo(t("Project at first"));
    }
  };

  const onDocUpdate = () => {
    const {projectnote, projectdeadline, projectsum} = values;
    dispatch(
      updateProjectContract({
        contractId: currentContract.contractId,
        data: {
          note: projectnote,
          sum: projectsum,
          deadline: projectdeadline,
        },
      }),
    );
  };

  const onContractDelete = () => {
    Swal.fire({
      title: t("Sure"),
      text: `${t("Delete document")}?`,
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(answ => {
      if (answ.isConfirmed)
        dispatch(delProjectContract(contractId)).then(() => {
          setFieldValue("contractId", null);
        });
    });
  };

  const saveBtnDisabled = useMemo(
    () =>
      ["deadline", "note", "sum"].reduce(
        (acc, option) => acc && currentContract?.[option] === values?.[`project${option}`],
        true,
      ),
    [values, currentContract],
  );

  const filesUrls = useMemo(() => files?.map(file => file.fileUrl), [files]);

  return (
    <div className="w100 fGrow fc col nowrap">
      <div className="fc nowrap g1 aic autolabel mb w100">
        <div style={{width: "8em"}}>
          <FunctionButton name="Add" style={{width: "8em", marginBottom: "0.5em"}} onClick={onAddButtonClick} />
          <FunctionButton name="Delete" style={{width: "8em"}} disabled={!currentContract} onClick={onContractDelete} />
        </div>
        <div>
          <Select
            label={t("Contract witn num")}
            name="contractId"
            options={contractNumberList}
            inputClassName="ref_input"
            selectOnly={true}
            // question={t("Instructions")}
            generalClassName={"mb0_5"}
          />
          <MoneyInput label={t("Contract Amount")} width="8em" name="projectsum" readOnly={!currentContract} />
        </div>
        <div className="fGrow">
          <TextInput
            label={t("Briefly about")}
            name="projectnote"
            width="80%"
            readOnly={!currentContract}
            generalClassName={"mb0_5"}
          />
          <div className="fc nowrap jcsb">
            <DateInput
              label={t("Project term")}
              name="projectdeadline"
              flatpikrConfig={{
                minDate: moment().format("DD-MM-YYYY"),
              }}
              readOnly={!currentContract}
            />
            <div className="fc nowrap">
              <ImageButton
                src={saveImg}
                alt="save"
                className={"documentSaveBtn"}
                width={1.5}
                height={1.5}
                disabled={!currentContract || saveBtnDisabled}
                onClick={onDocUpdate}
              />
              <PdfFilePreview
                files={files || []}
                delFunc={onFileDel}
                addFunc={onFileAdding}
                disable={!currentContract}
                loading={docsLoading}
              />
              <ShowDocBtn type={PROJECT_DOC_TYPES.contract} files={filesUrls} disabled={!currentContract} />
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        defaultData={tableData}
        columns={columns}
        current={contractId === "" ? null : contractId}
        className={"project_table mb"}
        onSelect={id => {
          setFieldValue("contractId", id);
        }}
        loading={listsLoading}
      />
    </div>
  );
};

export default ProjectDocForm;
