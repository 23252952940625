import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {selectAllCurrency} from "../../../../../FinBlock/FinSettings/FinSettingsSlice";
import {MoneyInput} from "../../../../../Inputs";
import CurrencySelect from "../../../../../Inputs/CurrencySelect/CurrencySelect";

const SumCurrency = ({readOnly = false}) => {
  const {
    values: {sumComposed, currencyIdComposed, areAllFieldsFilledIn},
    setFieldValue,
  } = useFormikContext();
  const [currencyCode, setCurrencyCode] = useState(null);
  // const allCurrencies = useSelector(selectAllCurrency);

  useEffect(() => {
    if (!sumComposed || !currencyIdComposed) {
      setTimeout(() => setFieldValue("areAllFieldsFilledIn", false));
    }
    if (sumComposed && currencyIdComposed && !areAllFieldsFilledIn) {
      setFieldValue("areAllFieldsFilledIn", true);
    }
  }, [sumComposed, currencyIdComposed]);

  // useEffect(() => {
  //   const code = allCurrencies?.find(curr => curr.currencyId === currencyIdComposed)?.shortName;
  //   setCurrencyCode(code || null);
  // }, [currencyIdComposed]);

  return (
    <div className="row w100">
      <MoneyInput
        name="sumComposed"
        label="Sum"
        autolabel
        width="10em"
        required={!readOnly}
        readOnly={readOnly}
        currency={currencyCode}
      />
      <CurrencySelect name="currencyIdComposed" label="Currency" required={!readOnly} readOnly={readOnly} />
    </div>
  );
};

export default SumCurrency;
