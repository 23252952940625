import React from "react";
import {t} from "i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {MoneyInput, TextInput} from "../../../../../Inputs";
import CurrencySelect from "../../../../../Inputs/CurrencySelect/CurrencySelect";

const OrderAssociationPart = ({onCurrencySumSubmitBtnClick = () => {}}) => {
  return (
    <div className="fcCol">
      <div className="row asfe aic" style={{width: "60%", paddingTop: "2.5rem"}}>
        <CurrencySelect name="receiptCurrencyId" label={t("Receipt currency")} />
        <MoneyInput name="receiptSum" label={t("Receipt sum")} />
        <TextInput type="number" name="rate" label={t("rate UAH / EUR")} autolabel />
        <FunctionButton name="Ok" onClick={onCurrencySumSubmitBtnClick} autoWidth />
      </div>
    </div>
  );
};

export default OrderAssociationPart;
