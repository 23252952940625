import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";
import translit from "ua-en-translit";

import {selectAllUsers} from "../../../slices/PersonSlice";
import {getCurrentUser} from "../../../slices/UserSlice";
import {IT_MANAGERS_IDS} from "../../../utilities/constants";
import {dateFormat} from "../../../utilities/dateFormat";
import {swalCustom} from "../../../utilities/hooks/swalCustom";
import {toaster} from "../../../utilities/hooks/toaster";
import makeNameFromPersona, {makeTableNameFromPersona} from "../../../utilities/makeNameFromPersona";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import {
  addNewTicketFile,
  addNewTicketTask,
  clearCurrentTicket,
  deleteTicketFile,
  deleteTicketTask,
  fetchAllTickets,
  fetchAllTicketTypes,
  fetchDevITFilters,
  fetchTicket,
  selectAllDevITTickets,
  selectDevITLoading,
  selectDevITTicket,
  updateTicket,
  updateTicketTask,
} from "../../DevIT/DevITSlice";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {expandedTextArray} from "../../Requests/RequestsInProgress/utils/functions/expandDataForTable";

import {MANAGER_IT_TABLE_COLUMNS, PROJECT_TASKS_LIST_TABLE_COLUMNS} from "./constants";

const useDevTicketsList = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();
  const {initialValues, values, setValues} = useFormikContext();
  const {itNote, itManagerId, planDate, isDone} = values;
  const {filters} = useFiltersContext();

  const allTickets = useSelector(selectAllDevITTickets);
  const currentTicket = useSelector(selectDevITTicket);
  const isCurrentTicketInWork = currentTicket?.status?.statusId === 2;
  const isCurrentTicketClosed = currentTicket?.status?.statusId === 3;
  const loading = useSelector(selectDevITLoading);
  const users = useSelector(selectAllUsers);
  const user = useSelector(getCurrentUser);

  const appealTypes = [];
  const fileArray = currentTicket?.files?.map(({fileUrl}) => fileUrl) ?? [];
  const doneItemsIds = allTickets?.filter(ticket => ticket.status.statusId === 3)?.map(ticket => ticket.ticketId);

  const [currentTicketId, setCurrentTicketId] = useState(null);

  useEffect(() => {
    dispatch(clearCurrentTicket());
  }, []);

  useEffect(() => {
    if (currentTicketId) {
      dispatch(fetchTicket({ticketId: currentTicketId}));
    }
  }, [currentTicketId]);

  useEffect(() => {
    if (currentTicket?.tasks?.length > 0) {
      setCurrentTaskId(currentTicket?.tasks[0]?.taskId);
    } else setCurrentTaskId(null);
  }, [currentTicket]);

  useEffect(() => {
    if (currentTicket?.ticketId) {
      setValues(prev => ({
        ...prev,
        ticketText: currentTicket?.text,
        itNote: currentTicket?.note,
        itManagerId: "",
        planDate: "",
        isDone: false,
      }));
    } else {
      setCurrentTicketId(null);
      setValues(initialValues);
    }
  }, [currentTicket?.ticketId]);

  const onFilterChange = filters => {
    dispatch(clearCurrentTicket());

    if (!filters) {
      dispatch(fetchAllTickets({}));
      return;
    }

    dispatch(fetchAllTickets({params: filters}));
  };

  useEffect(() => {
    dispatch(fetchDevITFilters({params: {}})).then(() => {
      onFilterChange(filters);
    });
  }, []);

  const [currentTaskId, setCurrentTaskId] = useState(null);
  const currentTask = currentTicket?.tasks?.find(({taskId}) => currentTaskId === taskId);

  useEffect(() => {
    if (currentTaskId) {
      setValues(prev => ({
        ...prev,
        itManagerId: currentTask?.userId,
        planDate: currentTask?.deadline,
        isDone: Boolean(currentTask?.finishDate),
      }));
    } else {
      setValues(prev => ({
        ...prev,
        itManagerId: "",
        planDate: "",
        isDone: "",
      }));
    }
  }, [currentTaskId]);

  const ITManagersOptions =
    users
      ?.filter?.(user => IT_MANAGERS_IDS.includes(user.userId))
      ?.map(({userId, persona}) => ({value: userId, title: makeTableNameFromPersona({persona, language})})) ?? [];

  const ticketsTableColumns = useColumns(PROJECT_TASKS_LIST_TABLE_COLUMNS);
  const ticketsTableData =
    allTickets?.map(
      (
        {
          ticketId,
          type: {nameEng: typeNameEng, nameUkr: typeNameUkr},
          block: {nameEng: blockNameEng, nameUkr: blockNameUkr},
          status: {nameEng: statusNameEng, nameUkr: statusNameUkr},
          creator: {persona},
          text,
          note,
          startDate,
          finishDate,
          createdAt,
          tasks,
        },
        index,
      ) => ({
        number: ++index,
        id: ticketId,
        date: dateFormat(createdAt),
        appealType: language === "en" ? typeNameEng : typeNameUkr,
        about: text,
        block: language === "en" ? blockNameEng : blockNameUkr,
        manager: makeTableNameFromPersona({language, persona}),
        status: language === "en" ? statusNameEng : statusNameUkr,
        managerIT: expandedTextArray(
          tasks?.reduce(
            (acc, task) => [...acc, makeTableNameFromPersona({persona: task.performer.persona, language})],
            [],
          ),
        ),
        startDate: dateFormat(startDate) ?? "-",
        planDate: tasks?.[0] ? dateFormat(tasks?.[0]?.deadline) ?? "-" : "-",
        finishDate: dateFormat(finishDate) ?? "-",
        isDone: Boolean(finishDate),
        noteIT: note ?? "-",
      }),
    ) ?? [];

  const onTicketsTableSelect = ticketId => {
    setCurrentTicketId(ticketId);
    setCurrentTaskId(null);

    if (!ticketId) {
      dispatch(clearCurrentTicket());
    }
  };

  const managerITTableColumns = useColumns(MANAGER_IT_TABLE_COLUMNS);
  const managerITTableData =
    currentTicket?.tasks?.map(({taskId, performer: {persona}, finishDate, deadline}, index) => ({
      number: ++index,
      id: taskId,
      managerIT: makeTableNameFromPersona({persona, language}),
      planDate: dateFormat(deadline),
      finishDate: dateFormat(finishDate),
      isDone: finishDate ? t("done") : "",
    })) ?? [];

  const onManagerITTableSelect = taskId => {
    setCurrentTaskId(taskId);
  };

  const getTommorowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  };

  const onTakeIntoWorkBtnClick = () => {
    if (!currentTicketId) return;
    dispatch(updateTicket({ticketId: currentTicketId, body: {statusId: 2}})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        if (currentTicket?.tasks?.length < 1)
          dispatch(
            addNewTicketTask({
              ticketId: currentTicketId,
              body: {userId: user.id, deadline: planDate || getTommorowDate()},
            }),
          ).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              toaster.success("Ticket was taken into work");
              setCurrentTaskId(resp.payload.tasks[0].taskId);
            }
          });
      }
    });
  };

  const onCancelTakeIntoWorkBtnClick = () => {
    if (!currentTicketId) return;
    dispatch(
      deleteTicketTask({
        taskId: currentTaskId,
      }),
    ).then(resp => {
      setCurrentTaskId(null);
      if (resp.meta.requestStatus === "fulfilled") {
        dispatch(updateTicket({ticketId: currentTicketId, body: {statusId: 1}})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("Ticket was back to new status");
          }
        });
      }
    });
  };

  const hasTaskDataChanged =
    (currentTask?.performer?.userId !== itManagerId ||
      currentTask?.deadline !== planDate ||
      Boolean(currentTask?.finishDate) !== isDone) &&
    itManagerId &&
    planDate;
  const hasItNoteChanged = currentTicket?.note !== itNote && itNote;

  const onSaveBtnClick = () => {
    if (hasItNoteChanged) {
      dispatch(updateTicket({ticketId: currentTicketId, body: {note: itNote}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Ticket updated");
        }
      });
    }
    if (currentTaskId) {
      dispatch(updateTicketTask({taskId: currentTaskId, body: {userId: itManagerId, deadline: planDate, isDone}})).then(
        resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            if (!hasItNoteChanged) toaster.success("Ticket updated");
          }
        },
      );
    }
  };

  const onReadyBtnClick = () => {
    if (!currentTicketId) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(updateTicketTask({taskId: currentTaskId, body: {isDone: true}})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            dispatch(updateTicket({ticketId: currentTicketId, body: {statusId: isCurrentTicketClosed ? 2 : 3}})).then(
              resp => {
                if (resp.meta.requestStatus === "fulfilled") {
                  toaster.success(isCurrentTicketClosed ? "Ticket returned to work" : "Ticket is ready");
                  dispatch(fetchTicket({ticketId: currentTicketId}));
                  dispatch(fetchAllTickets({params: filters}));
                }
              },
            );
          }
        });
      },
    });
  };

  const files = currentTicket?.files ?? [];

  const onAddTicketFile = arrFiles => {
    if (!currentTicketId) return;
    const formdata = new FormData();
    let file = arrFiles[0];
    let filename = translit(file.name);
    formdata.append("file", file, filename);

    if (formdata.has("file")) {
      dispatch(addNewTicketFile({ticketId: currentTicketId, body: formdata})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Ticket file added");
        }
      });
    }
  };

  const onDeleteTicketFile = fileId => {
    if (!currentTicketId) return;
    dispatch(deleteTicketFile({fileId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Ticket file deleted");
      }
    });
  };

  return {
    appealTypes,
    fileArray,
    ticketsTableColumns,
    ticketsTableData,
    onTakeIntoWorkBtnClick,
    onSaveBtnClick,
    onReadyBtnClick,
    ITManagersOptions,
    managerITTableColumns,
    managerITTableData,
    onTicketsTableSelect,
    currentTicketId,
    loading,
    isCurrentTicketInWork,
    isCurrentTicketClosed,
    doneItemsIds,
    onManagerITTableSelect,
    currentTaskId,
    files,
    onAddTicketFile,
    onDeleteTicketFile,
    hasItNoteChanged,
    hasTaskDataChanged,
    onCancelTakeIntoWorkBtnClick,
    currentTicket,
  };
};

export default useDevTicketsList;
