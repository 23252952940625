import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {clearCurrentCof} from "../kp-f/KpFinSlice";

const KpWrapper = ({children, props}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearCurrentCof());
    };
  }, []);
  return <>{children}</>;
};

export default KpWrapper;
