import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {Select, TextInput} from "../../../../../Inputs";

const SellerDetailsPart = ({onDeleteSellerDetailBtnClick, onAddSellerDetailBtnClick}) => {
  const {t} = useTranslation();

  const statusOptions = [];
  const disabled = false;
  const labelStyles = {textAlign: "end", width: "15%"};
  const placeholder = "start entering data you remember";

  return (
    <div className="fcCol gap2x w100 aistr">
      <div className="row aibl w100">
        <div>{t("Seller Details(i) :")}</div>
        <Select options={statusOptions} label="Status" name="statusId" autolabel />
      </div>
      <div className="fcCol aistr gap">
        <TextInput label="Title" name="title" disabled={disabled} labelStyles={labelStyles} placeholder={placeholder} />
        <TextInput label="Index" name="index" disabled={disabled} labelStyles={labelStyles} placeholder={placeholder} />
        <TextInput
          label="Address"
          name="address"
          disabled={disabled}
          labelStyles={labelStyles}
          placeholder={placeholder}
        />
        <TextInput
          label="EDRPOUO"
          name="edrpouo"
          disabled={disabled}
          labelStyles={labelStyles}
          placeholder={placeholder}
        />
        <div className="border pb5">
          <TextInput
            label="Bank 1, MFI"
            name="mfiFirstBank"
            disabled={disabled}
            labelStyles={labelStyles}
            placeholder={placeholder}
          />
          <TextInput
            label="p/acc"
            name="pAccFirstBank"
            disabled={disabled}
            labelStyles={labelStyles}
            placeholder={placeholder}
          />
        </div>
        <div className="border pb5">
          <TextInput
            label="Bank 2, MFI"
            name="mfiSecondBank"
            disabled={disabled}
            labelStyles={labelStyles}
            placeholder={placeholder}
          />
          <TextInput
            label="p/acc"
            name="pAccSecondBank"
            disabled={disabled}
            labelStyles={labelStyles}
            placeholder={placeholder}
          />
        </div>
        <TextInput label="VAT" name="vat" disabled={disabled} labelStyles={labelStyles} placeholder={placeholder} />
        <TextInput label="phone" name="phone" disabled={disabled} labelStyles={labelStyles} placeholder={placeholder} />
        <TextInput label="Chief" name="chief" disabled={disabled} labelStyles={labelStyles} placeholder={placeholder} />
        <TextInput label="Note" name="note" disabled={disabled} labelStyles={labelStyles} placeholder={placeholder} />
      </div>
      <div className="row gap3x jcfe">
        <FunctionButton name="Delete" onClick={onDeleteSellerDetailBtnClick} autoWidth />
        <FunctionButton name="Add" onClick={onAddSellerDetailBtnClick} autoWidth />
      </div>
    </div>
  );
};

export default SellerDetailsPart;
