import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../utilities/dateFormat";
import formatAccountNumber from "../../../utilities/formatAccountNumber";
import {toaster} from "../../../utilities/hooks/toaster";
import {numberFormat} from "../../../utilities/moneyFormat";
import {useModal} from "../../Modal/ModalProvider";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  completeBankTransaction,
  fetchAllBankTransactions,
  fetchBankTransaction,
  selectBankTransactions,
  selectFinBlockLoading,
} from "../FinBlockSlice";
import {clearStatementDirective, setFinOrder} from "../FinSettings/FinSettingsSlice";

import AccountAssociationAndTransaction from "./modals/AccountAssociationAndTransaction/AccountAssociationAndTransaction";
import ViewAccountAssociationAndTransaction from "./modals/AccountAssociationAndTransaction/ViewAccountAssociationAndTransaction";
import {formTransactionStatus} from "./BankClient";
import {BANK_CLIENT_TABLE_COLUMNS} from "./constants";
import {findIfBankTransactionHasNoClient} from "./functions";

export const OWN_ACCOUNT_TYPE_ID = 1;

const useBankClient = () => {
  const {openModal} = useModal();

  // const {files, handleFolderUpload, folderPickerRef, clearFilesArray} = useFileFolderPicker();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const loading = useSelector(selectFinBlockLoading);
  const bankTransactions = useSelector(selectBankTransactions);

  const [currentBankTransactionId, setCurrentBankTransactionId] = useState(null);
  const currentBankTransaction = bankTransactions?.find(
    ({transactionId}) => transactionId === currentBankTransactionId,
  );
  const isTransactionWithNoClient = findIfBankTransactionHasNoClient(currentBankTransaction);
  const isAlignedResidue = currentBankTransaction?.internalTransactions?.length > 1;
  const isTransactionCompleted = !!currentBankTransaction?.isCompleted;
  const hasCurrentTranscationAssociation = !!currentBankTransaction?.isFullfiled;
  const isOperationBetweenOwnAccounts =
    currentBankTransaction?.creditAccount?.externalAccount?.type?.accountTypeId === OWN_ACCOUNT_TYPE_ID &&
    currentBankTransaction?.debitAccount?.externalAccount?.type?.accountTypeId === OWN_ACCOUNT_TYPE_ID;

  const bankClientTableData = useMemo(
    () =>
      bankTransactions?.map((transaction, index) => {
        const isDebit = Boolean(transaction?.bankClientStatement?.isDebit);
        return {
          id: transaction?.transactionId,
          number: ++index,
          trId: transaction?.bankClientStatement?.transactionUuid,
          accountNumber: formatAccountNumber(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.accountNumber),
          counterpartyAccountNumber: formatAccountNumber(
            transaction?.[isDebit ? "creditAccount" : "debitAccount"]?.accountNumber,
          ),
          requisitesC: transaction?.bankClientStatement?.counterpartyEdrpou,
          counterpartyName: transaction?.bankClientStatement?.counterparty,
          commissionsAmmount: transaction?.bankClientStatement?.feesSum,
          counterparty: transaction?.bankClientStatement?.counterparty,
          date: dateFormat(transaction?.bankClientStatement?.dateAndTime),
          status: formTransactionStatus(transaction),
          operationType: "?",
          costsDt: isDebit ? numberFormat(transaction?.sum) : "",
          revenueCt: isDebit ? "" : numberFormat(transaction?.sum),
          currency:
            transaction?.creditAccount?.currency?.shortName || transaction?.debitAccount?.currency?.shortName || "-",
          paymentPurpose: transaction?.paymentPurpose,
          rate: transaction?.bankClientStatement?.rate || "-",
          equivalent: transaction?.bankClientStatement?.nbuEquivalentSum || "-",
          remainder: numberFormat(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance) || "-",
        };
      }) || [],
    [bankTransactions],
  );

  const bankClientTableColumns = useColumns(BANK_CLIENT_TABLE_COLUMNS);

  const onBankClientTableRowSelect = id => {
    setCurrentBankTransactionId(id);
  };

  // useEffect(() => {
  //   if (files?.length > 0) {
  //     const formData = new FormData();
  //     files.forEach(file => {
  //       formData.append("file", file);
  //     });
  //     dispatch(uploadBankClientStatements(formData)).then(resp => {
  //       clearFilesArray();
  //       if (resp.meta.requestStatus === "fulfilled") {
  //         dispatch(fetchAllBankTransactions());
  //       }
  //     });
  //   }
  // }, [files]);

  const onBankClientFilterChange = filters => {
    dispatch(fetchAllBankTransactions({...filters}));
  };
  const onBankStatementsListFilterChange = () => {};

  // const onGetDataBtnClick = () => {
  //   swalCustom.info({
  //     text: `${t("bank client folder pick")}\n *${t("folder pick warning")} `,
  //     noTitle: true,
  //     showCancelButton: false,
  //     confirmButtonText: "Ok",
  //     confirmFunc: () => folderPickerRef.current.click(),
  //   });
  // };

  const onAccountAssociationBtnClick = ({savedFilters = {}}) => {
    if (!currentBankTransactionId || hasCurrentTranscationAssociation) return;

    dispatch(fetchBankTransaction({id: currentBankTransactionId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        const hasDirective = resp.payload?.directive?.directive;
        openModal(<AccountAssociationAndTransaction />, {
          label: t("Account association"),
          closable: true,
          hasAssociation: false,
          style: {height: hasDirective ? "330px" : "280px", minHeight: hasDirective ? "330px" : "280px"},
          onModalClose: () => {
            dispatch(clearStatementDirective());
          },
          savedFilters,
        });
      }
    });
    // dispatch(clearStatementDirective());
  };

  const onToTheClientBalanceBtnClick = ({savedFilters = {}, isResidue}) => {
    if (!currentBankTransactionId) return;

    dispatch(fetchBankTransaction({id: currentBankTransactionId}));
    dispatch(clearStatementDirective());

    openModal(<AccountAssociationAndTransaction />, {
      label: t("To the Client`s balance"),
      className: "largeModal",
      closable: true,
      hasAssociation: true,
      onModalClose: () => {
        dispatch(clearStatementDirective());
        dispatch(setFinOrder(null));
      },
      savedFilters,
      isResidue,
    });
  };

  const onConversionBtnClick = () => {};
  const onCheckTransactionsBtnClick = () => {};
  const onConductBtnClick = () => {};
  const onCreateDebtBtnClick = () => {};

  const onConductBetweenOwnBtnClick = ({savedFilters = {}}) => {
    dispatch(completeBankTransaction(currentBankTransaction?.transactionId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Transaction completed");
        dispatch(fetchAllBankTransactions(savedFilters));
      }
    });
  };

  const onTableDoubleClick = transaction => {
    if (!transaction?.id) return;
    dispatch(fetchBankTransaction({id: transaction.id})).then(() => {
      openModal(<ViewAccountAssociationAndTransaction />, {
        label: t("View Account iban", {iban: transaction?.accountNumber}),
        closable: true,
        style: {height: "210px", minHeight: "210px"},
        onModalClose: () => {
          dispatch(clearStatementDirective());
        },
        isResidue: transaction?.isResidue,
      });
    });
  };

  const onViewTransactionsBtnClick = ({isResidue}) => {
    const isDebit = Boolean(currentBankTransaction?.bankClientStatement?.isDebit);

    onTableDoubleClick({
      id: currentBankTransaction?.transactionId,
      accountNumber: formatAccountNumber(
        currentBankTransaction?.[isDebit ? "debitAccount" : "creditAccount"]?.accountNumber,
      ),
      isResidue,
    });
  };

  const onConductAfterAutoAssociationBtnClick = ({savedFilters = {}}) => {
    dispatch(completeBankTransaction(currentBankTransaction?.transactionId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Transaction completed");
        dispatch(fetchAllBankTransactions(savedFilters));
      }
    });
  };

  return {
    loading,
    onBankClientFilterChange,
    onBankStatementsListFilterChange,
    // onGetDataBtnClick,
    bankClientTableColumns,
    bankClientTableData,
    // folderPickerRef,
    // handleFolderUpload,
    currentBankTransactionId,
    onBankClientTableRowSelect,
    onAccountAssociationBtnClick,
    onToTheClientBalanceBtnClick,
    onConversionBtnClick,
    onCheckTransactionsBtnClick,
    onConductBtnClick,
    onCreateDebtBtnClick,
    hasCurrentTranscationAssociation,
    isOperationBetweenOwnAccounts,
    onConductBetweenOwnBtnClick,
    isTransactionCompleted,
    onTableDoubleClick,
    onViewTransactionsBtnClick,
    setCurrentBankTransactionId,
    isTransactionWithNoClient,
    openModal,
    isAlignedResidue,
    onConductAfterAutoAssociationBtnClick,
  };
};

export default useBankClient;
