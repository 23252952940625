import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import {fetchData} from "../../../../hook/axios.hook";
import {dateFormat} from "../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../utilities/formatAccountNumber";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {getError, getInfo} from "../../../../utilities/toasts";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {
  addNewTransaction,
  compleateOrder,
  deleteOrder,
  fetchDirective,
  fetchFinOrder,
  runOrder,
  saveFinOrder,
  selectDirective,
  selectFinOrder,
  setFinOrder,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS, ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS} from "./constants";
import {generateDirectiveOrderPdf} from "./orderFileGenerate";

const useOrderDirectiveView = orderId => {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const dispatch = useDispatch();
  const {setValues, setFieldValue, values} = useFormikContext();
  const {currentTransactionId} = values;
  const {closeCurrentTab} = useTabsContext();

  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [printModalActive, setPrintModalActive] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const currentOrder = useSelector(selectFinOrder);
  const directive = useMemo(() => currentOrder?.directive, [currentOrder]);
  const currentOrderId = currentOrder?.orderId;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!orderId) {
      return;
    }
    dispatch(fetchFinOrder(orderId)).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      dt: formatAccountNumber(currentTransaction?.debitAccount.accountNumber) || "",
      kt: formatAccountNumber(currentTransaction?.creditAccount.accountNumber) || "",
      debitAccountName: currentTransaction?.debitAccount.accountName || "",
      creditAccountName: currentTransaction?.creditAccount.accountName || "",
      paymentPurpose: currentTransaction?.paymentPurpose || "",
      paymentSumm: currentTransaction?.sum || "",
      paymentCurrency: currentTransaction?.debitAccount.currencyId || "",
      paymentNote: "",
    }));
    checkExchangeRate(currentTransaction?.debitAccount.currencyId, false);
  }, [currentTransaction, currentOrder]);

  useEffect(() => {
    setCurrentTransaction(
      currentTransactionId
        ? currentOrder?.transactions?.find(transaction => transaction.transactionId === currentTransactionId)
        : null,
    );
  }, [currentTransactionId, currentOrder]);

  useEffect(() => {
    if (directive?.directiveId && currentOrderId && currentOrderId === orderId) {
      const {status, co, operationType, note, recipient, sumPlan, sumFact, fulfilBy, payer} = directive;
      const {project, account, coNumber, sum, prepayment} = co;
      const {butget} = project;
      const {outsideParty, loanTerms} = currentOrder;

      setValues(prev => ({
        ...prev,
        compleateNote: "",
        fulfilBy,
        directiveType: "",
        status: language === "en" ? status.nameEng : status.nameUkr,
        coNumber,
        coBalance: account?.balance,
        clientBalance: project?.client?.account?.balance,
        clientProject: butget,
        coSum: sum,
        advance: (sum && (sumPlan / sum) * 100) || "0",
        nSum: sumPlan,
        fact: (sum && (account?.balance / sum) * 100) || "0",
        operationTypeId: operationType.operationTypeId,
        executorNote: note ?? t("No executor note"),
        finOrderTypeId: currentOrder.type.typeId ?? "",

        payerCoBalanceComposed: payer?.balance ?? "-",
        payerClientNumberComposed: payer?.clientNumber ?? "-",
        payerClientBalanceComposed: payer?.balance ?? "-",
        payerCreditForCoComposed: payer?.balance ?? "-",

        recipientClientBalanceComposed: recipient?.balance ?? "-",
        recipientCoBalanceComposed: recipient?.balance ?? "-",
        recipientClientNumberComposed: recipient?.clientNumber ?? "-",
        recipientCreditForCoComposed: recipient?.balance ?? "-",

        creditTermComposed: loanTerms?.termUpTo ?? "-",
        perAnnumComposed: loanTerms?.annualInterestRate ?? "-",
        repaymentTermIdComposed: loanTerms?.repaymentTermsId ?? "-",
        creditSumComposed: sumPlan ?? "-",

        payerNameComposed: outsideParty?.name ?? "-",
        edrpouoComposed: outsideParty?.edrpouCode ?? "-",
        payerAccountComposed: outsideParty?.currentAccount ?? "-",

        sumComposed: currentOrder?.sum ?? "-",
        currencyIdComposed: currentOrder?.currencyId ?? "-",

        coSumComposed: sum ?? "-",
        fSumComposed: sumFact ?? "-",
        coSumAdditionalComposed: prepayment ?? "-",

        apSumComposed: sumPlan ?? "-",
        advancePercentageComposed: Number.parseFloat(co?.sum)
          ? ((Number.parseFloat(sumPlan) / Number.parseFloat(co?.sum)) * 100).toFixed(2)
          : "0",
        afSumComposed: sumFact ?? "-",
      }));
      if (currentOrder?.transactions?.length > 0) {
        onTransactionSelect(currentOrder?.transactions[0].transactionId);
      }
    }
  }, [currentOrder]);

  const upperTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS);
  const upperTableData =
    currentOrder?.status.statusId === 1
      ? currentOrder?.transactions?.map(
          ({transactionId, creditAccount, debitAccount, paymentPurpose, sum, createdAt}, index) => ({
            id: transactionId,
            number: ++index,
            date: dateFormat(createdAt),
            sum,
            dt: formatAccountNumber(debitAccount.accountNumber),
            dtType: debitAccount.balanceSheet.type.balanceTypeName,
            kt: formatAccountNumber(creditAccount.accountNumber),
            ktType: creditAccount.balanceSheet.type.balanceTypeName,
            paymentPurpose,
            dtAccountName: debitAccount.accountName,
            ktAccountName: creditAccount.accountName,
            note: "",
          }),
        ) || []
      : [];

  const onTransactionSelect = id => {
    setFieldValue("currentTransactionId", id);
    const transaction = id ? currentOrder?.transactions?.find(transaction => transaction.transactionId === id) : null;
    setCurrentTransaction(transaction);
  };

  const lowerTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS);
  const lowerTableData =
    currentOrder?.status.statusId !== 1
      ? currentOrder?.transactions?.map(({transactionId, creditAccount, debitAccount, paymentPurpose, sum}, index) => ({
          id: transactionId,
          number: ++index,
          dateTo: "-",
          factDate: "-",
          directiveNumber: currentOrder?.directive?.directiveNumber,
          dt: formatAccountNumber(debitAccount.accountNumber),
          kt: formatAccountNumber(creditAccount.accountNumber),
          sumF: sum,
          paymentPurpose,
          note: "",
        })) || []
      : [];

  const onExitBtnClick = () => {
    swalCustom.confirm({
      confirmFunc: () => {
        closeCurrentTab();
      },
    });
  };

  useEffect(() => {
    if (!values?.debitAccountId) {
      setFieldValue(`debitAccountName`, "");
      setFieldValue(`dt`, "");
    }
  }, [values?.debitAccountId]);
  useEffect(() => {
    if (!values?.creditAccountId) {
      setFieldValue(`creditAccountName`, "");
      setFieldValue(`ct`, "");
    }
  }, [values?.creditAccountId]);

  const checkExchangeRate = (curr, showEror) => {
    if (curr === 978) {
      setFieldValue("exchangeRate", 1);
      return;
    }
    if (curr) {
      fetchData(`/finance/exchangeRates/get/${curr}`, "get", null, null, true)
        .then(res => {
          setFieldValue("exchangeRate", res?.saleRate);
        })
        .catch(() => {
          if (showEror) getError(t("Currency Rate for this currency not found"));
          setFieldValue("exchangeRate", "");
        });
    } else {
      setFieldValue("exchangeRate", "");
    }
  };

  const defineBalance = (accountNumber, type) => {
    if (!accountNumber || accountNumber === "") {
      setFieldValue(`${type}AccountId`, null);
      return;
    }
    fetchData(`/finance/accounts`, "get", {params: {accountNumber: accountNumber.trim()}}, null, true)
      .then(res => {
        if (res?.length > 0) {
          setFieldValue(`${type}AccountName`, res[0].accountName);
          setFieldValue(`${type}AccountId`, res[0].accountId);
        } else {
          getInfo("No such balance find");
          setFieldValue(`${type}AccountId`, null);
        }
      })
      .catch(e => {
        getInfo(e);
      });
  };

  const onCreateTransaction = () => {
    const {debitAccountId, creditAccountId, paymentNote, paymentPurpose, paymentSumm} = values;
    dispatch(
      addNewTransaction({
        orderId: currentOrderId,
        body: {
          debitAccountId,
          creditAccountId,
          sum: paymentSumm,
          paymentPurpose,
          note: paymentNote,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled")
        setValues(prev => ({
          ...prev,
          debitAccountId: "",
          creditAccountId: "",
          paymentNote: "",
          paymentPurpose: "",
          paymentSumm: "",
        }));
    });
  };

  const onCreateOrder = () => {
    dispatch(saveFinOrder(currentOrderId));
  };

  const onRunOrder = () => {
    dispatch(
      runOrder({
        orderId: currentOrderId,
        body: {
          currencyId: values?.paymentCurrency,
          sum: values?.paymentSumm,
        },
      }),
    )
      .then(res => {
        dispatch(setFinOrder({...currentOrder, ...res.payload.order}));
        setFieldValue("currentTransactionId", null);
        setValues(prev => ({
          ...prev,
          debitAccountId: "",
          creditAccountId: "",
          paymentNote: "",
          paymentPurpose: "",
          paymentSumm: "",
        }));
      })
      .catch(e => {
        console.error(e);
      });
  };

  const onPrintOrderBtnClick = () => {
    const data = {
      ...currentOrder,
      coNumber: currentOrder.directive.co?.coNumber,
      coManager: currentOrder.directive.co?.coManager?.name,
      operationType: currentOrder.directive?.operationType["nameUkr"],
    };
    const pdf = generateDirectiveOrderPdf(data);
    setPdfFile(pdf);
    setPrintModalActive(true);
  };

  const onDeleteOrder = () => {
    Swal.fire({
      title: t("Sure"),
      text: t("Order will be removed", {orderNumber: currentOrder.orderNumber}),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteOrder(currentOrderId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") closeCurrentTab();
        });
      }
    });
  };

  const onCompleateOrder = () => {
    dispatch(
      compleateOrder({
        orderId: currentOrderId,
        note: `${directive?.note ? directive?.note + " \n" : ""}  ${values?.compleateNote}`,
      }),
    ).then(() => {
      // dispatch(fetchDirective({directiveId: directive.directiveId}));
      dispatch(fetchFinOrder(currentOrderId));
      setFieldValue("compleateNote", "");
    });
  };

  const runOrderBtnDisabled = values?.paymentSumm && values?.paymentCurrency && values?.exchangeRate;

  const isOrderSaved = currentOrder?.status.statusId === 2;
  const isOrderCompleate = currentOrder?.status.statusId === 3;

  const saveTransactionBtnDisabled =
    values?.paymentSumm &&
    values?.paymentSumm !== "" &&
    values?.debitAccountId &&
    values?.creditAccountId &&
    values?.debitAccountId !== "" &&
    values?.creditAccountId !== "" &&
    values?.paymentPurpose &&
    values?.paymentPurpose !== "";

  return {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    currentTransactionId,
    defineBalance,
    onCreateTransaction,
    saveTransactionBtnDisabled,
    onCreateOrder,
    currentOrderId,
    onRunOrder,
    runOrderBtnDisabled,
    onDeleteOrder,
    isOrderSaved,
    onCompleateOrder,
    isOrderCompleate,
    onTransactionSelect,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrderNumber: currentOrder?.orderNumber || null,
    checkExchangeRate,
    loading,
  };
};

export default useOrderDirectiveView;
