import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../../utilities/Divider/Divider";
import ToolTip from "../../../../../utilities/ToolTip";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, TextAreaInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

import useAccountOpeningTab from "./useAccountOpeningTab";

const AccountOpeningTab = () => {
  const {t} = useTranslation();
  const {values, errors, touched, handleBlur} = useFormikContext();

  const {
    accountOwnerTypeOptions,
    accountTableColumns,
    accountTableData,
    onOpenAnAccountBtnClick,
    onAccountOpeningInfoBtnClick,
    onCheckAccountBtnClick,
    onSaveAccountBtnClick,
    onCloseAccountBtnClick,
    aoTableColumns,
    aoTableData,
    onAccountTableRowSelect,
    currentAccountId,
    currentAccountOwner,
    onAccountOwnersTableRowSelect,
    onBalanceSheetAccIdChange,
    isAccountCreated,
    isAccountChecked,
    onAccountOwnerInputChange,
    isAccountSaved,
    isLoading,
    limitInput,
    clearData,
  } = useAccountOpeningTab();

  const accInputStyle = {border: "1px solid #A3B9D9"};
  const accInputStyleAccent = {backgroundColor: "#fff", border: "1px solid #A3B9D9"};
  function formErrorInputStyle(fieldName) {
    return errors[fieldName] && touched[fieldName] ? {border: "1px solid red"} : {};
  }
  const isInputDisabled = !currentAccountOwner || !!currentAccountId;

  return (
    <div className="w100 fcCol aistr h100 fGrow pb0_5" style={{maxWidth: "100%"}}>
      <div className="fc nowrap aistr gap mt " style={{maxWidth: "100%"}}>
        <div className="fGrow" style={{width: "60%"}}>
          <div className="row aibl">
            <div className="row">
              <FunctionButton name="How to open an account" onClick={onAccountOpeningInfoBtnClick} autoWidth />
            </div>

            <Select
              name="accountOwnerType"
              label={t("Type of AO")}
              options={accountOwnerTypeOptions ?? []}
              width="14em"
            />
          </div>
          <Divider />
          <div className="row gap2x jcfs mb0_5 aibl">
            <FunctionButton name="Create new" onClick={clearData} autoWidth />
            <div className="row gap">
              <div className="row gap1">
                <ToolTip title="account country">
                  <TextInput name="accCountry" width="3em" style={accInputStyle} readOnly />
                </ToolTip>
                <ToolTip title="account country code">
                  <TextInput name="accCountryCode" width="2.5em" style={accInputStyle} readOnly />
                </ToolTip>
                <ToolTip title="persona id">
                  <TextInput name="personaId" width="4.5em" style={accInputStyle} readOnly />
                </ToolTip>
              </div>

              <ToolTip title="balance sheet account number" className="ml_05 mr_05">
                <TextInput
                  name="balanceSheetAccId"
                  width="3.4em"
                  style={
                    isInputDisabled
                      ? accInputStyle
                      : {...accInputStyleAccent, ...formErrorInputStyle("balanceSheetAccId")}
                  }
                  handleInputChange={onBalanceSheetAccIdChange}
                  onBlur={handleBlur}
                  // type="number"
                  inputClassName="inputTypeNumberNoArrows"
                  disabled={isInputDisabled}
                />
              </ToolTip>
              <div className="row gap1">
                <ToolTip title="+i balance sheet account">
                  <TextInput name="balanceSheetAccIdRel" width="4.5em" style={accInputStyle} readOnly />
                </ToolTip>
                <ToolTip title="account creation current year">
                  <TextInput name="currentYear" width="2.5em" style={accInputStyle} readOnly />
                </ToolTip>
                <ToolTip title="reserve code">
                  <TextInput
                    // type="number"
                    inputClassName="inputTypeNumberNoArrows"
                    name="reserveCode"
                    width="2.5em"
                    style={
                      isInputDisabled
                        ? accInputStyle
                        : {
                            ...accInputStyleAccent,
                            ...formErrorInputStyle("reserveCode", isInputDisabled ? accInputStyle : {}),
                          }
                    }
                    disabled={isInputDisabled}
                    onBlur={handleBlur}
                    handleInputChange={e => limitInput({inputValue: e.target.value, n: 2, name: "reserveCode"})}
                  />
                </ToolTip>
              </div>
              <ToolTip title="currency code" className="ml_05">
                <TextInput
                  // type="number"
                  inputClassName="inputTypeNumberNoArrows"
                  name="currencyId"
                  width="3em"
                  style={
                    isInputDisabled ? accInputStyle : {...accInputStyleAccent, ...formErrorInputStyle("currencyId")}
                  }
                  disabled={isInputDisabled}
                  onBlur={handleBlur}
                  handleInputChange={e => limitInput({inputValue: e.target.value, n: 3, name: "currencyId"})}
                />
              </ToolTip>
            </div>
            <FunctionButton
              name="Open an account"
              onClick={onOpenAnAccountBtnClick}
              disabled={
                !!currentAccountId ||
                !currentAccountOwner ||
                !!isAccountCreated ||
                Object.keys(errors).length !== 0 ||
                !values.accountName
              }
              autoWidth
            />
          </div>
          <TextAreaInput
            name="accountName"
            height="4.5em"
            placeholder={t("Account name")}
            disabled={!!isAccountCreated || !currentAccountOwner || !!currentAccountId}
          />
          <div className="row aibl gap2x">
            <div className="row jcsb gap0_5 mt">
              <FunctionButton
                name="Check an account"
                onClick={onCheckAccountBtnClick}
                disabled={!currentAccountOwner || !isAccountCreated || !!isAccountChecked}
                autoWidth
              />
              <FunctionButton
                name="Save an account"
                onClick={onSaveAccountBtnClick}
                disabled={!currentAccountOwner || !isAccountCreated || !isAccountChecked || !!isAccountSaved}
                autoWidth
              />
              <FunctionButton
                name="Close an account"
                onClick={onCloseAccountBtnClick}
                disabled={!currentAccountId || (!!isAccountCreated && !isAccountSaved)}
                autoWidth
              />
            </div>
            <div className="row fGrow">
              <TextInput
                name="controlName"
                label={t("Control")}
                width="14rem"
                readOnly
                autolabel
                generalClassName="fGrow"
                inputClassName="fGrow"
              />
              <DateInput name="controlDate" label={t("From")} width="2rem" readOnly />
            </div>
          </div>
        </div>
        <div style={{width: "30%"}}>
          <ToolTip title="account owner filter">
            <TextInput
              name="accountOwner"
              label={t("Chose an Account owner (AO)*")}
              width="12em"
              disabled={!values.accountOwnerType}
              handleInputChange={onAccountOwnerInputChange}
            />
          </ToolTip>
          <ReactTable
            className="mt fGrow "
            columns={aoTableColumns}
            defaultData={aoTableData}
            current={currentAccountOwner}
            onSelect={onAccountOwnersTableRowSelect}
            style={{height: "10em"}}
            loading={values.accountOwnerType && isLoading}
          />
        </div>
      </div>
      <Divider />
      <ReactTable
        className="fGrow "
        columns={accountTableColumns}
        defaultData={accountTableData}
        current={currentAccountId}
        onSelect={onAccountTableRowSelect}
        loading={isLoading}
        style={{height: "clamp(25em,50em,50vh)", flexGrow: 1}}
      />
    </div>
  );
};

// const AccountOpeningModal = withFormik({
//   mapPropsToValues: () => ({
//     accCountry: "",
//     accCountryCode: "",
//     personaId: "",
//     balanceSheetAccId: "",
//     balanceSheetAccIdRel: "",
//     currentYear: "",
//     reserveCode: "",
//     currencyId: "",
//     accountName: "",
//     controlName: "",
//     controlDate: "",
//     accountOwner: "",
//     ownerType: "",
//   }),
//   enableReinitialize: true,
//   validateOnBlur: false,
//   validateOnChange: true,
//   validationSchema: Yup.object({
//     balanceSheetAccId: Yup.string()
//       .length(4, "Must be exactly 4 characters")
//       .matches(/^\d+$/, "Must be digits only")
//       .required("This field is required"),
//     reserveCode: Yup.string()
//       .length(2, "Must be exactly 2 characters")
//       .matches(/^\d+$/, "Must be digits only")
//       .required("This field is required"),
//     currencyId: Yup.string()
//       .length(3, "Must be exactly 3 characters")
//       .matches(/^\d+$/, "Must be digits only")
//       .required("This field is required"),
//   }),
// })(AccountOpeningModalBase);
export default AccountOpeningTab;
