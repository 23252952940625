import {toast} from "react-toastify";
import {t} from "i18next";

const defaultAutoClose = 1000;

export const toaster = {
  success: (text, autoClose = defaultAutoClose) => {
    toast.success(t(text), {autoClose});
  },
  error: (text, autoClose = defaultAutoClose) => {
    toast.error(t(text), {autoClose});
  },
  warning: (text, autoClose = defaultAutoClose) => {
    toast.warning(t(text), {autoClose});
  },
  info: (text, autoClose = defaultAutoClose) => {
    toast.info(t(text), {autoClose});
  },
};
