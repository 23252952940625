import {toast} from "react-toastify";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Swal from "sweetalert2";

import {fetchData, fetchList} from "../hook/axios.hook";
import i18n from "../i18n";
import {checkEmptyFields} from "../utilities/checkEmptyFields";

export const getError = error => {
  toast.error(error);
};
export const getInfo = text => {
  toast.info(text);
};

const initialState = {
  persons: [],
  clients: [],
  designers: [],

  users: [],
  userPositions: [],
  currentPerson: null,
};

export const addNewPerson = createAsyncThunk("person/addNewPerson", async person => {
  return fetchData("/persona/add", "post", checkEmptyFields(person));
});
export const fetchPersons = createAsyncThunk("person/fetchPersons", async () => {
  return fetchData("/persona/getAll", "get");
});
export const fetchUsers = createAsyncThunk("person/fetchUsers", async params => {
  return fetchData("/users/getAll", "get", params);
});

export const fetchOrgStructures = createAsyncThunk("person/fetchOrgStructures", async () => {
  return fetchData("/orgStructure/getAll", "get");
});

export const fetchClients = createAsyncThunk("person/fetchClients", async () => fetchList("/clients/getAll", "get"));

export const registrClient = createAsyncThunk("person/registrClient", async client => {
  return fetchData("/clients/add", "post", client);
});

export const registrUser = createAsyncThunk("person/registrUser", async user => {
  return fetchData("/users/add", "post", user);
});

const personSlice = createSlice({
  name: "person",
  initialState,
  reducers: {
    personsFetched: (state, {payload}) => {
      state.persons = payload;
    },
    usersFetched: (state, {payload}) => {
      state.users = payload;
    },
    fetchClients: (state, {payload}) => {
      state.clients = payload;
    },
    clientsFetched: (state, {payload}) => {
      state.clients = payload;
    },
    userPositionsFetched: (state, {payload}) => {
      state.userPositions = payload.map(item => {
        return {value: item?.positionId, title: item?.[i18n.language === "en" ? "nameEng" : "nameUkr"]};
      });
    },
    addCurrentPerson: (state, {payload}) => {
      state.currentPerson = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addNewPerson.fulfilled, (state, {payload}) => {
        var newPers = payload.persona;
        state.persons.unshift(newPers);
      })
      .addCase(fetchPersons.fulfilled, (state, {payload}) => {
        state.persons = payload;
        // .map(persona=>{return {id: persona.CA_PERSONA_ID, name: persona.CA_FULL_NAME}})
      })
      .addCase(fetchUsers.fulfilled, (state, {payload}) => {
        state.users = payload;
      })
      .addCase(fetchOrgStructures.fulfilled, (state, {payload}) => {
        state.orgStructures = payload;
      })

      .addCase(registrClient.fulfilled, (state, {payload}) => {
        state.clients.unshift(payload.client);
        Swal.fire({
          title: `Клієнта успішно зареєстровано: \n${payload.client.clientNumber}`,
          // text: `<span>'CLIENT IN: <b>${payload.client.clientNumber}</b>'</span>`,
          icon: "info",
          confirmButtonText: "Ок",
        });
      })
      // .addCase(registrClient.rejected, (state, {error}) => {
      //     getError(error)
      // })
      .addCase(registrUser.fulfilled, (state, {payload}) => {
        state.users.unshift(payload.user);
        Swal.fire({
          title: `Користувача ${payload.user.name} успішно зареєстровано`,
          text: `Логін: ${payload.user.login}`,
          icon: "info",
          confirmButtonText: "Ок",
        });
      })
      // .addCase(registrUser.rejected, (state, {error}) => {
      //     getError(error)
      // })
      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = personSlice;

export default reducer;

export const {personsFetched, usersFetched, clientsFetched, addUser, userPositionsFetched, addCurrentPerson} = actions;

export const getUsers = state => state.person.users;
export const selectAllUsers = state => state.person.users;
export const getPersons = state => state.person.persons;
export const getClients = state => state.person.clients;
export const selectAllClients = state => state.person.clients;
export const getUsersFull = state => state.person.users;
