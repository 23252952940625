import React, {createContext, useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {useTranslation} from "react-i18next";
import {isEqual} from "lodash";
import Swal from "sweetalert2";

import CloseBtn from "../../utilities/Buttons/CloseBtn";
import {getInfo} from "../../utilities/toasts";

const ModalContext = createContext();

export function useModal() {
  return useContext(ModalContext);
}

function ModalProvider({children}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [couldClose, setCouldClose] = useState(true);
  const [initialFormData, setInitialFormData] = useState(null);

  const {t} = useTranslation();

  const openModal = (content, props) => {
    setModalContent(content); // Устанавливаем контент для модального окна

    setCouldClose(props?.closeBtnActive === undefined ? true : props?.closeBtnActive);
    setInitialFormData(props?.initialFormData || null);
    setModalProps(props);
    setModalOpen(true);
  };

  const closeModal = formData => {
    if (isModalOpen) {
      if (couldClose) {
        if (formData) {
          if (!isEqual(initialFormData, formData)) throw new Error("could not be closed");
          return;
        }
        setModalOpen(false);
        setModalContent(null);
      } else getInfo(t("You have unsaved issues"));
    }
  };

  return (
    <ModalContext.Provider
      value={{isModalOpen, openModal, closeModal, couldClose, setCouldClose, initialFormData, setInitialFormData}}
    >
      {children}
      {isModalOpen && <Modal {...modalProps}>{modalContent}</Modal>}
    </ModalContext.Provider>
  );
}

// Компонент модального окна, рендерящий разные компоненты через `children`
function Modal({
  children,
  label,

  closeBtnActive = true,
  closableOnEsc = true,

  className = "",
  modalInnerWrapperClassName = "",
  style,
  ...props
}) {
  const {closeModal, couldClose} = useModal();
  const {t} = useTranslation();

  const handleEscapeKey = event => {
    if (event.key === "Escape" && closableOnEsc) {
      closeModal();
    }
  };

  useEffect(() => {
    if (closableOnEsc) {
      window.addEventListener("keydown", handleEscapeKey);
    }
    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const removeFocusFromButton = isActive => {
    if (isActive) {
      const openButton = document.activeElement;
      if (openButton && openButton.blur) {
        openButton.blur(); // remove focus on button when modal was closed by Escape
      }
    }
  };

  useEffect(() => {
    removeFocusFromButton();
  }, []);

  const onClose = async () => {
    if (props?.additionalCloseCondition) {
      await props
        ?.additionalCloseCondition()
        .then(() => {
          closeModal();
          return;
        })
        .catch(() => {
          Swal.fire({
            title: t("Close the modal?"),
            text: t("You have unsaved issues"),
            icon: "info",
            confirmButtonText: t("Close"),
            showCancelButton: true,
            cancelButtonText: t("Cancel"),

            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          }).then(answ => {
            if (answ.isConfirmed) {
              closeModal();
              return;
            } else {
              return;
            }
          });
        });
    } else {
      closeModal();
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-form__container ">
      <div className={`${className} modal_form`} style={style}>
        <label className="label modal_form_label">{label}</label>

        {closeBtnActive && (
          <CloseBtn
            onClick={() => {
              if (couldClose) onClose();
            }}
          />
        )}

        <div className="overflow_form_modal">
          <div className={`${modalInnerWrapperClassName} modal_form__child`}>
            {React.cloneElement(children, {...props})}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root"),
  );
}

export default ModalProvider;
