import {useTranslation} from "react-i18next";

import {TextInput} from "../../../../../Inputs";

const CounterpartyInfo = () => {
  const {t} = useTranslation();

  return (
    <div className="border padding0_5 row aic jcsb w100">
      <span className="header">{t("Counterparty Info")}</span>
      <TextInput name="counterpartyNaming" label="Title" autolabel readOnly />
      <TextInput name="counterpartyEdrpou" label="EDRPOUO" autolabel readOnly />
      <TextInput name="counterpartyIban" label="p/acc" autolabel width="18rem" readOnly />
      <TextInput name="counterpartyDirectiveNumber" label="Directive №" autolabel readOnly />
    </div>
  );
};

export default CounterpartyInfo;
