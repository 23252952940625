import {useEffect} from "react";
import {NavLink, useLocation} from "react-router-dom";

import {MODE} from "../../../gitIgnore/appMode";

const MyLink = props => {
  let activeStyle = {
    fontWeight: 600,
  };

  const notAvailableStyle = {
    color: "#7f95b4",
    fontWeight: 400,
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === props.to) {
      document.title = props.name + " / " + MODE;
    }
  }, [location]);

  return (
    <li className="nav-item">
      <NavLink
        end
        to={props.to}
        style={({isActive}) => {
          if (props.notAvailableStyle) {
            return notAvailableStyle;
          }

          return isActive ? props.style || activeStyle : undefined;
        }}
      >
        {props.name}
      </NavLink>
    </li>
  );
};

export default MyLink;
