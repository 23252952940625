import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import {getMeasurementUnits} from "../../../../selectors/skuSelectors";
import {currencyOptions} from "../../../../utilities/currencyOptions";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import SkuPriceInput from "../../SkuPriceInput";

export const PRICES_TYPES = Object.freeze({SKU_VED_FORM: "skuVedForm"});

const Prices = ({mayEdit = false, type}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const [procents, setProcents] = useState([]);

  const measurementUnits = useSelector(getMeasurementUnits);

  const {values, setFieldValue} = useFormikContext();
  const {listPrice, minimumPrice, settlementPrice, costPrice, retailPrice, discountPrice} = values;

  useEffect(() => {
    var proc = [100];
    proc.unshift((settlementPrice * 100) / minimumPrice);
    proc.unshift((listPrice * 100) / minimumPrice);
    setProcents(proc);
  }, [listPrice, minimumPrice, settlementPrice]);

  const getProcents = price => {
    return listPrice ? (price * 100) / listPrice : "";
  };

  useEffect(() => {
    const mu = measurementUnits?.find(item => item?.unitId === values?.unitId);
    const muTranslate = language === "en" ? mu?.engName : mu?.name;
    setFieldValue("unit", muTranslate);
  }, [values?.unitId]);

  return (
    <div>
      {type === PRICES_TYPES.SKU_VED_FORM ? (
        <div className="row gap jcfs aic">
          <TextInput label={t("SKU price for")} name="unit" width="6em" readOnly />
          <span>*:</span>
          <Select label="" name="currencyId" options={currencyOptions} width="5em" />
          <span>%%</span>
        </div>
      ) : (
        <label> {t("Price characteristics:")}</label>
      )}
      <table className="sku_price__table">
        <tbody>
          {/* <tr>
            <th style={{width: "40%", textAlign: "left", textDecoration: "underline"}}></th>
            <th style={{width: "30%"}}></th>
            <th style={{width: "30%", textAlign: "center"}}></th>
          </tr> */}
          <SkuPriceInput
            id="1"
            label={t("listPrice") + "*"}
            mayEdit={mayEdit}
            price={"listPrice"}
            priceProc={"%"}
            verify={false}
            control={"priceCheckUser"}
          />
          <SkuPriceInput
            id="2"
            label={t("discPrice")}
            price={"discountPrice"}
            priceProc={listPrice}
            verify={false}
            mayEdit={mayEdit}
            control={"settlementPriceCheckUser"}
          />
          <SkuPriceInput
            id="3"
            label={t("costPrice")}
            mayEdit={mayEdit}
            price={"costPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
          <SkuPriceInput
            id="4"
            mayEdit={mayEdit}
            label={t("RR price") + "*"}
            price={"retailPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
          <SkuPriceInput
            id="5"
            mayEdit={mayEdit}
            label={t("Min price")}
            price={"minimumPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
          <SkuPriceInput
            id="6"
            mayEdit={mayEdit}
            label={t("Max price")}
            price={"maxPrice"}
            priceProc={listPrice}
            verify={false}
            control={""}
          />
        </tbody>
      </table>
    </div>
  );
};

Prices.propTypes = {mayEdit: PropTypes.bool};

export default Prices;
