import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {swalCustom} from "../../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../../utilities/hooks/toaster";
import ReactTable from "../../../../../conteiners/ReactTable";
import {MoneyInput, NumberInput} from "../../../../../Inputs";
import ModalForm from "../../../../../Modal/FormModal";
import {useModal} from "../../../../../Modal/ModalProvider";
import CurencySelect from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/CurencySelect";
import DtCtPart from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/DtCtPart";
import PdfPreviewModal from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/pdfPreviewModal";
import useOrderDirectiveView from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/useOrderDirectiveView";
import OrderTypeComposedPart, {
  ORDER_COMPOSED_PART_TYPES,
} from "../../../../../Project/ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";
import Spinner from "../../../../../spinner/Spinner";
import {fetchAllBankTransactions, selectBankTransaction} from "../../../../FinBlockSlice";
import {
  cancelDirective,
  clearStatementDirective,
  deleteOrder,
  selectStatementDirective,
  setFinOrder,
} from "../../../../FinSettings/FinSettingsSlice";
import ClientCreditingMod from "../../AccountTransactionLoanModals/ClientCreditingMod";
import ClientOverdraftMod from "../../AccountTransactionLoanModals/ClientOverdraftMod";

import DirectiveCreation from "./DirectiveCreation";

const DirectiveAndOrder = ({
  orderId,
  readOnly,
  onCreateDirectiveBtnClick,
  noCounterpartyAssociation,
  hasAssociation,
  isDirectiveCreated,
  isBankTransactionCompleted,
  savedFilters = {},
}) => {
  const {
    upperTableData,
    upperTableColumns,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    currentTransactionId,
    onCreateOrder,
    currentOrderId,
    onRunOrder,
    runOrderBtnDisabled,
    isOrderSaved,
    isOrderCompleate,
    onTransactionSelect,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrderNumber,
    checkExchangeRate,
    currentOrder,
    loading,
    isClientCreditingModActive,
    setClientCreditingModActive,
    modalData,
    onConduct,
    isClientOverdraftModActive,
    setClientOverdraftModActive,
    isOrderSecondary,
  } = useOrderDirectiveView(orderId);
  const {t} = useTranslation();

  const blockOkBtn = isOrderCompleate || isOrderSaved || upperTableData?.length > 0 || isOrderSecondary;

  const dispatch = useDispatch();
  const {closeModal} = useModal();

  const bankTransaction = useSelector(selectBankTransaction);
  const orderDirective = currentOrder?.directive;
  const statementDirective = useSelector(selectStatementDirective);
  const directive = orderDirective || statementDirective;
  const hasDirectiveFilledOrders =
    directive?.orders?.length > 0 && directive?.orders?.find(order => order.status.statusId > 1);

  useEffect(() => {
    if (bankTransaction?.transactionId) {
      checkExchangeRate(bankTransaction?.currency?.currencyId, true);
    }
  }, [bankTransaction?.transactionId]);

  const onDeleteOrder = () => {
    Swal.fire({
      title: t("Sure"),
      text: t("Order will be removed", {orderNumber: currentOrder.orderNumber}),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteOrder(currentOrderId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            // dispatch(clearStatementDirective());
          }
        });
      }
    });
  };

  const onCancelDirectiveBtnClick = () => {
    if (!directive || !directive?.orders[0]?.status?.statusId > 1) return;

    swalCustom.confirm({
      confirmFunc: () => {
        if (!currentOrderId) {
          dispatch(cancelDirective(directive?.directiveId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              toaster.success(t("directiveDeleted"));
              dispatch(clearStatementDirective());
              dispatch(setFinOrder(null));
            }
          });
        } else {
          dispatch(deleteOrder(directive?.orders[0]?.orderId)).then(res => {
            if (res.meta.requestStatus === "fulfilled") {
              dispatch(cancelDirective(directive?.directiveId)).then(resp => {
                if (resp.meta.requestStatus === "fulfilled") {
                  toaster.success(t("directiveDeleted"));
                  dispatch(clearStatementDirective());
                  dispatch(setFinOrder(null));
                }
              });
            }
          });
        }
      },
    });
  };

  const onConductInAssociation = () => {
    onConduct()
      .then(res => {
        if (res) {
          closeModal();
          dispatch(setFinOrder(null));
          dispatch(clearStatementDirective());
          dispatch(fetchAllBankTransactions(savedFilters));
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <div className="pr w100 fGrow fcCol aistr">
      {loading && <Spinner />}
      <div className="fc nowrap g2">
        <DirectiveCreation
          onCreateDirectiveBtnClick={onCreateDirectiveBtnClick}
          noCounterpartyAssociation={noCounterpartyAssociation}
          hasAssociation={hasAssociation}
          isDirectiveCreated={isDirectiveCreated}
          isBankTransactionCompleted={isBankTransactionCompleted}
        />
        <div className="row asfe aic jcc g1" style={{width: "60%"}}>
          <CurencySelect name="paymentCurrency" title="Payment currency" required readOnly />
          <MoneyInput
            name="paymentSumm"
            width="7em"
            label={t("Payment Sum")}
            required
            disabled={false}
            currency={null}
            placeholder={""}
            readOnly={blockOkBtn || !directive}
          />
          <NumberInput
            name="exchangeRate"
            width="6em"
            label={t("exchange rate") + " VAL / EUR:"}
            autolabel
            readOnly={true}
          />
          {!blockOkBtn && (
            <FunctionButton name="Ok" onClick={onRunOrder} disabled={!runOrderBtnDisabled} width={"3em"} />
          )}
        </div>
      </div>
      <div className="row nowrap gap pt5 fGrow">
        <OrderTypeComposedPart readOnly className="fGrow" type={ORDER_COMPOSED_PART_TYPES.VIEW_FUll}>
          <div className="row w100 jcsb">
            <FunctionButton
              onClick={onConductInAssociation}
              name="Conduct"
              autoWidth
              disabled={!isOrderSaved && !isOrderSecondary}
              tooltipMessage={isOrderSaved ? null : "Order must be saved"}
            />
            <FunctionButton
              name="Cancel directive"
              autoWidth
              onClick={onCancelDirectiveBtnClick}
              disabled={!directive || !!hasDirectiveFilledOrders}
              tooltipMessage={
                directive && hasDirectiveFilledOrders ? "Directive with filled orders can't be canceled" : null
              }
            />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol" style={{width: "77%"}}>
          <DtCtPart isOrderCompleate={isOrderCompleate || !isOrderSaved} />
          <div className="fcCol mw100 fGrow">
            <div className="fGrow w100 mw100 h100">
              <ReactTable
                columns={upperTableColumns}
                defaultData={upperTableData}
                current={currentTransactionId}
                onSelect={onTransactionSelect}
                className="mb scrollX"
                style={{height: "7rem"}}
              />
            </div>
            {!readOnly && (
              <div className={"fc nowrap jcfe w100 mb"}>
                <FunctionButton
                  name="Create order"
                  autoWidth
                  onClick={onCreateOrder}
                  disabled={!currentOrderId || upperTableData?.length === 0}
                />
              </div>
            )}
            <div className="fGrow w100 mw100 h100 fGrow">
              <ReactTable
                columns={lowerTableColumns}
                defaultData={lowerTableData}
                current={currentTransactionId}
                onSelect={onTransactionSelect}
                className=" mb scrollX"
                style={{height: "9rem"}}
              />
            </div>
            <div className="row jcfe w100">
              {!readOnly && (
                <FunctionButton
                  onClick={onDeleteOrder}
                  disabled={!currentOrderId}
                  name="Delete order"
                  autoWidth
                  style={{marginRight: "40px"}}
                />
              )}
              <FunctionButton
                onClick={onPrintOrderBtnClick}
                name="Print an order"
                autoWidth
                disabled={!isOrderCompleate}
                tooltipMessage={isOrderCompleate ? null : "Order must be saved and completed"}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalForm
        closable={true}
        Component={PdfPreviewModal}
        label={t("Printing")}
        active={printModalActive}
        setModalActive={setPrintModalActive}
        pdfFile={pdfFile}
        fileName={currentOrderNumber}
      />
      <ModalForm
        closable={true}
        Component={ClientOverdraftMod}
        label={t("Client Overdraft")}
        active={isClientOverdraftModActive}
        setModalActive={setClientOverdraftModActive}
        amount={modalData?.shortageSum}
        currency="EUR"
        className="xsModal"
        orderId={modalData?.orderId}
      />
      <ModalForm
        closable={true}
        Component={ClientCreditingMod}
        label={t("Client Crediting")}
        active={isClientCreditingModActive}
        setModalActive={setClientCreditingModActive}
        amount={modalData?.shortageSum}
        currency="EUR"
        className="xsModal"
        orderId={modalData?.orderId}
      />
    </div>
  );
};

export default DirectiveAndOrder;
