export const CLIENT_ORDERS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    size: 25,
    maxSize: 25,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 50,
    maxSize: 50,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    size: 90,
    maxSize: 90,
    alignStart: true,
    isSortable: true,
  },
  {
    header: "Sum (n)",
    accessorKey: "sumPlan",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumFact",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 50,
    alignStart: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 200,
    alignStart: true,
  },
]);
