import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import moment from "moment";

import saveImg from "../../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import UpDownButtons from "../../../../utilities/Buttons/UpDownButtons";
import ReactTable from "../../../conteiners/ReactTable";
// import ReactTable from "../../../conteiners/ReactTableUniSelect";
import {DateInput, TextInput} from "../../../Inputs";
import ModalForm from "../../../Modal/FormModal";
import useSkuTable from "../../../SKU/skuTableService";
import {getLoadingRequest, getRequestSkuInfo, getSkuRequestInfo} from "../../RequestsSlice";
import ViewSkuFromTable from "../RequestSkuBlock/ViewSkuFromTable";
import {SPLIT_TYPES} from "../utils/splitTypes";

const SplitUniModal = ({
  isStatus,
  selector,
  initialValues,
  onUnloadMethod,
  onSeparate,
  secondRequestSku,
  setSecondRequestSku,
  onNoteUpdate,
}) => {
  const currentSkuList = useSelector(selector);
  const currentSkuInRequest = useSelector(getRequestSkuInfo);
  const requestLoading = useSelector(getLoadingRequest);

  const dispatch = useDispatch();

  const {t} = useTranslation();

  const [firstRequestSku, setFirstRequestSku] = useState([]);
  // const [secondRequestSku, setSecondRequestSku] = useState([]);

  const firstRequestSkuRef = useRef([]);
  const secondRequestSkuRef = useRef([]);

  const [firstSelected, setFirstSelected] = useState([]);
  const [secondSelected, setSecondSelected] = useState([]);

  const [modalActive, setModalActive] = useState(false);

  const {skuTablecolumns, skuListData, transformSkuDataForTable} = useSkuTable(firstRequestSku, "skuRequestId");

  const onUnload = () => {
    if (firstRequestSkuRef.current.length < 1 || secondRequestSkuRef.current.length < 1) {
      onUnloadMethod();
    }
  };

  useEffect(() => {
    firstRequestSkuRef.current = firstRequestSku; // Обновляем значение в useRef при изменении state
  }, [firstRequestSku]);

  useEffect(() => {
    secondRequestSkuRef.current = secondRequestSku; // Обновляем значение в useRef при изменении state
  }, [secondRequestSku]);

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      onUnload();
      window.removeEventListener("beforeunload", onUnload);
      //
    };
  }, []);

  useEffect(() => {
    setFirstRequestSku(currentSkuList);
  }, [currentSkuList]);

  const directions = Object.freeze({
    UP: "up",
    DOWN: "down",
  });

  const changeRequest = (idList, direction) => {
    const switchedSkus = currentSkuList.filter(sku => idList.includes(sku.skuRequestId));
    switch (direction) {
      case directions.UP: {
        setFirstRequestSku(prev => [...prev, ...switchedSkus]);
        const filteredSecond = secondRequestSku.filter(sku => !idList.includes(sku.skuRequestId));

        setSecondRequestSku(filteredSecond);
        break;
      }
      case directions.DOWN: {
        const filteredFirst = firstRequestSku.filter(sku => !idList.includes(sku.skuRequestId));
        setFirstRequestSku(filteredFirst);
        setSecondRequestSku(prev => {
          return [...prev, ...switchedSkus];
        });

        break;
      }
    }
  };

  const onCancel = () => {
    setSecondRequestSku([]);
    setFirstRequestSku(currentSkuList);
  };

  const secondOrderTableData = useMemo(() => {
    return secondRequestSku.map(transformSkuDataForTable);
  }, [secondRequestSku]);

  const handleRowDoubleClick = rowData => {
    isStatus !== SPLIT_TYPES.NORMAL_ORDER &&
      dispatch(getSkuRequestInfo(rowData.id)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setModalActive(true);
        }
      });
  };

  const label = type => {
    switch (type) {
      case SPLIT_TYPES.NORMAL_ORDER:
        return t("Order number");

      case SPLIT_TYPES.READY_REQUEST:
        return t("Request №");

      case SPLIT_TYPES.DEFECTED_ORDER:
        return t("Order number");

      default:
        break;
    }
  };

  return (
    <div style={{height: "100%", width: "100%"}}>
      <Formik initialValues={initialValues} enableReinitialize>
        {({values}) => (
          <Form className="autolabel flex_form g15" style={{paddingTop: "1em"}}>
            {isStatus === SPLIT_TYPES.DEFECTED_ORDER ? (
              <div className="border_bottom_container fdr pb jcc" style={{gap: "7em"}}>
                <TextInput label={t("Order number")} name="requestNumber" width="12em" autolabel />
                <TextInput label={t("Manager CO")} name="managerCo" width="12em" autolabel />
                <TextInput label={t("Manager FEA")} name="managerFea" width="12em" autolabel />
              </div>
            ) : (
              <div className="fc nowrap jcfs g2">
                <TextInput label={label(isStatus)} name="requestNumber" labelStyles={{width: "8em"}} />
              </div>
            )}

            <div style={{height: "15em"}}>
              <ReactTable
                // defaultData={firstRequestSku.map(transformForTable)}
                defaultData={skuListData}
                columns={skuTablecolumns}
                setMultiSelect={setFirstSelected}
                selectable={false}
                onRowDoubleClick={handleRowDoubleClick}
                style={{height: "100%", width: "100%"}}
                className="scrollX"
                enableMultiSelection={true}
                loading={requestLoading}
              />
            </div>

            <div className="fc jcsb">
              <div className="fc nowrap jcfs g2">
                <TextInput label={label(isStatus)} name="secondRequestNumber" labelStyles={{width: "8em"}} />
                {isStatus === SPLIT_TYPES.NORMAL_ORDER && (
                  <DateInput name="dateD" label={"DD per Factory(p):"} readOnly />
                )}

                {isStatus === SPLIT_TYPES.DEFECTED_ORDER && (
                  <DateInput
                    name="deliveryDate"
                    label={"Shipment date (n)"}
                    flatpikrConfig={{
                      minDate: moment().add(1, "days").format("DD-MM-YYYY"),
                    }}
                  />
                )}
                {isStatus !== SPLIT_TYPES.NORMAL_ORDER && (
                  <TextInput label="" name="note" width="25em" placeholder={t("Add a Note to the Application ")} />
                )}
                {isStatus === SPLIT_TYPES.DEFECTED_ORDER && (
                  <ImageButton
                    src={saveImg}
                    onClick={() => {
                      onNoteUpdate(values.note);
                    }}
                    width={1.4}
                    height={1.4}
                    disabled={values.note === ""}
                  />
                )}
              </div>
              {isStatus !== SPLIT_TYPES.NORMAL_ORDER && (
                <div style={{color: "#D62D30"}}>{t("not ready skus separat warn")}</div>
              )}
              <UpDownButtons
                onUpClick={() => {
                  changeRequest(secondSelected, directions.UP);
                }}
                onDownClick={() => {
                  changeRequest(firstSelected, directions.DOWN);
                }}
              />
            </div>
            <div style={{height: "15em"}}>
              <ReactTable
                defaultData={secondOrderTableData}
                columns={skuTablecolumns}
                setMultiSelect={setSecondSelected}
                selectable={false}
                onRowDoubleClick={handleRowDoubleClick}
                className="scrollX"
                enableMultiSelection={true}
                loading={requestLoading}
                style={{height: "100%", width: "100%"}}
                // style={{ height: "100%", width: "100%" }}
              />
            </div>

            <div className="btn_container left">
              <FunctionButton
                name="Split orders"
                onClick={() => {
                  onSeparate(values.note);
                }}
                autoWidth
                disabled={Object.values(secondOrderTableData).length === 0}
              />
              <FunctionButton name="Cancel" onClick={onCancel} />
            </div>
          </Form>
        )}
      </Formik>

      <ModalForm
        closable={true}
        Component={ViewSkuFromTable}
        element={currentSkuInRequest}
        label={t(
          `${t("View SKU")} № ${currentSkuInRequest?.brand?.prefix + "-" + currentSkuInRequest?.article} ${t(
            "in order",
          )}`,
        )}
        active={modalActive}
        setModalActive={setModalActive}
        className="split_modal_in_one"
      />
    </div>
  );
};

export default SplitUniModal;
