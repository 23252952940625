import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {TextAreaInput, TextInput} from "../../Inputs";
import PhoneInput from "../../Inputs/PhoneInput";

const AddLeadModal = () => {
  const {initialValues, values, setValues} = useFormikContext();

  const onCreateLeadBtnClick = () => {};
  const onCancelBtnClick = () => {
    setValues(initialValues);
  };

  return (
    <div className="fcCol gap jcsa aic fGrow pt">
      <div className="fcCol gap aistr" style={{width: "25rem"}}>
        <TextInput name="firstName" label={t("Name")} autolabel required />
        <TextInput
          name="patronimicName"
          label={t("Patronymic Name")}
          autolabel
          generalClassName="fGrow"
          className="fGrow"
        />
        <TextInput name="lastName" label={t("Last Name")} autolabel />
      </div>
      <div className="fcCol gap aistr" style={{width: "25rem"}}>
        <PhoneInput label={t("Phone")} name="phone" style={{flexGrow: 1}} />
        <TextInput name="email" label="eMail" autolabel />
        <TextAreaInput name="note" label={t("Notes on Lead")} />
      </div>
      <div className="row gap3x">
        <FunctionButton name="Create Lead" onClick={onCreateLeadBtnClick} autoWidth disabled={!values.firstName} />
        <FunctionButton name="Cancel" onClick={onCancelBtnClick} autoWidth />
      </div>
    </div>
  );
};

export default withFormik({enableReinitialize: true})(AddLeadModal);
