export function makeTableNameFromPersona({persona, language}) {
  if (!persona || !language) return "";

  return `${language === "en" ? persona.engLastName : persona.lastName} ${
    language === "en" ? persona.engFirstName : persona.firstName
  }`;
}

export default function makeNameFromPersona({persona, language}) {
  if (!persona || !language) return "";

  return `${language === "en" ? persona.engFirstName : persona.firstName} ${
    language === "en" ? persona.engLastName : persona.lastName
  }`;
}
