import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {max} from "lodash";
import moment from "moment";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import {fetchData} from "../../../../../hook/axios.hook";
import saveImg from "../../../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../../../utilities/dateFormat";
import {useFiltersContext} from "../../../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, MoneyInput, TextInput} from "../../../../Inputs";
import PdfFilePreview from "../../../../Inputs/fileSelect/preview/PdfPreview";
import {Select} from "../../../../Inputs/SelectField";
import ModalForm from "../../../../Modal/FormModal";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import ChooseCo from "../../../ProjectModals/ChooseCo/ChooseCo";
import {
  addAnnex,
  deleteAnnex,
  fetchAnnexes,
  getCoAnnexes,
  getCurrentProject,
  getProjectDocumentLoading,
  updateAnnex,
} from "../../../ProjectSlice";
import {CO_DOCUMENTS_COLUMNS, PROJECT_DOC_TYPES} from "../constants";

import ShowDocBtn from "./ShowDocBtn";
const CoDocForm = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const annexes = useSelector(getCoAnnexes),
    currentProject = useSelector(getCurrentProject),
    listsLoading = useSelector(getProjectDocumentLoading);

  const docsLoading = false;
  const columns = useColumns(CO_DOCUMENTS_COLUMNS);

  const [chooseCoModalActive, setChooseCoModalActive] = useState(false);
  const [currentAnnex, setCurrentAnnex] = useState(null);
  const [files, setFiles] = useState([]);

  const {values, setValues, setFieldValue} = useFormikContext();
  const {annexId} = values;
  const {filters} = useFiltersContext();

  useEffect(() => {
    if (currentProject) fetchList();
  }, [currentProject, filters]);

  useEffect(() => {
    if (!annexId) {
      setCurrentAnnex(null);
      setValues(prev => ({
        ...prev,
        annexsum: "",
        annexnote: "",
        annexbringTo: "",
      }));
      setFiles([]);
      return;
    } else {
      fetchData(`/annexes/get/${annexId}`, "get").then(res => {
        setFiles(res.files);
      });
      let current = annexes.find(annex => annex.annexId === annexId);
      if (!current) {
        fetchList();
      } else {
        setCurrentAnnex(current);
        setValues(prev => ({
          ...prev,
          annexsum: current.sum,
          annexnote: current.note,
          annexbringTo: current.bringTo,
        }));
      }
    }
  }, [annexId]);

  useEffect(() => {
    if (!currentAnnex) {
      let current = annexes.find(annex => annex.annexId === annexId);
      setCurrentAnnex(current);
    }
  }, [annexes]);

  function onAddCoBtnClick() {
    setChooseCoModalActive(true);
  }

  const fetchList = () => {
    dispatch(fetchAnnexes({projectId: currentProject.projectId, ...filters}));
  };

  const addCoAnnex = () => {
    if (values.chosenCoNumber !== "") {
      setValues(prev => ({...prev, annexId: "", annexsum: "", annexnote: "", annexbringTo: ""}));

      dispatch(addAnnex({coId: values.chosenCoNumber})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setChooseCoModalActive(false);

          Swal.fire({
            title: "",
            text: `${t("Annex added", {annexNumber: res.payload?.annex?.annexNumber})}`,
            icon: "info",
            confirmButtonText: "OK",
          });

          setFieldValue("annexId", res.payload?.annex?.annexId);
        }
      });
    }
  };

  const onDocUpdate = () => {
    const {annexnote, annexbringTo, annexsum} = values;
    if (!annexbringTo || !currentAnnex.annexId) return;

    dispatch(
      updateAnnex({
        annexId: currentAnnex.annexId,
        data: {
          note: annexnote,
          sum: annexsum === "" ? null : annexsum,
          bringTo: annexbringTo,
        },
      }),
    );
  };

  const onAnnexDelete = () => {
    Swal.fire({
      title: t("Sure"),
      text: `${t("Delete document")}?`,
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(answ => {
      if (answ.isConfirmed)
        dispatch(deleteAnnex(annexId)).then(() => {
          setFieldValue("annexId", null);
        });
    });
  };

  const onFileAdding = arrFiles => {
    let formdata = new FormData();
    let file = arrFiles[0];
    let filename = translit(file.name);
    formdata.append("file", file, filename);

    fetchData(`/annexes/files/upload/${annexId}`, "post", formdata).then(res => {
      setFiles(res.files);
    });
  };

  const onFileDel = fileId => {
    fetchData(`/annexes/files/remove/${fileId}`, "delete").then(res => {
      fetchData(`/annexes/get/${annexId}`, "get").then(res => {
        setFiles(res.files);
      });
    });
  };

  const tableData =
    annexes?.map((annex, i) => ({
      id: annex.annexId,
      number: i + 1,
      coNumber: annex.co?.coNumber,
      annexNumber: annex.annexNumber,
      takeTo: dateFormat(annex.bringTo),
      brieflyAbout: annex.note,
      coSum: annex.sum,
    })) || [];

  const annexesNumberList =
    annexes?.map(annex => ({
      title: annex.annexNumber,
      value: annex.annexId,
    })) || [];

  const filesUrls = useMemo(() => files?.map(file => file.fileUrl), [files]);

  const saveBtnDisabled = useMemo(
    () =>
      ["bringTo", "note", "sum"].reduce(
        (acc, option) => acc && currentAnnex?.[option] === values?.[`annex${option}`],
        true,
      ),
    [values, currentAnnex],
  );

  return (
    <div className="w100 fGrow fc col nowrap">
      <div className="fc nowrap g1 aic autolabel mb w100">
        <div style={{width: "8em"}}>
          <FunctionButton
            name={`${t("Add")} ▼`}
            style={{width: "8em", marginBottom: "0.5em"}}
            onClick={onAddCoBtnClick}
          />
          <FunctionButton name={"Delete"} style={{width: "8em"}} disabled={!currentAnnex} onClick={onAnnexDelete} />
        </div>
        <div>
          <Select
            label={t("CO Annex №")}
            name="annexId"
            options={annexesNumberList}
            inputClassName="ref_input"
            selectOnly={true}
            // question={t("Instructions")}
            generalClassName={"mb0_5"}
          />
          <MoneyInput label={t("CO Sum")} width="8em" name="annexsum" readOnly={!currentAnnex} />
        </div>
        <div className="fGrow">
          <TextInput
            label={t("Briefly about")}
            name="annexnote"
            width="80%"
            readOnly={!currentAnnex}
            generalClassName={"mb0_5"}
          />
          <div className="fc nowrap  jcsb">
            <DateInput
              label={t("Take To")}
              name="annexbringTo"
              flatpikrConfig={{
                minDate: moment().format("DD-MM-YYYY"),
              }}
              readOnly={!currentAnnex}
              required
            />
            <div className="fc nowrap">
              <ImageButton
                src={saveImg}
                alt="save"
                className={"documentSaveBtn"}
                width={1.5}
                height={1.5}
                disabled={!currentAnnex || saveBtnDisabled || !values.annexbringTo}
                onClick={onDocUpdate}
              />
              <PdfFilePreview
                files={files || []}
                delFunc={onFileDel}
                addFunc={onFileAdding}
                disable={!currentAnnex}
                loading={docsLoading}
              />
              <ShowDocBtn type={PROJECT_DOC_TYPES.annex} files={filesUrls} disabled={!currentAnnex} />
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        defaultData={tableData}
        columns={columns}
        current={annexId === "" ? null : annexId}
        className={"project_table mb fGrow fShrink"}
        onSelect={id => {
          setFieldValue("annexId", id);
        }}
        loading={listsLoading}
        style={{height: "12em"}}
      />

      <ModalForm
        closable={true}
        Component={ChooseCo}
        label={t("Choose CO")}
        active={chooseCoModalActive}
        setModalActive={setChooseCoModalActive}
        className="smallModal"
        addCoAnnex={addCoAnnex}
      />
    </div>
  );
};

export default CoDocForm;
