import {useEffect, useState} from "react";
import {useFormikContext} from "formik";

import {CASHDESK_DIRECTIVE_TYPES} from "./CashdeskDirective";

const useCashdeskDirective = () => {
  const [currentDirectiveSubType, setCurrentDirectiveSubType] = useState(null);
  const [currentPayer, setCurrentPayer] = useState(null);
  const [currentReciver, setCurrentReciver] = useState(null);

  const [isOrderCreateModalActive, setOrderCreateModalActive] = useState(false);
  const [isOrderViewModalActive, setOrderViewModalActive] = useState(false);

  const {values} = useFormikContext();
  const directiveType = values?.directiveType;

  useEffect(() => {
    if (directiveType) {
      console.log(directiveType);
    }
  }, [directiveType]);

  const onAddNewDirectiveBtnClick = () => {
    setOrderCreateModalActive(true);
  };

  return {
    onAddNewDirectiveBtnClick,
    directiveType,
    currentDirectiveSubType,
    setCurrentDirectiveSubType,
    currentPayer,
    setCurrentPayer,
    currentReciver,
    setCurrentReciver,
    isOrderCreateModalActive,
    setOrderCreateModalActive,
    isOrderViewModalActive,
    setOrderViewModalActive,
  };
};

export default useCashdeskDirective;
