import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {getHiddenContainerState, setHiddenContainerState} from "../../../slices/UserSlice";
import ToolTip from "../../../utilities/ToolTip";

import "./hiddenContent.scss";

const HiddenContainer = ({children, tooltipMessageShow, tooltipMessageHide, window, ...props}) => {
  const [showContent, setShowContent] = useState(false);
  const {t} = useTranslation();
  const initialState = useSelector(getHiddenContainerState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (window) {
      if (initialState?.[window]) {
        setShowContent(initialState?.[window]);
      }
    }
  }, []);

  useEffect(() => {
    if (window) dispatch(setHiddenContainerState({window, value: showContent}));
  }, [showContent]);

  return (
    <div className="hiddenContainer">
      <div className={`content ${showContent ? "show" : ""}`}>{showContent && children}</div>
      <div className="w100 fc jcc" style={{marginTop: "-5px"}}>
        <ToolTip title={showContent ? t(tooltipMessageHide) || t(tooltipMessageShow) : t(tooltipMessageShow)}>
          <div
            className="toggle-button"
            onClick={() => {
              setShowContent(prev => !prev);
            }}
          >
            <span>{showContent ? "▲" : "▼"}</span>
          </div>
        </ToolTip>
      </div>
    </div>
  );
};

export default HiddenContainer;
