import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getOrgStructures} from "../../../selectors/CatalogSelectors";
import {dateFormat, dateTimeFormat} from "../../../utilities/dateFormat";
import {toaster} from "../../../utilities/hooks/toaster";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addNewExchangeRate,
  fetchAllCurrencies,
  selectAllCurrency,
  selectAllEcbRates,
  selectAllExchangeRates,
} from "../FinSettings/FinSettingsSlice";

import {ECB_RATE_TABLE_COLUMNS, OFFICE_RATE_TABLE_COLUMNS, TT_RATE_TABLE_COLUMNS} from "./constants";

const useCurrencyRate = () => {
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const {officeRateId, officeBuyingRate, officeSalesRate, officeId} = values;

  const allExchangeRates = useSelector(selectAllExchangeRates);
  const allEcbRates = useSelector(selectAllEcbRates);
  const allCurrency = useSelector(selectAllCurrency);
  const allOffices = useSelector(getOrgStructures);

  useEffect(() => {
    dispatch(fetchAllCurrencies());
  }, []);

  const officeOptions =
    allOffices?.map(({orgStructureId, shortName}) => ({value: orgStructureId, title: shortName})) ?? [];
  const rateOptions = allCurrency?.map(({currencyId, shortName}) => ({value: currencyId, title: shortName})) ?? [];

  const ecbTableColumns = useColumns(ECB_RATE_TABLE_COLUMNS);
  const ecbTableData =
    allEcbRates?.map(({exchangeRateId, createdAt, rate, currency: {currencyId, fullName, shortName}}, index) => ({
      id: exchangeRateId,
      number: ++index,
      date: dateFormat(createdAt),
      rate,
      code: currencyId,
      currency: `${shortName} (${fullName})`,
    })) ?? [];

  const officeTableColumns = useColumns(OFFICE_RATE_TABLE_COLUMNS);
  const officeTableData =
    allExchangeRates?.map(
      ({exchangeRateId, createdAt, purchaseRate, saleRate, currency: {currencyId, fullName, shortName}}, index) => ({
        id: exchangeRateId,
        number: ++index,
        dateTime: dateTimeFormat(createdAt),
        buyingRate: purchaseRate,
        salesRate: saleRate,
        code: currencyId,
        currency: `${shortName} (${fullName})`,
      }),
    ) ?? [];

  function onSaveOfficeRateOptionsBtnClick() {
    if (!officeRateId) return;

    dispatch(
      addNewExchangeRate({
        currencyId: officeRateId,
        body: {orgStructureId: officeId, saleRate: officeSalesRate, purchaseRate: officeBuyingRate},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("New office rate added");
        setValues(prev => ({
          ...prev,
          officeId: "",
          officeSalesRate: "",
          officeBuyingRate: "",
          officeRateId: "",
        }));
      }
    });
  }

  const ttTableColumns = useColumns(TT_RATE_TABLE_COLUMNS);
  const ttTableData = [];

  function onSaveTTRateOptionsBtnClick() {}

  return {
    ecbTableColumns,
    ecbTableData,
    officeTableColumns,
    officeTableData,
    ttTableColumns,
    ttTableData,
    officeOptions,
    rateOptions,
    onSaveOfficeRateOptionsBtnClick,
    onSaveTTRateOptionsBtnClick,
  };
};

export default useCurrencyRate;
