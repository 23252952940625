import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";
import translit from "ua-en-translit";

import {fetchData} from "../../../../../hook/axios.hook";
import saveImg from "../../../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../../utilities/Buttons/ImageBtn";
import {dateFormat} from "../../../../../utilities/dateFormat";
import {getInfo} from "../../../../../utilities/toasts";
import {useFiltersContext} from "../../../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../../Inputs";
import PdfFilePreview from "../../../../Inputs/fileSelect/preview/PdfPreview";
import {Select} from "../../../../Inputs/SelectField";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addEinfo,
  addEinfoFile,
  addProjectContract,
  delEinfo,
  delEinfoFile,
  fetchProjectEinfos,
  getCurrentProject,
  getDocsLoading,
  getProjectDocumentLoading,
  getProjectEinfo,
  updateEinfo,
} from "../../../ProjectSlice";
import {PROJECT_DOC_TYPES, SCREENSHPTS_DOCUMENTS_COLUMNS} from "../constants";

import ShowDocBtn from "./ShowDocBtn";

const ScreenShots = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentProject = useSelector(getCurrentProject);
  const einfos = useSelector(getProjectEinfo);
  const docsLoading = useSelector(getDocsLoading),
    listsLoading = useSelector(getProjectDocumentLoading);

  const {filters} = useFiltersContext();
  const {values, setValues, setFieldValue} = useFormikContext();

  const {einfoId} = values;

  const [currentEinfo, setCurrentEinfo] = useState(null);
  const [files, setFiles] = useState([]);

  const columns = useColumns(SCREENSHPTS_DOCUMENTS_COLUMNS);

  const fetchList = () => {
    let docFilters = {projectId: currentProject.projectId, ...filters};
    dispatch(fetchProjectEinfos(docFilters));
  };

  useEffect(() => {
    if (currentProject) fetchList();
  }, [currentProject, filters]);

  useEffect(() => {
    if (!einfoId) {
      setCurrentEinfo(null);
      setValues(prev => ({...prev, einfonote: "", einfodate: ""}));
      setFiles([]);
      return;
    }
    fetchData(`/einfo/get/${einfoId}`, "get").then(res => {
      setFiles(res.files);
    });
    let current = einfos.find(einfo => einfo.einfoId === einfoId);

    setCurrentEinfo(current);
    if (current) setValues(prev => ({...prev, einfonote: current.note, einfodate: current.date}));
  }, [einfoId]);

  const einfoNumberList =
    einfos?.map(einfo => ({
      title: einfo.einfoNumber,
      value: einfo.einfoId,
    })) || [];

  const tableData =
    einfos?.map((einfo, i) => ({
      number: i + 1,
      screenShotNumber: einfo.einfoNumber,
      creationDate: einfo?.date ? dateFormat(einfo?.date) : "",
      brieflyAbout: einfo?.note || "",
      id: einfo.einfoId,
    })) || [];

  const onAddButtonClick = () => {
    if (currentProject) {
      setValues(prev => ({...prev, einfoId: "", einfonote: "", einfodate: ""}));

      dispatch(addEinfo(currentProject.projectId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          Swal.fire({
            title: "",
            text: `${t("Screenshot added", {einfoNumber: res.payload.einfo.einfoNumber})}`,
            icon: "info",
            confirmButtonText: "OK",
          });
          setFieldValue("einfoId", res.payload.einfo.einfoId);
          fetchList();
        }
      });
    } else {
      getInfo(t("Project at first"));
    }
  };

  const onFileAdding = arrFiles => {
    let formdata = new FormData();
    let file = arrFiles[0];
    let filename = translit(file.name);
    formdata.append("file", file, filename);

    dispatch(addEinfoFile({einfoId: currentEinfo.einfoId, formdata})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setFiles(res.payload.files);
      }
    });
  };

  const onFileDel = fileId => {
    dispatch(delEinfoFile(fileId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        fetchData(`/einfo/get/${einfoId}`, "get").then(res => {
          setFiles(res.files);
        });
      }
    });
  };

  const onDocUpdate = () => {
    const {einfonote, einfodate} = values;
    dispatch(
      updateEinfo({
        einfoId: currentEinfo.einfoId,
        data: {
          date: einfodate,
          note: einfonote,
        },
      }),
    );
  };

  const onEinfoDelete = () => {
    Swal.fire({
      title: t("Sure"),
      text: `${t("Delete document")}?`,
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(answ => {
      if (answ.isConfirmed)
        dispatch(delEinfo(einfoId)).then(() => {
          setFieldValue("einfoId", null);
        });
    });
  };

  const saveBtnDisabled = useMemo(
    () => ["date", "note"].reduce((acc, option) => acc && currentEinfo?.[option] === values?.[`einfo${option}`], true),
    [values, currentEinfo],
  );

  const filesUrls = useMemo(() => files?.map(file => file.fileUrl), [files]);

  return (
    <div className="w100 fGrow fc col nowrap">
      <div className="fc nowrap g1 aic autolabel mb w100">
        <div style={{width: "8em"}}>
          <FunctionButton name="Add" style={{width: "8em", marginBottom: "0.5em"}} onClick={onAddButtonClick} />
          <FunctionButton name="Delete" style={{width: "8em"}} onClick={onEinfoDelete} disabled={!currentEinfo} />
        </div>
        <div>
          <Select
            label={t("ScreenShot №")}
            name="einfoId"
            options={einfoNumberList}
            inputClassName="ref_input"
            selectOnly={true}
            // question={t("Instructions")}
            generalClassName={"mb0_5"}
          />
          <DateInput label={t("Screenshot date")} name="einfodate" readOnly={!currentEinfo} />
        </div>
        <div className="fGrow">
          <TextInput
            label={t("Briefly about")}
            name="einfonote"
            width="80%"
            readOnly={!currentEinfo}
            generalClassName={"mb0_5"}
          />
          <div className="fc nowrap jcfe">
            <ImageButton
              src={saveImg}
              alt="save"
              className={"documentSaveBtn"}
              width={1.5}
              height={1.5}
              disabled={!currentEinfo || saveBtnDisabled}
              onClick={onDocUpdate}
            />
            <PdfFilePreview
              accept={".jpg,.jpeg,.png,.webp"}
              files={files || []}
              delFunc={onFileDel}
              addFunc={onFileAdding}
              disable={!currentEinfo}
              loading={docsLoading}
            />
            <ShowDocBtn type={PROJECT_DOC_TYPES.screenshot} files={filesUrls} disabled={!currentEinfo} />
          </div>
        </div>
      </div>
      <ReactTable
        defaultData={tableData}
        columns={columns}
        current={einfoId === "" ? null : einfoId}
        className={"project_table fGrow"}
        onSelect={id => {
          setFieldValue("einfoId", id);
        }}
        loading={listsLoading}
        style={{height: "5em"}}
      />
    </div>
  );
};

export default ScreenShots;
