import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {t} from "i18next";

import {fetchData, fetchList} from "../../../hook/axios.hook";
import {toaster} from "../../../utilities/hooks/toaster";
import {getSuccess} from "../../../utilities/toasts";

const initialState = {
  ownerTypes: [],
  types: [],
  classes: [],
  bsAccounts: [],
  currentBsAccount: null,
  accounts: [],
  currentAccount: null,
  transactionPackageGroups: [],
  deskOperations: [],
  allDirectives: [],
  directive: null,
  directiveDoc: null,
  currentOperation: null,
  transactions: [],
  readyToCreateDirectiveCO: null,
  operationTypes: [],
  finOrderTypes: [],
  repaymentTerms: [],
  createdDirective: null,
  statuses: [],
  finOrder: null,
  filters: [],

  allCurrency: [],

  allExchangeRates: [],
  allEcbRates: [],

  package: null,

  allExchangeRatesLoadingStatus: false,
  allEcbRatesLoadingStatus: false,
  finSettingsLoading: false,
  error: false,
};

//*** Operations ***

//** Balance Sheet Accounts **
export const fetchAllBsaTypes = createAsyncThunk("finSettings/fetchAllBsaTypes", async () =>
  fetchData("/finance/balanceSheet/type/getAll", "get"),
);
export const fetchAllBsaClasses = createAsyncThunk("finSettings/fetchAllBsaClasses", async () =>
  fetchData("/finance/balanceSheet/class/getAll", "get"),
);
export const fetchAllBsAccounts = createAsyncThunk("finSettings/fetchAllBsAccounts", async params =>
  fetchData("/finance/balanceSheet/getAll", "get", {params}),
);
export const fetchBsAccount = createAsyncThunk("finSettings/fetchAllBsAccount", async balanceSheetAccId => {
  if (!balanceSheetAccId) return;
  return fetchData(`/finance/balanceSheet/get/${balanceSheetAccId}`, "get");
});
export const addNewBsAccount = createAsyncThunk(
  "finSettings/addNewBsAccount",
  async ({balanceClassId, balanceTypeId, params}) => {
    if (!balanceClassId || !balanceTypeId) return;
    return fetchData(`/finance/balanceSheet/add/${balanceClassId}/${balanceTypeId}`, "post", {...params});
  },
);
export const updateBsAccount = createAsyncThunk("finSettings/updateBsAccount", async ({balanceSheetAccId, params}) => {
  if (!balanceSheetAccId) return;
  return fetchData(`/finance/balanceSheet/update/${balanceSheetAccId}`, "patch", {...params});
});
export const deleteBsAccount = createAsyncThunk("finSettings/deleteBsAccount", async balanceSheetAccId => {
  if (!balanceSheetAccId) return;
  return fetchData(`/finance/balanceSheet/remove/${balanceSheetAccId}`, "delete");
});
//** /Balance Sheet Accounts **

//** Accounts **
export const fetchAllAccounts = createAsyncThunk("finSettings/fetchAllAccounts", async () =>
  fetchData("/finance/accounts", "get"),
);
export const fetchOwnerTypes = createAsyncThunk("finSettings/fetchOwnerTypes", async () =>
  fetchData("/finance/accountOwners", "get"),
);
export const fetchAccount = createAsyncThunk("finSettings/fetchAccount", async accountId => {
  if (!accountId) return;
  return fetchData(`/finance/accounts/${accountId}`, "get");
});
export const checkAccount = createAsyncThunk("finSettings/checkAccount", async accountId =>
  fetchData(`/finance/accounts/${accountId}`, "head"),
);
export const addNewAccount = createAsyncThunk("finSettings/addNewAccount", async body => {
  return fetchData(`/finance/accounts`, "post", {...body});
});
export const saveAccount = createAsyncThunk("finSettings/saveAccount", async accountId => {
  if (!accountId) return;
  return fetchData(`/finance/accounts/${accountId}`, "post");
});
export const updateAccount = createAsyncThunk("finSettings/updateAccount", async ({accountId}) => {
  if (!accountId) return;
  return fetchData(`/finance/accounts/${accountId}`, "patch");
});
export const deleteAccount = createAsyncThunk("finSettings/deleteAccount", async accountId => {
  if (!accountId) return;
  return fetchData(`/finance/accounts/${accountId}`, "delete");
});
//** /Accounts **

//*** /Operations ***

//*** Transaction templates ***
export const fetchTransactionPackageGroups = createAsyncThunk("finSettings/fetchTransactionPackageGroups", async () =>
  fetchData("/finance/transactionTemplates/packages/groups/getAll", "get"),
);
//*** /Transaction templates ***

//*** Desk Operations ***
export const fetchAllDeskOperations = createAsyncThunk("finSettings/fetchAllDeskOperations", async params =>
  fetchData("/finance/deskOperations/getAll", "get", {params}),
);
export const runOperation = createAsyncThunk("finSettings/runOperation", async ({operationId, params}) => {
  if (!operationId) return;
  return fetchData(`/finance/deskOperations/run/${operationId}`, "put", null, null, false, params);
});
export const fetchOperation = createAsyncThunk("finSettings/fetchOperation", async operationId => {
  if (!operationId) return;
  return fetchData(`/finance/deskOperations/get/${operationId}`, "get");
});
//*** /Desk Operations ***

//*** Directives ***
export const fetchAllDirectives = createAsyncThunk("finSettings/fetchAllDirectives", async params =>
  fetchData("/finance/directives/getAll", "get", {params}),
);
export const fetchDirective = createAsyncThunk("finSettings/fetchDirective", async ({directiveId}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/get/${directiveId}`, "get");
});
export const saveDirective = createAsyncThunk("finSettings/saveDirective", async ({directiveId, body}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/save/${directiveId}`, "post", body);
});
export const addNewDirective = createAsyncThunk("finSettings/addNewDirective", async body =>
  fetchData(`/finance/directives/add`, "post", body),
);
export const updateDirective = createAsyncThunk("finSettings/updateDirective", async ({directiveId, body}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/update/${directiveId}`, "patch", body);
});
export const cancelDirective = createAsyncThunk("finSettings/cancelDirective", async directiveId => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/cancel/${directiveId}`, "put");
});
export const preCreateDirective = createAsyncThunk("finSettings/preCreateDirective", async ({cofId}) => {
  return fetchData(`/finance/directives/preCreate/${cofId}`, "post");
});

export const completeDirective = createAsyncThunk("finSettings/completeDirective", async ({directiveId}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/complete/${directiveId}`, "patch");
});
export const deleteDirective = createAsyncThunk("finSettings/deleteDirective", async ({directiveId}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/remove/${directiveId}`, "delete");
});
export const createOrder = createAsyncThunk("finSettings/createOrder", async ({directiveId}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/orders/create/${directiveId}`, "post");
});
export const uploadDirectiveDoc = createAsyncThunk(
  "finSettings/uploadDirectiveDoc",
  async ({directiveId, formdata}) => {
    if (!directiveId) return;
    return fetchData(`/finance/directives/docs/upload/${directiveId}`, "post", formdata);
  },
);
export const deleteDirectiveDoc = createAsyncThunk("finSettings/deleteDirectiveDoc", async ({docId}) => {
  if (!docId) return;
  return fetchData(`/finance/directives/docs/remove/${docId}`, "delete");
});
export const fetchDirectiveFilters = createAsyncThunk("finSettings/fetchDirectiveFilters", async params =>
  fetchData(`/finance/directives/getFilters`, "get", {params}),
);

//*** /Directives ***

//*** Transactions ***
export const fetchAllTransactions = createAsyncThunk("finSettings/fetchAllTransactions", async ({table}) =>
  fetchData("/finance/transactions/getAll", "get", {params: {table}}),
);
export const addNewTransaction = createAsyncThunk("finSettings/addNewTransaction", async ({orderId, body}) => {
  if (!orderId) return;
  return fetchData(`/finance/transactions/add/${orderId}`, "post", body);
});
//*** /Transactions ***

export const fetchAllCurrency = createAsyncThunk("finSettings/fetchAllCurrency", async () =>
  fetchData("/references/currency", "get"),
);

//*** Fin Orders */
export const fetchFinOrder = createAsyncThunk("finSettings/fetchFinOrder", async orderId => {
  if (!orderId) return;
  return fetchData(`/finance/directives/orders/get/${orderId}`, "get");
});
export const addNewFinOrder = createAsyncThunk("finSettings/addNewFinOrder", async ({directiveId, body}) => {
  if (!directiveId) return;
  return fetchData(`/finance/directives/orders/add/${directiveId}`, "post", body, null, true, null, true);
});
export const saveFinOrder = createAsyncThunk("finSettings/saveFinOrder", async orderId => {
  if (!orderId) throw new Error("Order not set");
  return fetchData(`/finance/directives/orders/save/${orderId}`, "patch");
});
export const printFinOrder = createAsyncThunk("finSettings/printFinOrder", async ({orderId}) => {
  if (!orderId) return;
  return fetchData(`/finance/directives/orders/print/${orderId}`, "patch");
});
export const completeFinOrder = createAsyncThunk("finSettings/completeFinOrder", async ({orderId}) => {
  if (!orderId) return;
  return fetchData(`/finance/directives/orders/complete/${orderId}`, "patch");
});
export const deleteFinOrder = createAsyncThunk("finSettings/deleteFinOrder", async ({orderId}) => {
  if (!orderId) return;
  return fetchData(`/finance/directives/orders/remove/${orderId}`, "delete");
});
export const fetchOperationTypes = createAsyncThunk("finSettings/fetchOperationTypes", async ({typeId}) =>
  fetchData(`/finance/directives/operationType/getAll`, "get", {params: {typeId}}),
);
export const fetchFinOrderTypes = createAsyncThunk("finSettings/fetchFinOrderTypes", async ({operationTypeId}) =>
  fetchData(`/finance/directives/orders/types/getAll`, "get", {params: {operationTypeId}}),
);
export const fetchRepaymentTerms = createAsyncThunk("finSettings/fetchRepaymentTerms", async () =>
  fetchData(`/finance/directives/orders/repaymentTerms/getAll`, "get"),
);

export const fetchAllFinStatuses = createAsyncThunk("finSettings/fetchAllFinStatuses", async () =>
  fetchList(`/finance/directives/status/getAll`, "get"),
);

export const runOrder = createAsyncThunk("finSettings/runOrder", async ({orderId, body}) => {
  return fetchData(`/finance/directives/orders/run/${orderId}`, "patch", body);
});

export const deleteOrder = createAsyncThunk("finSettings/deleteOrder", async orderId =>
  fetchData(`/finance/directives/orders/remove/${orderId}`, "delete"),
);

export const printOrder = createAsyncThunk("finSettings/printOrder", async orderId =>
  fetchData(`finance/directives/orders/print/${orderId}`, "patch"),
);

export const compleateOrder = createAsyncThunk("finSettings/compleateOrder", async ({orderId, note}) =>
  fetchData(`finance/directives/orders/complete/${orderId}`, "patch", {note}),
);

//*** Exchange Rates ***
export const fetchAllExchangeRates = createAsyncThunk(
  "finSettings/fetchAllExchangeRates",
  async ({page, dateStart, dateEnd}) =>
    fetchData(`/finance/exchangeRates/getAll`, "get", {params: {page, dateStart, dateEnd}}),
);
export const fetchAllEcbRates = createAsyncThunk("finSettings/fetchAllEcbRates", async ({page, dateStart, dateEnd}) =>
  fetchData(`/finance/exchangeRates/getEcbRates`, "get", {params: {page, dateStart, dateEnd}}),
);
export const fetchAllCurrencies = createAsyncThunk("finSettings/fetchAllCurrencies", async () =>
  fetchData(`/finance/exchangeRates/getCurrency`, "get"),
);
export const addNewExchangeRate = createAsyncThunk("finSettings/addNewExchangeRate", async ({currencyId, body}) => {
  if (!currencyId) return;
  return fetchData(`/finance/exchangeRates/add/${currencyId}`, "post", body, null, false, null, true);
});

//Packages
export const fetchPackage = createAsyncThunk("finSettings/fetchPackage", async transactionPackageId => {
  if (!transactionPackageId) return;
  return fetchData(`/finance/transactionTemplates/packages/get/${transactionPackageId}`, "get");
});
export const removePackage = createAsyncThunk("finSettings/removePackage", async transactionPackageId => {
  if (!transactionPackageId) return;
  return fetchData(`/finance/transactionTemplates/removeLast/${transactionPackageId}`, "delete");
});

export const FIN_SETTINGS_STORE_NAME = "finSettings";

const FinSettingsSlice = createSlice({
  name: FIN_SETTINGS_STORE_NAME,
  initialState,
  reducers: {
    clearCurrentBsAccount: state => {
      state.currentBsAccount = null;
    },
    clearCurrentAccount: state => {
      state.currentAccount = null;
    },
    clearDeskOperations: state => {
      state.deskOperations = [];
    },
    clearAccountOwners: state => {
      state.deskOperations = [];
    },
    clearDirectiveDoc: state => {
      state.directiveDoc = null;
    },
    clearDirective: state => {
      state.directive = null;
    },
    clearPackage: state => {
      state.package = null;
    },
    changeDirectivePurpose: (state, {payload: {transactionId, paymentPurpose}}) => {
      state.directive.transactions = state.directive.transactions.map(transaction => {
        if (transaction.transactionId === transactionId) {
          transaction.paymentPurpose = paymentPurpose;
        }
        return transaction;
      });
    },
    clearFinOrderTypes: state => {
      state.finOrderTypes = [];
    },
    clearOperationTypes: state => {
      state.operationTypes = [];
    },
    setCreatedDirective: (state, {payload}) => {
      state.createdDirective = payload;
    },
    clearCreatedDirective: state => {
      state.createdDirective = null;
    },
    setFinOrder: (state, {payload}) => {
      state.finOrder = payload;
    },
  },
  extraReducers: builder => {
    builder
      //** Balance Sheet Accounts **
      .addCase(fetchAllBsaTypes.fulfilled, (state, {payload}) => {
        state.types = payload;
      })
      .addCase(fetchOwnerTypes.fulfilled, (state, {payload}) => {
        state.ownerTypes = payload;
      })
      .addCase(fetchAllBsaClasses.fulfilled, (state, {payload}) => {
        state.classes = payload;
      })
      .addCase(fetchAllBsAccounts.fulfilled, (state, {payload}) => {
        state.bsAccounts = payload;
      })
      .addCase(fetchBsAccount.fulfilled, (state, {payload}) => {
        state.currentBsAccount = payload;
      })
      .addCase(addNewBsAccount.fulfilled, (state, {payload}) => {
        state.currentBsAccount = payload;
      })
      .addCase(updateBsAccount.fulfilled, (state, {payload}) => {
        state.currentBsAccount = payload;
      })
      .addCase(deleteBsAccount.fulfilled, state => {
        state.currentBsAccount = null;
      })
      //** Accounts **
      .addCase(fetchAllAccounts.fulfilled, (state, {payload}) => {
        state.accounts = payload.reverse();
      })
      .addCase(fetchAccount.fulfilled, (state, {payload}) => {
        state.currentAccount = payload;
      })
      .addCase(addNewAccount.fulfilled, (state, {payload}) => {
        state.currentAccount = payload.account;
      })
      .addCase(saveAccount.fulfilled, (state, {payload}) => {
        state.currentAccount = payload.account;
      })
      .addCase(deleteAccount.fulfilled, state => {
        state.currentAccount = null;
      })

      //*** Transaction templates ***
      .addCase(fetchTransactionPackageGroups.fulfilled, (state, {payload}) => {
        state.transactionPackageGroups = payload;
      })

      //*** Desk Operations ***
      .addCase(fetchAllDeskOperations.fulfilled, (state, {payload}) => {
        state.deskOperations = payload;
      })
      .addCase(runOperation.fulfilled, (state, {payload}) => {
        state.directive = payload;
      })
      .addCase(fetchOperation.fulfilled, (state, {payload}) => {
        state.currentOperation = payload;
      })

      //*** Transactions ***
      .addCase(fetchAllTransactions.fulfilled, (state, {payload}) => {
        state.transactions = payload;
      })
      .addCase(addNewTransaction.fulfilled, (state, {payload}) => {
        // state.transactions = payload.order.transactions;
        state.finOrder = payload.order;
      })

      //*** Directives ***
      .addCase(fetchAllDirectives.fulfilled, (state, {payload}) => {
        state.allDirectives = payload;
      })
      .addCase(fetchDirective.fulfilled, (state, {payload}) => {
        state.directive = payload;
      })
      .addCase(saveDirective.fulfilled, (state, {payload}) => {
        state.directive = payload;
      })
      .addCase(addNewDirective.fulfilled, (state, {payload}) => {
        state.createdDirective = payload.directive;
      })
      .addCase(updateDirective.fulfilled, (state, {payload}) => {
        state.directive = payload.directive;
      })
      .addCase(cancelDirective.fulfilled, state => {
        state.directive = null;
      })
      .addCase(completeDirective.fulfilled, (state, {payload}) => {
        state.directive = payload;
      })
      .addCase(deleteDirective.fulfilled, state => {
        state.directive = null;
      })
      .addCase(uploadDirectiveDoc.fulfilled, (state, {payload}) => {
        state.directiveDoc = payload.docs[0].files;
      })
      .addCase(deleteDirectiveDoc.fulfilled, (state, {payload}) => {
        state.directiveDoc = payload.docs;
      })
      .addCase(fetchDirectiveFilters.fulfilled, (state, {payload}) => {
        state.filters = payload;
      })

      .addCase(fetchAllCurrency.fulfilled, (state, {payload}) => {
        state.allCurrency = payload;
      })
      .addCase(preCreateDirective.fulfilled, (state, {payload}) => {
        state.readyToCreateDirectiveCO = payload.co;
      })

      //*** Fin Orders */
      .addCase(fetchFinOrder.fulfilled, (state, {payload}) => {
        state.finOrder = payload;
      })
      .addCase(addNewFinOrder.fulfilled, (state, {payload}) => {
        state.directive = payload.directive;
      })
      .addCase(addNewFinOrder.rejected, (state, {error}) => {
        toaster.warning(error?.message || "no credit account", 2500);
      })
      .addCase(saveFinOrder.fulfilled, (state, {payload}) => {
        state.finOrder = payload.order;
        getSuccess(t("The Order filled and saved", {orderNumber: payload.order.orderNumber}));
      })
      .addCase(printFinOrder.fulfilled, (state, {payload}) => {
        state.finOrder = payload.order;
      })
      .addCase(completeFinOrder.fulfilled, (state, {payload}) => {
        state.finOrder = payload;
      })
      .addCase(deleteFinOrder.fulfilled, state => {
        state.finOrder = null;
      })
      .addCase(fetchOperationTypes.fulfilled, (state, {payload}) => {
        state.operationTypes = payload;
      })
      .addCase(fetchFinOrderTypes.fulfilled, (state, {payload}) => {
        state.finOrderTypes = payload;
      })
      .addCase(fetchRepaymentTerms.fulfilled, (state, {payload}) => {
        state.repaymentTerms = payload;
      })

      .addCase(fetchAllFinStatuses.fulfilled, (state, {payload}) => {
        state.statuses = payload;
      })

      //*** Exchange Rates ***
      .addCase(fetchAllExchangeRates.pending, state => {
        state.allExchangeRatesLoadingStatus = true;
      })
      .addCase(fetchAllExchangeRates.fulfilled, (state, {payload}) => {
        state.allExchangeRates =
          payload.currentPage === 1 ? payload.rates : [...state.allExchangeRates, ...payload.rates];
        state.allExchangeRatesLoadingStatus = false;
      })
      .addCase(fetchAllExchangeRates.rejected, state => {
        state.allExchangeRatesLoadingStatus = false;
      })
      .addCase(fetchAllEcbRates.pending, state => {
        state.allEcbRatesLoadingStatus = true;
      })
      .addCase(fetchAllEcbRates.fulfilled, (state, {payload}) => {
        state.allEcbRates = payload.currentPage === 1 ? payload.rates : [...state.allEcbRates, ...payload.rates];
        state.allEcbRatesLoadingStatus = false;
      })
      .addCase(fetchAllEcbRates.rejected, state => {
        state.allEcbRatesLoadingStatus = false;
      })
      .addCase(fetchAllCurrencies.fulfilled, (state, {payload}) => {
        state.allCurrency = [{shortName: "EUR", currencyId: 978}, ...payload.currencies];
      })
      .addCase(addNewExchangeRate.fulfilled, (state, {payload}) => {
        state.allExchangeRates = [payload.rate, ...state.allExchangeRates];
      })
      .addCase(runOrder.fulfilled, (state, {payload}) => {
        state.finOrder = payload.order;
      })
      .addCase(compleateOrder.fulfilled, (state, {payload}) => {
        state.finOrder = payload;
        getSuccess(t("Order compleated"));
      })
      .addCase(printOrder.fulfilled, (state, {payload}) => {})
      .addCase(deleteOrder.fulfilled, (state, {meta}) => {
        state.finOrder = null;
        state.directive = {
          ...state.directive,
          orders: state.directive.orders.filter(order => order.orderId !== meta.arg),
        };
        getSuccess(t("Order deleted"));
      })

      //Packages
      .addCase(fetchPackage.fulfilled, (state, {payload}) => {
        state.package = payload;
      })
      .addCase(removePackage.fulfilled, (state, {payload}) => {
        state.package = payload;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(FIN_SETTINGS_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(FIN_SETTINGS_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(FIN_SETTINGS_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.finSettingsLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.finSettingsLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.finSettingsLoading = false;
  state.error = error;
}

const {actions, reducer} = FinSettingsSlice;

export const {
  clearCurrentBsAccount,
  clearCurrentAccount,
  clearDeskOperations,
  clearDirectiveDoc,
  changeDirectivePurpose,
  clearAccountOwners,
  clearDirective,
  clearFinOrderTypes,
  clearOperationTypes,
  clearCreatedDirective,
  setCreatedDirective,
  setFinOrder,
  clearPackage,
} = actions;

export default reducer;

// *** Selectors ***
export const selectBsaTypes = state => state[FIN_SETTINGS_STORE_NAME].types;
export const selectOwnerTypes = state => state[FIN_SETTINGS_STORE_NAME].ownerTypes;
export const selectBsaClasses = state => state[FIN_SETTINGS_STORE_NAME].classes;
export const selectAllBsAccounts = state => state[FIN_SETTINGS_STORE_NAME].bsAccounts;
export const selectBsCurrentAccount = state => state[FIN_SETTINGS_STORE_NAME].currentBsAccount;
export const selectAllAccounts = state => state[FIN_SETTINGS_STORE_NAME].accounts;
export const selectCurrentAccount = state => state[FIN_SETTINGS_STORE_NAME].currentAccount;
export const selectFinSettingsLoading = state => state[FIN_SETTINGS_STORE_NAME].finSettingsLoading;
export const selectTransactionPackageGroups = state => state[FIN_SETTINGS_STORE_NAME].transactionPackageGroups;
export const selectAllDeskOperations = state => state[FIN_SETTINGS_STORE_NAME].deskOperations;
export const selectCurrentOperation = state => state[FIN_SETTINGS_STORE_NAME].currentOperation;
export const selectAllTransactions = state => state[FIN_SETTINGS_STORE_NAME].transactions;
export const selectDirective = state => state[FIN_SETTINGS_STORE_NAME].directive;
export const selectDirectiveDoc = state => state[FIN_SETTINGS_STORE_NAME].directiveDoc;
export const selectAllCurrency = state => state[FIN_SETTINGS_STORE_NAME].allCurrency;
export const readyToCreateDirectiveCO = state => state[FIN_SETTINGS_STORE_NAME].readyToCreateDirectiveCO;
export const selectFinOrder = state => state[FIN_SETTINGS_STORE_NAME].finOrder;
export const selectReadyToCreateDirectiveCO = state => state[FIN_SETTINGS_STORE_NAME].readyToCreateDirectiveCO;
export const selectOperationTypes = state => state[FIN_SETTINGS_STORE_NAME].operationTypes;
export const selectFinOrderTypes = state => state[FIN_SETTINGS_STORE_NAME].finOrderTypes;
export const selectRepaymentTerms = state => state[FIN_SETTINGS_STORE_NAME].repaymentTerms;
export const selectCreatedDirective = state => state[FIN_SETTINGS_STORE_NAME].createdDirective;
export const selectAllStatuses = state => state[FIN_SETTINGS_STORE_NAME].statuses;
export const selectDirectiveFilters = state => state[FIN_SETTINGS_STORE_NAME].filters;
export const selectAllDirectives = state => state[FIN_SETTINGS_STORE_NAME].allDirectives;
export const selectAllExchangeRates = state => state[FIN_SETTINGS_STORE_NAME].allExchangeRates;
export const selectAllEcbRates = state => state[FIN_SETTINGS_STORE_NAME].allEcbRates;
export const selectAreAllExchangeRatesLoading = state => state[FIN_SETTINGS_STORE_NAME].allExchangeRatesLoadingStatus;
export const selectAreEcbRatesLoading = state => state[FIN_SETTINGS_STORE_NAME].allEcbRatesLoadingStatus;
export const selectPackage = state => state[FIN_SETTINGS_STORE_NAME].package;
