import {useTranslation} from "react-i18next";

import {Filters} from "../../../../conteiners/filters/Filter.hoc";
import {TextInput} from "../../../../Inputs";

const AccountOpeningTabFilters = ({onFilterChange, onFilterDelete}) => {
  const {t} = useTranslation();

  return (
    <Filters onFilterChange={onFilterChange} onFilterDelete={onFilterDelete}>
      <div className="w100 fc jcsb asc" style={{gap: "10%"}}>
        <TextInput label={t("Account №")} name="accountNumber" autolabel generalClassName="fGrow" />
        <TextInput label={t("Account name")} name="accountName" autolabel generalClassName="fGrow" />
        <TextInput label={t("Account owner")} name="owner" autolabel generalClassName="fGrow" />
      </div>
    </Filters>
  );
};

export default AccountOpeningTabFilters;
