import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import "moment/locale/uk";
// Загрузка шрифтов для pdfmake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generateDirectiveOrderPdf = order => {
  moment.locale("uk");
  // Определение стилей для таблицы
  const styles = {
    tableHeader: {
      bold: true,
      fontSize: 10,
      color: "black",
      alignment: "center",
    },
    cell: {
      fontSize: 9,
      color: "black",
      alignment: "left",
    },
    justy: {
      alignment: "center",
    },
    nestedTable: {
      margin: [20, 0, 0, 0],
      alignment: "left", // Убираем отступы внутри ячейки с вложенной таблицей
    },
    header: {
      bold: true,
      fontSize: 9,
    },
    headerH1: {
      bold: true,
      fontSize: 16,
      alignment: "center",
      margin: [0, 10, 0, 0],
    },
    headerH2: {
      bold: true,
      fontSize: 14,
      alignment: "center",
      margin: [0, 10, 0, 5],
    },
    simpletext: {
      margin: [0, 5, 0, 0],
    },
    emptyRow: {
      lineHeight: 2,
      color: "white",
    },
  };

  const headers = [
    {text: "Дебет", style: "tableHeader", accesor: "dt"},
    {text: "Кредит", style: "tableHeader", accesor: "ct"},
    {text: "Сума", style: "tableHeader", accesor: "sum"},
    {text: "Валюта", style: "tableHeader", accesor: "curr"},
    {text: "Призначення платежу", style: "tableHeader", accesor: "payPurpose"},
  ];

  const tableContent = [];

  tableContent.push(headers);

  order.transactions.forEach(transaction => {
    const tableRow = [];
    let dataObj = {
      dt: transaction.debitAccount.accountNumber,
      ct: transaction.creditAccount.accountNumber,
      sum: transaction?.sum || "",
      curr: transaction?.currency?.shortName || "",
      payPurpose: transaction.paymentPurpose,
    };
    for (let header of headers) {
      tableRow.push({
        text: dataObj[header.accesor],
        style: "cell",
        pageBreak: "avoid",
        alignment: "right",
      });
    }

    tableContent.push(tableRow);
  });

  const documentDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",

    content: [
      {text: `Ордер №: ${order.orderNumber}`, style: "simpletext"},
      {
        text: `Дата розпорядження: ${moment(order.createdAt)?.format("DD MMMM YYYY[р.]")}           Оплата до: ${moment(
          order.createdAt,
        )?.format("DD MMMM YYYY[р.]")}`,
        style: "simpletext",
        // margin: [0, 0, 20, 20],
      },
      // {
      //   text: `оплата до ${moment(order.createdAt)?.format("DD MMMM YYYY[р.]")}`,
      //   style: "simpletext",
      // },
      {text: `КП №: ${order?.coNumber || ""}`, style: "simpletext"},
      {text: `Менеджер КП: ${order?.coManager || ""}`, style: "simpletext"},
      {text: `Тип операції: ${order?.operationType || ""}`, style: "simpletext"},
      {
        text: `Валюта платежу: ${order?.currency?.shortName || ""}       Сума платежу: ${order?.sum || ""}`,
        style: "simpletext",
        margin: [0, 5, 0, 20],
      },
      {
        table: {
          widths: ["auto", "auto", "auto", "auto", "*"],
          style: "nestedTable",
          body: tableContent,
        },
        layout: {
          dontBreakRows: true, // Это свойство предотвращает разрыв строк между страницами
          pageBreak: "before",
        },
      },
      // {text: `Прийнято від: ${order.creator.name}`, style: "simpletext", margin: [0, 20, 0, 5]},
    ],
    styles: styles,
  };

  // Генерация и экспорт PDF-файла
  var pdf = pdfMake.createPdf(documentDefinition);
  // pdf.print();
  // pdf.download(tableData.name);
  // pdf.open();
  return pdf;
};
