import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../conteiners/ReactTable";
import {NumberInput, TextAreaInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

import useBalanceSheetAccountsTab from "./useBalanceSheetAccountTab";

const BalanceSheetAccountsTab = () => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {
    typeOptions,
    accountClassOptions,
    balanceTableColumns,
    balanceTableData,
    onSaveBtnClick,
    onEditBtnClick,
    onDeleteBtnClick,
    onBalanceTableRowSelect,
    currentAccountId,
    onBalanceClassSelect,
    isSaveBtnDisabled,
    isEditBtnDisabled,
  } = useBalanceSheetAccountsTab();

  return (
    <div className="w100 fcCol aistr pb0_5 h100">
      <div className="dg gridTemplate2Rows gridTemplate3ColsFirstMin gap0_5 pt rowGap">
        <Select
          name="balanceClassId"
          label={t("Account class")}
          options={accountClassOptions ?? []}
          width="10em"
          makeEffect={data => onBalanceClassSelect({data})}
        />
        <TextAreaInput
          name="balanceClassDescription"
          height="45px"
          className="fGrow w100"
          readOnly
          placeholder={t("Balance class desciption")}
        />
        <TextAreaInput
          name="debitDescription"
          className="fGrow w100"
          style={{height: "100%"}}
          placeholder={t("Amounts are debited to the account")}
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
        />
        <div className="column aist">
          <NumberInput
            name="balanceSerialNumber"
            label={t("Balance №")}
            width="4em"
            question={t("must consist of 4 digits and start with account class digit")}
            inputClassName="border ml"
            questionItemClassName="pl1_5x"
            noFormat
            maxNumberLength={4}
          />

          <Select
            name="balanceTypeId"
            label={t("Type")}
            options={typeOptions ?? []}
            width="6em"
            style={{marginTop: "12px"}}
          />
        </div>
        <div className="column noGap">
          <TextAreaInput
            name="balanceName"
            height="45px"
            className="fGrow w100"
            style={{paddingTop: 0}}
            placeholder={t("Account naming")}
          />
          <TextAreaInput
            name="balancePurpose"
            height="45px"
            className="fGrow w100"
            style={{paddingTop: "5px"}}
            placeholder={t("Account purpose")}
          />
        </div>
        <TextAreaInput
          name="creditDescription"
          className="fGrow w100 h100"
          style={{paddingTop: 0, height: "100%"}}
          placeholder={t("Amounts are debited to the Credit account")}
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
        />
      </div>
      <div className="row jcfe gap2x mt">
        <FunctionButton
          name="Save"
          onClick={onSaveBtnClick}
          disabled={isSaveBtnDisabled}
          tooltipMessage={
            !values.balanceSerialNumber?.startsWith(values.balanceClassId) ? "wrongFirstBalanceNumber" : ""
          }
        />
        <FunctionButton name="Edit" onClick={onEditBtnClick} disabled={isEditBtnDisabled} />
        <FunctionButton name="Delete" onClick={onDeleteBtnClick} disabled={!currentAccountId} />
      </div>

      <ReactTable
        className="mt fGrow"
        tableClassName="fGrow"
        columns={balanceTableColumns}
        defaultData={balanceTableData}
        current={currentAccountId}
        onSelect={onBalanceTableRowSelect}
        style={{minHeight: "25em", flexGrow: 1}}
      />
    </div>
  );
};

export default BalanceSheetAccountsTab;
