import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ModalForm from "../../Modal/FormModal";
import SkuReactTableList from "../../SKU/SkuReactTableList";
import {getCurrentSku, getSkuInfo} from "../../SKU/SkuSlice";

import KpPreviewModal from "./kpFinForm/KpPreviewModal";
import {deselect} from "./KpFinSlice";

const KpFSkuList = ({onAddSkuToKpf}) => {
  const {t} = useTranslation();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const currentSku = useSelector(getCurrentSku);
  const dispatch = useDispatch();

  const onIdealSkuDoubleClick = sku => {
    dispatch(getSkuInfo(sku?.id)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setPreviewModalOpen(true);
      }
    });
  };

  // useEffect(() => {
  //   dispatch(deselect());
  // }, [currentSku]);

  return (
    <>
      <SkuReactTableList
        onRowDoubleClick={onIdealSkuDoubleClick}
        enableColumnResizing={true}
        onSkuSelect={() => {
          dispatch(deselect());
        }}
      />
      <ModalForm
        closable={true}
        Component={KpPreviewModal}
        label={t("View SKU") + currentSku?.article}
        active={previewModalOpen}
        setModalActive={setPreviewModalOpen}
        className="modal_sizes_preview"
        onAddSkuToKpf={onAddSkuToKpf}
      />
    </>
  );
};

export default KpFSkuList;
