import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {getCurrentTemplate} from "../../../slices/KPSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../utilities/dateFormat";
import Divider from "../../../utilities/Divider/Divider";
import makeNameFromPersona from "../../../utilities/makeNameFromPersona";
import {TextInput} from "../../Inputs";
import CharDeskBlock from "../../KP/kp-f/kpFinForm/kpFinFormBlocks/CharDeskBlock";
import DecorColorBlock from "../../KP/kp-f/kpFinForm/kpFinFormBlocks/DecorColorBlock";
import NameBlock from "../../KP/kp-f/kpFinForm/kpFinFormBlocks/NameBlock";
import SeriesBatchBlock from "../../KP/kp-f/kpFinForm/kpFinFormBlocks/SeriesBatchBlock";
import SummBlock from "../../KP/kp-f/kpFinForm/kpFinFormBlocks/SummBlock";
import VolumeWeightCharacteristicsKpf from "../../KP/kp-f/kpFinForm/kpFinFormBlocks/VolumeWeightCharacteristicsKpf";
import useKpFinDataService from "../../KP/kp-f/kpFinTable/KpFinDataService";
import useKpFinTableService from "../../KP/kp-f/kpFinTable/KpFinTableService";
import useKpFinCollumnsService from "../../KP/kp-f/kpFinTable/utils/kpFinColumns";
import ModalForm from "../../Modal/FormModal";
import {fetchSkuCatalogs} from "../../SKU/SkuSlice";

import ChooseCoModal from "./ChooseCoModal/ChooseCoModal";
import TemplateImageBlock from "./TemplatesImagesBlock";

const CoTemplates = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();

  const {values, setValues} = useFormikContext();

  const [chooseCoModalActive, setChooseCoModalActive] = useState(false);
  const [currentSkuTemplate, setCurrentSkuTemplate] = useState(null);

  const currentTemplate = useSelector(getCurrentTemplate);

  const initialLine = {
    tCoNumber: currentTemplate?.templateNumber,
    projectManager: makeNameFromPersona({
      persona: currentTemplate?.cof?.co?.project?.projectManager?.persona,
      language,
    }),
    managerCo: makeNameFromPersona({persona: currentTemplate?.cof?.co?.managerCo?.persona, language}),
    created: dateFormat(currentTemplate?.createdAt),
    generalNote: currentTemplate?.note,
  };

  useEffect(() => {
    setValues({
      ...currentSkuTemplate,
      ...initialLine,
    });
  }, [currentSkuTemplate]);

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
  }, []);

  const {getKpFinTableDataForTemplates} = useKpFinDataService();

  const selectSkuPlan = id => {
    if (currentSkuTemplate && currentSkuTemplate.skuTemplateId === id) {
      setCurrentSkuTemplate(null);
      return;
    }
    const neededSkuTemp = currentTemplate.skuTemplates.find(temp => temp.skuTemplateId === id);
    setCurrentSkuTemplate(neededSkuTemp);
  };

  const onReorder = () => {};

  const kpFinTableData = useMemo(() => {
    return currentTemplate?.skuTemplates?.map(getKpFinTableDataForTemplates) || [];
  }, [currentTemplate?.skuTemplates]);

  const toggledColl = useMemo(() => ["article", "characteristics", "complectDesc", "discount", "topDiscount"], []);
  const {collumns} = useKpFinCollumnsService(toggledColl);

  const {KpFinTableRender} = useKpFinTableService(collumns, kpFinTableData, onReorder, selectSkuPlan, toggledColl);

  const KpFinTable = KpFinTableRender();

  const readOnly = true;

  return (
    <>
      {values && (
        <div className="form_wrapper" style={{padding: "1em", paddingTop: "0", paddingBottom: "0.5em"}}>
          <div className="f_block w100 nowrap" style={{paddingTop: "0.7em"}}>
            <TextInput label={t("tCO №")} name="tCoNumber" width="100%" autolabel readOnly />
            <TextInput label={t("Manager")} name="projectManager" width="100%" autolabel readOnly />
            <TextInput label={t("Manager CO")} name="managerCo" width="100%" autolabel readOnly />
            <TextInput label={t("About")} name="generalNote" width="100%" autolabel />
            <TextInput label={t("C date")} name="created" width="100%" autolabel readOnly />
          </div>
          <Divider />
          <div>
            <div className="fields_check form-part">
              <div className="upper-block">
                <div className="part-with-img">
                  <div className="left-part-p">
                    <NameBlock readOnly={readOnly} />
                    <CharDeskBlock readOnly={readOnly} />
                  </div>

                  <div className="image-block-three">
                    <TemplateImageBlock label={"OverallView"} images={currentSkuTemplate?.uploadedOverallView} />
                    <TemplateImageBlock label={"Product view"} images={currentSkuTemplate?.sku?.images} />
                    <TemplateImageBlock label={"Drawings"} images={currentSkuTemplate?.sku?.drawings} />
                  </div>

                  <div className="image-block-two">
                    <TemplateImageBlock label={"Finishing"} images={currentSkuTemplate?.sku?.decorImages} />
                    <TemplateImageBlock label={"Color"} images={currentSkuTemplate?.sku?.colorImages} />
                  </div>
                  <DecorColorBlock readOnly={readOnly} />
                </div>
                <div className="lower-part">
                  <SeriesBatchBlock readOnly={readOnly} />
                  <div className="vwchar">
                    <label className="label">{t("VWC")}</label>
                    <VolumeWeightCharacteristicsKpf readOnly={readOnly} />
                  </div>
                </div>
              </div>
            </div>
            <TextInput
              label="SKU*"
              name="article"
              question="curr sku"
              labelStyles={{width: "3em"}}
              readOnly={true}
              width="10em"
              disabled={readOnly}
            />
            <SummBlock readOnly={readOnly} initialLine={initialLine} />
            <Divider />
          </div>

          {KpFinTable}

          <div className="fdr gap2x jcfe mb0_5">
            <FunctionButton name={t("Create CO(f)")} onClick={() => setChooseCoModalActive(true)} autoWidth />
          </div>
          <ModalForm
            // closable={true}
            Component={ChooseCoModal}
            label={t("Creating CO(f) from template")}
            active={chooseCoModalActive}
            setModalActive={setChooseCoModalActive}
            className="largeModal"
            templateIds={[currentTemplate?.cofTemplateId]}
          />
        </div>
      )}
    </>
  );
};

export default CoTemplates;
