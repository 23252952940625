import {ContextMenu, ContextMenuOption} from "../../../hook/useContextMenu";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";

const ResiduesContextMenu = ({
  onToTheClientBalanceBtnClick,
  onConversionBtnClick,
  onCheckTransactionsBtnClick,
  onConductBtnClick,
  onCreateDebtBtnClick,
  menuVisible,
  menuPosition,
  currentBankTransactionId,
  isOperationBetweenOwnAccounts,
  onViewTransactionsBtnClick,
  isTransactionWithNoClient,
  onAssociateResidueTransactionWithClientBtnClick,
  isAlignedResidue,
}) => {
  const {filters: savedFilters = {}} = useFiltersContext();

  return (
    <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
      <ContextMenuOption
        text={"View Account"}
        onClick={() => onViewTransactionsBtnClick({isResidue: true})}
        disabled={!currentBankTransactionId}
      />
      {isTransactionWithNoClient ? (
        <ContextMenuOption
          text={"Associate with a client"}
          onClick={() => onAssociateResidueTransactionWithClientBtnClick({savedFilters})}
          disabled={!currentBankTransactionId || isOperationBetweenOwnAccounts || isAlignedResidue}
        />
      ) : (
        <ContextMenuOption
          text={"To the Client`s balance"}
          onClick={() => onToTheClientBalanceBtnClick({savedFilters, isResidue: true})}
          disabled={!currentBankTransactionId || isOperationBetweenOwnAccounts}
        />
      )}
      <ContextMenuOption text={"Crediting to the balance"} onClick={() => {}} disabled />
      <ContextMenuOption text={"Conversion"} onClick={onConversionBtnClick} disabled />
      <ContextMenuOption text={"Check transactions"} onClick={onCheckTransactionsBtnClick} disabled />
      <ContextMenuOption text={"Conduct"} onClick={onConductBtnClick} disabled />
      <ContextMenuOption text={"Create debt"} onClick={onCreateDebtBtnClick} disabled />
    </ContextMenu>
  );
};

export default ResiduesContextMenu;
