import {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {numberFormat} from "../../../../utilities/moneyFormat";
import {processImages} from "../../../SKU/VED/constants/functions";

import {procformatter} from "./utils/functionalFormatters";

const useKpFinDataService = () => {
  // const {i18n} = useTranslation();
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const mappingObj = useMemo(() => {
    return {
      productSeriesCheck: "productSeries",
      materialCheck: "material",
      colorCheck: "color",
      batchCheck: "batch",
    };
  }, []);

  const joinCharacteristics = plan => {
    var currList = [];
    for (var prop in mappingObj) {
      if (plan[prop]) {
        var name = mappingObj[prop];
        if (plan[name] && plan[name] !== "") currList.push(plan[name]);
      }
    }
    return currList.join(", ");
  };

  const countOvh = (value, unit) => {
    if (value && unit)
      return value > 0
        ? numberFormat(value * unit.multiplier) + (unit?.name ? unit[language === "en" ? "engName" : "name"] : null)
        : "";
    else return "";
  };

  const ovhJoin = sku => {
    try {
      var ovhValues = [];

      if (sku.overallSizeCheck) {
        const {length, width, height, overallSizeUnit} = sku;
        var dimensionMass = [length, width, height];
        const hasDimensionValue = dimensionMass.some(item => item !== null && item !== "0.00");
        const name = language === "en" ? "engName" : "name";
        const multiplier = overallSizeUnit?.multiplier;

        dimensionMass.map(item => {
          if (item > 0) return countOvh(item, multiplier);
          else return "";
        });

        const updatedNumbers = dimensionMass.map(num => (num === null || num === "0.00" ? "0" : num));

        if (hasDimensionValue) {
          ovhValues.push(`${updatedNumbers[0]}x${updatedNumbers[1]}x${updatedNumbers[2]} ${overallSizeUnit[name]}`);
        }
      }

      const ovhKeys = ["area", "volume", "weight", "pallet"];
      let addedOvhKey = false;

      ovhKeys.forEach(key => {
        if (sku[key + "Check"]) {
          const value = countOvh(sku[key], sku[key + "Unit"]);
          if (value) {
            ovhValues.push(value);
            addedOvhKey = true;
          }
        }
      });

      if (addedOvhKey) {
        return ovhValues.join(" ");
      }

      if (ovhValues.length > 0) {
        return ovhValues.join(" ");
      }

      return "";
    } catch (error) {
      return "";
    }
  };

  const getKpFinTableData = plan => {
    const {settlementPrice, amount, topDiscount, discount, topDiscountCheck, discountCheck} = plan;
    var summ = (+settlementPrice || +plan.retailPrice) * (amount || 1);
    var total = discountCheck ? summ - (summ * discount) / 100 : summ;
    var toPay = topDiscountCheck ? total - (total * topDiscount) / 100 : total;

    return {
      number: plan?.sequenceNumber || null,
      article: plan?.brand?.prefix + "-" + plan?.article,
      id: plan.skuPlanId,
      brandName: plan.brand?.name,
      skuName: plan.nameCheck ? plan.name : plan.nameEng,
      characteristics: plan.charCheck ? joinCharacteristics(plan) : "",
      ovh: ovhJoin(plan),
      image1: plan.addedImages?.length ? plan.addedImages[0]?.imageUrl : "",
      image2: plan.addedImages?.length >= 2 ? plan.addedImages[1].imageUrl : "",
      complectDesc: plan.complectDescCheck ? plan.complectDesc : "",
      amount: plan.amount,
      measurUnits: language === "en" ? plan?.sku?.measurementUnit?.engName : plan?.sku?.measurementUnit?.name,
      price: plan.retailPrice,
      summ: summ,
      discount: plan.discountCheck ? procformatter.format(plan.discount / 100) : "",
      totalSumm: total,
      topDiscount: plan.topDiscountCheck ? procformatter.format(plan.topDiscount / 100) : "",
      paymentSumm: toPay,
      overallView: processImages(plan?.addedOverallView),
      decorText: plan?.materialTypeCheck ? plan?.materialType : "",
      decorImages: processImages(plan?.addedDecorImages),
      colorText: plan?.colorTypeCheck ? plan?.colorType : "",
      colorImages: processImages(plan?.addedColorImages),
      headerData: plan?.note,
    };
  };

  const getKpFinTableDataForTemplates = plan => {
    const {settlementPrice, amount, topDiscount, discount, topDiscountCheck, discountCheck} = plan;
    var summ = (+settlementPrice || +plan.retailPrice) * (amount || 1);
    var total = discountCheck ? summ - (summ * discount) / 100 : summ;
    var toPay = topDiscountCheck ? total - (total * topDiscount) / 100 : total;

    return {
      number: plan?.sequenceNumber || null,
      article: plan?.article,
      id: plan.skuTemplateId,
      brandName: plan.brand?.name,
      skuName: plan.nameCheck ? plan.name : plan.nameEng,
      characteristics: plan.charCheck ? joinCharacteristics(plan) : "",
      ovh: ovhJoin(plan),
      image1: plan.addedImages?.length ? plan.addedImages[0]?.imageUrl : "",
      image2: plan.addedImages?.length >= 2 ? plan.addedImages[1].imageUrl : "",
      complectDesc: plan.complectDescCheck ? plan.complectDesc : "",
      amount: plan.amount,
      measurUnits: language === "en" ? plan?.sku?.measurementUnit?.engName : plan?.sku?.measurementUnit?.name,
      price: plan.retailPrice,
      summ: summ,
      discount: plan.discountCheck ? procformatter.format(plan.discount / 100) : "",
      totalSumm: total,
      topDiscount: plan.topDiscountCheck ? procformatter.format(plan.topDiscount / 100) : "",
      paymentSumm: toPay,
      overallView: processImages(plan?.addedOverallView),
      decorText: plan?.materialTypeCheck ? plan?.materialType : "",
      decorImages: processImages(plan?.addedDecorImages),
      colorText: plan?.colorTypeCheck ? plan?.colorType : "",
      colorImages: processImages(plan?.addedColorImages),
      headerData: plan?.note,
    };
  };

  const getRequestSkuTableData = sku => {
    const {amount, listPrice, topDiscountCheck, discountCheck, discount, topDiscount} = sku;
    var summ = listPrice * (amount || 1);
    var total = discountCheck ? summ - (summ * discount) / 100 : summ;
    var toPay = topDiscountCheck ? total - (total * topDiscount) / 100 : total;
    return {
      number: sku.sequenceNumber,
      article: sku?.brand?.prefix + "-" + sku?.article,

      id: sku.skuRequestId,
      brandName: sku.brand?.name,
      skuName: sku.nameCheck ? sku.name : sku.nameEng,
      characteristics: sku.charCheck ? joinCharacteristics(sku) : "",
      ovh: ovhJoin(sku),
      image1: sku.addedImages?.length ? sku.addedImages[0].imageUrl : "",
      image2: sku.addedImages?.length >= 2 ? sku.addedImages[1].imageUrl : "",
      complectDesc: sku.complectDescCheck ? sku.complectDesc : "",
      amount: sku.amount,

      price: sku?.retailPrice,
      paymentSumm: toPay,
      decorText: sku?.materialTypeCheck ? sku?.materialType : "",
      decorImages: processImages(sku?.addedDecorImages),
      colorText: sku?.colorTypeCheck ? sku?.colorType : "",
      colorImages: processImages(sku?.addedColorImages),
      overallView: null,

      headerData: sku?.note,
    };
  };

  const getRequestVarificationSkuTableData = sku => {
    const {amount, listPrice} = sku;
    var summ = listPrice * (amount || 1);

    return {
      number: sku.sequenceNumber,
      article: sku?.brand?.prefix + "-" + sku?.article,

      id: sku.skuRequestId,
      brandName: sku.brand?.name,
      skuName: sku.nameCheck ? sku.name : sku.nameEng,
      characteristics: sku.charCheck ? joinCharacteristics(sku) : "",
      ovh: ovhJoin(sku),
      image1: sku.addedImages?.length ? sku.addedImages[0] : "",
      image2: sku.addedImages?.length >= 2 ? sku.addedImages[1] : "",
      complectDesc: sku.complectDescCheck ? sku.complectDesc : "",
      amount: sku.amount,
      measurUnits: language === "en" ? sku?.sku?.measurementUnit?.engName : sku?.sku?.measurementUnit?.name,
      price: listPrice,
      paymentSumm: summ,
      decorText: sku?.materialTypeCheck ? sku?.materialType : "",
      decorImages: sku?.addedDecorImages,
      colorText: sku?.colorTypeCheck ? sku?.colorType : "",
      colorImages: sku?.addedColorImages,
      headerData: sku?.note,
      isSelectFroze: sku?.isInspected,
    };
  };

  return {getKpFinTableData, getRequestSkuTableData, getRequestVarificationSkuTableData, getKpFinTableDataForTemplates};
};

export default useKpFinDataService;
