import React from "react";
import {t} from "i18next";

import {TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import PageContainer from "./../PageContainer";
import {Filters, SessionFiltersContainer} from "./Filter.hoc";
import {useFiltersContext} from "./useSessionFiltersContext";

const Demo = () => {
  return (
    <PageContainer title={"Settings (FinBlock)"}>
      <SessionFiltersContainer window={"DEMO"}>
        <DEMO_FIlters />

        <DEMO2 />
      </SessionFiltersContainer>
    </PageContainer>
  );
};

const DEMO2 = () => {
  const {filters} = useFiltersContext();

  return (
    <>
      <p>HELOOO</p> {JSON.stringify(filters)}
    </>
  );
};

const DEMO_FIlters = () => {
  return (
    <Filters>
      <div>
        <TextInput label={t("Address")} required name="address" />
      </div>
      <TextInput label={t("Country")} required name="country" />
      <TextInput label={t("Index")} name="index" />
      <Select
        label={t("Color")}
        options={[
          {value: "1", title: "red"},
          {value: "2", title: "blue"},
        ]}
        name="color"
      />
    </Filters>
  );
};
export default Demo;
