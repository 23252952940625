import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import useFileFolderPicker from "../../../../../hook/useFileFolderPicker";
import {dateFormat, timeFormat} from "../../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../../utilities/formatAccountNumber";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  fetchAllBankClientStatements,
  fetchAllBankTransactions,
  selectBankClientStatements,
  selectFinBlockLoading,
  uploadBankClientStatements,
} from "../../../FinBlockSlice";
import {BANK_STATEMENTS_LIST_TABLE_COLUMNS} from "../../constants";

const useBankStatementsList = () => {
  const bankStatementsListColumns = useColumns(BANK_STATEMENTS_LIST_TABLE_COLUMNS);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const {files, handleFolderUpload, folderPickerRef, clearFilesArray} = useFileFolderPicker();

  useEffect(() => {
    if (files?.length > 0) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append("file", file);
      });
      dispatch(uploadBankClientStatements(formData)).then(resp => {
        clearFilesArray();
        if (resp.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllBankTransactions());
        }
      });
    }
  }, [files]);

  const onGetDataBtnClick = () => {
    swalCustom.info({
      text: `${t("bank client folder pick")}\n *${t("folder pick warning")} `,
      noTitle: true,
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmFunc: () => folderPickerRef.current.click(),
    });
  };

  const bankClientStatements = useSelector(selectBankClientStatements);

  const bankStatementsListData = useMemo(
    () =>
      bankClientStatements?.map((statement, index) => {
        const isDebit = Boolean(statement?.isDebit);
        return {
          id: statement?.statementId,
          trId: statement?.transactionUuid,
          number: index + 1,
          // bank: statement?.bank || "-",
          mfi: statement?.bankMfis,
          accountNumber: formatAccountNumber(statement?.iban),
          // accHolderName: "?",

          docNumber: statement?.documentNumber || "-",
          // docDate: "?",
          date: dateFormat(statement?.dateAndTime),
          time: timeFormat(statement?.dateAndTime),
          costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
          revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
          currency: statement?.currency?.shortName || "-",
          counterpartyAccountNumber: formatAccountNumber(statement?.counterpartyIban),
          requisitesC: statement?.counterpartyEdrpou,
          counterpartyName: statement?.counterparty,
          counterpartyBank: statement?.counterpartyBank || "-",
          mfiC: statement?.counterpartyBankMfis || "-",
          paymentPurpose: statement?.paymentPurpose,
          rate: statement?.rate || "-",
          equivalent: statement?.nbuEquivalentSum || "-",
          commissionsAmmount: statement?.feesSum,
          remainder: isDebit ? statement?.debitAccount?.balance : statement?.creditAccount?.balance, // !!!!!
          reference: "?",
          operationCode: statement?.operationCode,
          status: "?",
        };
      }) || [],
    [bankClientStatements],
  );
  const onBankStatementsListFilterChange = filters => {
    dispatch(fetchAllBankClientStatements({...filters}));
  };
  const loading = useSelector(selectFinBlockLoading);

  return {
    loading,
    bankStatementsListColumns,
    bankStatementsListData,
    onBankStatementsListFilterChange,
    folderPickerRef,
    onGetDataBtnClick,
    handleFolderUpload,
  };
};

export default useBankStatementsList;
