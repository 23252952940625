import {useFormikContext} from "formik";

import Divider from "../../../../../utilities/Divider/Divider";
import ReactTable from "../../../../conteiners/ReactTable";

import CreditParams from "./parts/CreditParams";
import MiniOverDraftParams from "./parts/MiniOverDraftParams";
import useCreditOverdraftTab from "./useCreditOverdraftTab";

const CreditOverdraftTab = () => {
  const {
    columns,
    defaultData,
    onPauseMiniOverdraftBtnClick,
    onRestoreMiniOverdraftBtnClick,
    onDeleteMiniOverdraftBtnClick,
    onSaveMiniOverdraftBtnClick,
    onPauseCreditBtnClick,
    onSaveCreditBtnClick,
    onSelectParam,
    onDeleteCreditBtnClick,
  } = useCreditOverdraftTab();

  const {
    values: {currentParamId},
  } = useFormikContext();

  return (
    <div className="grid2ColWithDivider gap2x h100 pb5 pt5">
      <div className="fcCol aistr">
        <MiniOverDraftParams
          onPauseMiniOverdraftBtnClick={onPauseMiniOverdraftBtnClick}
          onRestoreMiniOverdraftBtnClick={onRestoreMiniOverdraftBtnClick}
          onDeleteMiniOverdraftBtnClick={onDeleteMiniOverdraftBtnClick}
          onSaveMiniOverdraftBtnClick={onSaveMiniOverdraftBtnClick}
        />
        <Divider />
        <CreditParams
          onPauseCreditBtnClick={onPauseCreditBtnClick}
          onSaveCreditBtnClick={onSaveCreditBtnClick}
          onDeleteCreditBtnClick={onDeleteCreditBtnClick}
          onRestoreCreditBtnClick={onRestoreMiniOverdraftBtnClick}
        />
      </div>
      <div className="h100">
        <ReactTable columns={columns} defaultData={defaultData} onSelect={onSelectParam} current={currentParamId} />
      </div>
    </div>
  );
};

export default CreditOverdraftTab;
