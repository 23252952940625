import ReactTable from "../../../../../conteiners/ReactTable";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {ACC_ASSOTIATION_TABLE_COLUMNS} from "../constants";

const AccAssociationTable = () => {
  const loading = false;

  const columns = useColumns(ACC_ASSOTIATION_TABLE_COLUMNS);
  const defaultData = [];

  return (
    <div className="fGrow fcCol">
      <ReactTable
        className="fGrow"
        tableClassName="fGrow"
        columns={columns}
        defaultData={defaultData}
        // current={currentAccountId}
        // onSelect={onAccountTableRowSelect}
        loading={loading}
      />
    </div>
  );
};

export default AccAssociationTable;
