import {useState} from "react";

import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {SELLER_DETAILS_TABLE_COLUMNS} from "./constants";

const useSellerDetailsTab = () => {
  const loading = false;
  const [current, setCurrent] = useState(null);

  const sellerDetailsTableColumns = useColumns(SELLER_DETAILS_TABLE_COLUMNS);
  const sellerDetailsTableData = [];

  //PG part
  const [currentProductGroup, setCurrentProductGroup] = useState(null);

  const productGroupTableData = [];
  const onAddProductGroupBtnClick = () => {};
  const onDeleteProductGroupBtnClick = () => {};

  //Seller part
  const [currentSeller, setCurrentSeller] = useState(null);

  const sellerTableData = [];
  const onAddSellerBtnClick = () => {};
  const onDeleteSellerBtnClick = () => {};

  //Seller Details part
  const onAddSellerDetailBtnClick = () => {};
  const onDeleteSellerDetailBtnClick = () => {};

  return {
    sellerDetailsTableColumns,
    sellerDetailsTableData,
    current,
    setCurrent,
    loading,
    productGroupTableData,
    currentProductGroup,
    setCurrentProductGroup,
    onDeleteProductGroupBtnClick,
    onAddProductGroupBtnClick,
    sellerTableData,
    currentSeller,
    setCurrentSeller,
    onDeleteSellerBtnClick,
    onAddSellerBtnClick,
    onDeleteSellerDetailBtnClick,
    onAddSellerDetailBtnClick,
  };
};

export default useSellerDetailsTab;
