export const CASHDESK_DIRECTIVE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 20,
    maxSize: 20,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 60,
    maxSize: 60,
    isSortable: true,
    textAlign: "start",
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  // {
  //   header: "Date To",
  //   accessorKey: "dateTo",
  //   size: 50,
  //   maxSize: 50,
  //   isSortable: true,
  // },
  // {
  //   header: "Sum (n)",
  //   accessorKey: "sumPlan",
  //   size: 50,
  //   maxSize: 50,
  //   isSortable: true,
  // },
  {
    header: "Sum (f)",
    accessorKey: "sumFact",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    size: 90,
    maxSize: 90,
    alignStart: true,
    isSortable: true,
  },
  {
    header: "Directive type",
    accessorKey: "operationType",
    // size: 120,
    // maxSize: 120,
    alignStart: true,
    isSortable: true,
  },
  // {
  //   header: "Client №",
  //   accessorKey: "clientNumber",
  //   size: 40,
  //   maxSize: 40,
  //   isSortable: true,
  // },
  // {
  //   header: "CO №",
  //   accessorKey: "coNumber",
  //   size: 50,
  //   maxSize: 50,
  //   isSortable: true,
  // },
  {
    header: "Payer type",
    accessorKey: "payerType",
    size: 50,
    maxSize: 60,
    isSortable: true,
    textAlign: "start",
  },
  {
    header: "Payer",
    accessorKey: "payerName",
    size: 100,
    alignStart: true,
    isSortable: true,
  },
  {
    header: "Receiver type",
    accessorKey: "receiverType",
    size: 50,
    maxSize: 65,
    isSortable: true,
    textAlign: "start",
  },
  {
    header: "Receiver",
    accessorKey: "receiverName",
    size: 50,
    isSortable: true,
    textAlign: "start",
  },
  {
    header: "Note",
    accessorKey: "note",
    size: 300,
    alignStart: true,
    // isSortable: true,
  },
  // {
  //   header: "Payer`s EDRPOUO",
  //   accessorKey: "payerEDRPOU",
  //   size: 70,
  //   maxSize: 70,
  //   isSortable: true,
  // },
  // {
  //   header: "Payer`s account",
  //   accessorKey: "payerAccount",
  //   size: 100,
  //   isSortable: true,
  // },
]);

export const DIRECTIVE_TYPE_PART_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 15,
  },
  {
    header: "Directive type",
    accessorKey: "directiveType",
    size: 100,
    alignStart: true,
  },
]);

export const PAYER_TYPE_PART_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 20,
  },
  {
    header: "Payer type",
    accessorKey: "payerType",
    size: 60,
    maxSize: 60,
    alignStart: true,
  },
  {
    header: "Payer ",
    accessorKey: "payerName",
    size: 200,
    maxSize: 300,
    alignStart: true,
  },
]);

export const RECEIVER_TYPE_PART_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    maxSize: 20,
  },
  {
    header: "Receiver type",
    accessorKey: "receiverType",
    size: 60,
    maxSize: 60,
    alignStart: true,
  },
  {
    header: "Receiver",
    accessorKey: "receiverName",
    size: 200,
    maxSize: 300,
    alignStart: true,
  },
]);
