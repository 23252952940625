export const ADDITIONAL_SERV_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 70,
    isSortable: true,
  },
  {
    header: "Service type",
    accessorKey: "serviceType",
    size: 70,
    isSortable: true,
  },
  {
    header: "costPrice",
    accessorKey: "costPrice",
    size: 100,
    isSortable: true,
  },

  {
    header: "note",
    accessorKey: "note",
    alignStart: true,
    size: 100,
    isSortable: true,
  },
]);
