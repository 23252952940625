import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import {addNewFinOrder, selectFinOrder} from "../../../FinSettings/FinSettingsSlice";

const ClientCreditingMod = ({amount, currency = "EUR", setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentOrder = useSelector(selectFinOrder);
  const directiveId = currentOrder?.directive?.directiveId;

  const onClientExtraPayBtnClick = () => {
    setModalActive(false);
  };
  const onCreditBtnClick = () => {
    dispatch(addNewFinOrder({directiveId, body: {typeId: 5}}));
  };
  const onCancelBtnClick = () => {
    setModalActive(false);
  };

  return (
    <div className="fcCol jcsb fGrow padding gap2x">
      <div
        className="fcCol tac gap0_5"
        style={{fontSize: "20px", width: "70%", lineHeight: "25px", whiteSpace: "pre-wrap"}}
      >
        {t("toCloseOrderAmount")}
        <div className="row gap italic">
          <span>{currency}</span>
          <span>{numberFormat(amount)}</span>
        </div>
        {t("createAnApplicationForCredit")}
      </div>

      <div className="row w100 jcsa">
        <FunctionButton name="Client extra pay" autoWidth onClick={onClientExtraPayBtnClick} />
        <FunctionButton name="On credit!" autoWidth onClick={onCreditBtnClick} />
        <FunctionButton name="Cancel" autoWidth onClick={onCancelBtnClick} />
      </div>
    </div>
  );
};

ClientCreditingMod.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
};

export default ClientCreditingMod;
