import React from "react";

import {ContextMenu, ContextMenuOption} from "../../../hook/useContextMenu";
import ShowIcon from "../../../images/icons/openEye.png";
import PencilIcon from "../../../images/icons/pencil.png";
import DeleteIcon from "../../../images/icons/trash.png";
import Divider from "../../../utilities/Divider/Divider";
const CashDirectibesContextMenu = ({onDelete, onOpenDirective, onEdit, menuVisible, menuPosition}) => {
  return (
    <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
      <ContextMenuOption icon={ShowIcon} text={"Open order"} onClick={onOpenDirective} />
      <ContextMenuOption icon={PencilIcon} text={"Edit order"} onClick={onEdit} />
      <Divider />
      <ContextMenuOption icon={DeleteIcon} text={"Cancel directive"} onClick={onDelete} />
    </ContextMenu>
  );
};

export default CashDirectibesContextMenu;
