export const stockPoolNumber_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },

  {
    header: "Request status",
    accessorKey: "requestStatus",
    isSortable: true,
  },
  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    isSortable: true,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    isSortable: true,
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
    isSortable: true,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    isSortable: true,
    size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    size: 50,
  },
  {
    header: "Request date",
    accessorKey: "createdAt",
    size: 70,
  },

  {
    header: "Bring to",
    accessorKey: "bringTo",
    // size: 100,
  },
  {
    header: "Product country",
    accessorKey: "country",
    isSortable: true,
    size: 80,
  },
  {
    header: "Prof №",
    accessorKey: "prof",
    // size: 100,
  },
  {
    header: "Invoice №",
    accessorKey: "invoice",
    // size: 100,
  },
  {
    header: "Packing List №",
    accessorKey: "packL",
    // size: 100,
  },
  {
    header: "Tr pool №",
    accessorKey: "trPool",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    alignStart: true,
    size: 270,
  },
]);

export const UPPER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },

  {
    header: "Request status",
    accessorKey: "requestStatus",
    isSortable: true,
    size: 60,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    isSortable: true,
    size: 100,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    isSortable: true,
    size: 100,
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
    isSortable: true,
    size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    isSortable: true,
    size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    size: 50,
  },
  {
    header: "Request date",
    accessorKey: "requestDate",
    size: 70,
  },

  {
    header: "Bring to",
    accessorKey: "bringTo",
    // size: 100,
  },
  {
    header: "Product country",
    accessorKey: "country",
    isSortable: true,
    size: 80,
  },
  {
    header: "Prof №",
    accessorKey: "proformaNumber",
    // size: 100,
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
    // size: 100,
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
    // size: 100,
  },
  {
    header: "Tr pool №",
    accessorKey: "trPoolNumber",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    alignStart: true,
    size: 270,
  },
]);

export const LOWER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },

  {
    header: "Request status",
    accessorKey: "requestStatus",
    isSortable: true,
  },
  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
    isSortable: true,
    // size: 100,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    isSortable: true,
  },
  {
    header: "CO Manager",
    accessorKey: "managerCo",
    isSortable: true,
  },
  {
    header: "FEA Manager",
    accessorKey: "managerFea",
    isSortable: true,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    isSortable: true,
    size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "requestSum",
    size: 50,
  },
  {
    header: "Request date",
    accessorKey: "requestDate",
    size: 70,
  },

  {
    header: "Bring to",
    accessorKey: "bringTo",
    // size: 100,
  },
  {
    header: "Product country",
    accessorKey: "country",
    isSortable: true,
    size: 80,
  },
  {
    header: "Prof №",
    accessorKey: "proformaNumber",
    // size: 100,
  },
  {
    header: "Invoice №",
    accessorKey: "invoiceNumber",
    // size: 100,
  },
  {
    header: "Packing List №",
    accessorKey: "packingListNumber",
    // size: 100,
  },
  {
    header: "Tr pool №",
    accessorKey: "trPoolNumber",
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    alignStart: true,
    size: 270,
  },
]);

export const SLEEPING_REQ_MODAL_TYPES = {
  sp: "SP",
  request: "REQUEST",
};

export const SLEEPING_WINDOW_PARAMS = Object.freeze({SLEEPING: "Sleeping", SLEEPING_IN_SP: "Sleeping in SP"});
