import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../../conteiners/ReactTable";
import {Select} from "../../../../../Inputs";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {SELLERS_TABLE_COLUMNS} from "../constants";

const Seller = ({sellerTableData, currentSeller, setCurrentSeller, onDeleteSellerBtnClick, onAddSellerBtnClick}) => {
  const {t} = useTranslation();
  const columns = useColumns(SELLERS_TABLE_COLUMNS);

  const sellerOptions = [];

  return (
    <div className="grid2Col gap w100">
      <div className="fcCol jcsb aist gap">
        <div>{t("Seller(i) :")}</div>
        <Select
          options={sellerOptions}
          label=""
          name="sellerId"
          width="18rem"
          labelStyles={{display: "none"}}
          generalClassName="asfe"
        />
        <div className="row w100">
          <FunctionButton name="Delete" onClick={onDeleteSellerBtnClick} autoWidth />
          <FunctionButton name="Add" onClick={onAddSellerBtnClick} autoWidth />
        </div>
      </div>
      <ReactTable
        columns={columns}
        defaultData={sellerTableData}
        current={currentSeller}
        onSelect={setCurrentSeller}
        enableColumnResizing={true}
        className="tableTac fGrow"
        tableClassName="fGrow"
        //   loading={loading}
      />
    </div>
  );
};

export default Seller;
