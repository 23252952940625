import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import {lastUpdateDate} from "../../gitIgnore/updateDate";
import {clearPreviousUserLocation, login, selectPreviousUserLocation} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {TextInput} from "../Inputs";
import PasswordInput from "../Inputs/PasswordInput/PasswordInput";

const LoginForm = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {token, user} = useSelector(state => state.user);
  const previousUserLocation = useSelector(selectPreviousUserLocation);

  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    return {
      login: "",
      password: "",
    };
  }, []);

  useEffect(() => {
    if (token && user) {
      navigate(previousUserLocation ?? "/ba3a/main");
      dispatch(clearPreviousUserLocation());
    }
  }, [token]);

  return (
    <div className="login-form__container">
      <div className="login_form">
        <label className="label login_form_label">{t("Auth")}</label>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            login: Yup.string().required(t("Enter Login")),
            password: Yup.string().required(t("Enter Password")),
          })}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={values => {
            dispatch(login(values));
          }}
        >
          {({values}) => (
            <Form className="project_form">
              <TextInput label={t("Login")} id="login" name="login" type="text" />
              <PasswordInput label={t("Password")} id="password" name="password" />
              <p>
                {t("Update")} {lastUpdateDate} {"test deploy 2"}
              </p>
              <FunctionButton type="submit" name={t("Sign in")} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
