import {t} from "i18next";

import {TextInput} from "../../../../../Inputs";

const PayerData = ({readOnly = false}) => {
  // const {
  //   values: {payerNameComposed, edrpouoComposed, payerAccountComposed, areAllFieldsFilledIn},
  //   setFieldValue,
  // } = useFormikContext();

  // useEffect(() => {
  //   if (!payerNameComposed || !edrpouoComposed || !payerAccountComposed) {
  //     areAllFieldsFilledIn && setFieldValue("areAllFieldsFilledIn", false);
  //   }
  //   if (payerNameComposed && edrpouoComposed && payerAccountComposed && !areAllFieldsFilledIn) {
  //     setFieldValue("areAllFieldsFilledIn", true);
  //   }
  // }, [payerNameComposed, edrpouoComposed, payerAccountComposed]);

  return (
    <div className="fcCol aistr gap w100 mb" style={{border: "1px solid #A3B9D9", padding: "0.5em"}}>
      <TextInput name="payerNameComposed" label={t("Payer Name")} autolabel readOnly={readOnly} />
      <TextInput name="edrpouoComposed" label={t("Payer`s EDRPOUO")} autolabel readOnly={readOnly} width="10em" />
      <TextInput name="payerAccountComposed" label={t("Payer`s account")} autolabel readOnly={readOnly} />
    </div>
  );
};

export default PayerData;
