import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";

import {getCurrentUser} from "../../../../../slices/UserSlice";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../utilities/hooks/toaster";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addNewBsAccount,
  clearCurrentBsAccount,
  deleteBsAccount,
  fetchAllBsAccounts,
  fetchAllBsaClasses,
  fetchAllBsaTypes,
  fetchBsAccount,
  selectAllBsAccounts,
  selectBsaClasses,
  selectBsaTypes,
  selectBsCurrentAccount,
  updateBsAccount,
} from "../../FinSettingsSlice";
import {BALANCE_TABLE_COLUMNS} from "../constants";

const useBalanceSheetAccountsTab = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();

  useEffect(() => {
    dispatch(fetchAllBsaTypes());
    dispatch(fetchAllBsaClasses());
    dispatch(fetchAllBsAccounts());
  }, []);

  const currentUser = useSelector(getCurrentUser);
  const types = useSelector(selectBsaTypes);
  const classes = useSelector(selectBsaClasses);
  const allAccounts = useSelector(selectAllBsAccounts);
  const currentAccount = useSelector(selectBsCurrentAccount);
  const currentAccountId = currentAccount?.balanceSheetAccId;

  const emptyTabValues = {
    balanceClassId: "",
    balanceClassDescription: "",
    debitDescription: `${t("Amounts are debited to the account")}: `,
    balanceNumber: "",
    balanceTypeId: "",
    balanceName: "",
    balancePurpose: `${t("Account purpose")} `,
    creditDescription: `${t("Amounts are debited to the Credit account")}: `,
    balanceSerialNumber: "",
  };

  useEffect(() => {
    setValues(prev => ({...emptyTabValues, ...prev}));
  }, []);

  const typeOptions =
    types?.map(({balanceTypeName, balanceTypeId}) => ({title: balanceTypeName, value: balanceTypeId})) ?? [];
  const accountClassOptions =
    classes?.map(({description, balanceClassId}) => ({
      title: String(balanceClassId),
      value: balanceClassId,
      description,
    })) ?? [];

  const balanceTableColumns = useColumns(BALANCE_TABLE_COLUMNS);
  const balanceTableData =
    allAccounts?.map(
      (
        {
          balanceSheetAccId,
          // balanceSerialNumber,
          balanceTypeId,
          balanceName,
          balancePurpose,
          creditDescription,
          debitDescription,
        },
        index,
      ) => ({
        number: ++index,
        id: balanceSheetAccId,
        balanceSerialNumber: balanceSheetAccId,
        type: typeOptions?.find(option => option.value === balanceTypeId)?.title ?? "",
        nameAndPurpose: `${balanceName ?? ""} ${balancePurpose ?? ""}`,
        operationDescription: `${creditDescription ?? ""} ${debitDescription ?? ""}`,
      }),
    ) ?? [];

  const isSaveBtnDisabled =
    !!currentAccountId ||
    !values.balanceClassId ||
    !values.balanceTypeId ||
    !values.balanceSerialNumber ||
    !/^\d{4}$/.test(values.balanceSerialNumber) ||
    !values.balanceSerialNumber?.startsWith(values.balanceClassId);

  const isEditBtnDisabled =
    !currentAccountId ||
    isEqual(formLocalValues(values), formValues(currentAccount)) ||
    values.balanceSerialNumber?.length !== 4;

  function formLocalValues(values) {
    const {
      balanceClassId,
      balanceName,
      balancePurpose,
      creditDescription,
      debitDescription,
      balanceTypeId,
      balanceSerialNumber,
      balanceClassDescription,
    } = values;

    return {
      balanceSerialNumber,
      balanceClassId,
      balanceClassDescription,
      debitDescription,
      balanceTypeId,
      balanceName,
      balancePurpose,
      creditDescription,
    };
  }

  function formValues(currentAccount) {
    if (!currentAccount) {
      return emptyTabValues;
    }

    const {
      balanceClassId,
      balanceName,
      balancePurpose,
      creditDescription,
      debitDescription,
      balanceTypeId,
      // balanceSerialNumber,
      balanceSheetAccId,
      class: balanceClass,
    } = currentAccount;
    return {
      balanceSerialNumber: String(balanceSheetAccId),
      balanceClassId,
      balanceClassDescription: balanceClass?.description,
      debitDescription,
      balanceTypeId,
      balanceName,
      balancePurpose,
      creditDescription,
    };
  }

  function onBalanceClassSelect({data}) {
    setValues(prev => ({...prev, balanceClassId: data.value, balanceClassDescription: data.description}));
  }

  function onBalanceTableRowSelect(id) {
    if (!id) {
      dispatch(clearCurrentBsAccount());
      setValues(prev => ({...prev, ...emptyTabValues}));
      return;
    }
    dispatch(fetchBsAccount(id)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setValues(prev => ({...prev, ...formValues(resp.payload)}));
      }
    });
  }

  function onSaveBtnClick() {
    const {
      balanceClassId,
      balanceTypeId,
      balanceName,
      balancePurpose,
      debitDescription,
      creditDescription,
      balanceSerialNumber,
    } = values;
    if (!balanceClassId || !balanceTypeId) return;

    dispatch(
      addNewBsAccount({
        balanceClassId,
        balanceTypeId,
        params: {
          balanceSheetAccId: balanceSerialNumber,
          balanceSerialNumber: balanceSerialNumber,
          balanceName,
          balancePurpose,
          creditDescription,
          debitDescription,
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("New balance sheet account created");
        dispatch(fetchAllBsAccounts());
      }
    });
  }

  function onEditBtnClick() {
    const {balanceName, balancePurpose, debitDescription, creditDescription, balanceSheetAccId} = values;

    if (!currentAccountId) return;

    dispatch(
      updateBsAccount({
        balanceSheetAccId: currentAccountId,
        params: {balanceSheetAccId, balanceName, balancePurpose, creditDescription, debitDescription},
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Balance sheet account updated");
        dispatch(fetchAllBsAccounts());
      }
    });
  }

  function onDeleteBtnClick() {
    if (!currentAccountId || !currentAccount || !currentUser) return;
    if (currentAccount.creatorId !== currentUser.id) {
      toaster.error("An account can be deleted only by its creator or by a superuser");
      return;
    }

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteBsAccount(currentAccountId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("Balance sheet account deleted");
            dispatch(clearCurrentBsAccount());
            dispatch(fetchAllBsAccounts());
            setValues(prev => ({...prev, ...emptyTabValues}));
          }
        });
      },
    });
  }

  return {
    typeOptions,
    accountClassOptions,
    balanceTableColumns,
    balanceTableData,
    onSaveBtnClick,
    onEditBtnClick,
    onDeleteBtnClick,
    onBalanceTableRowSelect,
    currentAccountId,
    onBalanceClassSelect,
    isSaveBtnDisabled,
    isEditBtnDisabled,
  };
};

export default useBalanceSheetAccountsTab;
