import {t} from "i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

const CounterpartyAssociation = ({onSaveAssociationBtnClick = () => {}}) => {
  const counterpartyTypeOptions = [];
  const transactionTypeOptions = [];

  return (
    <div className="border padding0_5 row aic">
      <span className="header">{`1. ${t("Counterparty Association")}`}</span>
      <Select name="counterpartyType" label={t("Counterparty Type")} options={counterpartyTypeOptions} autolabel />
      <TextInput name="clientNumber" label="№" autolabel />
      <Select name="transactionType" label={t("Transaction type ")} options={transactionTypeOptions} autolabel />
      <FunctionButton name="Save association" onClick={onSaveAssociationBtnClick} autoWidth />
    </div>
  );
};

export default CounterpartyAssociation;
