import React, {useEffect} from "react";
import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {useModal} from "../../../Modal/ModalProvider";

const PdfPreviewModal = ({pdfFile, exportToPdf}) => {
  const {closeModal} = useModal();
  useEffect(() => {
    if (pdfFile) {
      pdfFile.getDataUrl(dataUrl => {
        const iframe = document.createElement("iframe");
        const parent = document.getElementById("pdfContainer");
        iframe.src = dataUrl;
        iframe.style.width = "100%";
        iframe.style.height = "500px";
        parent.appendChild(iframe);
      });
    }
  }, []);
  return (
    <div className="w100">
      <div className="w100" id={"pdfContainer"}></div>
      <div className="fc w100 nowrap jcc g2 mt">
        <FunctionButton
          onClick={() => {
            exportToPdf(false);
            closeModal();
          }}
          name={t("Save")}
          autoWidth
        />
        <FunctionButton
          onClick={() => {
            exportToPdf(true);
            closeModal();
          }}
          name={t("Save + pdf")}
          autoWidth
        />
      </div>
    </div>
  );
};

export default PdfPreviewModal;
