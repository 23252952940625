import {useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";

import DevTicketsList from "../Components/Reference/DevTicketsList/DevTicketsList";
import {getCurrentUser} from "../slices/UserSlice";
import {IT_MANAGERS_IDS} from "../utilities/constants";

const ReferencePage = () => {
  const user = useSelector(getCurrentUser);
  const isITManager = IT_MANAGERS_IDS.includes(user?.id);
  return <Routes>{isITManager && <Route path="/tasksList" element={<DevTicketsList />} />}</Routes>;
};

export default ReferencePage;
