import React from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";

import FieldWithSample from "../../../../Inputs/FieldWithSample";
import useVWCharInputsService from "../../../../SKU/viewWidthCharInputs/vwCharInputsService";

const VolumeWeightCharacteristicsKpf = ({readOnly}) => {
  const {values} = useFormikContext();

  const {t} = useTranslation();

  const {area, dimension, volume, weight, pallet} = useVWCharInputsService("6em", "Dimension");

  // const renderPallet = () => {
  //   return (
  //     <FieldWithSample name="palletCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "100%"}}>
  //       {/* <div className=" nowrap autolabel"> */}
  //       <label>{t("Pallet")}</label>

  //       <input name="" value={values.pallet?.name} readOnly={true} style={{width: "7em"}} />

  //       <label>{t("Length")}</label>
  //       <input name="" value={values.pallet?.length} readOnly={true} style={{width: "5em"}} />
  //       <label>{t("Width")}</label>
  //       <input name="" value={values.pallet?.width} readOnly={true} style={{width: "5em"}} />
  //       {/* </div> */}
  //     </FieldWithSample>
  //   );
  // };

  return (
    <div className="" style={{display: "flex", flexDirection: "row", gap: "10px"}}>
      {values?.length ||
      values?.width ||
      (values?.height && (+values?.length > 0 || +values?.width > 0 || +values?.height > 0)) ? (
        <FieldWithSample name="overallSizeCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "100%"}}>
          {dimension}
        </FieldWithSample>
      ) : null}
      {values?.area && +values?.area > 0 && (
        <FieldWithSample name="areaCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "100%"}}>
          {area}
        </FieldWithSample>
      )}
      {values?.volume && +values?.volume > 0 && (
        <FieldWithSample name="volumeCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "100%"}}>
          {volume}
        </FieldWithSample>
      )}
      {values?.weight && +values?.weight > 0 && (
        <FieldWithSample name="weightCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "100%"}}>
          {weight}
        </FieldWithSample>
      )}
      {values?.pallet && (
        <FieldWithSample name="palletCheck" readOnly={readOnly} style={{alignItems: "self-end", width: "100%"}}>
          {pallet}
        </FieldWithSample>
      )}
    </div>
  );
};

export default VolumeWeightCharacteristicsKpf;
