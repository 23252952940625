import {withFormik} from "formik";
import {t} from "i18next";

import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import PageContainer from "../../conteiners/PageContainer";
import {DateInput} from "../../Inputs";

import CurrencyRateBody from "./CurrencyRateBody";
import useCurrencyRate from "./useCurrencyRate";

const CurrencyRate = () => {
  const {
    ecbTableColumns,
    ecbTableData,
    officeTableColumns,
    officeTableData,
    ttTableColumns,
    ttTableData,
    officeOptions,
    rateOptions,
    onSaveOfficeRateOptionsBtnClick,
    onSaveTTRateOptionsBtnClick,
  } = useCurrencyRate();

  return (
    <PageContainer title={t("Exchange rates")}>
      <SessionFiltersContainer window="ExchangeRates">
        <Filters>
          <div className="row w100 gap2x h100 aic jcfs">
            <DateInput label={t("Period From")} name="dateStart" />
            <DateInput label={t("To")} name="dateEnd" />
          </div>
        </Filters>
        <Divider />
        <CurrencyRateBody
          ecbTableColumns={ecbTableColumns}
          ecbTableData={ecbTableData}
          officeTableColumns={officeTableColumns}
          officeTableData={officeTableData}
          ttTableColumns={ttTableColumns}
          ttTableData={ttTableData}
          officeOptions={officeOptions}
          rateOptions={rateOptions}
          onSaveOfficeRateOptionsBtnClick={onSaveOfficeRateOptionsBtnClick}
          onSaveTTRateOptionsBtnClick={onSaveTTRateOptionsBtnClick}
        />
      </SessionFiltersContainer>
    </PageContainer>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({officeBuyingRate: "", officeSalesRate: "", ttBuyingRate: "", ttSalesRate: ""}),
})(CurrencyRate);
