import {t} from "i18next";

import Divider from "../../../../../../utilities/Divider/Divider";
import ReactTable from "../../../../../conteiners/ReactTable";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {BANK_CLIENT_TABLE_COLUMNS} from "../../../constants";

const AssociatedTransaction = ({associatedTransactionTableData = [], onAssociatiedTransactionSelect = () => {}}) => {
  const columns = useColumns(BANK_CLIENT_TABLE_COLUMNS);

  return (
    <div className="fcCol w100 gap0_5">
      <div className="asfs header">{t("Transaction with associated accounts")}</div>
      <ReactTable
        columns={columns}
        defaultData={associatedTransactionTableData}
        onSelect={onAssociatiedTransactionSelect}
        className="fGrow scrollX scrollY fShrink"
        current={null}
        loading={false}
        style={{height: "5rem"}}
      />
      <Divider margin="5px" />
    </div>
  );
};

export default AssociatedTransaction;
