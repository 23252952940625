import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {delSkuPlan, deselect, getCurrentSkuPlan, updateSkuPlan} from "../KpFinSlice";

import KpFinForm from "./KpFinForm";

const KpCreateForm = ({setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const onSubmit = values => {
    // if (values.discount === "")
    dispatch(
      updateSkuPlan({
        skuPlanId: values.skuPlanId,
        skuPlan: {
          ...values,
          discount: values?.discount === "" ? null : values.discount,
          topDiscount: values?.topDiscount === "" ? null : values.topDiscount,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        // resetForm();
        setModalActive(false);
        dispatch(deselect());
      }
    });
  };

  const onCancel = () => {
    dispatch(delSkuPlan(currentSkuPlan.skuPlanId));

    setModalActive(false);
  };

  return (
    <KpFinForm
      saveLabel={t("Add to CO(f)")}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isShowOverallView={true}
      toolTip="save and return kp upd create"
    />
  );
};

KpCreateForm.propTypes = {setModalActive: PropTypes.func};

export default KpCreateForm;
