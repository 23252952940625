import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import DoubleCheckIcon from "../../../images/icons/doubleCheck3x.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import Divider from "../../../utilities/Divider/Divider";
import ToolTip from "../../../utilities/ToolTip";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../conteiners/ReactTable";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {fetchAllDirectives, fetchAllFinStatuses, selectAllStatuses} from "../../FinBlock/FinSettings/FinSettingsSlice";

import useProjectDirectiveList from "./useProjectDirectiveList";

const ProjectDirectiveList = ({readOnly}) => {
  const {
    getFilterData,
    onFilterDelete,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
    currentDirectiveId,
    loading,
    filtersLayout,
    formPayerDataParamName,
    setFilterFields,
    onDoubleClick,
    isNoCoDirective,
    onSortCreditBtnClick,
    isCredit,
    onProjectDirectiveTablePaginate,
  } = useProjectDirectiveList({readOnly});

  const PROJECT_DIRECTIVE_LIST_WINDOW_NAME = "ProjectDirectiveList";
  const FINBLOCK_DIRECTIVE_LIST_WINDOW_NAME = "FinBlockDirectiveList";
  const disabled = !currentDirectiveId;

  return (
    <div className="fcCol h100 w100 pb">
      <SessionFiltersContainer
        window={readOnly ? PROJECT_DIRECTIVE_LIST_WINDOW_NAME : FINBLOCK_DIRECTIVE_LIST_WINDOW_NAME}
        onFilterChange={getFilterData}
      >
        <Filters onFilterDelete={onFilterDelete}>
          <ToolTip title="Sort by credit" style={{position: "absolute", top: "2.8em", left: 27, width: "50px"}} bottom>
            <ImageButton
              src={DoubleCheckIcon}
              width={2}
              height={1.5}
              onClick={onSortCreditBtnClick}
              className={`fc jcc w100 mt br ${isCredit ? "bgcActive" : "bgc"}`}
            />
          </ToolTip>

          {filtersLayout}
        </Filters>
        <FiltersLogik formPayerDataParamName={formPayerDataParamName} setFilterFields={setFilterFields} />
      </SessionFiltersContainer>

      <Divider />
      <ReactTable
        columns={projectDirectiveTableColumns}
        defaultData={projectDirectiveTableData}
        current={currentDirectiveId}
        onSelect={onProjectDirectiveTableSelect}
        onRowDoubleClick={onDoubleClick}
        className="tableTac fGrow scrollX"
        tableClassName="fGrow"
        loading={loading}
        paginatable
        onPaginate={onProjectDirectiveTablePaginate}
      />
      <div className="row w100 mt0_5">
        <div className="row gap2x">
          <FunctionButton
            name="Open directive"
            onClick={() => onOpenDirectiveBtnClick()}
            autoWidth
            disabled={disabled}
          />
          <FunctionButton
            name="Open project"
            onClick={onOpenProjectBtnClick}
            autoWidth
            disabled={disabled || isNoCoDirective}
            tooltipMessage={isNoCoDirective ? "Current directive is from Bank-Client does not have project" : ""}
          />
        </div>
      </div>
    </div>
  );
};
const FiltersLogik = ({formPayerDataParamName, setFilterFields}) => {
  const {filters, sessionF} = useFiltersContext();
  const {isCredit, setLastPage} = useProjectDirectiveList({});
  const dispatch = useDispatch();
  const statuses = useSelector(selectAllStatuses);

  useEffect(() => {
    if (statuses?.length < 1) dispatch(fetchAllFinStatuses());
  }, []);

  useEffect(() => {
    if (isEmpty(sessionF)) {
      dispatch(fetchAllDirectives(isCredit ? {isCredit} : {}));
      return;
    }
    const {payerDataType, payerData, ...restFilters} = filters;
    const payerDataParamName = formPayerDataParamName(payerDataType);

    dispatch(
      fetchAllDirectives({
        ...restFilters,
        [payerDataParamName]: payerData,
        ...(isCredit ? {isCredit} : {}),
      }),
    );
    setFilterFields(checkEmptyFilterFields(restFilters));
    setLastPage(false);
  }, [sessionF]);

  return <></>;
};
export default withTabs({
  isMainTabControlled: true,
  isClosableTabs: true,
  label: "Project directives list",
  formInitialValues: {},
})(ProjectDirectiveList);
