import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../conteiners/ReactTable";
import {NumberInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {
  fetchAllEcbRates,
  fetchAllExchangeRates,
  selectAreAllExchangeRatesLoading,
  selectAreEcbRatesLoading,
} from "../FinSettings/FinSettingsSlice";

const CurrencyRateBody = ({
  ecbTableColumns,
  ecbTableData,
  officeOptions,
  rateOptions,
  onSaveOfficeRateOptionsBtnClick,
  officeTableColumns,
  officeTableData,
  onSaveTTRateOptionsBtnClick,
  ttTableColumns,
  ttTableData,
}) => {
  const dispatch = useDispatch();
  const {values} = useFormikContext();
  const {filters} = useFiltersContext();
  const isEcbTableLoading = useSelector(selectAreEcbRatesLoading);
  const isOfficeTableLoading = useSelector(selectAreAllExchangeRatesLoading);

  const [scrollTop, setScrollTop] = useState({ecbTable: false, officeTable: false});
  const [ecbTablePage, setEcbTablePage] = useState({currentPage: null, totalPages: null});

  const [officeTablePage, setOfficeTablePage] = useState({currentPage: null, totalPages: null});

  useEffect(() => {
    dispatch(fetchAllEcbRates({page: 1, ...(filters ?? {})}))
      .then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          const {currentPage, totalPages} = resp.payload;

          setScrollTop(prev => ({...prev, ecbTable: true}));
          setEcbTablePage({currentPage, totalPages});
        }
      })
      .then(setScrollTop(prev => ({...prev, ecbTable: false})));
    dispatch(fetchAllExchangeRates({page: 1, ...(filters ?? {})}))
      .then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          const {currentPage, totalPages} = resp.payload;
          setScrollTop(prev => ({...prev, officeTable: true}));
          setOfficeTablePage({currentPage, totalPages});
        }
      })
      .then(setScrollTop(prev => ({...prev, officeTable: false})));
  }, [filters]);

  return (
    <div className="grid3Col fGrow pb" style={{maxWidth: "100%"}}>
      <div className="fcCol gap" style={{height: "100%"}}>
        <div className="asfs">{t("ECB Rate") + ":"}</div>
        <ReactTable
          columns={ecbTableColumns}
          defaultData={ecbTableData}
          tableClassName="scrollY"
          className="fGrow"
          style={{height: "60vh"}}
          loading={isEcbTableLoading}
          paginatable
          scrollTop={scrollTop.ecbTable}
          onPaginate={() => {
            setEcbTablePage(prev => {
              const {currentPage, totalPages} = prev;
              if (totalPages <= currentPage) return prev;

              dispatch(fetchAllEcbRates({page: currentPage + 1, ...(filters ?? {})}));
              return {...prev, currentPage: prev.currentPage + 1};
            });
          }}
        />
      </div>
      <div className="fcCol gap">
        <div>
          <Select
            label={t("Office Rate")}
            options={officeOptions}
            name="officeId"
            generalClassName="asfs mb0_5"
            width="7rem"
          />
          <div className="row w100">
            <div className="row gap5 aibl">
              EUR/
              <Select label="" options={rateOptions} name="officeRateId" />
            </div>
            <NumberInput label={t("cc")} name="officeBuyingRate" autolabel />
            <NumberInput label={t("co")} name="officeSalesRate" autolabel />
            <FunctionButton
              name="Save"
              onClick={onSaveOfficeRateOptionsBtnClick}
              autoWidth
              disabled={!values.officeId || !values.officeRateId || !values.officeBuyingRate || !values.officeSalesRate}
            />
          </div>
        </div>

        <ReactTable
          columns={officeTableColumns}
          defaultData={officeTableData}
          tableClassName="scrollY"
          className="fGrow"
          style={{height: "60vh"}}
          loading={isOfficeTableLoading}
          scrollTop={scrollTop.officeTable}
          paginatable
          onPaginate={() => {
            setOfficeTablePage(prev => {
              const {currentPage, totalPages} = prev;
              if (totalPages <= currentPage) return prev;

              dispatch(fetchAllExchangeRates({page: currentPage + 1, ...(filters ?? {})}));
              return {...prev, currentPage: prev.currentPage + 1};
            });
          }}
        />
      </div>
      <div className="fcCol gap inactive">
        <div>
          <Select
            label={t("TT Rate")}
            options={officeOptions}
            name="ttId"
            generalClassName="asfs mb0_5"
            width="7rem"
            disabled
          />
          <div className="row w100">
            <div className="row gap5 aibl">
              EUR/
              <Select label="" options={rateOptions} name="ttRateId" disabled />
            </div>
            <NumberInput label={t("cc")} name="ttBuyingRate" autolabel disabled />
            <NumberInput label={t("co")} name="ttSalesRate" autolabel disabled />
            <FunctionButton name="Save" onClick={onSaveTTRateOptionsBtnClick} autoWidth disabled />
          </div>
        </div>

        <ReactTable
          columns={ttTableColumns}
          defaultData={ttTableData}
          tableClassName="scrollY"
          className="fGrow"
          style={{height: "60vh"}}
        />
      </div>
    </div>
  );
};

export default CurrencyRateBody;
