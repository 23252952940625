import moment from "moment";

export const dateFormat = date => {
  if (!date || date == "") return null;
  // const d = new Date(date).toLocaleDateString().replaceAll(/\./g, "-").split("-").reverse().join("-");
  const d = new Date(date);
  return moment(d)?.format("DD.MM.YYYY");
};

export const dateTimeFormat = date => {
  if (!date || date == "") return null;
  // const d = new Date(date).toLocaleDateString().replaceAll(/\./g, "-").split("-").reverse().join("-");
  const d = new Date(date);
  return moment(d)?.format("DD.MM.YYYY hh:mm:ss");
};

export const fullDateFormat = date => {
  if (!date || date == "") return null;

  const d = new Date(date);
  return moment(d, "YYYY-MM-DDTHH:mm:ss.sssZ");
};

export const dateUnformat = date => {
  if (!date || date == "") return null;

  return moment(date).toDate();
};

export const dateTimeUnformatToUnix = date => {
  if (!date || date == "") return null;

  return moment(date, "DD.MM.YYYY hh:mm:ss").unix();
};
