export const ORDER_TRANSACTIONS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 100,
  },
  {
    header: "Transaction type ",
    accessorKey: "transactionType",
    // size: 100,
  },
  {
    header: "Costs (Dt)",
    accessorKey: "costsDt",
    // size: 100,
  },
  {
    header: "Revenue (Ct)",
    accessorKey: "revenueCt",
    // size: 100,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    // size: 100,
  },
  {
    header: "Payers Acc. №",
    accessorKey: "payerAccountNumber",
    // size: 100,
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
    // size: 100,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    // size: 100,
  },
  {
    header: "Recipient Acc. №",
    accessorKey: "recipientAccountNumber",
    // size: 100,
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
    // size: 100,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    // size: 100,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    // size: 100,
  },
  {
    header: "Remainder",
    accessorKey: "remainder",
    // size: 100,
  },
  {
    header: "tr.id",
    accessorKey: "trId",
    // size: 200,
  },
  {
    header: "note",
    accessorKey: "note",
    alignStart: true,
    // size: 200,
  },
]);
export const CONDUCTED_ORDER_TRANSACTIONS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Date To",
    accessorKey: "startDate",
    // size: 100,
  },
  {
    header: "Date (f)",
    accessorKey: "factDate",
    // size: 100,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 100,
  },
  {
    header: "Transaction type ",
    accessorKey: "transactionType",
    // size: 100,
  },
  {
    header: "Costs (Dt)",
    accessorKey: "costsDt",
    // size: 100,
  },
  {
    header: "Revenue (Ct)",
    accessorKey: "revenueCt",
    // size: 100,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    // size: 100,
  },
  {
    header: "Payers Acc. №",
    accessorKey: "payerAccountNumber",
    // size: 100,
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
    // size: 100,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    // size: 100,
  },
  {
    header: "Recipient Acc. №",
    accessorKey: "recipientAccountNumber",
    // size: 100,
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
    // size: 100,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    // size: 100,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    // size: 100,
  },
  {
    header: "Remainder",
    accessorKey: "remainder",
    // size: 100,
  },
  {
    header: "tr.id",
    accessorKey: "trId",
    // size: 200,
  },
  {
    header: "note",
    accessorKey: "note",
    alignStart: true,
    // size: 200,
  },
]);
