import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import {fetchData} from "../../../../hook/axios.hook";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {deleteOrder, fetchFinOrder, selectFinOrder} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {DateInput, MoneyInput, Select, TextAreaInput, TextInput} from "../../../Inputs";
import withDirectiveWithDocsHOC from "../MainDirective/DirectiveWithDocsHOC";
import RecipientClientBalance from "../OrderChangingPart/Parts/bottom/RecipientClientBalance";

const CreditOrderFin = ({orderId}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const currentOrder = useSelector(selectFinOrder);
  const {currentTabIndex, closeTabs} = useTabsContext();

  useEffect(() => {
    dispatch(fetchFinOrder(orderId));
  }, []);

  useEffect(() => {
    if (currentOrder?.orderId && currentOrder?.orderId === orderId) {
      const {termUpTo, annualInterestRate, repaymentTerms, repaymentTermsSum} = currentOrder?.loanTerms || {};
      setTimeout(
        () =>
          setValues(prev => ({
            ...prev,
            creditForCoNumber: currentOrder?.directive?.co?.coNumber,
            termUpTo: termUpTo,
            perAnnumComposed: annualInterestRate,
            repaymentTermIdComposed: repaymentTerms?.[language === "en" ? "nameEng" : "nameUkr"],
            repaymentTermsSumId: repaymentTermsSum?.[language === "en" ? "nameEng" : "nameUkr"],
            creditSumCoAdvance: currentOrder?.sum,
          })),
        100,
      );
    }
  }, [currentOrder?.orderId]);
  const closeAllOrderTabs = () => {
    closeTabs([currentTabIndex, currentTabIndex - 1]);
  };

  const onCompleate = () => {
    fetchData(`finance/directives/orders/complete/${currentOrder?.orderId}`, "patch", {
      note: `${
        currentOrder?.directive?.note ? currentOrder?.directive?.note?.trim() + "\n" : ""
      }${values?.compleateNote?.trim()}`,
    })
      .then(() => closeAllOrderTabs())
      .catch(() => {});
  };
  const onReject = () => {
    Swal.fire({
      title: t("Sure"),
      text: t("Order will be removed", {orderNumber: currentOrder.orderNumber}),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(deleteOrder(currentOrder?.orderId)).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            if (values?.compleateNote) {
              fetchData(`finance/directives/update/${currentOrder?.directive?.directiveId}`, "patch", {
                note: `${
                  currentOrder?.directive?.note ? currentOrder?.directive?.note?.trim() + "\n" : ""
                }${values?.compleateNote?.trim()}`,
              })
                .then(() => closeAllOrderTabs())
                .catch(() => {});
            } else {
              closeAllOrderTabs();
            }
          }
        });
      }
    });
  };

  return (
    <div className={`fcCol aistr gap jcsb fGrow`}>
      <>
        <Select
          name="finOrderTypeId"
          label={"Order type"}
          autolabel
          options={[{value: 5, title: t("Credit for CO")}]}
          readOnly
        />
        <DateInput name="fulfilBy" label={t("Term Until")} autolabel fromCurrentDate readOnly />
      </>

      <div className="fc   pt pb fGrow">
        <div className="fcCol jcsb aistr mt fGrow g1 w100">
          <div className="row w100 aibl">
            <span>{t("Payer")}</span>
            <TextInput name="creditForCoNumber" label={t("Credit for CO№")} autolabel readOnly width="8em" />
          </div>

          <DateInput name="termUpTo" label={t("Credit Term*")} autolabel width="10em" readOnly />
          <TextInput name="perAnnumComposed" label={t("%%per annum per year")} autolabel readOnly />
          <TextInput name="repaymentTermIdComposed" label={t("repayment %%")} autolabel readOnly />
          <TextInput name="repaymentTermsSumId" label="repayment sum" autolabel readOnly />
          <MoneyInput name="creditSumCoAdvance" label={t("Credit Sum")} autolabel readOnly width="14em" />
        </div>
        {/* <Divider /> */}
        <RecipientClientBalance />
      </div>
      <TextAreaInput name="compleateNote" placeholder={"Add note"} />
      <TextAreaInput name="executorNote" placeholder={"Executant note"} readOnly />
      <div className="row">
        <FunctionButton
          onClick={onCompleate}
          name="Run order"
          autoWidth
          disabled={currentOrder?.status?.statusId > 2}
          tooltipMessage={currentOrder?.status?.statusId === 3 ? "Order is already compleated" : ""}
        />
        <FunctionButton
          onClick={onReject}
          name={"Reject"}
          autoWidth
          tooltipMessage={currentOrder?.status?.statusId === 3 ? "Order is already compleated" : ""}
          disabled={currentOrder?.status?.statusId > 2}
        />
      </div>
    </div>
  );
};
const CreditOrderCompleateForm = withDirectiveWithDocsHOC(CreditOrderFin);
export default CreditOrderCompleateForm;
