import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {getOrgStructures} from "../../../../../selectors/CatalogSelectors";
import {
  fetchClients,
  fetchOrgStructures,
  fetchUsers,
  selectAllClients,
  selectAllUsers,
} from "../../../../../slices/PersonSlice";
import {getCurrentUser} from "../../../../../slices/UserSlice";
import formatAccountNumber from "../../../../../utilities/formatAccountNumber";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../utilities/hooks/toaster";
import makeNameFromPersona from "../../../../../utilities/makeNameFromPersona";
import {clearCOs, fetchAllCOs, selectAllCOs} from "../../../../Project/ProjectSlice";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addNewAccount,
  checkAccount,
  clearCurrentAccount,
  deleteAccount,
  fetchAccount,
  fetchAllAccounts,
  fetchBsAccount,
  fetchOwnerTypes,
  saveAccount,
  selectAllAccounts,
  selectCurrentAccount,
  selectFinSettingsLoading,
  selectOwnerTypes,
} from "../../FinSettingsSlice";
import {ACCOUNT_OPENING_TABLE_COLUMNS, ACCOUNT_OWNERS_TABLE_COLUMNS} from "../constants";

export const OWNER_TYPES = Object.freeze({
  INDIVIDIAL_CLIENT: {name: "Individual client", key: "client"},
  LEGAL_CLIENT: {name: "Legal client"},
  COMMISSIONER: {name: "Commissioner"},
  PARTNER: {name: "Partner"},
  COLLABORATOR: {name: "Collaborator", key: "employee"},
  OFFICE: {name: "Office ", key: "office"},
  CO: {name: "CO", key: "co"},
});

const useAccountOpeningTab = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();

  const {values, setValues, resetForm} = useFormikContext();

  const allAccounts = useSelector(selectAllAccounts);
  const currentAccount = useSelector(selectCurrentAccount);
  const currentAccountId = currentAccount?.accountId ?? null;
  const allUsers = useSelector(selectAllUsers);
  const allClients = useSelector(selectAllClients);
  const allOffices = useSelector(getOrgStructures);
  const allCOs = useSelector(selectAllCOs);
  const isLoading = useSelector(selectFinSettingsLoading);
  const currentUser = useSelector(getCurrentUser);
  const ownerTypes = useSelector(selectOwnerTypes);

  const [accounts, setAccounts] = useState(allAccounts ?? []);

  useEffect(() => {
    setAccounts(allAccounts);
  }, [allAccounts.length]);

  // useEffect(() => {
  //   if (values.accountOwnerType) {
  //     setAccounts(allAccounts.filter(account => account.ownerType === values.accountOwnerType));
  //   }
  // }, [values.accountOwnerType]);

  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [offices, setOffices] = useState([]);
  const [cos, setCos] = useState([]);

  useEffect(() => {
    setClients(allClients);
  }, [allClients?.length]);

  useEffect(() => {
    setUsers(allUsers);
  }, [allUsers?.length]);

  useEffect(() => {
    setOffices(allOffices);
  }, [allOffices?.length]);

  useEffect(() => {
    setCos(allCOs);
  }, [allCOs?.length]);

  const [currentAccountOwner, setCurrentAccountOwner] = useState(null);
  const [isAccountCreated, setAccountCreated] = useState(false);
  const [isAccountChecked, setAccountChecked] = useState(false);
  const [isAccountSaved, setAccountSaved] = useState(false);

  useEffect(() => {
    dispatch(fetchAllAccounts());
    dispatch(fetchOwnerTypes());
    dispatch(clearCOs());
  }, []);

  useEffect(() => {
    switch (values.accountOwnerType) {
      case OWNER_TYPES.INDIVIDIAL_CLIENT.key:
        if (!allClients) dispatch(fetchClients());
        break;
      case OWNER_TYPES.OFFICE.key:
        if (!allOffices) dispatch(fetchOrgStructures());
        break;
      case OWNER_TYPES.COLLABORATOR.key:
        if (!allUsers) dispatch(fetchUsers());
        break;
      case OWNER_TYPES.CO.key:
        if (!allCOs || allCOs.length === 0) dispatch(fetchAllCOs({}));
        break;
    }
  }, [values.accountOwnerType]);

  useEffect(() => {
    if (currentAccount) {
      setValues(formValues(currentAccount));
    } else {
      setValues(formValues());
    }
  }, [currentAccount?.accountId]);

  function formValues(currentAccount) {
    if (!currentAccount) {
      return {
        accountOwnerType: "",
        accountOwner: "",
        accCountry: "",
        accCountryCode: "",
        personaId: "",
        balanceSheetAccId: "",
        balanceSheetAccIdRel: "",
        currentYear: "",
        reserveCode: "",
        currencyId: "",
        accountName: "",
        controlName: "",
        controlDate: "",
      };
    }

    return {
      ...formAccountNumberValues(currentAccount?.accountNumber),
      accountName: currentAccount?.accountName ?? "",
      controlName: makeNameFromPersona({persona: currentAccount?.creator?.persona, language}) ?? "",
      controlDate: currentAccount?.createdAt ?? "",
      accountOwner: formAccountOwner(currentAccount) ?? "",
      accountOwnerType: currentAccount?.ownerType ?? "",
    };
  }

  const accountOwnerTypeOptions = ownerTypes.map(({ownerType, nameEng, nameUkr}) => ({
    title: language === "en" ? nameEng : nameUkr,
    value: ownerType,
  }));

  const aoTableColumns = useColumns(ACCOUNT_OWNERS_TABLE_COLUMNS);
  const aoTableData = useMemo(() => {
    switch (values.accountOwnerType) {
      case OWNER_TYPES.INDIVIDIAL_CLIENT.key:
        return (
          clients?.map(({clientId, persona, clientNumber}, index) => ({
            number: ++index,
            id: makeNameFromPersona({persona, language}) + `|${clientId}`,
            aoCode: "-",
            accountOwner: clientNumber,

            // accountOwner: makeNameFromPersona({persona, language}),
          })) ?? []
        );
      case OWNER_TYPES.OFFICE.key:
        return (
          offices?.map(({orgStructureId, fullNameEng, fullNameUkr}, index) => ({
            number: ++index,
            id:
              language === "en"
                ? fullNameEng + `|${orgStructureId}`
                : language === "uk"
                ? fullNameUkr + `|${orgStructureId}`
                : "",
            aoCode: "-",
            accountOwner: language === "en" ? fullNameEng : language === "uk" ? fullNameUkr : "",
          })) ?? []
        );
      case OWNER_TYPES.COLLABORATOR.key:
        return (
          users?.map(({userId, persona}, index) => ({
            number: ++index,
            id: makeNameFromPersona({persona, language}) + `|${userId}`,
            aoCode: "-",
            accountOwner: makeNameFromPersona({persona, language}),
          })) ?? []
        );
      case OWNER_TYPES.CO.key:
        return cos?.map(({coId, coNumber}, index) => ({
          id: coNumber + `|${coId}`,
          number: ++index,
          aoCode: "-",
          accountOwner: coNumber,
        }));
      default:
        return [];
    }
  }, [values.accountOwnerType, clients, offices, users, cos]);

  const getOwnerName = account => {
    switch (account.ownerType) {
      case "client":
        return makeNameFromPersona({persona: account?.owner?.persona, language});

      case "co":
        return account?.owner?.coNumber;

      case "office":
        return language === "en" ? account?.owner?.fullNameEng : account?.owner?.fullNameUkr;

      case "employee":
        return makeNameFromPersona({persona: account?.owner?.persona, language});

      default:
        return "";
    }
  };

  const accountTableColumns = useColumns(ACCOUNT_OPENING_TABLE_COLUMNS);
  const accountTableData =
    accounts.map(({accountId, accountNumber, accountName, balanceSheet, owner}, index) => {
      const {persona, fullNameEng, fullNameUkr, coNumber} = owner ?? {};

      return {
        number: ++index,
        id: accountId,
        accountType: balanceSheet?.type?.balanceTypeName ?? "",
        accountNumber: formatAccountNumber(accountNumber),
        accountName,
        accountOwner: persona
          ? makeNameFromPersona({persona, language})
          : coNumber
          ? coNumber
          : language === "en"
          ? fullNameEng
          : fullNameUkr,
      };
    }) ?? [];

  function onAccountOpeningInfoBtnClick() {
    swalCustom.info({text: "Account opening info", noTitle: true, showCancelButton: false});
  }

  function onOpenAnAccountBtnClick() {
    const {accountOwnerType, accountName, currencyId, reserveCode, balanceSheetAccId} = values;
    if (
      !accountOwnerType ||
      !currentAccountOwner ||
      !balanceSheetAccId ||
      !currencyId ||
      !reserveCode ||
      !accountName
    ) {
      toaster.error("Fill in all fields!");
      return;
    }

    dispatch(
      addNewAccount({
        ownerId: currentAccountOwner?.split("|")[1],
        ownerType: accountOwnerType,
        balanceSheetAccId,
        currencyId,
        reserveCode,
        accountName,
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setAccountCreated(true);
        // toaster.success("Account created");
        const accountNumber = resp.payload.account.accountNumber;
        setValues(prev => ({
          ...prev,
          ...formAccountNumberValues(accountNumber),
        }));
      }
    });
  }

  function formAccountNumberValues(accountNumber) {
    return {
      accCountry: accountNumber?.slice(0, 3) ?? "",
      accCountryCode: accountNumber?.slice(3, 5) ?? "",
      personaId: accountNumber?.slice(5, 11) ?? "",
      balanceSheetAccId: accountNumber?.slice(11, 15) ?? "",
      balanceSheetAccIdRel: accountNumber?.slice(-13, -7) ?? "",
      currentYear: accountNumber?.slice(-7, -5) ?? "",
      reserveCode: accountNumber?.slice(-5, -3) ?? "",
      currencyId: accountNumber?.slice(-3) ?? "",
    };
  }

  function limitInput({inputValue, n, name}) {
    if (inputValue.length > n) {
      setValues(prev => ({...prev, [name]: inputValue.slice(0, n)}));
      return;
    }
  }

  function onBalanceSheetAccIdChange(e) {
    const inputValue = e.target.value;

    if (inputValue.length > 4) {
      // toaster.error("Bsa number length");
      setValues(prev => ({...prev, balanceSheetAccId: inputValue.slice(0, 4)}));
      return;
    }
    if (!values.balanceSheetAccId || !currentAccountOwner || isAccountCreated || inputValue.length !== 4) return;

    dispatch(fetchBsAccount(inputValue)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled")
        toaster.success(`${t("Balance sheet account with this number exists")}: ${inputValue}`);
    });
  }

  function onAccountOwnerInputChange(e) {
    const inputValue = e.target.value.toLowerCase();
    if (!inputValue || inputValue.length < 3) {
      onAoFilterDelete();
      return;
    }

    switch (values.accountOwnerType) {
      case OWNER_TYPES.INDIVIDIAL_CLIENT.key:
        setClients(
          clients.filter(client =>
            makeNameFromPersona({persona: client.persona, language}).toLowerCase().includes(inputValue),
          ),
        );
        break;
      case OWNER_TYPES.OFFICE.key:
        setOffices(
          offices.filter(office =>
            (language === "en" ? office.fullNameEng : office.fullNameUkr).toLowerCase().includes(inputValue),
          ),
        );
        break;
      case OWNER_TYPES.COLLABORATOR.key:
        setUsers(
          users.filter(user =>
            makeNameFromPersona({persona: user.persona, language}).toLowerCase().includes(inputValue),
          ),
        );
        break;
      case OWNER_TYPES.CO.key:
        setCos(allCOs.filter(cos => cos.coNumber.toLowerCase().includes(inputValue)));
    }
    // setAccounts(
    //   accounts.filter(account =>
    //     makeNameFromPersona({persona: account.owner.persona, language}).toLowerCase().includes(inputValue),
    //   ),
    // );
  }

  function onCheckAccountBtnClick() {
    if (!currentAccountId) return;

    dispatch(checkAccount(currentAccountId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Account checked");
        setAccountChecked(true);
        setValues(prev => ({
          ...prev,
          controlName: `${language === "en" ? currentUser.engName : currentUser.firstName} ${
            language === "en" ? currentUser.engLastName : currentUser.lastName
          }`,
          controlDate: currentAccount?.createdAt,
        }));
      }
    });
  }

  function onSaveAccountBtnClick() {
    if (!currentAccountId) return;

    dispatch(saveAccount(currentAccountId)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Account saved ");
        dispatch(fetchAllAccounts());
        setAccountSaved(true);
      }
    });
  }

  function onCloseAccountBtnClick() {
    if (!currentAccountId) return;

    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteAccount(currentAccountId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("Account closed");
            dispatch(fetchAllAccounts());
            setAccountCreated(false);
            setAccountChecked(false);
            setAccountSaved(false);
            resetForm();
          }
        });
      },
    });
  }

  function onAoFilterDelete() {
    setAccounts(allAccounts);
    setClients(allClients);
    setUsers(allUsers);
    setOffices(allOffices);
  }

  function onAccountOwnersTableRowSelect(id) {
    if (!id) {
      onAoFilterDelete();
    }
    setValues(prev => ({...prev, accountOwner: id?.split("|")[0] ?? ""}));
    setCurrentAccountOwner(id);
  }

  function formAccountOwner(account) {
    const {
      ownerType,
      owner: {persona, coNumber, fullNameEng, fullNameUkr},
    } = account;
    return ownerType === OWNER_TYPES.CO.key
      ? coNumber
      : ownerType === OWNER_TYPES.COLLABORATOR.key || ownerType === OWNER_TYPES.INDIVIDIAL_CLIENT.key
      ? makeNameFromPersona({persona, language})
      : language === "en"
      ? fullNameEng
      : fullNameUkr;
  }

  useEffect(() => {
    if (currentAccount) {
      const {ownerId: selectedOwnerId, ownerType} = currentAccount;

      switch (ownerType) {
        case OWNER_TYPES.INDIVIDIAL_CLIENT.key:
          setClients(allClients.filter(client => client.clientId === selectedOwnerId));
          break;
        case OWNER_TYPES.OFFICE.key:
          setOffices(allOffices.filter(office => office.orgStructureId === selectedOwnerId));
          break;
        case OWNER_TYPES.COLLABORATOR.key:
          setUsers(allUsers.filter(user => user.userId === selectedOwnerId));
          break;
        case OWNER_TYPES.CO.key:
          setCos(allCOs.filter(cos => cos.coId === selectedOwnerId));
          break;
      }

      setCurrentAccountOwner(`${formAccountOwner(currentAccount)}|${selectedOwnerId}`);
    }
  }, [currentAccountId]);

  function onAccountTableRowSelect(id) {
    if (!id) {
      // dispatch(clearCurrentAccount());
      // setCurrentAccountOwner(null);
      // onAoFilterDelete();
      clearData();
      return;
    }
    setAccountCreated(false);
    setAccountSaved(false);

    dispatch(fetchAccount(id)).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        const account = resp.payload;
        const {ownerId: selectedOwnerId, ownerType} = account;

        switch (ownerType) {
          case OWNER_TYPES.INDIVIDIAL_CLIENT.key:
            setClients(allClients.filter(client => client.clientId === selectedOwnerId));
            break;
          case OWNER_TYPES.OFFICE.key:
            setOffices(allOffices.filter(office => office.orgStructureId === selectedOwnerId));
            break;
          case OWNER_TYPES.COLLABORATOR.key:
            setUsers(allUsers.filter(user => user.userId === selectedOwnerId));
            break;
          case OWNER_TYPES.CO.key:
            setCos(allCOs.filter(cos => cos.coId === selectedOwnerId));
            break;
        }

        setCurrentAccountOwner(`${formAccountOwner(account)}|${selectedOwnerId}`);
      }
    });
  }

  function clearData() {
    setValues({
      accCountry: "",
      accCountryCode: "",
      personaId: "",
      balanceSheetAccId: "",
      balanceSheetAccIdRel: "",
      currentYear: "",
      reserveCode: "",
      currencyId: "",
      accountName: "",
      controlName: "",
      controlDate: "",
      accountOwner: "",
      ownerType: "",
    });
    setCurrentAccountOwner(null);
    setAccountCreated(false);
    setAccountChecked(false);
    setAccountSaved(false);
    dispatch(clearCurrentAccount());
    onAoFilterDelete();
  }

  return {
    accountOwnerTypeOptions,
    onAccountOpeningInfoBtnClick,
    onOpenAnAccountBtnClick,
    onCheckAccountBtnClick,
    onSaveAccountBtnClick,
    onCloseAccountBtnClick,
    accountTableColumns,
    accountTableData,
    aoTableColumns,
    aoTableData,
    onAccountTableRowSelect,
    currentAccountId,
    currentAccountOwner,
    onAccountOwnersTableRowSelect,
    onBalanceSheetAccIdChange,
    isAccountCreated,
    isAccountChecked,
    onAccountOwnerInputChange,
    isAccountSaved,
    isLoading,
    limitInput,
    clearData,
  };
};

export default useAccountOpeningTab;
