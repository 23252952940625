import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, Form, Formik} from "formik";
import moment from "moment";
import * as Yup from "yup";

import plusIcon from "../../images/icons/plus.png";
import saveIcon from "../../images/icons/save.png";
import {BUTTON_TYPES} from "../../utilities/Buttons/constants";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import {formatStatus} from "../../utilities/CirculeStatus";
import {STATUS_COLORS} from "../../utilities/colorConstants";
import ControlFieldsChanges from "../../utilities/ControllChanges";
import {dateFormat} from "../../utilities/dateFormat";
import {calcStatusColorViaPlannedDate} from "../../utilities/DateStatusCircle";
import ToolTip from "../../utilities/ToolTip";
import ReactTable from "../conteiners/ReactTable";
import TableWithSelect from "../conteiners/TableWithSelect";
import {DateInput, TextInput} from "../Inputs";
import DateField from "../Inputs/noformik/DateField";
import useColumns from "../Requests/OrderProcessing/utils/hooks/useColumns";

import {remindCreated, remindUpdated, resetReminds, selectRemind} from "./RemindsSlice";

import "../../Styles/Reminder.scss";

const ReminderSimple = props => {
  const {t} = useTranslation();

  const {height, create, update, loading, disabled} = props;

  const [note, setNote] = useState("");
  const [dueDate, setDueDate] = useState("");

  const {reminds, currentRemind} = useSelector(state => state.reminds);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetReminds());
  }, []);

  const formatStatus = statusColor => {
    const textColor = STATUS_COLORS.GREY;

    return [
      <div key="radio-label" className="availability_circule_box">
        <div className="outer_circle">
          <div className="inner_circle" style={{backgroundColor: statusColor}}></div>
        </div>
      </div>,

      <span key="text-span" style={{color: textColor}}></span>,
    ];
  };

  const remindsTableField = useMemo(() => {
    return reminds.map((item, i) => {
      const color = calcStatusColorViaPlannedDate(item.dueDate, item.createdAt);

      return {
        todoNumber: i + 1,
        createdAt: dateFormat(item.createdAt),
        note: item.note,
        dueDate: dateFormat(item.dueDate),
        status: color,
        id: item.todoId,
      };
    });
  }, [reminds]);

  const onSaveNote = () => {
    // const {note, dueDate} = newnote;
    if (!currentRemind) {
      create({
        note,
        dueDate,
      })
        .then(res => {
          dispatch(remindCreated(res));
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      update({
        todoId: currentRemind.todoId,
        data: {
          note,
          dueDate,
        },
      }).then(res => {
        dispatch(remindUpdated(res));
      });
    }
  };

  const onPlus = () => {
    dispatch(selectRemind(null));
    create({
      note: "",
      dueDate: dateFormat(new Date()),
    }).then(res => {
      dispatch(remindCreated(res));
    });
  };

  const onNewSelect = currentNote => {
    if (currentRemind && currentNote === currentRemind?.todoId) {
      dispatch(selectRemind(null));
      return;
    }
    dispatch(selectRemind(currentNote));
  };

  useEffect(() => {
    setNote(currentRemind?.note || "");
    setDueDate(currentRemind?.dueDate || "");
  }, [currentRemind]);

  const colls = [
    {
      header: t("Date"),
      accessorKey: "createdAt",
      size: 50,
    },
    {
      header: t("Notes"),
      accessorKey: "note",
      alignStart: true,
      size: 100,
      isSortable: true,
    },
    {
      header: "D-day",
      accessorKey: "dueDate",
      size: 100,
      isSortable: true,
    },
  ];

  return (
    <div className="reminder" style={{height: height, overflowY: "hidden"}}>
      <div className="form_wrapper">
        <div className="reminder_form fc nowrap">
          <div style={{width: "80%"}}>
            <div className={`project_input`}>
              <label htmlFor={props.name} className={"autolabel"}>
                ToDO
              </label>
              <input
                disabled={disabled}
                autoComplete="off"
                value={note || ""}
                onChange={e => {
                  setNote(e.target.value);
                }}
              />
            </div>
          </div>
          <DateField
            name="dueDate"
            flatpikrConfig={{
              minDate: moment().add(1, "days").format("DD-MM-YYYY"),
            }}
            setValue={setDueDate}
            value={dueDate}
            disabled={disabled}
          />

          <ImageButton
            src={saveIcon}
            alt="save"
            type={BUTTON_TYPES.BUTTON}
            onClick={onSaveNote}
            width={2.2}
            height={1.5}
            style={{zIndex: "1000"}}
            disabled={disabled || note === "" || dueDate === ""}
          />
        </div>

        {/* /////////список///////// */}
        <div className="reminder_table project_list" style={{height: "100%"}}>
          <ReactTable
            columns={colls}
            defaultData={remindsTableField}
            current={currentRemind?.todoId || null}
            onSelect={onNewSelect}
            loading={loading}
            //  formatStatus={formatStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default ReminderSimple;
