export const CHOOSE_CO_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    maxSize: 15,
  },
  {
    header: "Co №",
    accessorKey: "coNumber",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  // {
  //   header: "CO Annex №",
  //   accessorKey: "coAnnexNumber",
  //   size: 70,
  //   maxSize: 70,
  //   isSortable: true,
  // },
  {
    header: "Briefly about",
    accessorKey: "about",
    alignStart: true,
    size: 120,
  },
  {
    header: "CO Sum",
    accessorKey: "coSum",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
]);
