import {t} from "i18next";

import ReactTable from "../../../../../conteiners/ReactTable";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {ASSOCIATED_TRANSACIONS_TABLE_COLUMNS} from "../../../constants";

const AssociatedTransaction = ({associatedTransactionTableData = []}) => {
  const columns = useColumns(ASSOCIATED_TRANSACIONS_TABLE_COLUMNS);

  return (
    <div className="fcCol w100 gap0_5">
      <div className="asfs header">{t("Transaction with associated accounts")}</div>
      <ReactTable
        columns={columns}
        defaultData={associatedTransactionTableData}
        selectable={false}
        className="fGrow scrollX scrollY fShrink"
        current={null}
        loading={false}
        style={{height: "6.3em"}}
      />
    </div>
  );
};

export default AssociatedTransaction;
