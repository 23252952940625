import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import ContractDocsScreenShotsTables from "../../../Project/ProjectDirectiveList/ContractDocsScreenShotsTables/ContractDocsScreenShotsTables";
import DirectiveFilters from "../../../Project/ProjectDirectiveList/DirectiveFilters";
import FinStatus from "../../../Project/ProjectDirectiveList/FinStatus";
import OrderTypeComposedPart, {
  ORDER_COMPOSED_PART_TYPES,
} from "../../../Project/ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";

import useFinBlockOrderView from "./useFinBlockOrderView";

const FinBlockOrderView = () => {
  const {onAcceptToWorkBtnClick, onExitBtnClick, fileArray} = useFinBlockOrderView();

  return (
    <div className="fcCol h100 w100 pb aist">
      <DirectiveFilters />
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow mb0_5">
        <OrderTypeComposedPart readOnly noAsterisk type={ORDER_COMPOSED_PART_TYPES.VIEW_FUll}>
          <div className="row">
            <FunctionButton onClick={onAcceptToWorkBtnClick} name="Accept to work" autoWidth />
            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2" style={{maxWidth: "70%"}}>
          <FinStatus />
          <div className="grid2ColFirstBigger fGrow">
            <ContractDocsScreenShotsTables />
            <div className="mb2x">
              <NewPdfViewer fileArray={fileArray} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinBlockOrderView;
