import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";

import {makeOrder, saveKpfAsMain} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../utilities/dateFormat.js";
import {getError, getSuccess} from "../../../../utilities/toasts";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {
  clearCreatedDirective,
  preCreateDirective,
  selectDirective,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {TextInput} from "../../../Inputs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import CreateMainDirective from "../../../Project/ProjectDirectiveList/MainDirective/CreateDirective.js";
import {MAIN_DIRECTIVE_TAB_TYPES} from "../../../Project/ProjectDirectiveList/MainDirective/MainDirective.js";
import {fetchCO, selectCurrentCO} from "../../../Project/ProjectSlice";
import {fetchAllCOfs, fetchCOf, selectCurrentCof} from "../../kp-f/KpFinSlice";

const KpPreviewAndMainButtonsSection = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {addNewTabCustom} = useTabsContext();

  const currentCo = useSelector(selectCurrentCO);
  const currentCoId = currentCo?.coId;
  const currentCof = useSelector(selectCurrentCof);
  const currentCofId = currentCof?.cofId;
  const noFileInCof = (currentCof && !currentCof?.docsArray) || (currentCof && currentCof.docsArray?.length < 1);
  const directive = useSelector(selectDirective);
  const isDirectiveCreated = currentCo?.directives?.length > 0;
  const isDirectiveResolved = directive?.statusId === 4 || currentCo?.directives?.[0]?.statusId === 4;

  // const allDirectives = useSelector(selectAllDirectives);
  // console.log("allDirectives :", allDirectives);

  // useEffect(() => {
  //   if (currentCofId) dispatch(clearDirective());
  // }, [currentCofId]);

  // useEffect(() => {
  //   dispatch(fetchAllDirectives({coId: currentCo?.coId}));
  // }, [currentCo?.coId]);

  function onCreateDirectiveBtnClick() {
    dispatch(preCreateDirective({cofId: currentCofId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        addNewTabCustom({
          TabComponent: CreateMainDirective,
          title: `${t("Directive №")}`,
          controlled: true,
          type: MAIN_DIRECTIVE_TAB_TYPES.KP_CREATE,
          directiveId: null,
          cofId: currentCofId,
        });
        dispatch(clearCreatedDirective());
      } else {
        Swal.fire({
          title: t(resp?.error?.message),
          text: t("date of delivery of the order way"),
          icon: "info",
          confirmButtonText: "Ok",
        });
      }
    });
  }

  function onOpenDirectiveClick() {
    dispatch(clearCreatedDirective());
    addNewTabCustom({
      TabComponent: CreateMainDirective,
      title: `${t("Directive №")}`,
      controlled: true,
      type: MAIN_DIRECTIVE_TAB_TYPES.KP_CREATE,
      directiveId: currentCof?.directives?.[0]?.directiveId,
    });
  }

  function onSaveAsMainBtnClick() {
    if (!currentCof || !currentCoId) return;

    Swal.fire({
      title: "",
      text: `${t("CO will be saved as the main one", {cofNumber: currentCof.cofNumber})}`,
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        if (!currentCof.docsArray) {
          getError(t("cof without data"));
        } else {
          const cofId = currentCof.cofId;
          if (!cofId) return;

          dispatch(saveKpfAsMain(cofId)).then(({meta}) => {
            if (meta.requestStatus === "fulfilled") {
              getSuccess(t("CO was successfully saved as the main one", {cofNumber: currentCof.cofNumber}));
              dispatch(fetchCO({coId: currentCoId}));
              dispatch(fetchCOf({cofId: currentCofId}));
              dispatch(fetchAllCOfs({coId: currentCo?.coId}));
            }
          });
          // .then(res => {
          //   if (res.meta.requestStatus === "fulfilled") {
          //     dispatch(selectSubKp({kp: cofId, type: "f"}));
          //   }
          // });
        }
      }
    });
  }

  function onCreateOrderForFeaBtnClick() {
    if (!currentCo?.bringTo) {
      Swal.fire({
        title: "",
        text: `${t("enter bringTo at first")}`,
        icon: "error",
        confirmButtonText: t("Go to project"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(answ => {
        if (answ.isConfirmed) {
          navigate("/ba3a/project/projectSecondVersion", {
            state: {currentKpId: currentCo.coId, currentProjectId: currentCo.projectId},
          });
        }
      });
      return;
    }
    Swal.fire({
      title: "",
      text: `${t("Will be created an order for FEA")}`,
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(makeOrder(currentCof.cofId)).then(({meta}) => {
          if (meta.requestStatus === "fulfilled") {
            getSuccess(t("Order for FEA success", {date: dateFormat(new Date())}));
            // navigate("/ba3a/fea/bos/request-registr");
          }
        });
      }
    });
  }

  // const isCreateDirectiveBtnDisabled = disabled || isDirectiveCreated;
  // const isSaveAsMainBtnDisabled = disabled || currentCof?.isMain || !isDirectiveCreated || !isDirectiveResolved;
  // const isCreateOrderForFeaDisabled = disabled || !currentCof?.isMain || !isDirectiveCreated || !isDirectiveResolved;

  return (
    <>
      <div className="fdr aic mb0_5">
        <div>{t("CO preview 2")}</div>
        <TextInput autolabel label="" name="cofNumber" readOnly />
      </div>

      <div className="kpPreview__box w100">
        <NewPdfViewer fileArray={currentCof?.docsArray ?? []} />
      </div>
      <div className="fc jcsb gap mb">
        <FunctionButton
          name={isDirectiveCreated ? t("Open directive") : t("Create directive")}
          autoWidth
          // disabled={isCreateDirectiveBtnDisabled}
          disabled={!currentCofId || noFileInCof || (isDirectiveCreated && currentCof?.directives?.length === 0)}
          onClick={currentCof?.directives?.length > 0 && isDirectiveCreated ? onOpenDirectiveClick : onCreateDirectiveBtnClick}
          tooltipMessage={
            noFileInCof
              ? "No file in Co(f)"
              : isDirectiveCreated && currentCof?.directives?.length === 0
              ? "Directive was created from another CO"
              : ""
            //allDirectives?.length > 0 ? "Directive has already been created" : ""
          }
        />
        <FunctionButton
          name={t("Save as main")}
          autoWidth
          // disabled={isSaveAsMainBtnDisabled}
          disabled={
            !currentCofId ||
            currentCo?.statusId >= 6 ||
            // !isDirectiveCreated ||
            // !isDirectiveResolved ||
            !Number(currentCo?.prepayment) ||
            Number(currentCo?.prepayment) === 0 ||
            !(Number(currentCo?.prepayment) <= Number(currentCo?.accounts[0]?.balance))
          }
          onClick={onSaveAsMainBtnClick}
          tooltipMessage={
            !currentCofId
              ? ""
              : !isDirectiveCreated
              ? "Directive is not created"
              : !isDirectiveResolved
              ? "Directive is not resolved"
              : ""
          }
        />
        <FunctionButton
          name={t("Create an Order for FEA")}
          autoWidth
          // disabled={isCreateOrderForFeaDisabled}
          disabled={!currentCofId || currentCo?.statusId < 6}
          onClick={onCreateOrderForFeaBtnClick}
          tooltipMessage={
            !currentCofId
              ? ""
              : !isDirectiveCreated
              ? "Directive is not created"
              : !isDirectiveResolved
              ? "Directive is not resolved"
              : !currentCof?.isMain
              ? "Didn`t saved as main"
              : ""
          }
        />
      </div>
    </>
  );
};

export default KpPreviewAndMainButtonsSection;
