import React from "react";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../../conteiners/ReactTable";
import DtCtPart from "../../../../../Project/ProjectDirectiveList/OrderDirectiveView/DtCtPart";
import OrderTypeComposedPart, {
  ORDER_COMPOSED_PART_TYPES,
} from "../../../../../Project/ProjectDirectiveList/OrderTypeComposedPart/OrderTypeComposedPart";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {CONDUCTED_ORDER_TRANSACTIONS_TABLE_COLUMNS, ORDER_TRANSACTIONS_TABLE_COLUMNS} from "../constants";

import DirectiveCreation from "./DirectiveCreation";
import OrderAssociationPart from "./OrderAssociationPart";

const DirectiveAndOrder = ({
  readOnly,
  orderTransactionsTableData = [],
  conductedOrderTransactionsTableData = [],
  onCompleteOrder = () => {},
  onExitBtnClick = () => {},
  onPrintOrderBtnClick = () => {},
  onCreateOrder = () => {},
  onDeleteOrder = () => {},
  onCreateDirectiveBtnClick,
  onCurrencySumSubmitBtnClick,
  noCounterpartyAssociation,
}) => {
  const orderTransactionsTableColumns = useColumns(ORDER_TRANSACTIONS_TABLE_COLUMNS);
  const conductedOrderTransactionsTableColumns = useColumns(CONDUCTED_ORDER_TRANSACTIONS_TABLE_COLUMNS);

  return (
    <div className="pr w100">
      <DirectiveCreation
        onCreateDirectiveBtnClick={onCreateDirectiveBtnClick}
        noCounterpartyAssociation={noCounterpartyAssociation}
      />
      <OrderAssociationPart onCurrencySumSubmitBtnClick={onCurrencySumSubmitBtnClick} />
      <div className="row nowrap gap pt5">
        <OrderTypeComposedPart readOnly className="fGrow" type={ORDER_COMPOSED_PART_TYPES.VIEW_FUll}>
          <div className="row w100 jcsb">
            <FunctionButton
              onClick={onCompleteOrder}
              name="Conduct"
              autoWidth
              // disabled={!isOrderSaved}
              // tooltipMessage={isOrderSaved ? null : "Order must be saved"}
            />

            <FunctionButton onClick={onExitBtnClick} name="Exit" autoWidth />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol" style={{width: "77%"}}>
          <DtCtPart />
          <div className="fcCol mw100">
            <div className="fGrow w100 mw100 h100">
              <ReactTable
                columns={orderTransactionsTableColumns}
                defaultData={orderTransactionsTableData}
                // selectable={false}
                // current={currentTransactionId}
                // onSelect={onTransactionSelect}
                className="mb scrollX"
                style={{height: "7rem"}}
              />
            </div>
            {!readOnly && (
              <div className={"fc nowrap jcfe w100 mb"}>
                <FunctionButton
                  name="Create order"
                  autoWidth
                  // disabled={!currentOrderId || upperTableData?.length === 0}
                  onClick={onCreateOrder}
                />
              </div>
            )}
            <div className="fGrow w100 mw100 h100">
              <ReactTable
                columns={conductedOrderTransactionsTableColumns}
                defaultData={conductedOrderTransactionsTableData}
                // selectable={false}
                // current={currentTransactionId}
                // onSelect={onTransactionSelect}
                className=" mb scrollX"
                style={{height: "9rem"}}
              />
            </div>
            <div className="row jcfe w100">
              {!readOnly && (
                <FunctionButton onClick={onDeleteOrder} name="Delete order" autoWidth style={{marginRight: "40px"}} />
              )}
              <FunctionButton
                onClick={onPrintOrderBtnClick}
                name="Print an order"
                autoWidth
                // disabled={!isOrderCompleate}
                // tooltipMessage={isOrderCompleate ? null : "Order must be saved and completed"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectiveAndOrder;
