import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Route, Routes} from "react-router-dom";

import ErrorBoundary from "../Components/errorBoundary/ErrorBoundary";
import Project from "../Components/Project/Project";
import ProjectDirectiveList from "../Components/Project/ProjectDirectiveList/ProjectDirectiveList";
import ProjectSecondVersion from "../Components/Project/ProjectSecondVersion/ProjectSecondVersion";
import {fetchAgents, fetchAgentTypes, fetchDesignerTypes} from "../slices/CatalogsSlice";

const ProjectPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDesignerTypes());
    dispatch(fetchAgents());
    dispatch(fetchAgentTypes());
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <Project />
          </ErrorBoundary>
        }
      />
      <Route
        path="/projectSecondVersion"
        element={
          // <ErrorBoundary>
          <ProjectSecondVersion />
          // </ErrorBoundary>
        }
      />
      <Route
        path="/projectDirectiveList"
        element={
          <ErrorBoundary>
            <ProjectDirectiveList readOnly />
          </ErrorBoundary>
        }
      />
      {/* <Route
        path="/projectView"
        element={
          <ErrorBoundary>
            <ProjectView />
          </ErrorBoundary>
        }
      /> */}
    </Routes>
  );
};

export default ProjectPage;
