import DoubleCheckIcon from "../../../images/icons/doubleCheck3x.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import Filters from "../../Filters/Filters";

import useFinBlockDirectiveList from "./useFinBlockDirectiveList";

const FinBlockDirectiveList = () => {
  const {
    filterFields,
    getFilterData,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    onOpenCreateInnerDirectieveModalBtnClick,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
  } = useFinBlockDirectiveList();

  return (
    <div className="fcCol h100 w100 pb">
      <Filters window="CashRegistrModal" fields={filterFields} getFilterData={getFilterData} className="grid2_5">
        <ImageButton
          src={DoubleCheckIcon}
          width={2}
          height={1.5}
          onClick={onOpenCreateInnerDirectieveModalBtnClick}
          className="fc jcc w100 mt bgc br"
        />
      </Filters>

      <Divider />
      <ReactTable
        columns={projectDirectiveTableColumns}
        defaultData={projectDirectiveTableData}
        current={null}
        onSelect={onProjectDirectiveTableSelect}
        className="tableTac fGrow"
        tableClassName="fGrow"
      />
      <div className="row w100 mt">
        <div className="row gap2x">
          <FunctionButton name="Open directive" onClick={onOpenDirectiveBtnClick} autoWidth />
          <FunctionButton name="Open project" onClick={onOpenProjectBtnClick} autoWidth />
        </div>
      </div>
    </div>
  );
};

export default withTabs({
  isMainTabControlled: true,
  isClosableTabs: true,
  label: "Project directives list",
  formInitialValues: {additionalPaymentAmount: ""},
})(FinBlockDirectiveList);
