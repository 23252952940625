import {useEffect, useState} from "react";
import CurrencyInput from "react-currency-input-field";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import {MoneyInput} from "../Inputs";

const SkuPriceInput = ({label, mayEdit, price, priceProc, verify, control, id, ...props}) => {
  const {t} = useTranslation();

  const [canEdit, setCanEdit] = useState(mayEdit);
  const [procent, setProcent] = useState(100);

  const {values} = useFormikContext();

  useEffect(() => {
    if (priceProc !== "%") {
      var p = +priceProc ? (values[price] * 100) / +priceProc : "";
      setProcent(p);
    }
  }, [priceProc, values[price]]);

  const onPencilClick = () => {
    setCanEdit(!canEdit);
  };

  return (
    <tr>
      <td>
        <label>{label}</label>
      </td>

      <td>
        <MoneyInput name={price} width="100%" readOnly={!canEdit} id={id} />
      </td>

      <td style={{textAlign: "center"}}>
        <CurrencyInput
          style={{width: "3.3em"}}
          allowDecimals={false}
          decimalSeparator={"."}
          decimalsLimit={0}
          decimalScale={0}
          disableGroupSeparators={true}
          autoComplete="off"
          suffix="%"
          readOnly={true}
          value={procent !== 0 ? procent : ""}
        />
      </td>
    </tr>
  );
};

SkuPriceInput.propTypes = {
  label: PropTypes.string,
  mayEdit: PropTypes.bool,
  price: PropTypes.string,
  priceProc: PropTypes.string,
  verify: PropTypes.bool,
  control: PropTypes.string,
  id: PropTypes.string,
  props: PropTypes.array,
};

export default SkuPriceInput;
