import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import * as Yup from "yup";

import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import LogSettingsSectionBlock from "../../Logist/LogSettings/LogSettingsSectionBlock/LogSettingsSectionBlock";

import AccountHolderTypeModal from "./modals/AccountHolderTypeModal/AccountHolderTypeModal";
import AccountOpeningTab from "./modals/AccountOpeningModal/AccountOpeningTab";
import BalanceSheetAccountsTab from "./modals/BalanceSheetAccountsModal/BalanceSheetAccountTab";
import CreatingBatchModal from "./modals/CreatingBatchModal/CreatingBatchModal";
import CT_Type from "./modals/CT_Type/CT_Type";
import AccAssociationTab from "./tabs/AccAssociationTab/AccAssociationTab";

const FinSettings = () => {
  const {t} = useTranslation();
  const {addNewTabCustom} = useTabsContext();

  // const FIN_SETTINGS_MODAL_TYPES = Object.freeze({
  //   BALANCE_SHEET: t("Balance sheet accounts"),
  //   ACCOUNT_OPENING: t("Account opening"),
  //   CREATING_BATCH: t("Creating a batch posting template"),
  //   ACCOUNT_HOLDER_TYPE: t("Type of Account Holder (AH)"),
  //   CT_TYPE: t("Type of cash transactions (CT)"),
  // });

  // const [currentType, setCurrentType] = useState(null);
  // const [modalActive, setModalActive] = useState(false);
  // const currentComponent = useMemo(() => {
  //   switch (currentType) {
  //     case FIN_SETTINGS_MODAL_TYPES.BALANCE_SHEET:
  //       return BalanceSheetAccountsTab;

  //     case FIN_SETTINGS_MODAL_TYPES.ACCOUNT_OPENING:
  //       return AccountOpeningTab;

  //     case FIN_SETTINGS_MODAL_TYPES.CREATING_BATCH:
  //       return CreatingBatchModal;

  //     case FIN_SETTINGS_MODAL_TYPES.ACCOUNT_HOLDER_TYPE:
  //       return AccountHolderTypeModal;

  //     case FIN_SETTINGS_MODAL_TYPES.CT_TYPE:
  //       return CT_Type;

  //     default:
  //       return null;
  //   }
  // }, [currentType]);

  const firstColumnBlocks = [
    {
      headingNumber: 1,
      heading: "Balance sheet accounts",
      text: "Chart of Accounts",
      onClick: () => {
        // setCurrentType(FIN_SETTINGS_MODAL_TYPES.BALANCE_SHEET);
        // setModalActive(true);
        addNewTabCustom({
          TabComponent: BalanceSheetAccountsTab,
          title: `1. ${t("Balance sheet accounts")}`,
        });
      },
      disabled: false,
      validateOnBlur: true,
    },
    {
      headingNumber: 2,
      heading: "Account opening",
      text: "The operation of opening",
      onClick: () => {
        // setCurrentType(FIN_SETTINGS_MODAL_TYPES.ACCOUNT_OPENING);
        // setModalActive(true);
        addNewTabCustom({TabComponent: AccountOpeningTab, title: `2. ${t("Account opening")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 3,
      heading: "Creating a batch posting template",
      text: "Creation of a package",
      onClick: () => {
        // setCurrentType(FIN_SETTINGS_MODAL_TYPES.CREATING_BATCH);
        // setModalActive(true);
        addNewTabCustom({TabComponent: CreatingBatchModal, title: `3. ${t("Creating a batch posting template")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 4,
      heading: "Type of Account Holder (AH)",
      text: "Create account holder type",
      onClick: () => {
        // setCurrentType(FIN_SETTINGS_MODAL_TYPES.ACCOUNT_HOLDER_TYPE);
        // setModalActive(true);
        addNewTabCustom({TabComponent: AccountHolderTypeModal, title: `4. ${t("Type of Account Holder (AH)")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 5,
      heading: "Type of cash transactions (CT)",
      text: "Creating a type of cash transaction",
      onClick: () => {
        // setCurrentType(FIN_SETTINGS_MODAL_TYPES.CT_TYPE);
        // setModalActive(true);
        addNewTabCustom({TabComponent: CT_Type, title: `5. ${t("Type of cash transactions (CT)")}`});
      },
      disabled: false,
    },
    {
      headingNumber: 6,
      heading: "Internal Accounts Association",
      text: "Creating an association for the Company's internal accounts.",
      onClick: () => {
        addNewTabCustom({TabComponent: AccAssociationTab, title: `6. ${t("Internal Accounts Association")}`});
      },
      disabled: false,
    },
  ];

  return (
    <div className="fcCol h100 w100 pb">
      <div className="h100 grid3Col">
        <div className="borderRight paddingRight">
          {firstColumnBlocks.map(({headingNumber, heading, text, onClick, disabled}) => (
            <LogSettingsSectionBlock
              key={headingNumber}
              headingNumber={headingNumber}
              heading={heading}
              text={text}
              onClick={onClick}
              disabled={disabled}
            />
          ))}
        </div>
        <div className="paddingLeft paddingRight"></div>
        <div className="borderLeft paddingLeft"></div>
      </div>

      {/* <ModalForm
        closable={true}
        Component={currentComponent}
        label={currentType}
        active={modalActive}
        setModalActive={setModalActive}
        onModalClose={() => {
          dispatch(clearCurrentBsAccount());
          dispatch(clearCurrentAccount());
        }}
        className="largeModal"
      /> */}
    </div>
  );
};

// export default FinSettings;
export default withTabs({
  isClosableTabs: true,
  label: "Settings (FinBlock)",
  // validateOnBlur: true,
  // validationSchema: Yup.object().shape({
  //   balanceSerialNumber: Yup.string().matches(/^\d{4}$/, "Must be 4 digits"),
  //   // .required("Required"),
  //   balanceSheetAccId: Yup.string().length(4, "Must be exactly 4 characters").matches(/^\d+$/, "Must be digits only"),
  //   // .required("This field is required")
  //   reserveCode: Yup.string().length(2, "Must be exactly 2 characters").matches(/^\d+$/, "Must be digits only"),
  //   // .required("This field is required")
  //   currencyId: Yup.string().length(3, "Must be exactly 3 characters").matches(/^\d+$/, "Must be digits only"),
  //   // .required("This field is required")
  // }),
})(FinSettings);
