import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {useFiltersContext} from "../../../conteiners/filters/useSessionFiltersContext";

const ResiduesBtnRow = ({
  currentBankTransactionId,
  onToTheClientBalanceBtnClick,
  onConversionBtnClick,
  onCheckTransactionsBtnClick,
  onConductBtnClick,
  onCreateDebtBtnClick,
  isOperationBetweenOwnAccounts,
  isTransactionWithNoClient,
  onAssociateResidueTransactionWithClientBtnClick,
  isAlignedResidue,
}) => {
  const {filters: savedFilters = {}} = useFiltersContext();

  return (
    <div className="row w100 mt0_5">
      {isTransactionWithNoClient ? (
        <FunctionButton
          name="Associate with a client"
          onClick={() => onAssociateResidueTransactionWithClientBtnClick({savedFilters})}
          autoWidth
          disabled={!currentBankTransactionId || isOperationBetweenOwnAccounts || isAlignedResidue}
          tooltipMessage={isOperationBetweenOwnAccounts ? "Operation between own accounts" : null}
        />
      ) : (
        <FunctionButton
          name="To the Client`s balance"
          onClick={() => onToTheClientBalanceBtnClick({savedFilters, isResidue: true})}
          autoWidth
          disabled={!currentBankTransactionId || isOperationBetweenOwnAccounts}
          tooltipMessage={isOperationBetweenOwnAccounts ? "Operation between own accounts" : null}
        />
      )}

      <FunctionButton
        name="Crediting to the balance"
        onClick={onToTheClientBalanceBtnClick}
        autoWidth
        className="noFunctional"
      />
      <FunctionButton name="Conversion" onClick={onConversionBtnClick} autoWidth className="noFunctional" />
      <FunctionButton
        name="Check transactions"
        onClick={onCheckTransactionsBtnClick}
        autoWidth
        className="noFunctional"
      />
      <FunctionButton name="Conduct" onClick={onConductBtnClick} autoWidth className="noFunctional" />
      <FunctionButton name="Create debt" onClick={onCreateDebtBtnClick} autoWidth className="noFunctional" />
    </div>
  );
};

export default ResiduesBtnRow;
