import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import Divider from "../../../../../utilities/Divider/Divider";
import {SessionFiltersContainer} from "../../../../conteiners/filters/Filter.hoc";
import ReactTable from "../../../../conteiners/ReactTable";
import withTabs from "../../../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../../../conteiners/tabs/useTabs";
import BankClient from "../../BankClient";
import BankClientFilters from "../../parts/BankClientFilters";
import ResiduesTab from "../ResiduesTab/ResiduesTab";

import useBankStatementsList from "./useBankStatementsList";

const BankStatementsList = () => {
  const BANK_STATEMENTS_LIST_WINDOW_NAME = "BankStatementsList";

  const {t} = useTranslation();

  const {
    bankStatementsListColumns,
    bankStatementsListData,
    onBankStatementsListFilterChange,
    loading,
    onGetDataBtnClick,
    folderPickerRef,
    handleFolderUpload,
  } = useBankStatementsList();

  const [current, setCurrent] = useState(null);

  const {setTabs} = useTabsContext();

  useEffect(() => {
    setTabs(prev => [
      prev[0],
      {TabComponent: BankClient, index: 1, title: "Bank operation register"},
      {TabComponent: ResiduesTab, index: 2, title: t("remains as of {{number}}", {number: "2699/09"})},
    ]);
  }, []);

  return (
    <div className="fcCol h100 w100 pb">
      <SessionFiltersContainer
        window={BANK_STATEMENTS_LIST_WINDOW_NAME}
        onFilterChange={onBankStatementsListFilterChange}
      >
        <BankClientFilters
          forBankStatements
          onGetDataBtnClick={onGetDataBtnClick}
          folderPickerRef={folderPickerRef}
          handleFolderUpload={handleFolderUpload}
          hasGetDataBtn
        />

        <Divider />
        <ReactTable
          columns={bankStatementsListColumns}
          defaultData={bankStatementsListData}
          current={current}
          onSelect={setCurrent}
          enableColumnResizing={true}
          className="tableTac fGrow scrollX"
          tableClassName="fGrow"
          loading={loading}
        />
      </SessionFiltersContainer>
    </div>
  );
};

export default withTabs({isClosableTabs: true, label: "Bank-Client statements (register)"})(
  withFormik({enableReinitialize: true, mapPropsToValues: () => ({})})(BankStatementsList),
);
