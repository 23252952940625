import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {fetchAllCurrencies, selectAllCurrency} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {Select} from "../../../Inputs/SelectField";

const CurencySelect = ({name = "currency", title, ...props}) => {
  const currencies = useSelector(selectAllCurrency);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currencies?.length === 0) {
      dispatch(fetchAllCurrencies());
    }
  }, []);
  const options = currencies?.map(curr => ({title: curr.shortName, value: curr.currencyId})) || [];
  return <Select label={t(title)} options={options} name={name} width="5em" minFilterLength={1} {...props} />;
};

export default CurencySelect;
