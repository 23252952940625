import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import {getOrgStructures} from "../../../selectors/CatalogSelectors";
import {registrUser} from "../../../slices/PersonSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {InvisibleInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import UsersList, {getPersonsList} from "./User/UserList/UserList";

const RegistrDemo = () => {
  const {t, i18n} = useTranslation();

  const persons = useSelector(getPersonsList);
  const positions = useSelector(state => state.person.userPositions);

  const [isFirstNameExcist, setIsFirstNameExcist] = useState(false);

  const orgStructures = useSelector(getOrgStructures);
  // const orgStructuresList = useSelector(getOrgStructuresList);

  const orgStructuresList = useMemo(() => {
    if (orgStructures && orgStructures.length > 0) {
      return orgStructures.map(item => {
        return {
          title: item[i18n.language === "en" ? "fullNameEng" : "fullNameUkr"],
          value: item.orgStructureId,
        };
      });
    } else {
      return [];
    }
  }, [orgStructures]);

  const orgStructuresCodeList = useMemo(() => {
    if (orgStructures && orgStructures.length > 0) {
      return orgStructures.map(org => {
        return {
          title: org.shortName,
          value: org.orgStructureId,
        };
      });
    } else {
      return [];
    }
  }, [orgStructuresList]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    return {
      fullName: "",
      personaId: "",
      orgFullName: "",
      orgShortName: "",
      orgStructureId: "",
      positionId: "",
      positionName: "",
    };
  }, []);

  const persList = persons
    .map(item => {
      return {
        title: item.value,
        value: item.personaId,
        lastName: item.lastName,
      };
    })
    .filter(pers => pers.lastName !== null);

  // const positionList = positions.map(item => {
  //   return {
  //     title: item.value,
  //     value: item.positionId,
  //   };
  // });

  const findPersonFullName = id => {
    var persona_ = persons.find(per => per.personaId === +id);
    console.log(persona_);
    setIsFirstNameExcist(!!persona_?.lastName);
    return persona_ ? persona_.personaId : "";
  };

  const onOfficeSelect = id => {
    var office = orgStructures.find(item => item.orgStructureId === +id);
    return office ? office.orgStructureId : "";
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          fullName: Yup.string().required(),
          personaId: Yup.number().required(),
          // orgStructureId: Yup.number().required(),
          // positionId: Yup.number().required(),
        })}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={(values, {resetForm}) => {
          const {fullName, personaId, positionName, orgShortName} = values;

          if (personaId && fullName) {
            dispatch(
              registrUser({
                personaId: personaId,
                orgStructureId: orgShortName,
                positionId: positionName,
              }),
            ).then(res => {
              if (res.meta.requestStatus === "fulfilled") resetForm();
            });
          } else {
            Swal.fire({
              title: t("Doesnt exist"),
              text: t("Go to creation"),
              icon: "question",
              confirmButtonText: t("Yes"),
              showCancelButton: true,
              cancelButtonText: t("No"),
            }).then(value => {
              if (value.isConfirmed) {
                navigate(`/admin/addPerson/${fullName.split(" ").join("&")}`);
              }
            });
          }
        }}
      >
        {({
          // values, errors, meta, isSubmitting, resetForm, handleChange,
          setFieldValue,
        }) => (
          <Form className="project_form">
            <div className="" style={{width: "15em"}}>
              <TextInput
                // width='20'
                label={t("Personal id")}
                name="personaId"
                func={e => {
                  setFieldValue("fullName", findPersonFullName(e));
                }}
              />
            </div>
            <Select
              label={t("Full Name")}
              name="fullName"
              width="100%"
              multi={true}
              minFilterLength={1}
              options={persList}
              makeEffect={e => {
                // setFieldValue("personaId", onOfficeSelect(e));
                setFieldValue("personaId", e?.value);
                setIsFirstNameExcist(!!e?.lastName);
              }}
            />

            <InvisibleInput name="orgStructureId" />
            <div className="flex_container nowrap autolabel">
              <Select
                label={t("Office")}
                name="orgFullName"
                type="text"
                width="60%"
                multi={true}
                options={orgStructuresList}
                makeEffect={e => {
                  setFieldValue("orgShortName", onOfficeSelect(e?.value));
                }}
              />

              <Select
                label={t("Office code")}
                name="orgShortName"
                type="text"
                width="60%"
                multi={true}
                minFilterLength={1}
                options={orgStructuresCodeList}
                makeEffect={e => {
                  setFieldValue("orgFullName", findPersonFullName(e?.value));
                }}
              />
            </div>
            <Select label={t("Position")} options={positions} name="positionName" multi={true} />

            <FunctionButton type="submit" name={t("Register")} />

            <FunctionButton
              name={t("Add person")}
              style={{left: "2em"}}
              onClick={() => {
                navigate("/ba3a/admin/addPerson");
              }}
            />
          </Form>
        )}
      </Formik>
      <UsersList />
    </>
  );
};

export default RegistrDemo;
