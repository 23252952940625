import {t} from "i18next";

import {TextAreaInput, TextInput} from "../../../../../Inputs";

import AccountNumber from "./AccountNumber";

const ExtIntAccPart = () => {
  return (
    <div className="fcCol w100 aistr">
      <span className="mb">{t("External / Internal accounts №№")}</span>
      <div className="row gap mb0_5">
        <TextInput name="bank" label={t("Bank")} autolabel generalClassName="fGrow" style={{minWidth: "9rem"}} />
        <TextInput
          type="number"
          name="mfi"
          label={t("MFI")}
          autolabel
          inputClassName="border inputTypeNumberNoArrows ml5"
          width="6rem"
        />
        <TextInput
          name="externalAccNumber"
          inputClassName="border leftPlaceholder"
          placeholder={t("External account № ")}
          width="25.9rem"
        />
      </div>
      <div className="row jcfe">
        <AccountNumber disabled />
      </div>
      <TextAreaInput name="internalAccNaming" placeholder={t("Account name")} height="2.5rem" disabled />
    </div>
  );
};

export default ExtIntAccPart;
