import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {checkEmptyFilterFields} from "../../../../utilities/checkEmptyFilterFields";
import {Filters} from "../../../conteiners/filters/Filter.hoc";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import {
  fetchAllFinStatuses,
  fetchDirectiveFilters,
  selectAllStatuses,
  selectOperationTypes,
} from "../../FinSettings/FinSettingsSlice";

const CashdeskDirectiveFilters = () => {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const dispatch = useDispatch();

  const statuses = useSelector(selectAllStatuses);
  // const fetchedFilters = useSelector(selectDirectiveFilters);
  const operationTypes = useSelector(selectOperationTypes);

  // const clientNumberOptions =
  //   fetchedFilters?.clients?.map(({clientNumber, clientId}) => ({title: clientNumber, value: clientId})) ?? [];
  // const coNumberOptions = fetchedFilters?.cos?.map(({coNumber, coId}) => ({title: coNumber, value: coId})) ?? [];
  // const operationTypeOptions =
  //   fetchedFilters?.operationTypes?.map(({operationTypeId, nameEng, nameUkr}) => ({
  //     value: operationTypeId,
  //     title: language === "en" ? nameEng : nameUkr,
  //   })) ?? [];
  const operationTypeOptions =
    operationTypes?.map(({operationTypeId, nameEng, nameUkr}) => ({
      value: operationTypeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const statusOptions =
    statuses?.map(({statusId, nameEng, nameUkr}) => ({
      value: statusId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  // const sumOptions = fetchedFilters?.sumPlans?.map(sum => ({title: sum, value: sum})) ?? [];
  // const PAYER_DATA = Object.freeze({
  //   TITLE: "pName",
  //   EDRPOUO: "edrpouCode",
  //   P_ACCOUNT: "currentAccount",
  // });
  // const payerDataTypeOptions = [
  //   {title: t("Title"), value: PAYER_DATA.TITLE},
  //   {title: t("EDRPOUO"), value: PAYER_DATA.EDRPOUO},
  //   {title: t("p/acc"), value: PAYER_DATA.P_ACCOUNT},
  // ];
  // const [payerDataType, setPayerDataType] = useState(null);
  const [filterFields, setFilterFields] = useState({});

  // const payerDataOptions =
  //   payerDataType === PAYER_DATA.TITLE
  //     ? fetchedFilters?.payerNames?.map(name => ({title: name, value: name}))
  //     : payerDataType === PAYER_DATA.EDRPOUO
  //     ? fetchedFilters?.payerEdrpouCodes?.map(code => ({title: code, value: code}))
  //     : payerDataType === PAYER_DATA.P_ACCOUNT
  //     ? fetchedFilters?.payerAccounts?.map(accountName => ({title: accountName, value: accountName}))
  //     : [];

  const onMakeEffect = ({value, name}) => {
    const field = {[name]: value};
    setFilterFields(prev => ({...prev, ...field}));
  };

  useEffect(() => {
    dispatch(fetchDirectiveFilters(checkEmptyFilterFields(filterFields)));
  }, [filterFields]);

  useEffect(() => {
    dispatch(fetchAllFinStatuses());
  }, []);

  // const formPayerDataParamName = payerDataType => {
  //   switch (payerDataType) {
  //     case PAYER_DATA.TITLE:
  //       return "name";

  //     case PAYER_DATA.EDRPOUO:
  //       return "edrpouCode";

  //     case PAYER_DATA.P_ACCOUNT:
  //       return "currentAccount";
  //   }
  // };

  const onFilterDelete = value => {
    if (!value) setFilterFields({});
    else setFilterFields(prev => ({...prev, [value]: ""}));
  };

  return (
    <Filters onFilterDelete={onFilterDelete} style={{alignItems: "center"}}>
      <div className="row nowrap w100">
        <TextInput
          name="directiveNumber"
          label={t("Directive №")}
          style={{
            justifySelf: "stretch",
            gridArea: "directiveNumber",
          }}
          autolabel
          onBlur={(_, value) => {
            onMakeEffect({value, name: "directiveNumber"});
          }}
        />
        <Select
          name="statusId"
          label={t("Status")}
          options={statusOptions}
          style={{
            justifySelf: "stretch",
            gridArea: "statusId",
          }}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: "statusId"});
          }}
        />
        {/* <Select
          name="clientId"
          label={t("Client №")}
          options={clientNumberOptions}
          style={{
            justifySelf: "stretch",
            gridArea: "clientId",
          }}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: "clientId"});
          }}
        /> */}
        {/* <Select
          name="coId"
          label={t("CO №")}
          options={coNumberOptions}
          style={{
            justifySelf: "stretch",
            gridArea: "coId",
          }}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: "coId"});
          }}
        /> */}
        <Select
          name="operationTypeId"
          label={t("Transaction type")}
          options={operationTypeOptions}
          style={{
            justifySelf: "stretch",
            gridArea: "operationTypeId",
          }}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: "operationTypeId"});
          }}
        />
        {/* <Select
          name="sumPlan"
          label={t("Sum (s/t)")}
          options={sumOptions}
          style={{
            justifySelf: "stretch",
            gridArea: "sumPlan",
          }}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: "sumPlan"});
          }}
        /> */}
        {/* <div className="row gap5 border" style={{gridArea: "payerData", justifySelf: "stretch", padding: "2px"}}>
          <Select
            name="payerDataType"
            label={t("Payer data")}
            options={payerDataTypeOptions}
            inputClassName="fGrow"
            className="fGrow"
            generalClassName="fGrow"
            makeEffect={option => {
              setPayerDataType(option?.value || null);
            }}
          />
          <Select
            name="payerData"
            label=" "
            options={payerDataOptions}
            style={{justifySelf: "stretch"}}
            makeEffect={option => {
              onMakeEffect({value: option?.value, name: formPayerDataParamName(payerDataType)});
            }}
          />
        </div> */}
        <DateInput
          name="dateStart"
          style={{gridArea: "dateStart"}}
          label={t("Period From")}
          onDateChange={value => {
            onMakeEffect({value, name: "dateStart"});
          }}
        />
        <DateInput
          name="dateEnd"
          label={t("To")}
          style={{justifyContent: "flex-end", gridArea: "dateEnd"}}
          makeEffect={value => {
            onMakeEffect({value, name: "dateEnd"});
          }}
        />
      </div>
    </Filters>
  );
};

export default CashdeskDirectiveFilters;
