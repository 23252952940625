import {useState} from "react";
import PropTypes from "prop-types";

import Modal from "../../../Modal/Modal";
import ImgSlider from "../slider/NewImgSlider";

import "./ImgBox.scss";

const ImgBox = ({imgs, classNames, width, height, className, onActiveImgChange, label, showIndicators = true}) => {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Modal active={modal} setActive={setModal} width={"800px"} height={"700px"}>
        {imgs?.length > 0 && <ImgSlider width={"600px"} height={"600px"} imgs={imgs} />}
      </Modal>
      {label && <div className="label imgBox__label">{label}</div>}
      <div className={"imgBox " + classNames}>
        <div className="imgBox__container" onDoubleClick={() => (imgs.length === 0 ? null : setModal(true))}>
          {imgs?.length > 0 && (
            <ImgSlider
              width={width}
              height={height}
              // className={className}
              imgs={imgs}
              onActiveImgChange={onActiveImgChange}
              showIndicators={showIndicators}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ImgBox.propTypes = {
  imgs: PropTypes.array,
  classNames: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onActiveImgChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
};

export default ImgBox;
