import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {dateFormat, timeFormat} from "../../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../../utilities/formatAccountNumber";
import {toaster} from "../../../../../utilities/hooks/toaster";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import {
  alignCounterpartyAcc,
  alignUntilFurtherNotice,
  completeBankTransaction,
  fetchAllBankTransactions,
  selectBankTransaction,
} from "../../../FinBlockSlice";
import {fetchAllDirectives, fetchFinOrder, selectStatementDirective} from "../../../FinSettings/FinSettingsSlice";
import {formTransactionStatus} from "../../BankClient";

import {CLIENT_ACCOUNT_TYPE_ID} from "./parts/CounterpartyAssociation";

const useAccAssotiation = props => {
  const {isResidue} = props ?? {};
  const dispatch = useDispatch();

  const bankTransaction = useSelector(selectBankTransaction);
  const isBankTransactionCompleted = bankTransaction?.isCompleted;
  const hasInternal = bankTransaction?.internalTransactions?.length > 0;
  const isAlignedResidue = bankTransaction?.internalTransactions?.length > 1;
  const internalTransaction = (bankTransaction?.internalTransactions ?? [])[0];
  const clientId =
    bankTransaction?.counterpartyAccount?.owner?.clientId || bankTransaction?.creditAccount?.owner?.clientId;
  const hasCurrentTranscationAssociation = !!bankTransaction?.isFullfiled;
  const statementDirective = useSelector(selectStatementDirective);

  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (!statementDirective?.orders) {
      setCurrentOrderId(null);
      return;
    }
    const reversed = [...statementDirective.orders]?.reverse();
    const order = reversed.find(order => order?.typeId === 3);
    setCurrentOrderId(order?.orderId);
    if (order?.orderId) dispatch(fetchFinOrder(order?.orderId));
  }, [statementDirective]);

  const realTransactionTableData = useMemo(() => {
    const statement = bankTransaction?.bankClientStatement;
    if (!statement) return [];

    const counterparty = {
      name: statement?.counterparty,
      iban: statement?.counterpartyIban,
      edrpou: statement?.counterpartyEdrpou,
      bankMfis: statement?.counterpartyBankMfis,
      bank: statement?.counterpartyBank,
    };
    const initial = {
      name: "ГРУПА КОМПАНIЙ ТЕХНО ПП",
      iban: statement?.iban,
      edrpou: statement?.edrpou,
      bankMfis: statement?.bankMfis,
    };
    const isDebit = Boolean(statement?.isDebit);
    const payer = isDebit ? initial : counterparty;
    const recipient = isDebit ? counterparty : initial;
    return [
      {
        id: statement?.statementId,
        number: 1,
        bankP: payer?.bank,
        mfiP: payer?.bankMfis,
        docNumber: statement?.documentNumber,
        docDate: dateFormat(statement?.createdAt),
        date: dateFormat(statement?.dateAndTime),
        time: timeFormat(statement?.dateAndTime),
        costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
        revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
        currency: bankTransaction.currency.shortName,
        payerAccountNumber: formatAccountNumber(payer?.iban),
        requisitesP: payer?.edrpou,
        payerName: payer?.name,
        paymentPurpose: statement?.paymentPurpose,
        recipientAccountNumber: formatAccountNumber(recipient?.iban),
        requisitesR: recipient?.edrpou,
        recipientName: recipient?.name,
        recipientBank: recipient?.bank,
        mfiR: recipient?.bankMfis,
        rate: statement?.rate,
        equivalent: statement?.nbuEquivalentSum,
        commissionsAmmount: statement?.feesSum,
        remainder: isDebit ? statement?.debitAccount?.balance : statement?.creditAccount?.balance, // !!!!!
        reference: "?",
        operationCode: statement?.operationCode,
        status: "?",
        trId: statement?.transactionUuid,
        bank: statement?.bank,
        mfi: statement?.bankMfis,
        accountNumber: formatAccountNumber(statement?.iban),
        accHolderName: payer.name,
        counterpartyAccountNumber: formatAccountNumber(statement?.counterpartyIban),
        counterpartyName: statement?.counterparty,
        counterpartyBank: statement?.counterpartyBank,
        mfiC: statement?.counterpartyBankMfis,
        requisitesC: statement?.counterpartyEdrpou,
      },
    ];
  }, [bankTransaction?.transactionId]);

  const formInternalTransactionData = ({internalTransaction, isDebit, statement, number}) => {
    if (!internalTransaction) return {};

    return {
      id: internalTransaction?.transactionId + internalTransaction?.statementId,
      number,
      trId: bankTransaction?.bankClientStatement?.transactionUuid,
      accountNumber: formatAccountNumber(
        isDebit ? internalTransaction?.debitAccount.accountNumber : internalTransaction?.creditAccount.accountNumber,
      ),

      counterparty: statement?.counterparty,
      date: dateFormat(internalTransaction?.createdAt),
      status: formTransactionStatus(bankTransaction),
      operationType: t("To the Payer`s balance"),
      costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
      revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
      currency: bankTransaction?.currency?.shortName,
      counterpartyAccountNumber: formatAccountNumber(
        isDebit ? internalTransaction?.creditAccount.accountNumber : internalTransaction?.debitAccount.accountNumber,
      ),
      payerAccountNumber: formatAccountNumber(internalTransaction?.debitAccount?.accountNumber),
      requisitesP: "",
      //  payer?.edrpou,
      payerName: bankTransaction?.debitAccount?.accountName,
      paymentPurpose: internalTransaction?.paymentPurpose,
      recipientAccountNumber: formatAccountNumber(internalTransaction?.creditAccount?.accountNumber),
      requisitesR: "",
      // залишок: internal credit acc balance
      // recipient?.edrpou,
      recipientName: bankTransaction?.creditAccount?.accountName,
      rate: statement?.rate,
      equivalent: bankTransaction?.bankClientStatement?.nbuEquivalentSum,
      remainder: numberFormat(internalTransaction?.creditAccount?.balance),
      counterpartyName: bankTransaction?.bankClientStatement?.counterparty,
      requisitesC: bankTransaction?.bankClientStatement?.counterpartyEdrpou,
    };
  };

  const associatedTransactionTableData = useMemo(() => {
    if (!bankTransaction) return [];

    const statement = bankTransaction?.bankClientStatement;

    const counterparty = {
      name: statement?.counterparty,
      iban: statement?.counterpartyIban,
      edrpou: statement?.counterpartyEdrpou,
      bankMfis: statement?.counterpartyBankMfis,
      bank: statement?.counterpartyBank,
    };
    const initial = {
      name: "ГРУПА КОМПАНIЙ ТЕХНО ПП",
      iban: statement?.iban,
      edrpou: statement?.edrpou,
      bankMfis: statement?.bankMfis,
    };
    const isDebit = Boolean(statement?.isDebit);
    const payer = isDebit ? initial : counterparty;
    const recipient = isDebit ? counterparty : initial;

    const {creditAccount, debitAccount} = bankTransaction;

    //in residues tab
    if (isResidue) {
      return isAlignedResidue
        ? [
            formInternalTransactionData({internalTransaction, isDebit, statement, number: 1}),
            formInternalTransactionData({
              internalTransaction: bankTransaction?.internalTransactions[1],
              isDebit,
              statement,
              number: 2,
            }),
          ]
        : [formInternalTransactionData({internalTransaction, isDebit, statement, number: 1})];
    }
    //in bank client main when transaction in associated
    else if (hasInternal) {
      return [
        {
          id: bankTransaction?.transactionId,
          number: 1,
          trId: bankTransaction?.bankClientStatement?.transactionUuid,
          accountNumber: formatAccountNumber(isDebit ? debitAccount.accountNumber : creditAccount.accountNumber),
          counterparty: statement?.counterparty,
          date: dateFormat(bankTransaction?.createdAt),
          status: formTransactionStatus(bankTransaction),
          operationType: t("To the Payer`s balance"),
          costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
          revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
          counterpartyAccountNumber: formatAccountNumber(
            isDebit ? creditAccount.accountNumber : debitAccount.accountNumber,
          ),
          currency: bankTransaction?.currency?.shortName,
          payerAccountNumber: formatAccountNumber(bankTransaction?.debitAccount?.accountNumber),
          requisitesP: payer?.edrpou,
          payerName: payer?.name,
          paymentPurpose: bankTransaction?.paymentPurpose,
          recipientAccountNumber: formatAccountNumber(bankTransaction?.creditAccount?.accountNumber),

          requisitesR: recipient?.edrpou,
          recipientName: recipient?.name,
          rate: statement?.rate,
          equivalent: bankTransaction?.bankClientStatement?.nbuEquivalentSum,
          remainder: numberFormat(bankTransaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
          counterpartyName: bankTransaction?.bankClientStatement?.counterparty,
          requisitesC: bankTransaction?.bankClientStatement?.counterpartyEdrpou,
        },
        formInternalTransactionData({internalTransaction, isDebit, statement, number: 2}),
      ];
    }
    //in bank client main when transaction in not associated
    else {
      return [
        {
          id: bankTransaction?.transactionId,
          number: 1,
          trId: bankTransaction?.bankClientStatement?.transactionUuid,
          accountNumber: formatAccountNumber(isDebit ? debitAccount.accountNumber : creditAccount.accountNumber),
          counterparty: statement?.counterparty,
          date: dateFormat(bankTransaction?.createdAt),
          status: formTransactionStatus(bankTransaction),
          operationType: t("To the Payer`s balance"),
          costsDt: isDebit ? numberFormat(statement?.operationSum) : "",
          revenueCt: isDebit ? "" : numberFormat(statement?.operationSum),
          currency: bankTransaction?.currency?.shortName,
          payerAccountNumber: formatAccountNumber(bankTransaction?.debitAccount?.accountNumber),
          counterpartyAccountNumber: formatAccountNumber(
            isDebit ? creditAccount.accountNumber : debitAccount.accountNumber,
          ),
          requisitesP: payer?.edrpou,
          payerName: payer?.name,
          paymentPurpose: bankTransaction?.paymentPurpose,
          recipientAccountNumber: formatAccountNumber(bankTransaction?.creditAccount?.accountNumber),
          requisitesR: recipient?.edrpou,
          recipientName: recipient?.name,
          rate: statement?.rate,
          equivalent: bankTransaction?.bankClientStatement?.nbuEquivalentSum,
          remainder: numberFormat(bankTransaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
          counterpartyName: bankTransaction?.bankClientStatement?.counterparty,
          requisitesC: bankTransaction?.bankClientStatement?.counterpartyEdrpou,
        },
      ];
    }
  }, [bankTransaction?.transactionId, internalTransaction, isResidue, hasInternal, isAlignedResidue]);

  const onSaveAssociationBtnClick = ({isResidue, values, savedFilters = {}}) => {
    if (
      !bankTransaction ||
      !values.counterpartyTypeId ||
      (values.counterpartyTypeId === CLIENT_ACCOUNT_TYPE_ID && !values.clientId)
    )
      return;

    if (isResidue) {
      dispatch(
        alignUntilFurtherNotice({
          id: bankTransaction?.transactionId,
          clientId: values.clientId,
        }),
      ).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Association saved");

          dispatch(fetchAllBankTransactions({isResidues: !!isResidue, ...savedFilters}));
        }
      });
      return;
    }

    dispatch(
      alignCounterpartyAcc({
        id: bankTransaction?.transactionId,
        clientId: values.clientId,
        accountTypeId: values.counterpartyTypeId,
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Association saved");

        dispatch(completeBankTransaction(bankTransaction?.transactionId)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            dispatch(fetchAllBankTransactions(savedFilters));
          }
        });
      }
    });
  };

  const onCreateDirectiveBtnClick = () => {
    if (
      !bankTransaction
      // || isBankTransactionCompleted
    )
      return;

    dispatch(
      fetchAllDirectives({
        clientId,
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        // if (resp.payload.length > 0) {
        setModalActive(true);
        // } else {
        //   dispatch(
        //     addNewStatementDirective({
        //       clientId,
        //       transactionId: bankTransaction?.transactionId,
        //     }),
        //   );
        // }
      }
    });
  };
  return {
    isModalActive,
    currentOrderId,
    onCreateDirectiveBtnClick,
    onSaveAssociationBtnClick,
    associatedTransactionTableData,
    realTransactionTableData,
    bankTransaction,
    statementDirective,
    setModalActive,
    isBankTransactionCompleted,
    hasCurrentTranscationAssociation,
    hasInternal,
    isAlignedResidue,
  };
};

export default useAccAssotiation;
