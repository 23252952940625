import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../conteiners/ReactTable";
import {NumberInput, TextAreaInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

import useBalanceSheetAccountsModal from "./useBalanceSheetAccountsModal";

const BalanceSheetAccountsModal = () => {
  const {t} = useTranslation();

  const {
    typeOptions,
    accountClassOptions,
    balanceTableColumns,
    balanceTableData,
    initialValues,
    onSaveBtnClick,
    onEditBtnClick,
    onDeleteBtnClick,
    onBalanceTableRowSelect,
    currentAccountId,
    onBalanceClassSelect,
    validationSchema,
    findOutIfSaveBtnDisabled,
    findOutIfEditBtnDisabled,
  } = useBalanceSheetAccountsModal();

  return (
    <div className="w100 fcCol aistr">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
      >
        {({values, setValues}) => (
          <Form>
            <div className="dg gridTemplate2Rows gridTemplate3ColsFirstMin gap0_5 pt">
              <Select
                name="balanceClassId"
                label={t("Account class")}
                options={accountClassOptions ?? []}
                width="10em"
                makeEffect={data => onBalanceClassSelect({data, setValues})}
              />
              <TextAreaInput name="balanceClassDescription" width="35em" height="32.5px" className="fGrow" readOnly />
              <TextAreaInput
                name="debitDescription"
                width="35em"
                className="fGrow"
                placeholder={t("Amounts are debited to the account")}
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
              />
              <div className="column aist">
                <NumberInput
                  name="balanceSerialNumber"
                  label={t("Balance №")}
                  width="4em"
                  question={t("must consist of 4 digits and start with account class digit")}
                  inputClassName="border ml"
                  questionItemClassName="pl1_5x"
                  noFormat
                  maxNumberLength={4}
                />
                <Select
                  name="balanceTypeId"
                  label={t("Type")}
                  options={typeOptions ?? []}
                  width="4em"
                  style={{marginTop: "12px"}}
                />
              </div>
              <div className="column noGap">
                <TextAreaInput
                  name="balanceName"
                  height="32.5px"
                  width="35em"
                  className="fGrow"
                  style={{paddingTop: 0}}
                  placeholder={t("Account naming")}
                />
                <TextAreaInput
                  name="balancePurpose"
                  height="32.5px"
                  width="35em"
                  className="fGrow"
                  style={{paddingTop: "5px"}}
                  placeholder={t("Account purpose")}
                />
              </div>
              <TextAreaInput
                name="creditDescription"
                width="35em"
                className="fGrow"
                style={{paddingTop: 0}}
                placeholder={t("Amounts are debited to the Credit account")}
                spellCheck="false"
                autoComplete="off"
                autoCorrect="off"
              />
            </div>
            <div className="row jcfe gap2x mt">
              <FunctionButton
                name="Save"
                onClick={() => onSaveBtnClick({values, setValues})}
                disabled={findOutIfSaveBtnDisabled({values})}
                tooltipMessage={
                  !values.balanceSerialNumber?.startsWith(values.balanceClassId) ? "wrongFirstBalanceNumber" : ""
                }
              />
              <FunctionButton
                name="Edit"
                onClick={() => onEditBtnClick({values, setValues})}
                disabled={findOutIfEditBtnDisabled({values})}
              />
              <FunctionButton
                name="Delete"
                onClick={() => onDeleteBtnClick({values, setValues})}
                disabled={!currentAccountId}
              />
            </div>
          </Form>
        )}
      </Formik>
      <ReactTable
        className="mt fGrow"
        columns={balanceTableColumns}
        defaultData={balanceTableData}
        current={currentAccountId}
        onSelect={onBalanceTableRowSelect}
        style={{minHeight: "25em", flexGrow: 1}}
      />
    </div>
  );
};

export default BalanceSheetAccountsModal;
