import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import {createTemplate, fetchTemplatesWithFilter, getTemplates, kpDocAdded} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import useToaster from "../../../../utilities/hooks/useToaster";
import ModalForm from "../../../Modal/FormModal";
import {useModal} from "../../../Modal/ModalProvider";
import PdfFormatSwith from "../../../pdfGenerate/PdfFormatSwith";
import {generatePdf} from "../../../pdfGenerate/pdfMaker";
import {deselectSku} from "../../../SKU/SkuSlice";
import TemplateCreateFromKpModal from "../../../Templates/TemplateRegistr/TemplateRegisterParts/TemplateCreateFromKpModal";
import {
  addSkuPlanDoc,
  changeSequenceNumber,
  deselect,
  getCurrentSkuPlan,
  getPlanSkuList,
  getSkuPlanInfo,
} from "../KpFinSlice";

import useKpFinCollumnsService from "./utils/kpFinColumns";
import useKpFinDataService from "./KpFinDataService";
import useKpFinTableService from "./KpFinTableService";
import PdfPreviewModal from "./PdfPrevievModal";

import "./kpFinTable.scss";

const KpFinTable = ({currentKpF, kpFin}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const Toaster = useToaster();
  const {openModal} = useModal();

  // const location = useLocation();
  // const {currentKpId} = location.state || {currentRequestId: ""};
  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const [pdfFormat, setPDFFormat] = useState("A4");
  const [createTemplateModalActive, setCreateTemplateModalActive] = useState(false);

  const planSkuList = useSelector(getPlanSkuList);

  const {getKpFinTableData} = useKpFinDataService();

  const kpFinTableData = useMemo(() => {
    return planSkuList?.map(getKpFinTableData) || [];
  }, [planSkuList]);

  // for numeration change, when user moves the lines in table
  const onReorder = useCallback(
    reorderedTableData => {
      if (reorderedTableData) {
        var sequenceObject = reorderedTableData.map(plan => {
          return {
            skuPlanId: plan.id,
            sequenceNumber: plan.number,
          };
        });

        dispatch(changeSequenceNumber({sequenceObject}));
      }
    },
    [currentKpF],
  );

  const selectSkuPlan = id => {
    if (currentSkuPlan && currentSkuPlan.skuPlanId === id) {
      dispatch(deselect());
      return;
    }
    if (kpFin) {
      dispatch(getSkuPlanInfo(id));
      dispatch(deselectSku());
    }
  };

  const generateCofPdf = () => {
    const data = generateDataForExport(`${t("CO(f)")}_${currentKpF.cofNumber}`, pdfFormat);
    if (data.dataRows.length === 0) {
      Toaster.toastError("KP can`t be empty");
      return null;
    }

    return generatePdf(data, pdfFormat);
  };

  const exportToPdf = withDownload => {
    const pdf = generateCofPdf();
    if (!pdf) return;
    if (withDownload) pdf.download(`kpf_${currentKpF.cofNumber}_doc`);
    pdf.getBlob(file => {
      var formData = new FormData();
      var fileName = `kpf_${currentKpF.cofNumber}_doc.pdf`;
      formData.append("doc", file, fileName);

      dispatch(addSkuPlanDoc({cofId: currentKpF.cofId, formData})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(kpDocAdded(res.payload.docs));
        }
      });
    });
  };

  const onPreview = () => {
    const pdf = generateCofPdf();
    if (!pdf) return;
    openModal(<PdfPreviewModal pdfFile={pdf} exportToPdf={exportToPdf} />, {label: t("Pdf preview")});
  };

  // const onReturnToKpClick = () => {
  //   Swal.fire({
  //     text: t("export before return co"),
  //     icon: "question",
  //     confirmButtonText: t("Yes"),
  //     showCancelButton: true,
  //     cancelButtonText: t("No"),
  //   }).then(value => {
  //     if (value.isConfirmed) {
  //       exportToPdf();
  //       setTimeout(() => {
  //         dispatch(getKpInfoForKp(currentKpId)).then(() => navigate("/ba3a/co"));
  //       }, 2000);
  //     } else {
  //       // dispatch(getKpInfoForKp(currentKpId)).then(() => ;
  //       navigate("/ba3a/co", {state: {needUpd: true, coId: currentKpId}});
  //     }
  //   });
  // };

  const toggledColl = useMemo(
    () => [
      "article",
      "characteristics",
      "ovh",
      "image1",
      "image2",
      "complectDesc",
      "discount",
      "totalSumm",
      "topDiscount",
    ],
    [],
  );
  const {collumns} = useKpFinCollumnsService(toggledColl);

  const {KpFinTableRender, generateDataForExport} = useKpFinTableService(
    collumns,
    kpFinTableData,
    onReorder,
    selectSkuPlan,
    toggledColl,
  );
  const KpFinTable = KpFinTableRender();

  return (
    <>
      {KpFinTable}
      <div
        className="fc jcsb"
        style={{
          marginBottom: "10px",
          // position: "absolute",
          bottom: "-0.23em",
          width: "100%",
        }}
      >
        <div className="fc">
          <FunctionButton name={t("Save")} onClick={() => exportToPdf(false)} disabled={planSkuList?.length === 0} />
          <FunctionButton
            name={t("Save + pdf")}
            onClick={() => exportToPdf(true)}
            disabled={planSkuList?.length === 0}
            style={{marginLeft: "10px"}}
          />
          <FunctionButton name={t("Dublicate")} style={{marginLeft: "10px"}} disabled={true} />
          <FunctionButton
            name={t("PDF preview")}
            style={{marginLeft: "10px"}}
            onClick={onPreview}
            disabled={planSkuList?.length === 0}
          />
        </div>
        <PdfFormatSwith setFormat={setPDFFormat} />
        <div className="fc">
          <FunctionButton
            name={t("Template")}
            style={{marginLeft: "10px"}}
            onClick={() => {
              setCreateTemplateModalActive(true);
            }}
            disabled={planSkuList?.length === 0}
            autoWidth
          />
          <FunctionButton name={t("Postings")} style={{marginLeft: "10px"}} disabled={true} />
        </div>
        {/* <div>
          <FunctionButton name={t("return to kp")} style={{marginLeft: "10px"}} onClick={onReturnToKpClick} />
        </div> */}
      </div>
      <ModalForm
        // closable={true}
        Component={TemplateCreateFromKpModal}
        label={t("Creating template tCO")}
        active={createTemplateModalActive}
        setModalActive={setCreateTemplateModalActive}
        className="largeModal"
        currentCOf={currentKpF}
      />
    </>
  );
};

KpFinTable.propTypes = {
  onUpdate: PropTypes.func,
  currentKpF: PropTypes.object,
  setCurrSkuPlan: PropTypes.func,
  active: PropTypes.object,
  setActive: PropTypes.func,
};

export default KpFinTable;
