import PropTypes from "prop-types";

import MoneyInput from "./MoneyInput";

import "./utils/Inputs.scss";

const PriceInput = ({
  label,
  procent,
  width = "8em",
  labelStyles,
  required = false,
  // className = "", question = null,
  ...props
}) => {
  const roundedValue = procent ? parseFloat(procent).toFixed(2) : "";
  // const [field, meta] = useField(props)
  return (
    <div className="price_input aibl">
      <MoneyInput width={width} label={label} required={required} {...props} labelStyles={labelStyles} />
      <div
        style={{
          display: "flex",
          // alignSelf: "center"
        }}
      >
        <input
          // value={procent || ""}
          // decimalsLimit={2}
          value={roundedValue}
          autoComplete="off"
          readOnly
          style={{
            width: "5em",
            paddingRight: "0.5em",
            // ...(props.readOnly ? {cursor: "default"} : {background: "white"}),
          }}
        />
        <span>%</span>
      </div>
    </div>
  );
};
PriceInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  props: PropTypes.array,
  width: PropTypes.string,
  procent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PriceInput;
