export const CASHDESK_UPPER_PAYMENT_COLUMNS = Object.freeze([
  {
    header: "№ пп",
    accessorKey: "number",
    size: 40,
    maxSize: 40,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 80,
    maxSize: 80,
  },
  {
    header: "Sum",
    accessorKey: "sum",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Dt",
    accessorKey: "debitBalance",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Type",
    accessorKey: "debitBalanceType",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Ct",
    accessorKey: "creditBalance",
    size: 70,
    maxSize: 70,
  },
  {
    header: "Type",
    accessorKey: "creditBalanceType",
    size: 60,
    maxSize: 60,
  },

  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 217,
  },

  {
    header: "Dt. bal. name",
    accessorKey: "debitBalanceName",
    size: 200,
    maxSize: 200,
  },
  {
    header: "Ct. bal. name",
    accessorKey: "creditBalanceName",
    size: 200,
    maxSize: 200,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 80,
    maxSize: 80,
  },
]);

export const CASHDESK_LOWER_PAYMENT_COLUMNS = Object.freeze([
  {
    header: "№ пп",
    accessorKey: "number",
    size: 40,
    maxSize: 40,
  },
  {
    header: "Cash transactions (CT) №",
    accessorKey: "cashTransactionNumber",
    size: 60,
    maxSize: 60,
    isSortable: true,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 80,
    maxSize: 80,
  },
  {
    header: "Sum",
    accessorKey: "sum",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Dt",
    accessorKey: "debitBalance",
    size: 250,
    maxSize: 250,
  },
  {
    header: "Type",
    accessorKey: "debitBalanceType",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Ct",
    accessorKey: "creditBalance",
    size: 250,
    maxSize: 250,
  },
  {
    header: "Type",
    accessorKey: "creditBalanceType",
    size: 60,
    maxSize: 60,
  },

  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 217,
  },

  {
    header: "Dt. bal. name",
    accessorKey: "debitBalanceName",
    size: 200,
    maxSize: 200,
  },
  {
    header: "Ct. bal. name",
    accessorKey: "creditBalanceName",
    size: 200,
    maxSize: 200,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 80,
    maxSize: 80,
  },
]);
