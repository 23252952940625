import React from "react";
import {t} from "i18next";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {CLIENT_ORDERS_TABLE_COLUMNS} from "./constants";

const ClientOrderModal = () => {
  const loading = false;
  const clientOrdersTableColumns = useColumns(CLIENT_ORDERS_TABLE_COLUMNS);
  const clientOrdersTableData = [];

  const onOpenBtnClick = () => {};
  const onCreateNewOrderBtnClick = () => {};
  const onCancelBtnClick = () => {};

  return (
    <div className="fcCol jcsb fGrow padding gap2x">
      <div className="bold tac" style={{fontSize: "20px", width: "40%", lineHeight: "25px"}}>
        {t("orderHasAlreadyBeenCreated")}
      </div>
      <ReactTable
        columns={clientOrdersTableColumns}
        defaultData={clientOrdersTableData}
        //   current={currentDirectiveId}
        //   onSelect={onProjectDirectiveTableSelect}
        //   onRowDoubleClick={onDoubleClick}
        className="tableTac fGrow scrollX"
        tableClassName="fGrow"
        loading={loading}
      />
      <div className="row w100 jcsa">
        <FunctionButton name="Yes, open!" autoWidth onClick={onOpenBtnClick} />
        <FunctionButton name="Create new " autoWidth onClick={onCreateNewOrderBtnClick} />
        <FunctionButton name="Cancel" autoWidth onClick={onCancelBtnClick} />
      </div>
    </div>
  );
};

export default ClientOrderModal;
