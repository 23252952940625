import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import PropTypes from "prop-types";

import {Select} from "../../Inputs/SelectField";

const PalletSelect = ({pallets, ...props}) => {
  const {t} = useTranslation();

  const {values} = useFormikContext();
  const {palletId} = values;

  const [palletL, setPAlletL] = useState("");
  const [palletW, setPAlletW] = useState("");

  useEffect(() => {
    if (palletId) {
      var currentPallet = pallets.find(item => {
        return item.value === palletId;
      });
      if (currentPallet) {
        setPAlletL(currentPallet.length);
        setPAlletW(currentPallet.width);
      } else {
        setPAlletL("");
        setPAlletW("");
      }
    }
  }, [palletId]);

  return (
    <div className="fc nowrap autolabel jcfs">
      <Select
        // readOnly={props.readOnly}
        label={t("Pallet")}
        name="palletId"
        width="7em"
        options={pallets}
        multi={true}
        minFilterLength={1}
        {...props}
      />
      <label>{t("Length")}</label>
      <input name="" value={palletL} readOnly={true} style={{width: "5em"}} />
      <label>{t("Width")}</label>
      <input name="" value={palletW} readOnly={true} style={{width: "5em"}} />
    </div>
  );
};

PalletSelect.propTypes = {pallets: PropTypes.array, props: PropTypes.array};

export default PalletSelect;
