import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";

import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {toaster} from "../../../../../utilities/hooks/toaster";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import {clearStatementDirective, compleateOrder, setFinOrder} from "../../../FinSettings/FinSettingsSlice";

const ClientOverdraftMod = ({amount = "0.00", currency = "EUR", setModalActive, orderId, note, closeCurrentTab}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onProvideBtnClick = () => {
    dispatch(
      compleateOrder({
        orderId,
        note,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        toaster.success(t("overdraftOrderCompleted"));
        dispatch(clearStatementDirective());
        dispatch(setFinOrder(null));
        setModalActive(false);
        if (closeCurrentTab) closeCurrentTab();
      }
    });
  };

  // const onCancelBtnClick = () => {
  //   swalCustom.confirm({
  //     confirmFunc: () => {
  //       dispatch(deleteOrder(orderId)).then(res => {
  //         if (res.meta.requestStatus === "fulfilled") {
  //           dispatch(clearStatementDirective());
  //           dispatch(setFinOrder(null));
  //           setModalActive(false);
  //         }
  //       });
  //     },
  //   });
  // };

  const onExitBtnClick = () => {
    setModalActive(false);
  };

  return (
    <div className="fcCol jcsb fGrow padding gap2x">
      <div
        className="fcCol tac gap0_5"
        style={{fontSize: "20px", width: "70%", lineHeight: "25px", whiteSpace: "pre-wrap"}}
      >
        {t("toCloseOrderAmount")}
        <div className="row gap italic">
          <span>{currency}</span>
          <span>{numberFormat(amount)}</span>
        </div>
        {t("forAutoOverdraft")}
      </div>

      <div className="row w50 jcsa">
        <FunctionButton name="Yes, provide!" autoWidth onClick={onProvideBtnClick} disabled={!orderId} />
        <FunctionButton name="Exit" autoWidth onClick={onExitBtnClick} />
      </div>
    </div>
  );
};

ClientOverdraftMod.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
};

export default ClientOverdraftMod;
