import Check from "../../../images/icons/check.png";
import GrayCheckBox from "../../../images/icons/grayCheckbox.png";
import calcStatusColor from "../../../utilities/calcStatusColor";

export const PROJECT_TASKS_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 10,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 80,
    maxSize: 80,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Appeal type",
    accessorKey: "appealType",
    size: 85,
    maxSize: 85,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Briefly about",
    accessorKey: "about",
    size: 250,
    maxSize: 250,
    isSortable: true,
    alignStart: true,
    cell: value => (
      <span className={`${value.row.original.isDone ? "strikethroughRow" : ""} `}>{value.getValue()}</span>
    ),
  },
  {
    header: "Block",
    accessorKey: "block",
    size: 70,
    maxSize: 70,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Manager ",
    accessorKey: "manager",
    size: 100,
    maxSize: 100,
    alignStart: true,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 70,
    maxSize: 70,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "IT Manager",
    accessorKey: "managerIT",
    size: 100,
    maxSize: 100,
    alignStart: true,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Start date",
    accessorKey: "startDate",
    size: 80,
    maxSize: 80,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Plan Date",
    accessorKey: "planDate",
    size: 80,
    maxSize: 80,
    isSortable: true,
    cell: value => (
      <span
        className={value.row.original.isDone ? "strikethroughRow" : ""}
        style={{
          ...(!value.row.original.isDone
            ? {
                color: calcStatusColor({
                  startDate: value.row.original.startDate,
                  finalDate: value.row.original.planDate,
                }),
              }
            : {}),
        }}
      >
        {value.getValue()}
      </span>
    ),
  },
  {
    header: "Дата Фініш",
    accessorKey: "finishDate",
    size: 80,
    maxSize: 80,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Note IT",
    accessorKey: "noteIT",
    size: 200,
    maxSize: 200,
    alignStart: true,
    isSortable: true,
    // cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
]);

export const MANAGER_IT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 10,
  },
  {
    header: "IT Manager",
    accessorKey: "managerIT",
    size: 180,
    maxSize: 180,
    isSortable: true,
  },
  {
    header: "Plan Date",
    accessorKey: "planDate",
    size: 50,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Finish Date",
    accessorKey: "finishDate",
    size: 50,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img src={GrayCheckBox} width="15px" height="15px" />
      </div>
    ),
    accessorKey: "isDone",
    size: 30,
    cell: cell => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        {cell.getValue() ? <img src={Check} width="15px" height="15px" alt="Done" /> : ""}
      </div>
    ),
  },
]);
