import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../utilities/dateFormat";
import QuestionItem from "../../../utilities/QuestionItem";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModalForm from "../../Modal/FormModal";
import NewPdfViewer from "../../Project/Documents/DocumentView/NewPdfViewer";
import {fetchSkuCatalogs, getSkuInfo} from "../../SKU/SkuSlice";
import FactoryRqstsModal from "../OrderProcessing/FabricRequestsModal/FactoryRqstsModal";
import {REQUEST_CREATION_COLUMNS} from "../OrderProcessing/TableBlock/utils/constants";
import useColumns from "../OrderProcessing/utils/hooks/useColumns";
import {deselectCurrentRequest, getRequest, getRequestInfo, getRequestSkuInfo} from "../RequestsSlice";

import RequestPlanSkuTable from "./RequestPlanSkuTable";
import ZedUpdateForm from "./ZedUpdateForm";

import "./requestCreation.scss";
import styles from "../OrderProcessing/TableBlock/TableBlock.module.scss";

const RequestCreation = ({onSubmit}) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [factoryFormModalActive, setFactoryFormModalActive] = useState(false);

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
  }, []);

  const location = useLocation();
  const {currentRequestId} = location.state || {currentRequestId: ""};

  useEffect(() => {
    if (currentRequestId) {
      dispatch(getRequest(currentRequestId));
    }
    return () => {
      if (currentRequestId) {
        dispatch(deselectCurrentRequest());
      }
    };
  }, [currentRequestId]);

  const currentRequest = useSelector(getRequestInfo);
  // getRequestSkuInfo;
  const currentRequestSku = useSelector(getRequestSkuInfo);

  // const kp = currentRequest?.skuByRequest.map(item => item.kpKey)[0];

  const columns = useColumns(REQUEST_CREATION_COLUMNS);

  const requestInfoTableData = useMemo(() => {
    if (currentRequest) {
      const {requestId, managerCo, brand, requestSum, createdAt, note, status, requestNumber, requestDates} =
        currentRequest || {};
      return [
        {
          id: requestId,
          request: requestNumber,
          managerCo: managerCo?.name,
          factory: brand?.name,
          toFactoryDate: dateFormat(requestDates[2].plannedDate),
          requestSum,
          createdAt: dateFormat(createdAt),
          note,
          status: i18n === "eng" ? status.engName : status.ukrName,
        },
      ];
    }
    return [];
  }, [currentRequest]);

  const [showUpdateSkuModal, setShowUpdateSkuModal] = useState(false);

  const onEditRequestSku = currentRequestSku => {
    dispatch(getSkuInfo(currentRequestSku.skuId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setShowUpdateSkuModal(true);
      }
    });
  };

  const onOpenFactoryRqstFormBtnClick = () => {
    setFactoryFormModalActive(true);
  };

  return (
    <PageContainer style={{height: "100%"}} title={t("Request creation")}>
      <div className="form_wrapper " style={{padding: "0.5em 0 2.5em"}}>
        <div className="table_xscroll__container">
          <ReactTable
            defaultData={requestInfoTableData}
            columns={columns}
            current={null}
            className={styles.status4TableWrapper}
            onSelect={() => {}}
          />
        </div>
        <div className="request_header">
          <QuestionItem title="edit sku" />
          {t("Request factory form")}
        </div>
        <div style={{overflowY: "scroll"}}>
          <RequestPlanSkuTable onSubmit={onOpenFactoryRqstFormBtnClick} submitBtnLabel={"Create factory request"} />
        </div>
        <div className="button-wrapper req_creation_btn">
          <FunctionButton
            name={t("Edit SKU in request")}
            autoWidth
            onClick={() => {
              onEditRequestSku(currentRequestSku);
            }}
            disabled={!currentRequestSku}
          />
          {/* <div>
            <FunctionButton
              name={t("Close and return")}
              autoWidth
              onClick={() => {
                navigate("/ba3a/ved/reqInProgress");
              }}
            />
          </div> */}
        </div>
      </div>

      <ModalForm
        active={showUpdateSkuModal}
        Component={ZedUpdateForm}
        setModalActive={setShowUpdateSkuModal}
        label={`${t("Edit SKU")} ${t("In request")} ${currentRequest?.requestNumber}`}
      />

      <ModalForm
        closable={true}
        closableOnEsc={false}
        Component={FactoryRqsts}
        label={t("Create Factory Order")}
        active={factoryFormModalActive}
        setModalActive={setFactoryFormModalActive}
        className={styles.modal}
        modalInnerWrapperClassName={styles.modalInnerWrapper}
        // TableView={Child}
      />
    </PageContainer>
  );
};

const FactoryRqsts = ({setModalActive}) => {
  const currentRequest = useSelector(getRequestInfo);
  const onCancelBtnClick = () => {
    setModalActive(false);
  };

  return (
    <FactoryRqstsModal>
      <div style={{height: "90%", maxHeight: "55vh", marginBottom: "3em"}}>
        <NewPdfViewer hiding={false} fileArray={currentRequest?.pdfFileUrl ? [currentRequest?.pdfFileUrl] : []} />
      </div>
      <div className="fc jcc" style={{position: "relative", paddingBottom: "5px"}}>
        <FunctionButton
          name={"Create Request and PDF to Factory"}
          type="submit"
          tooltipMessage="pac of docs"
          autoWidth
        />
        <FunctionButton
          name="Cancel"
          tooltipMessage="canc req"
          onClick={onCancelBtnClick}
          style={{position: "absolute", bottom: "5px", right: "0"}}
          autoWidth
        />
      </div>
    </FactoryRqstsModal>
  );
};

export default RequestCreation;
