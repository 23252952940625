import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";

export const REQUEST_RAndV_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Request №",
    accessorKey: "reqNumber",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "managerCO",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 100,
  },
  {
    header: "Request amount",
    accessorKey: "reqAmount",
    // size: 100,
  },
  {
    header: "Request date",
    accessorKey: "reqDate",
    // size: 100,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    // size: 100,
  },
]);

export const PROF_RAndV_COLUMNS = Object.freeze([
  {
    header: "Pf №",
    accessorKey: "pfNumber",
    size: 15,
  },
  {
    header: "stat control",
    accessorKey: "statControl",
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
    // size: 100,
  },
]);

export const REQUEST_RAndV_IMG_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Brand",
    accessorKey: "brandName",
    // size: 100,
  },
  {
    header: "Nomenclature",
    accessorKey: "skuName",
    // size: 100,
  },
  {
    header: "Characteristics",
    accessorKey: "сharacteristics",
    // size: 100,
  },
  {
    header: "VWC",
    accessorKey: "ovh",
    // size: 100,
  },
  {
    header: "Image",
    accessorKey: "image1",
    // size: 100,
  },
  {
    header: "Image",
    accessorKey: "image2",
    // size: 100,
  },
  {
    header: "Set description",
    accessorKey: "complectDesc",
    // size: 100,
  },
  {
    header: "Quantity",
    accessorKey: "amount",
    // size: 100,
  },
  {
    header: "Price pcs",
    accessorKey: "price",
    // size: 100,
  },
  {
    header: "Sum €",
    accessorKey: "summ",
    // size: 100,
  },
  {
    header: "Discount %",
    accessorKey: "discount",
    // size: 100,
  },
  {
    header: "Total €",
    accessorKey: "totalSumm",
    // size: 100,
  },
  {
    header: "Total discount %",
    accessorKey: "topDiscount",
    // size: 100,
  },
  {
    header: "Payment €",
    accessorKey: "paymentSumm",
    // size: 100,
  },
]);

export const VERIF_SELECT_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Brand",
    accessorKey: "brand",
    // size: 100,
  },
  {
    header: "Nomenclature",
    accessorKey: "nomenclature",
    // size: 100,
  },
  {
    header: "Characteristics",
    accessorKey: "сharacteristics",
    // size: 100,
  },
  {
    header: "VWC",
    accessorKey: "vwc",
    // size: 100,
  },
  {
    header: "Image",
    accessorKey: "image1",
    // size: 100,
  },
  {
    header: "Image",
    accessorKey: "image2",
    // size: 100,
  },
  {
    header: "Set description",
    accessorKey: "complectDesc",
    // size: 100,
  },
  {
    header: "Quantity",
    accessorKey: "amount",
    // size: 100,
  },
  {
    header: "Price pcs",
    accessorKey: "price",
    // size: 100,
  },
  {
    header: "Sum €",
    accessorKey: "sum",
    // size: 100,
  },
  {
    header: "Discount %",
    accessorKey: "discount",
    // size: 100,
  },
  {
    header: "Total €",
    accessorKey: "totalSumm",
    // size: 100,
  },
  {
    header: "Total discount %",
    accessorKey: "topDiscount",
    // size: 100,
  },
  {
    header: "Payment €",
    accessorKey: "paymentSumm",
    // size: 100,
  },
]);
