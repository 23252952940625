import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {Select, TextAreaInput, TextInput} from "../../../../../Inputs";
import {fetchRepaymentTerms, selectRepaymentTerms} from "../../../FinSettingsSlice";

import CounterPartyLine from "./CounterPartyLine";

const CreditParams = ({
  onPauseCreditBtnClick,
  onSaveCreditBtnClick,
  // onDeleteCreditBtnClick,
  onRestoreCreditBtnClick,
}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();

  const {values} = useFormikContext();
  const {creditType, currentParamId, statusId, isFormDirty} = values;
  const savebtnDisabled =
    (values?.currentParamId && !isFormDirty) ||
    !(
      values?.creditName &&
      values?.counterpartyClassIdCredit &&
      values?.counterpartyTypeIdCredit &&
      values?.officeIdCredit
    );

  useEffect(() => {
    dispatch(fetchRepaymentTerms());
  }, []);

  const repaymentTerms = useSelector(selectRepaymentTerms);
  const repaymentTermsOptions =
    repaymentTerms?.map(({repaymentTermsId, nameEng, nameUkr}) => ({
      title: language === "en" ? nameEng : nameUkr,
      value: repaymentTermsId,
    })) ?? [];

  return (
    <div className={"fcCol gap aist " + (!(creditType === 2 || !creditType) && "disabled-div")}>
      <div className="header asfs">{`2. ${t("Credit params")}`}</div>
      <div className="italic">{t("Lending parameters are set")}</div>
      <CounterPartyLine namePart="Credit" />
      <TextInput label="Credit" name="creditName" autolabel width="15rem" required />
      <div className="row">
        <TextInput label="%%per annum per year" name="annualInterestRate" autolabel width="5rem" />%
      </div>
      <Select name="repaymentTermsId" label="repayment %%" options={repaymentTermsOptions} autolabel width="19rem" />
      <Select
        name="repaymentTermsSumId"
        label="repayment sum"
        options={repaymentTermsOptions}
        autolabel
        width="19rem"
      />
      <TextAreaInput name="repaymentComment" placeholder="note" />

      <div className="row w100">
        <div className="row gap">
          <FunctionButton
            name="Pause"
            autoWidth
            disabled={!(currentParamId && creditType === 2 && statusId === 1)}
            onClick={onPauseCreditBtnClick}
          />
          <FunctionButton
            name="Restore"
            autoWidth
            disabled={!(currentParamId && creditType === 2 && statusId === 2)}
            onClick={onRestoreCreditBtnClick}
          />
        </div>

        <FunctionButton name="Save" autoWidth disabled={savebtnDisabled} onClick={onSaveCreditBtnClick} />
      </div>
    </div>
  );
};

export default CreditParams;
