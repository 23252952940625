export const BANK_CLIENT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Counterparty",
    accessorKey: "counterparty",
    size: "auto",
    alignStart: true,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 100,
  },
  {
    header: "Operation type",
    accessorKey: "operationType",
    // size: 100,
  },
  {
    header: "Costs (Dt)",
    accessorKey: "costsDt",
    // size: 100,
  },
  {
    header: "Revenue (Ct)",
    accessorKey: "revenueCt",
    // size: 100,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    // size: 100,
  },
  {
    header: "Payers Acc. №",
    accessorKey: "payerAccountNumber",
    size: 270,
  },
  {
    header: "EDRPOU / TIN (P.)",
    accessorKey: "requisitesP",
    // size: 100,
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
    size: "auto",
    alignStart: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 500,
    alignStart: true,
  },
  {
    header: "Recipient Acc. №",
    accessorKey: "recipientAccountNumber",
    size: 270,
  },
  {
    header: "EDRPOU / TIN (R.)",
    accessorKey: "requisitesR",
    // size: 100,
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
    size: "auto",
    alignStart: true,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    // size: 100,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    // size: 100,
  },
  {
    header: "Remainder",
    accessorKey: "remainder",
    // size: 100,
  },
]);

export const BANK_STATEMENTS_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Bank (P.)",
    accessorKey: "bankP",
    alignStart: true,
    size: "auto",
  },
  {
    header: "MFI (P.)",
    accessorKey: "mfiP",
    // isSortable: true,
    // size: 100,
  },
  {
    header: "doc. №",
    accessorKey: "docNumber",
    size: "auto",
  },
  {
    header: "doc.Date",
    accessorKey: "docDate",
    // size: 200,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Time",
    accessorKey: "time",
  },
  {
    header: "Costs (Dt)",
    accessorKey: "costsDt",
    // size: 100,
  },
  {
    header: "Revenue (Ct)",
    accessorKey: "revenueCt",
    // size: 100,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    // size: 100,
  },
  {
    header: "Payers Acc. №",
    accessorKey: "payerAccountNumber",
    size: 270,
  },
  {
    header: "EDRPOU / TIN (P.)",
    accessorKey: "requisitesP",
    // size: 100,
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
    size: "auto",
    alignStart: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 500,
    alignStart: true,
  },
  {
    header: "Recipient Acc. №",
    accessorKey: "recipientAccountNumber",
    size: 270,
  },
  {
    header: "EDRPOU / TIN (R.)",
    accessorKey: "requisitesR",
    // size: 100,
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
    size: "auto",
    alignStart: true,
  },
  {
    header: "Recipient Bank",
    accessorKey: "recipientBank",
    size: "auto",
    alignStart: true,
  },
  {
    header: "MFI (R.)",
    accessorKey: "mfiR",
    // isSortable: true,
    // size: 100,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    // size: 100,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    // size: 100,
  },
  {
    header: "Commissions amount",
    accessorKey: "commissionsAmmount",
    // size: 100,
  },

  {
    header: "Remainder",
    accessorKey: "remainder",
    // size: 100,
  },
  {
    header: "Reference",
    accessorKey: "reference",
    // size: 100,
  },
  {
    header: "Operation code",
    accessorKey: "operationCode",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 100,
  },
]);

export const BANK_OPERATIONS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Counterparty",
    accessorKey: "counterparty",
    // size: 100,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 100,
  },
  {
    header: "Operation type",
    accessorKey: "operationType",
    // size: 100,
  },
  {
    header: "Costs (Dt)",
    accessorKey: "costsDt",
    // size: 100,
  },
  {
    header: "Revenue (Ct)",
    accessorKey: "revenueCt",
    // size: 100,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    // size: 100,
  },
  {
    header: "Payers Acc. №",
    accessorKey: "payerAccountNumber",
    // size: 100,
  },
  {
    header: "EDRPOU / TIN (P.)",
    accessorKey: "requisitesP",
    // size: 100,
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
    // size: 100,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    // size: 100,
  },
  {
    header: "Recipient Acc. №",
    accessorKey: "recipientAccountNumber",
    // size: 100,
  },
  {
    header: "EDRPOU / TIN (R.)",
    accessorKey: "requisitesR",
    // size: 100,
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
    // size: 100,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    // size: 100,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    // size: 100,
  },
  {
    header: "Remainder",
    accessorKey: "remainder",
    // size: 100,
  },
  {
    header: "tr.id",
    accessorKey: "trId",
    // size: 200,
  },
]);
