import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";

import {STATUS_COLORS} from "../../../utilities/colorConstants";
import QuestionItem from "../../../utilities/QuestionItem";
import {findRequestDateType, REQUEST_DATES_IDS} from "../../../utilities/requestDatesIdsService";
import ModalForm from "../../Modal/FormModal";
import {fetchMixedRequests, getRequest, getRequestInfo} from "../RequestsSlice";

import ModalPaymentDocuments from "./modalsInProgress/modalPaymentDocParts/ModalPaymentDocuments";
import PfOperationsModal from "./modalsInProgress/PfOperationsModal/PfOperationsModal";
import ReceiptGoodsModal from "./ReceiptGoodsModal/ReceiptGoodsModal";
import FactoryEditRqsts from "./toFactory/ToFactoryModal";
import {PROGRESS_CARD_TYPES, PROGRESS_CARDS} from "./utils/constants";
import {REQ_WINDOW_TYPES} from "./utils/functions/requestsFiltersManager";
import InProgressCard from "./InProgressCard";

const InProgressSector = ({showButton}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentType, setCurrentType] = useState(null);
  const [currentLabel, setCurrentLabel] = useState("");
  const [modalActive, setModalActive] = useState(false);

  const currentRequest = useSelector(getRequestInfo);
  const reqDates = currentRequest?.requestDates ?? [];

  const toFactoryDisabled = currentRequest?.statusId < 4;

  useEffect(() => {
    if (modalActive === false && currentType) {
      dispatch(getRequest(currentRequest.requestId));
    }
  }, [modalActive]);

  const onCardClick = (type, label) => {
    if (type === PROGRESS_CARD_TYPES.FACTORY) {
      navigate("/ba3a/fea/reqCreate", {state: {currentRequestId: currentRequest.requestId}});
      return;
    }
    setCurrentType(type);
    setCurrentLabel(label);
    setModalActive(true);
  };

  const currentComponent = useMemo(() => {
    switch (currentType) {
      case PROGRESS_CARD_TYPES.PF:
        return PfOperationsModal;

      case PROGRESS_CARD_TYPES.PAYMENT:
        return ModalPaymentDocuments;

      case PROGRESS_CARD_TYPES.RECEIPT:
        return ReceiptGoodsModal;

      case PROGRESS_CARD_TYPES.FACTORY:
        return FactoryEditRqsts;

      default:
        return null;
    }
  }, [currentType]);

  //initial values
  const initialValues = useMemo(() => {
    const toFactory = findRequestDateType(reqDates, REQUEST_DATES_IDS.SENDING_TO_FACTORY_ID);
    const gettingProforma = findRequestDateType(reqDates, REQUEST_DATES_IDS.GETTING_PROF_ID);
    const profControl = findRequestDateType(reqDates, REQUEST_DATES_IDS.PROF_CONTROL_ID);
    const forPayment = findRequestDateType(reqDates, REQUEST_DATES_IDS.FOR_PAYMENT_ID);
    const onPayment = findRequestDateType(reqDates, REQUEST_DATES_IDS.PAYMENT_ID);
    const readiness = findRequestDateType(reqDates, REQUEST_DATES_IDS.READINESS_ID);
    const readinessControl = findRequestDateType(reqDates, REQUEST_DATES_IDS.READINESS_CONTROL_ID);
    const logistics = findRequestDateType(reqDates, REQUEST_DATES_IDS.TRANSFER_TO_LOGISTICS_ID);

    return {
      sendToFactoryCreatedAt: toFactory?.createdAt || "",
      sendToFactoryDateS: toFactory?.plannedDate || "",
      sendToFactoryDateE: toFactory?.actualDate || "",

      gettingProformaDateCreatedAt: gettingProforma?.createdAt || "",
      gettingProformaDateS: gettingProforma?.plannedDate || "",
      gettingProformaDateE: gettingProforma?.actualDate || "",

      proformaControlDateCreatedAt: profControl?.createdAt || "",
      proformaControlDateS: profControl?.plannedDate || "",
      proformaControlDateE: profControl?.actualDate || "",

      forPaymentDateCreatedAt: forPayment?.createdAt || "",
      forPaymentDateS: forPayment?.plannedDate || "",
      forPaymentDateE: forPayment?.actualDate || "",

      onPaymentDateCreatedAt: onPayment?.createdAt || "",
      onPaymentDateS: onPayment?.plannedDate || "",
      onPaymentDateE: onPayment?.actualDate || "",

      forPaymentDate: "",
      onPaymentDate: "",

      readinessCreatedAt: readiness?.createdAt || "",
      plannedReadinessDate: readiness?.plannedDate || "",
      actualReadinessDate: readiness?.actualDate || "",

      readinessControlDate: readinessControl?.plannedDate || "",

      logistSendDate: logistics?.plannedDate || "",
      logistReceiveDate: "",
    };
  }, [currentRequest]);

  return (
    <div className="main">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        onSubmit={() => {}}
      >
        <div style={{width: "100%", paddingTop: "1em"}}>
          <div style={{width: "1em"}}>
            <QuestionItem title={"date tip"} />
          </div>
          <div className="list-card">
            {PROGRESS_CARDS.map(
              ({
                statusButton,
                header = "",
                dateStartLabel = "plan",
                dateEndLabel = "fact",
                dateStartName = "",
                dateEndName = "",
                buttonName,
                frame = STATUS_COLORS.TRANSPARENT,
                type,
                label = "",
                descText,
                disabledDate,
                forcedDisable,
                createdAtDate,
              }) => (
                <InProgressCard
                  key={buttonName}
                  statusButton={statusButton}
                  header={t(header)}
                  dateStartLabel={t(dateStartLabel)}
                  dateEndLabel={t(dateEndLabel)}
                  dateStartName={dateStartName}
                  dateEndName={dateEndName}
                  buttonName={t(buttonName)}
                  frame={frame}
                  onClick={() => {
                    onCardClick(type, t(label));
                  }}
                  descText={descText}
                  type={type}
                  disabledDate={disabledDate}
                  createdAtDate={createdAtDate}
                  forcedDisable={forcedDisable ? toFactoryDisabled : false}
                  showButton={showButton}
                />
              ),
            )}
          </div>
        </div>
      </Formik>
      <ModalForm
        closable={true}
        Component={currentComponent}
        label={currentLabel + " " + currentRequest?.requestNumber}
        active={modalActive}
        setModalActive={setModalActive}
        className="inProgressModal"
        onModalClose={() => dispatch(fetchMixedRequests({window: REQ_WINDOW_TYPES.INPROGRESS}))}
      />
    </div>
  );
};

export default InProgressSector;
