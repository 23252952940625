import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import useContextMenu from "../../../../../hook/useContextMenu";
import Divider from "../../../../../utilities/Divider/Divider";
import {SessionFiltersContainer} from "../../../../conteiners/filters/Filter.hoc";
import {fetchAllBankTransactions, fetchBankTransaction} from "../../../FinBlockSlice";
import {clearStatementDirective} from "../../../FinSettings/FinSettingsSlice";
import AccountAssociationAndTransaction from "../../modals/AccountAssociationAndTransaction/AccountAssociationAndTransaction";
import ResiduesBtnRow from "../../parts/ResiduesBtnRow";
import ResiduesContextMenu from "../../ResiduesContextMenu";
import useBankClient from "../../useBankClient";

import BankOperationFilters from "./BankOperationFilters";
import BankOperationTable from "./BankOperationTable";

const ResiduesTab = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onFilterChange = filters => {
    dispatch(fetchAllBankTransactions({isResidues: true, ...filters}));
  };

  const {menuVisible, menuPosition, handleContextMenu} = useContextMenu();

  const {
    currentBankTransactionId,
    setCurrentBankTransactionId,
    onToTheClientBalanceBtnClick,
    onConversionBtnClick,
    onCheckTransactionsBtnClick,
    onConductBtnClick,
    onCreateDebtBtnClick,
    isOperationBetweenOwnAccounts,
    onViewTransactionsBtnClick,
    isTransactionWithNoClient,
    openModal,
    isAlignedResidue,
  } = useBankClient();

  const onAssociateResidueTransactionWithClientBtnClick = ({savedFilters}) => {
    dispatch(fetchBankTransaction({id: currentBankTransactionId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        const hasDirective = resp.payload?.directive?.directive;
        openModal(<AccountAssociationAndTransaction />, {
          label: t("Account association"),
          closable: true,
          hasAssociation: false,
          style: {height: hasDirective ? "330px" : "280px", minHeight: hasDirective ? "330px" : "280px"},
          onModalClose: () => {
            dispatch(clearStatementDirective());
          },
          isResidue: true,
          savedFilters,
        });
      }
    });
  };

  return (
    <SessionFiltersContainer window="BankOperationList" onFilterChange={onFilterChange}>
      <div className="form_wrapper" style={{paddingBottom: "10px"}} id="context-menu-container">
        <BankOperationFilters />
        <Divider />
        <BankOperationTable
          handleContextMenu={handleContextMenu}
          currentBankTransactionId={currentBankTransactionId}
          setCurrentBankTransactionId={setCurrentBankTransactionId}
        />
        <ResiduesBtnRow
          currentBankTransactionId={currentBankTransactionId}
          onToTheClientBalanceBtnClick={onToTheClientBalanceBtnClick}
          onConversionBtnClick={onConversionBtnClick}
          onCheckTransactionsBtnClick={onCheckTransactionsBtnClick}
          onConductBtnClick={onConductBtnClick}
          onCreateDebtBtnClick={onCreateDebtBtnClick}
          isOperationBetweenOwnAccounts={isOperationBetweenOwnAccounts}
          isTransactionWithNoClient={isTransactionWithNoClient}
          onAssociateResidueTransactionWithClientBtnClick={onAssociateResidueTransactionWithClientBtnClick}
          isAlignedResidue={isAlignedResidue}
        />
        <ResiduesContextMenu
          onToTheClientBalanceBtnClick={onToTheClientBalanceBtnClick}
          onConversionBtnClick={onConversionBtnClick}
          onCheckTransactionsBtnClick={onCheckTransactionsBtnClick}
          onConductBtnClick={onConductBtnClick}
          onCreateDebtBtnClick={onCreateDebtBtnClick}
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          isOperationBetweenOwnAccounts={isOperationBetweenOwnAccounts}
          currentBankTransactionId={currentBankTransactionId}
          onViewTransactionsBtnClick={onViewTransactionsBtnClick}
          isTransactionWithNoClient={isTransactionWithNoClient}
          onAssociateResidueTransactionWithClientBtnClick={onAssociateResidueTransactionWithClientBtnClick}
          isAlignedResidue={isAlignedResidue}
        />
      </div>
    </SessionFiltersContainer>
  );
};

export default ResiduesTab;
