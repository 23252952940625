export const BANK_CLIENT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    enableResizing: false,
    size: 50,
    isSortable: true,
    sortingFn: "sortNumber",
  },
  {
    header: "Acc. №",
    accessorKey: "accountNumber",
    alignStart: true,
    size: 250,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 90,
    enableResizing: false,
    isSortable: true,
    sortingFn: "sortDate",
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 110,
    enableResizing: false,
    isSortable: true,
  },
  // {
  //   header: "Operation type",
  //   accessorKey: "operationType",
  //   size: 50,
  //   enableResizing: false,
  // },
  {
    header: "Sum (Dt)",
    accessorKey: "costsDt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Sum (Ct)",
    accessorKey: "revenueCt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 50,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 500,
    alignStart: true,
    maxSize: 1200,
  },
  {
    header: "Counterparty Acc. №",
    accessorKey: "counterpartyAccountNumber",
    alignStart: true,
    size: 250,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "EDRPOU / TIN (C.)",
    accessorKey: "requisitesC",
    size: 100,
    enableResizing: false,
    isSortable: true,
    sortingFn: "sortNumber",
  },
  {
    header: "Counterparty Name",
    accessorKey: "counterpartyName",
    size: 240,
    alignStart: true,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    size: 60,
    enableResizing: false,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    size: 100,
    enableResizing: false,
  },
  // {
  //   header: "Commissions amount",
  //   accessorKey: "commissionsAmmount",
  //   size: 100,
  //   enableResizing: false,
  // },
  {
    header: "Remainder",
    accessorKey: "remainder",
    size: 100,
    enableResizing: false,
  },
  {
    header: "tr.id",
    accessorKey: "trId",
    size: 80,
    enableResizing: true,
    alignStart: true,
  },
]);

export const ASSOCIATED_TRANSACIONS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    enableResizing: false,
    size: 50,
    isSortable: true,
    sortingFn: "sortNumber",
  },
  {
    header: "Acc. №",
    accessorKey: "accountNumber",
    alignStart: true,
    size: 270,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 90,
    enableResizing: false,
    isSortable: true,
    sortingFn: "sortDate",
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 110,
    enableResizing: false,
    isSortable: true,
  },
  // {
  //   header: "Operation type",
  //   accessorKey: "operationType",
  //   size: 50,
  //   enableResizing: false,
  // },
  {
    header: "Sum (Dt)",
    accessorKey: "costsDt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Sum (Ct)",
    accessorKey: "revenueCt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 50,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 500,
    alignStart: true,
    maxSize: 1200,
  },
  {
    header: "Counterparty Acc. №",
    accessorKey: "counterpartyAccountNumber",
    alignStart: true,
    size: 270,
    enableResizing: false,
  },
  {
    header: "EDRPOU / TIN (C.)",
    accessorKey: "requisitesC",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Counterparty Name",
    accessorKey: "counterpartyName",
    size: 250,
    alignStart: true,
    enableResizing: false,
  },

  // {
  //   header: "Rate",
  //   accessorKey: "rate",
  //   size: 60,
  //   enableResizing: false,
  // },
  // {
  //   header: "Equivalent",
  //   accessorKey: "equivalent",
  //   size: 100,
  //   enableResizing: false,
  // },
  // {
  //   header: "Commissions amount",
  //   accessorKey: "commissionsAmmount",
  //   size: 100,
  //   enableResizing: false,
  // },
  {
    header: "Remainder",
    accessorKey: "remainder",
    size: 100,
    enableResizing: false,
  },
  {
    header: "tr.id",
    accessorKey: "trId",
    size: 80,
    enableResizing: true,
    alignStart: true,
  },
]);

export const BANK_STATEMENTS_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    size: 50,
    isSortable: true,
    sortingFn: "sortNumber",
  },
  // {
  //   header: "Bank",
  //   accessorKey: "bank",
  //   size: 200,
  //   alignStart: true,
  //   enableResizing: false,
  // },
  {
    header: "MFI",
    accessorKey: "mfi",
    size: 80,
    enableResizing: false,
  },
  {
    header: "Acc. №",
    accessorKey: "accountNumber",
    alignStart: true,
    size: 240,
    enableResizing: false,
    isSortable: true,
  },
  // {
  //   header: "Account Holder Name",
  //   accessorKey: "accHolderName",
  //   alignStart: true,
  //   // size: 260,
  //   enableResizing: false,
  // },
  {
    header: "doc. №",
    accessorKey: "docNumber",
    size: 100,
    alignStart: true,
    enableResizing: false,
  },
  // {
  //   header: "doc.Date",
  //   accessorKey: "docDate",
  //   size: 80,
  //   enableResizing: false,
  // },
  {
    header: "Date",
    accessorKey: "date",
    size: 90,
    enableResizing: false,
    isSortable: true,
    sortingFn: "sortDate",
  },
  {
    header: "Time",
    accessorKey: "time",
    size: 75,
    enableResizing: false,
  },
  {
    header: "Sum (Dt)",
    accessorKey: "costsDt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Sum (Ct)",
    accessorKey: "revenueCt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 50,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 500,
    alignStart: true,
    maxSize: 1200,
  },
  {
    header: "Counterparty Acc. №",
    accessorKey: "counterpartyAccountNumber",
    alignStart: true,
    size: 240,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "EDRPOU / TIN (C.)",
    accessorKey: "requisitesC",
    alignStart: true,
    size: 100,
    enableResizing: false,
    isSortable: true,
    sortingFn: "sortNumber",
  },
  {
    header: "Counterparty Name",
    accessorKey: "counterpartyName",
    size: 240,
    alignStart: true,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Bank(C.)",
    accessorKey: "counterpartyBank",
    size: 200,
    alignStart: true,
    enableResizing: false,
  },
  {
    header: "MFI (C.)",
    accessorKey: "mfiC",
    size: 70,
    alignStart: true,
    enableResizing: false,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    size: 50,
    enableResizing: false,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    size: 100,
    enableResizing: false,
  },
  // {
  //   header: "Commissions amount",
  //   accessorKey: "commissionsAmmount",
  //   size: 100,
  //   enableResizing: false,
  // },
  // {
  //   header: "Remainder",
  //   accessorKey: "remainder",
  //   size: 100,
  // },
  // {
  //   header: "Reference",
  //   accessorKey: "reference",
  //   size: 100,
  // },
  {
    header: "Operation code",
    accessorKey: "operationCode",
    size: 50,
  },
  // {
  //   header: "Status",
  //   accessorKey: "status",
  //   size: 100,
  // },
  {
    header: "tr.id",
    accessorKey: "trId",
    size: 50,
    enableResizing: true,
    alignStart: true,
  },
]);

export const BANK_OPERATIONS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    // size: 50,
  },
  {
    header: "Counterparty",
    accessorKey: "counterparty",
    // size: 100,
  },
  {
    header: "Date",
    accessorKey: "date",
    // size: 100,
  },
  {
    header: "Status",
    accessorKey: "status",
    // size: 100,
  },
  {
    header: "Operation type",
    accessorKey: "operationType",
    // size: 100,
  },
  {
    header: "Sum (Dt)",
    accessorKey: "costsDt",
    // size: 100,
  },
  {
    header: "Sum (Ct)",
    accessorKey: "revenueCt",
    // size: 100,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    // size: 100,
  },
  {
    header: "Payers Acc. №",
    accessorKey: "payerAccountNumber",
    // size: 100,
  },
  {
    header: "EDRPOU / TIN (P.)",
    accessorKey: "requisitesP",
    // size: 100,
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
    // size: 100,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    // size: 100,
  },
  {
    header: "Recipient Acc. №",
    accessorKey: "recipientAccountNumber",
    // size: 100,
  },
  {
    header: "EDRPOU / TIN (R.)",
    accessorKey: "requisitesR",
    // size: 100,
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
    // size: 100,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    // size: 100,
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
    // size: 100,
  },
  {
    header: "Remainder",
    accessorKey: "remainder",
    // size: 100,
  },
  {
    header: "tr.id",
    accessorKey: "trId",
    size: 80,
    enableResizing: true,
    alignStart: true,
  },
]);

export const BANK_CLIENT_TABLE_COLUMNS_2699 = Object.freeze([
  {
    header: "№пп",
    accessorKey: "number",
    enableResizing: false,
    size: 50,
    isSortable: true,
    sortingFn: "sortNumber",
  },
  {
    header: "Acc. №",
    accessorKey: "accountNumber",
    alignStart: true,
    size: 270,
    enableResizing: false,
  },
  {
    header: "Remainder",
    accessorKey: "remainder",
    size: 100,
    enableResizing: false,
    isSortable: true,
  },
  {
    header: "Client №",
    accessorKey: "clientNumber",
    size: 85,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 90,
    enableResizing: false,
    isSortable: true,
    sortingFn: "sortDate",
  },
  // {
  //   header: "Status",
  //   accessorKey: "status",
  //   size: 100,
  //   enableResizing: false,
  //   isSortable: true,
  // },
  // {
  //   header: "Operation type",
  //   accessorKey: "operationType",
  //   size: 100,
  //   enableResizing: false,
  // },
  {
    header: "Sum (Dt)",
    accessorKey: "costsDt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Sum (Ct)",
    accessorKey: "revenueCt",
    size: 100,
    enableResizing: false,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 50,
    enableResizing: false,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 400,
    alignStart: true,
    maxSize: 700,
  },

  {
    header: "Counterparty Acc. №",
    accessorKey: "counterpartyAccountNumber",
    alignStart: true,
    size: 270,
    enableResizing: false,
  },
  {
    header: "Counterparty Name",
    accessorKey: "counterpartyName",
    size: 170,
    alignStart: true,
    enableResizing: false,
  },

  {
    header: "Rate",
    accessorKey: "rate",
    size: 50,
    enableResizing: false,
  },
  // {
  //   header: "Equivalent",
  //   accessorKey: "equivalent",
  //   size: 120,
  //   enableResizing: false,
  // },
  // {
  //   header: "Commissions amount",
  //   accessorKey: "commissionsAmmount",
  //   size: 100,
  //   enableResizing: false,
  // },

  {
    header: "tr.id",
    accessorKey: "trId",
    size: 100,
    enableResizing: true,
    alignStart: true,
  },
]);
