import {t} from "i18next";

import useContextMenu from "../../../hook/useContextMenu";
import Divider from "../../../utilities/Divider/Divider";
import {SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import ReactTable from "../../conteiners/ReactTable";

import BankClientButtonRow from "./parts/BankClientButtonRow";
import BankClientFilters from "./parts/BankClientFilters";
import BankClientContextMenu from "./BankClientContextMenu";
import useBankClient from "./useBankClient";

export const BANK_TRANSACTION_STATUSES = Object.freeze({NEW: "new", ASSOCIATED: "associated", CONDUCTED: "conducted"});

export const formTransactionStatus = transaction => {
  if (!transaction) return "";
  const {isFullfiled, isCompleted} = transaction;
  if (!isFullfiled && !isCompleted) return t(BANK_TRANSACTION_STATUSES.NEW);
  if (isFullfiled && !isCompleted) return t(BANK_TRANSACTION_STATUSES.ASSOCIATED);
  if (isCompleted) return t(BANK_TRANSACTION_STATUSES.CONDUCTED);
};

const BankClient = () => {
  const BANK_CLIENT_WINDOW_NAME = "BankClient";

  const {
    onBankClientFilterChange,
    bankClientTableColumns,
    bankClientTableData,
    loading,
    currentBankTransactionId,
    onBankClientTableRowSelect,
    onAccountAssociationBtnClick,
    onToTheClientBalanceBtnClick,
    hasCurrentTranscationAssociation,
    isOperationBetweenOwnAccounts,
    onConductBetweenOwnBtnClick,
    isTransactionCompleted,
    onTableDoubleClick,
    onViewTransactionsBtnClick,
    onConductAfterAutoAssociationBtnClick,
  } = useBankClient();

  const {menuVisible, menuPosition, handleContextMenu} = useContextMenu();

  return (
    <div className="fcCol h100 w100 pb" id="context-menu-container">
      <SessionFiltersContainer window={BANK_CLIENT_WINDOW_NAME} onFilterChange={onBankClientFilterChange}>
        <BankClientFilters />
        <Divider />
        <ReactTable
          columns={bankClientTableColumns}
          defaultData={bankClientTableData}
          current={currentBankTransactionId}
          onSelect={onBankClientTableRowSelect}
          enableColumnResizing={true}
          className="tableTac fGrow scrollX"
          tableClassName="fGrow"
          loading={loading}
          selectOnRightBtn={true}
          handleContextMenu={handleContextMenu}
          onRowDoubleClick={onTableDoubleClick}
        />
        <BankClientButtonRow
          currentBankTransactionId={currentBankTransactionId}
          onAccountAssociationBtnClick={onAccountAssociationBtnClick}
          hasCurrentTranscationAssociation={hasCurrentTranscationAssociation}
          isOperationBetweenOwnAccounts={isOperationBetweenOwnAccounts}
          onConductBetweenOwnBtnClick={onConductBetweenOwnBtnClick}
          isTransactionCompleted={isTransactionCompleted}
          onConductAfterAutoAssociationBtnClick={onConductAfterAutoAssociationBtnClick}
        />
        <BankClientContextMenu
          onAccountAssociationBtnClick={onAccountAssociationBtnClick}
          onToTheClientBalanceBtnClick={onToTheClientBalanceBtnClick}
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          hasCurrentTranscationAssociation={hasCurrentTranscationAssociation}
          isOperationBetweenOwnAccounts={isOperationBetweenOwnAccounts}
          currentBankTransactionId={currentBankTransactionId}
          onConductBetweenOwnBtnClick={onConductBetweenOwnBtnClick}
          isTransactionCompleted={isTransactionCompleted}
          onViewTransactionsBtnClick={onViewTransactionsBtnClick}
          onConductAfterAutoAssociationBtnClick={onConductAfterAutoAssociationBtnClick}
        />
      </SessionFiltersContainer>
    </div>
  );
};

export default BankClient;
