import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import update from "immutability-helper";

import closeEye from "../../../../images/icons/closeEye.png";
import openEye from "../../../../images/icons/openEye.png";
import {moneyFormat, numberFormat} from "../../../../utilities/moneyFormat";
import IndeterminateCheckbox from "../../../conteiners/reactTable/IndeterminateCheckbox";
import {getCurrentSkuPlan} from "../KpFinSlice";

import KpFinTableRow from "./KpFinTableRow";

const useKpFinTableService = (
  defaultColumns,
  dataRaw,
  onReorder,
  onSelect,
  scrollToBottom = true,
  moveRows = true,
  rowCheck = false,
  onRowCheck,
  frozeChecked,
) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [data, setData] = useState([]);
  const [disabledColls, setDisabledColls] = useState([]);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});

  const [totalSumm, setTotalSumm] = useState(null);

  const currentSkuPlan = useSelector(getCurrentSkuPlan);
  const tableRef = useRef(null);

  useEffect(() => {
    setData(dataRaw);
    if (scrollToBottom) setShouldScrollToBottom(true);
    if (dataRaw && dataRaw.length > 0) setTotalSumm(calculateTotalSumm(dataRaw));
  }, [dataRaw]);

  useEffect(() => {
    if (shouldScrollToBottom && tableRef.current) {
      const tableBody = tableRef.current.querySelector("tbody");
      tableBody.scrollTop = tableBody.scrollHeight;
      setShouldScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  useEffect(() => {
    // if (defaultSelection?.lenght === 0) setRowSelection({});
    if (frozeChecked?.length > 0 && data.length > 0) {
      var selection = {};
      data.forEach(row => {
        if (frozeChecked.includes(row.id)) {
          selection[row.number - 1] = true;
        }
      });
      setCheckedRows(selection);
    } else if (rowCheck && Object.keys(checkedRows).length) {
      setCheckedRows({});
    }
  }, [data, frozeChecked]);

  useEffect(() => {
    if (!currentSkuPlan) setSelectedRow(null);
  }, [currentSkuPlan?.skuPlanId]);

  const reorderRow = useCallback(
    (draggedRowIndex, targetRowIndex) => {
      const draggedRow = data[draggedRowIndex];
      const updatedData = update(data, {
        $splice: [
          [draggedRowIndex, 1],
          [targetRowIndex, 0, draggedRow],
        ],
      });

      updatedData.forEach((row, index) => {
        row.number = index + 1;
      });

      onReorder(updatedData);
      setShouldScrollToBottom(true);
      setData(updatedData);
    },
    [data, onReorder],
  );

  const calculateTotalSumm = data => {
    const totalSumm = data.reduce((accumulator, currentValue) => {
      const number = Number(currentValue.paymentSumm);
      if (!isNaN(number)) {
        return Number(accumulator) + number;
      }
      return Number(accumulator);
    }, 0);

    return numberFormat(totalSumm);
  };

  const getRowId = useCallback(row => {
    return row.id;
  }, []);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 10,
      width: 140,
      maxWidth: 400,
    }),
    [],
  );

  const table = useReactTable(
    {
      columns,
      data,
      defaultColumn,
      enableColumnResizing: true,
      enableRowSelection: true,
      columnResizeMode: "onChange",
      getCoreRowModel: getCoreRowModel(),
      getRowId: row => row.userId,
      // debugTable: true,
      // debugHeaders: true,
      // debugColumns: true,

      state: {
        rowSelection: checkedRows,
      },
      onRowSelectionChange: setCheckedRows,
    },
    getRowId,
  );

  useEffect(() => {
    //!Important for redux state management on checkbox change
    const selectedIds = Object.keys(checkedRows).map(id => {
      return table.getRow(id).original.id;
    });

    if (onRowCheck) {
      onRowCheck(selectedIds);
    }
  }, [checkedRows]);

  const toggleColumn = column => {
    if (disabledColls.includes(column.id)) {
      setDisabledColls(coll => coll.filter(c => c !== column.id));
    } else {
      setDisabledColls(coll => [...coll, column.id]);
    }

    const updatedColumns = columns.map(col => {
      if (col.accessorKey === column.id) {
        return {
          ...col,
          isDisabled: !col.isDisabled,
        };
      }
      return col;
    });
    setColumns(updatedColumns);
  };

  const handleRowClick = row => {
    if (selectedRow === row && selectedRow !== null) {
      setSelectedRow(null);
      // dispatch(deselect());
      onSelect(row.original.id);

      return;
    }
    onSelect(row.original.id);
    setSelectedRow(row);
  };

  const checkCollWidth = () => {
    return table.getHeaderGroups()[0].headers.map(coll => {
      return coll.getSize();
    });
  };

  const checkFontSize = () => {
    var element = document.getElementById("checkFontSize");
    var fontSize = window.getComputedStyle(element).fontSize;
    return fontSize.split("px")[0];
  };

  const generateDataForExport = (text, format) => {
    var headerRow = [];
    var ww = checkCollWidth();

    const fontSize = checkFontSize();

    let charCollDisabled = false;

    let collSpanBeforeCharCell = -1;
    const beforeCharCellKeys = [];

    columns.some((coll, i) => {
      if (coll.accessorKey !== "characteristics") {
        collSpanBeforeCharCell++;
        beforeCharCellKeys.push(coll.accessorKey);
        // return coll.accessorKey;
      }
      return coll.accessorKey === "characteristics";
    });

    var newWW = [];
    const numberFormatAccessors = [];

    columns.forEach((coll, index) => {
      if (index === 0) return;
      if (!disabledColls.includes(coll.accessorKey)) {
        headerRow.push(coll.header());
        newWW.push(ww[index]);
      } else {
        if (coll.accessorKey === "characteristics") {
          charCollDisabled = true;
        }
        if (beforeCharCellKeys.includes(coll.accessorKey)) {
          collSpanBeforeCharCell--;
        }
      }
      if (coll.numberFormat) {
        numberFormatAccessors.push(coll.accessorKey);
      }
    });

    var dataRows = [];
    const rowImages = [];
    const headerStringData = [];

    const typeCells = charCollDisabled ? null : [];

    const summRow = `${t("Total to be paid")} ${moneyFormat(totalSumm)}`;

    data.forEach(item => {
      const {id, overallView, decorImages, colorImages, headerData, number, decorText, colorText, ...restItem} = item;

      var row = [];

      for (var key in restItem) {
        if (disabledColls.includes(key)) {
          continue;
        }
        if (key === "image1" || key === "image2") {
          row.push([restItem[key]]);
        } else if (numberFormatAccessors.includes(key)) {
          row.push(numberFormat(restItem[key]));
        } else {
          row.push(restItem[key] || "");
        }
      }

      if (!charCollDisabled) {
        typeCells.push({decorType: decorText, colorType: colorText});
      }

      rowImages.push({overallView, decorImages, colorImages});
      headerStringData.push(headerData);
      dataRows.push(row);
    });

    return {
      headerRow,
      typeCells,
      rowImages,
      dataRows,
      headerStringData,
      summRow,
      text: text,
      // widths: adjustTableWidths(newWW, 690),
      fontSize: fontSize,
      widths: newWW,
      collSpanBeforeCharCell,
    };
  };

  const KpFinTableRender = () => {
    return (
      <div
        style={{
          height: "100%",
          // width: "297mm",
          // width: "11.7in",
        }}
        className="project_list_kp table_dnd_container "
      >
        <DndProvider backend={HTML5Backend}>
          <table
            className="project_table fixedLayout"
            ref={tableRef}
            style={{
              // width: "297mm",
              fontSize: "11px",
            }}
          >
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {moveRows && <th style={{width: "2em"}}></th>}
                  {rowCheck && (
                    <th
                      key={"i0"}
                      style={{
                        position: "relative",
                        width: 25,
                      }}
                    >
                      <IndeterminateCheckbox
                        {...{
                          checked: table.getIsAllRowsSelected(),
                          indeterminate: table.getIsSomeRowsSelected(),
                          onChange: () => {},
                        }}
                        readOnly={true}
                      />
                    </th>
                  )}
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        position: "relative",
                        width: header.getSize(),
                        // width: header.column.columnDef.width,
                        // width: "100px",
                        opacity: header.column.columnDef.isDisabled ? 0.33 : 1,
                        // overflowWrap: "break-word",
                        wordBreak: "break-word",

                        whiteSpace: "normal",
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.columnDef.toggled && (
                        <img
                          src={header.column.columnDef.isDisabled ? closeEye : openEye}
                          alt=""
                          style={{width: "1em", marginLeft: "10px"}}
                          onClick={() => toggleColumn(header)}
                          draggable="false"
                        />
                      )}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${header.column.getIsResizing() ? "isResizing" : ""}`}
                        ></div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="lines">
              {table.getRowModel().rows.map((row, index) => (
                <KpFinTableRow
                  index={index}
                  key={row.id}
                  row={row}
                  reorderRow={reorderRow}
                  onClick={() => handleRowClick(row)}
                  columns={table.getHeaderGroups()[0].headers}
                  selectedRow={selectedRow}
                  shouldScrollToBottom={shouldScrollToBottom}
                  className={index % 2 === 0 ? "row-even" : "row-odd"}
                  moveRows={moveRows}
                  rowCheck={rowCheck}
                />
              ))}

              <tr style={{position: "sticky", bottom: 0, backgroundColor: "white", fontWeight: "bold"}}>
                <td colSpan={columns.length + 1}>
                  <div style={{display: "flex", justifyContent: "end"}}>
                    {t("Total to be paid")} {moneyFormat(totalSumm)}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </DndProvider>
      </div>
    );
  };

  return {KpFinTableRender, disabledColls, data, checkCollWidth, generateDataForExport};
};

export default useKpFinTableService;
