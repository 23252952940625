import React from "react";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";

import logoutPng from "../../../images/icons/logout.png";
import {getCurrentUser, logout} from "../../../slices/UserSlice";

const NavbarInfo = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const user = useSelector(getCurrentUser);
  const userName =
    i18n.language === "en" ? `${user?.engLastName} ${user?.engName}` : `${user?.lastName} ${user?.firstName}`;
  return (
    <div className="navbar__datetime">
      <div className="navbar__datetime_wrapper">
        <h1>
          <Moment format="DD.MM.YYYY" />
        </h1>
        <h1>
          <Moment format="HH:mm" interval={1000 * 60} />
        </h1>
      </div>
      {user && Object.prototype.hasOwnProperty.call(user, "login") && (
        <div className="user_label__container">
          <label htmlFor="" className="label user_label" style={{overflow: "hidden"}}>
            {userName}
          </label>
          <img
            src={logoutPng}
            alt=""
            title={t("LogOut")}
            onClick={() => {
              dispatch(logout());
            }}
            draggable="false"
          />
        </div>
      )}
    </div>
  );
};

export default NavbarInfo;
