import React from "react";

import DirectiveTypePart from "./parts/DirectiveTypePart";
import PayerReceiverPart, {PAYER_RECEIVER_PART_TYPES} from "./parts/PayerReceiverPart";
import {CASHDESK_DIRECTIVE_TYPES} from "./CashdeskDirective";

const ComposedPart = ({
  directiveType,
  currentDirectiveSubTypeId,
  setCurrentDirectiveSubTypeId,
  currentPayer,
  setCurrentPayer,
  currentReceiver,
  setCurrentReceiver,
}) => {
  switch (directiveType) {
    case CASHDESK_DIRECTIVE_TYPES.ADVENT:
    case CASHDESK_DIRECTIVE_TYPES.EXPENCE:
      return (
        <div className="row fGrow gap border padding">
          <DirectiveTypePart
            currentDirectiveSubTypeId={currentDirectiveSubTypeId}
            setCurrentDirectiveSubTypeId={setCurrentDirectiveSubTypeId}
            directiveType={directiveType}
          />
          <PayerReceiverPart
            currentEntity={currentPayer}
            setCurrentEntity={setCurrentPayer}
            type={PAYER_RECEIVER_PART_TYPES.PAYER}
            directiveType={directiveType}
          />
          <PayerReceiverPart
            currentEntity={currentReceiver}
            setCurrentEntity={setCurrentReceiver}
            type={PAYER_RECEIVER_PART_TYPES.RECEIVER}
            directiveType={directiveType}
          />
        </div>
      );
    case CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE:
      return (
        <div className="row fGrow gap border padding">
          <DirectiveTypePart
            currentDirectiveSubTypeId={currentDirectiveSubTypeId}
            setCurrentDirectiveSubTypeId={setCurrentDirectiveSubTypeId}
            directiveType={directiveType}
          />
        </div>
      );
    case CASHDESK_DIRECTIVE_TYPES.TRANSIT:
      return (
        <div className="row fGrow gap border padding">
          <DirectiveTypePart
            currentDirectiveSubTypeId={currentDirectiveSubTypeId}
            setCurrentDirectiveSubTypeId={setCurrentDirectiveSubTypeId}
            directiveType={directiveType}
          />
          <PayerReceiverPart
            currentEntity={currentPayer}
            setCurrentEntity={setCurrentPayer}
            type={PAYER_RECEIVER_PART_TYPES.PAYER}
            directiveType={directiveType}
          />
          <PayerReceiverPart
            currentEntity={currentReceiver}
            setCurrentEntity={setCurrentReceiver}
            type={PAYER_RECEIVER_PART_TYPES.RECEIVER}
            directiveType={directiveType}
          />
        </div>
      );
    default:
      return <div className="row fGrow gap border"></div>;
  }
};

export default ComposedPart;
