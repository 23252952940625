import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import moment from "moment";

import cancelIcon from "../../../../images/icons/cancel.png";
import deleteIcon from "../../../../images/icons/closeBig.png";
import okIcon from "../../../../images/icons/ok.png";
import saveIcon from "../../../../images/icons/saveBig.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import calcStatusColor from "../../../../utilities/calcStatusColor";
import {dateFormat} from "../../../../utilities/dateFormat";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../utilities/hooks/toaster";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import {KP_TO_DO_TABLE_COLUMNS} from "../../../KP/KpSecondVersion/constants";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {
  addTodo,
  clearCurrentTodo,
  clearTodos,
  deleteTodo,
  fetchAllTodos,
  fetchTodo,
  getToDoLoading,
  selectAllTodos,
  selectCurrentTodo,
  updateTodo,
} from "../../ProjectSlice";

const ToDoSection = ({coId, projectId, noProject = false}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();

  const allTodos = useSelector(selectAllTodos);
  const currentTodo = useSelector(selectCurrentTodo);
  const toDoLoadingStatus = useSelector(getToDoLoading);

  const [currentTodoId, setCurrentTodoId] = useState(null);

  const [isDone, setDone] = useState(false);

  // const doneItemsId = useMemo(() => allTodos?.filter(item => item?.isDone).map(todo => todo.todoId), [allTodos]);

  useEffect(() => {
    setValues(prev => ({...prev, todoNote: currentTodo?.note ?? "", dueDate: currentTodo?.dueDate ?? ""}));
    setDone(currentTodo?.isDone ?? false);
  }, [currentTodo?.todoId]);

  useEffect(() => {
    // if (projectId && coId) dispatch(fetchAllTodos({projectId,coId}));
    // if (projectId && !coId) dispatch(fetchAllTodos({projectId}));
    // if (!projectId) dispatch(clearTodos());

    if (projectId) {
      dispatch(fetchAllTodos({projectId}));
    } else if (noProject && coId) {
      dispatch(fetchAllTodos({coId}));
      setCurrentTodoId(null);
      dispatch(clearCurrentTodo());
    } else {
      dispatch(clearTodos());
    }
  }, [projectId, coId]);

  useEffect(() => {
    if (currentTodo?.projectId !== projectId) {
      setCurrentTodoId(null);
      dispatch(clearCurrentTodo());
    }
  }, [projectId]);

  const todoTableColumns = useColumns(KP_TO_DO_TABLE_COLUMNS);
  const todoTableData =
    allTodos?.map(({todoId, note, dueDate, isDone, co, createdAt}, index) => {
      return {
        number: ++index,
        id: todoId,
        coNumber: co?.coNumber ?? "-",
        note,
        dueDate: dateFormat(dueDate),
        isDone: isDone,
        status: isDone ? "done" : calcStatusColor({startDate: createdAt, finalDate: dueDate}),
      };
    }) ?? [];

  function onTodoTableSelect(todoId) {
    setCurrentTodoId(todoId);

    if (todoId) {
      dispatch(fetchTodo({todoId}));
    } else {
      dispatch(clearCurrentTodo());
    }
  }

  function onSaveTodoBtnClick() {
    const {todoNote: note, dueDate} = values;

    if (note.length > 300) {
      toaster.warning(t("Too long note", {number: 300}));
      return;
    }

    if (currentTodoId) {
      dispatch(updateTodo({todoId: currentTodoId, body: {note, isDone}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Todo updated");
          dispatch(fetchAllTodos({projectId, coId}));
        }
      });
    } else {
      // if (!projectId) return;

      dispatch(addTodo({...(coId ? {coId} : {projectId}), body: {note, dueDate}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Todo created");
          dispatch(fetchAllTodos({projectId, coId}));
          setCurrentTodoId(resp.payload.todo.todoId);
        }
      });
    }
  }

  function onSubmitTodoBtnClick() {
    if (currentTodoId) {
      dispatch(updateTodo({todoId: currentTodoId, body: {isDone: !isDone}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Todo updated");
          setCurrentTodoId(null);
          dispatch(clearCurrentTodo());
          dispatch(fetchAllTodos({projectId, coId}));
        }
      });
      setDone(!isDone);
    }
  }

  function onDeleteTodoBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        dispatch(deleteTodo({todoId: currentTodoId})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            toaster.success("Todo deleted");
            dispatch(fetchAllTodos({projectId, coId}));
            setCurrentTodoId(null);
            dispatch(clearCurrentTodo());
          }
        });
      },
    });
  }

  return (
    <div className="pt">
      <div className="row gap aifs mb0_5">
        <ToolTip title={!noProject && !projectId ? "Choose a project or a CO first" : ""} className="fGrow">
          <TextInput
            label=""
            name="todoNote"
            placeholder={t("TODO note to the Project / KP")}
            disabled={(!noProject && !projectId) || (noProject && !coId)}
          />
        </ToolTip>

        <DateInput
          name="dueDate"
          flatpikrConfig={{
            minDate: moment().add(1, "days").format("DD-MM-YYYY"),
          }}
          className="asfe"
          readOnly={(!noProject && !projectId) || (noProject && !coId) || !!currentTodoId}
        />
        <div className="row">
          <ImageButton
            src={saveIcon}
            alt="save"
            width={1.5}
            height={1.4}
            onClick={onSaveTodoBtnClick}
            disabled={(!noProject && !projectId) || (noProject && !coId) || !values.todoNote || !values.dueDate}
          />
          <ImageButton
            src={deleteIcon}
            alt="delete"
            width={1.8}
            height={1.8}
            onClick={onDeleteTodoBtnClick}
            disabled={(!noProject && !projectId) || !currentTodoId}
            tooltipMessage={!currentTodoId ? "" : "Delete ToDo"}
          />
          <ImageButton
            src={!isDone ? okIcon : cancelIcon}
            alt="ok"
            width={1.8}
            height={1.8}
            onClick={onSubmitTodoBtnClick}
            disabled={(!noProject && !projectId) || !currentTodoId || !currentTodo}
            tooltipMessage={!currentTodoId ? "" : !isDone ? "Mark ToDo as done" : "Remove done mark"}
          />
        </div>
      </div>
      <ReactTable
        defaultData={todoTableData ?? []}
        columns={todoTableColumns}
        current={currentTodoId}
        className="scrollX  mb0_5"
        loading={toDoLoadingStatus}
        style={{height: "7em"}}
        onSelect={onTodoTableSelect}
        // disabledRowIds={doneItemsId}
        disabledRowClassName={"react-table-disabled-td-todo"}
      />
    </div>
  );
};

export default ToDoSection;
