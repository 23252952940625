export const WR_SETTINGS_MAIN_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    isSortable: true,
  },
  {
    header: "Type",
    accessorKey: "type",
    isSortable: true,
  },
  {
    header: "Warehouse name",
    accessorKey: "warehouseName",
    isSortable: true,
  },
  {
    header: "Address",
    accessorKey: "address",
    isSortable: true,
  },
  {
    header: "Full name",
    accessorKey: "contactFullName",
    isSortable: true,
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Phone",
    accessorKey: "phone",
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
  },
]);
