import {useTranslation} from "react-i18next";

import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModCheckList from "../CheckLists/ModCheckList";
import {LOGIST_WINDOW_TYPES} from "../common/constants";
import FullSleepingFiltersForm from "../common/SleepingFilters/SleepingFilters";

import useSleepingRequestsService from "./useSleepingRequestsService";

const SleepingRequestsBase = () => {
  const {t} = useTranslation();

  const {
    uppperTableColumns,
    onUpperTableRowSelect,
    upperTableCurrent,
    onUpperTableRowDoubleClick,
    isUpperTableLoading,
    lowerTableColumns,
    lowerTableData,
    onLowerTableRowSelect,
    lowerTableCurrent,
    onLowerTableRowDoubleClick,
    isLowerTableLoading,
    isModalActive,
    setModalActive,
    upperTableData,
  } = useSleepingRequestsService();

  return (
    <PageContainer title={t("Request Register for planning")}>
      <div className="page_flex_container mb">
        <FullSleepingFiltersForm window={LOGIST_WINDOW_TYPES.SLEEPING} />
        <ReactTable
          className="w100 scrollX"
          columns={uppperTableColumns}
          defaultData={upperTableData}
          onSelect={onUpperTableRowSelect}
          current={upperTableCurrent}
          onRowDoubleClick={onUpperTableRowDoubleClick}
          loading={isUpperTableLoading}
        />
        <ReactTable
          className="w100 scrollX"
          columns={lowerTableColumns}
          defaultData={lowerTableData}
          onSelect={onLowerTableRowSelect}
          current={lowerTableCurrent}
          onRowDoubleClick={onLowerTableRowDoubleClick}
          loading={isLowerTableLoading}
        />
      </div>
      <ModCheckList modalShow={isModalActive} setModalShow={setModalActive} />
      {/* <LogModRqstCheckListMC modalShow={isModalActive} setModalShow={setModalActive} type={modalType} /> */}
    </PageContainer>
  );
};

export default SleepingRequestsBase;
