import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";

import {withFormikForm} from "../../../../hook/formikFormHOC";
import AvailabilityStatusCIrcule from "../../../../utilities/AvailabilityStatusCIrcule";
import FilterButton from "../../../../utilities/Buttons/FilterBtn/FilterButton";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import compareIfFormikValuesChanged from "../../../../utilities/compareIfFormikValuesChanged";
import {getError} from "../../../../utilities/toasts";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useCountryIdOptionsService from "../../../VED/settings/conditions&contactsFactory/utils/hooks/getCountryIdOptionsService";
import useRequestFilters from "../../Filters/filters.hook";
import {REQ_WINDOW_TYPES} from "../../RequestsInProgress/utils/functions/requestsFiltersManager";
import {fetchRequests, getFirstStatusRequest} from "../../RequestsSlice";

const OrderingFilterForm = () => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  const [buttonColor, setButtonColor] = useState(STATUS_COLORS.GREEN);

  const [areFiltersDefined, setAreFiltersDefined] = useState(false);

  const firstSatusRequests = useSelector(getFirstStatusRequest);

  const {initialValues, values, resetForm} = useFormikContext();

  useEffect(() => {
    if (firstSatusRequests.length > 0) {
      setButtonColor(STATUS_COLORS.RED);
    } else {
      setButtonColor(STATUS_COLORS.GREEN);
    }
  }, [firstSatusRequests]);

  const {
    brandsOptions,
    coManagersOptions,
    // countryOptions
  } = useRequestFilters();

  const countryOptions = useCountryIdOptionsService();

  //TODO add countryId
  const onFilter = () => {
    const didFiltersChange = compareIfFormikValuesChanged(values, initialValues);
    if (!didFiltersChange) {
      getError(t("Define the filters first!"));
      return;
    }
    setAreFiltersDefined(didFiltersChange);
    const {requestNumber, managerCo, factory, countryId} = values;
    const params = checkEmptyFields({
      requestNumber,
      managerCoId: managerCo,
      brandId: factory,
      countryId,
    });
    if (Object.keys(params).length === 0) {
      getError(t("Define at least one filter first"));
      return;
    }
    setAreFiltersDefined(true);
    dispatch(fetchRequests({...params, window: REQ_WINDOW_TYPES.INCOMING}));
  };

  const delFilters = () => {
    setAreFiltersDefined(false);
    dispatch(fetchRequests({window: REQ_WINDOW_TYPES.INCOMING})).then(res => {
      if (res.meta.requestStatus === "fulfilled") resetForm();
    });
    setAreFiltersDefined(false);
  };

  return (
    <div className="fc jcfs autolabel w100 nowrap" style={{gap: "2em"}}>
      <AvailabilityStatusCIrcule tooltipMessage="indicator" color={buttonColor} />

      <FilterButton
        onFilter={onFilter}
        delFilter={delFilters}
        questionTitle={[t("Filter")]}
        areFiltersDefined={areFiltersDefined}
      />

      <div className="row fGrow gap2x">
        <TextInput label={t("Order number") + ":"} name="requestNumber" />
        <Select
          label={t("CO Manager") + ":"}
          options={coManagersOptions}
          name="managerCo"
          generalClassName="fGrow fShrink"
        />
        <Select
          label={t("Product country") + ":"}
          options={countryOptions}
          name="countryId"
          generalClassName="fGrow fShrink"
        />
        <Select label={t("Factory")} options={brandsOptions} name="factory" generalClassName="fGrow fShrink" />
      </div>
    </div>
  );
};

const OrderingFilterFormComp = withFormikForm(OrderingFilterForm);

const OrderingFilter = withFormik({
  mapPropsToValues: () => ({managerCo: "", factory: "", requestNumber: "", countryId: ""}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(OrderingFilterFormComp);

export default OrderingFilter;
