import {t} from "i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../../../Inputs";

const DirectiveCreation = ({
  onCreateDirectiveBtnClick = () => {},
  noCounterpartyAssociation,
  hasAssociation,
  isDirectiveCreated,
  // isBankTransactionCompleted,
}) => {
  return (
    <div className="border padding0_5 l0 t0 row gap fitContentWidth aist">
      <div className="row aic gap2x">
        <div style={{whiteSpace: "nowrap"}} className="header fitContentWidth ">{`${
          !noCounterpartyAssociation ? "2. " : ""
        }${t("Crediting to the Client's balance")}`}</div>
        <TextInput name="clientNumber" label="№" autolabel width="7rem" readOnly />
      </div>

      <FunctionButton
        name="Directive operations"
        disabled={
          !hasAssociation || isDirectiveCreated
          // || isBankTransactionCompleted
        }
        tooltipMessage={
          !hasAssociation
            ? "Create an association first"
            : // : isBankTransactionCompleted ? "Transaction completed"
              ""
        }
        onClick={onCreateDirectiveBtnClick}
        width="9rem"
        style={{width: "10rem", height: "3rem", textAlign: "center"}}
        className="br"
      />
    </div>
  );
};

export default DirectiveCreation;
