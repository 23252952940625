import {useSelector} from "react-redux";

import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectChosenCargo} from "../../../redux/selectors";

const useTPModalTableService = () => {
  const chosenCargo = useSelector(selectChosenCargo);

  const coll = [
    {
      header: "№",
      accessorKey: "number",
      size: 25,
    },
    {
      header: "Stock pool №",
      accessorKey: "stockPoolNumber",
    },
    {
      header: "Quantity",
      accessorKey: "quantity",
    },
    {
      header: "weight (kg)",
      accessorKey: "weight",
    },
    {
      header: "volume (m3)",
      accessorKey: "volume",
    },
    {
      header: "Note",
      accessorKey: "note",
      alignStart: true,
    },
  ];

  const columns = useColumns(coll);
  const packingSpaces = chosenCargo?.stockPools?.flatMap(sp => sp.packingSpaces) ?? [];
  const defaultData =
    packingSpaces?.map((sp, i) => ({
      number: i + 1,
      id: sp.spaceId,
      stockPoolNumber:
        chosenCargo?.stockPools.find(stockPool => stockPool.stockPoolId === sp.stockPoolId)?.stockPoolNumber || "",
      quantity: sp?.quantity || "",
      weight: sp?.weight || "",
      volume: sp?.volume || "",
      note: sp?.note || "",
    })) ?? [];
  const onSelect = () => {};

  return {columns, defaultData, onSelect};
};

export default useTPModalTableService;
