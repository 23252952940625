import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

// import uploadImg from "./Upload.png";
import cloud from "../../../../images/icons/download.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../../utilities/ToolTip";
import Modal from "../../../Modal/Modal";
import Spinner from "../../../spinner/Spinner";
import {CROP_IMG_CATEGORIES, CROP_IMG_SIZES} from "../cropper/cropImgSizes";
import ReactCropper from "../react-cropper/ReactCropper";

import FileInput from "./utils/fileInp";
import {ImageNotSavePreviewItem} from "./utils/ImagePreviewItem";
import PdfPreviewItem from "./utils/PdfPreviewItem";

const ImgFilePreview = ({
  imgs,
  addFunc,
  delFunc,
  disable,
  accept = "image/*",
  necessaryCrop = false,
  loading = false,
  category = CROP_IMG_CATEGORIES.DEFAULT,
}) => {
  const {t} = useTranslation();

  const [modal, setModal] = useState(false);

  const [justAddedImagesUrls, setJustAddedImageUrls] = useState([]);
  const [justAddedBlobs, setJustAddedBlobs] = useState([]);

  const [croppingImg, setCroppingImg] = useState(null);
  const [croppingImgIndex, setCroppingImgIndex] = useState(null);
  const [cropper, setCropper] = useState(false);

  useEffect(() => {
    if (necessaryCrop && justAddedBlobs.length > 0) {
      setCropper(true);
      setCroppingImg(justAddedBlobs[0]);
      setCroppingImgIndex(0);
    }
  }, [justAddedBlobs]);

  /////// paste images
  useEffect(() => {
    const handlePaste = e => {
      const pasteData = e.clipboardData || window.clipboardData;
      if (pasteData && pasteData.items) {
        for (let i = 0; i < pasteData.items.length; i++) {
          const item = pasteData.items[i];
          if (item.type.indexOf("image") !== -1) {
            const blob = item.getAsFile();
            setJustAddedBlobs(prev => [...prev, blob]);
            filesToUrl([blob]).then(res => setJustAddedImageUrls(prev => [...prev, ...res]));
            break;
          }
        }
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = e => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    setJustAddedBlobs(prev => [...prev, ...droppedFiles]);
    filesToUrl(droppedFiles).then(res => setJustAddedImageUrls(prev => [...prev, ...res]));
  };

  const handleInputChoise = files => {
    setJustAddedBlobs(prev => [...prev, ...files]);
    filesToUrl(files).then(res => setJustAddedImageUrls(prev => [...prev, ...res]));
  };

  /////---- cropper func -----////

  const setNeedCrope = files => {
    if (files) {
      setCropper(true);
      setCroppingImg(files[0]);
    }
  };

  const cropFunc = url => {
    const index = justAddedImagesUrls.findIndex(imUrl => imUrl === url);
    setCroppingImgIndex(index);
    setNeedCrope([justAddedBlobs[index]]);
  };

  const onCropedFinish = file => {
    if (file) {
      addFunc([file]);
      dellJustAdded(null, croppingImgIndex);
      if (necessaryCrop && justAddedBlobs.length - 1 > 0) {
        // setCroppingImg(justAddedBlobs[1]);
      } else setCropper(false);
    } else if (necessaryCrop && justAddedBlobs.length > 0) {
      Swal.fire({
        title: "",
        text: t("You have to crop selected images, otherwise they will be deleted"),
        icon: "warning",
        confirmButtonText: t("Continiue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(value => {
        if (value.isConfirmed) {
          setCropper(false);
          setJustAddedBlobs([]);
          setJustAddedImageUrls([]);
        }
      });
    } else {
      setCropper(false);
    }
  };

  ////////----- JUST ADDED FUNCTIONAL---- ///////////////////////////////

  const filesToUrl = files => {
    return new Promise((resolve, reject) => {
      const urls = [];
      const reader = new FileReader();

      const readNextFile = index => {
        if (index >= files.length) {
          resolve(urls);
          return;
        }

        const file = files[index];
        reader.onload = event => {
          urls.push(event.target.result);
          readNextFile(index + 1);
        };
        reader.onerror = error => {
          reject(error);
        };
        reader.readAsDataURL(file);
      };

      readNextFile(0);
    });
  };

  const dellJustAdded = (url, index_) => {
    let index = index_;
    if (url !== null && !index_) {
      index = justAddedImagesUrls.findIndex(imUrl => imUrl === url);
    }
    setJustAddedBlobs(prev => prev.filter((file, i) => i !== index));
    setJustAddedImageUrls(prev => prev.filter((file, i) => i !== index));
  };
  //////////////////////////////////////////////////////////////

  //  ----- render data-------////

  const uploadedRender = useMemo(() => {
    return renderBoxes({fileList: imgs, delFunc, Component: PdfPreviewItem});
  }, [imgs]);

  const justAddedImagesRender = renderBoxes({
    fileList: justAddedImagesUrls,
    delFunc: dellJustAdded,
    Component: ImageNotSavePreviewItem,
    cropFunc,
  });

  return (
    <>
      <ImageButton
        src={cloud}
        alt="upload"
        className="btn__upload"
        width={1.9}
        height={1.8}
        onClick={() => {
          setModal(true);
        }}
        disabled={disable}
      />

      <Modal active={modal} setActive={setModal}>
        {cropper && croppingImg ? (
          <div className="" style={{width: "100%", height: "auto"}}>
            <ReactCropper
              initialImage={croppingImg}
              setCroppedImage={onCropedFinish}
              setOpenCrop={setCropper}
              cropBoxResizable={!necessaryCrop}
              cropBoxSize={CROP_IMG_SIZES[category]}
            />
          </div>
        ) : (
          <div className="imgPreview">
            <div className="previewBox" style={{position: "relative"}} onDragOver={handleDragOver} onDrop={handleDrop}>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {uploadedRender}
                  {justAddedImagesRender}
                  <div className="previewBoxItem img-adding">
                    <ToolTip title={[t("Add document")]}>
                      <FileInput accept={accept} name="fileInput" addFunc={handleInputChoise} />
                    </ToolTip>
                  </div>
                </>
              )}
            </div>

            <div className="imgPreview__buttons">
              {justAddedImagesUrls?.length > 0 && (
                <p style={{color: "#892C19", paddingTop: "0.5em", paddingLeft: "0.5em"}}>
                  {t("You have unsaved pictures!")}
                </p>
              )}
              {justAddedImagesUrls?.length > 0 && (
                <FunctionButton
                  name={t("Save All Images")}
                  onClick={() => {
                    addFunc(justAddedBlobs);
                    setJustAddedBlobs([]);
                    setJustAddedImageUrls([]);
                  }}
                />
              )}
              <FunctionButton
                name={t("Close")}
                onClick={() => {
                  setModal(false);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

function renderBoxes({fileList, delFunc, Component, ...props}) {
  return (
    fileList?.map((file, i) => {
      return (
        <div key={i}>
          <Component file={file} delFunc={delFunc} index={i} {...props} />
        </div>
      );
    }) || []
  );
}

ImgFilePreview.propTypes = {
  imgs: PropTypes.array,
  addFunc: PropTypes.func,
  delFunc: PropTypes.func,
  disable: PropTypes.bool,
  accept: PropTypes.string,
  cropNeed: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ImgFilePreview;
