import {t} from "i18next";

import {MoneyInput, NumberInput, TextInput} from "../../../../../Inputs";

const SumsWithAdvance = ({readOnly = true}) => {
  return (
    <div className="fcCol gap aistr w100">
      <MoneyInput name="coSumComposed" label={t("CO Sum")} autolabel width="10em" readOnly={true} />
      <div className="row aibl">
        <MoneyInput name="apSumComposed" label={t("Sum (ap)")} autolabel width="10em" readOnly={true} />
        <NumberInput name="advancePercentageComposed" label={t("%advance")} autolabel readOnly={true} />%
      </div>
      <MoneyInput name="afSumComposed" label={t("Sum (af)")} autolabel width="10em" readOnly={true} />
    </div>
  );
};

export default SumsWithAdvance;
