import AvailabilityStatusCIrcule from "../../../utilities/AvailabilityStatusCIrcule";

export const KP_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "Status.",
    accessorKey: "status",
    size: 40,
    maxSize: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "CO №",
    accessorKey: "coNumber",
    size: 80,
    maxSize: 80,
    isSortable: true,
  },
  {
    header: "Manager",
    accessorKey: "projectManager",
    size: 90,
    maxSize: 90,
    isSortable: true,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    size: 90,
    maxSize: 90,
    isSortable: true,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    alignStart: true,
    size: 200,
  },
  {
    header: "Date from",
    accessorKey: "startDate",
    size: 70,
    maxSize: 70,
    isSortable: true,
    sortingFn: "sortDate",
  },
  {
    header: "Date to",
    accessorKey: "finalDate",
    size: 70,
    maxSize: 70,
    isSortable: true,
    sortingFn: "sortDate",
  },
]);

export const KP_F_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "Status",
    accessorKey: "status",
    size: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "Directive",
    accessorKey: "isDirective",
    size: 25,
    maxSize: 25,
  },
  {
    header: "CO(f) №",
    accessorKey: "cofNumber",
    // size: 100,
  },
  {
    header: "Manager",
    accessorKey: "projectManager",
    size: 120,
    maxSize: 120,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    size: 120,
    maxSize: 120,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    alignStart: true,
    size: 450,
  },
  // {
  //   header: "Date from",
  //   accessorKey: "startDate",
  //   // size: 100,
  // },
  // {
  //   header: "Date to",
  //   accessorKey: "finalDate",
  //   // size: 100,
  // },
  {
    header: "COf amount",
    accessorKey: "cofSum",
    // size: 100,
  },
]);

export const KP_A_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "Status",
    accessorKey: "status",
    size: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "№nn",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "CO(a) №",
    accessorKey: "coaNumber",
    // size: 100,
  },

  {
    header: "Manager",
    accessorKey: "projectManager",
    // size: 100,
  },
  {
    header: "Manager CO",
    accessorKey: "coManager",
    // size: 60,
  },
  {
    header: "Note/desc",
    accessorKey: "note",
    alignStart: true,
    size: 400,
  },
  {
    header: "Date from",
    accessorKey: "startDate",
    // size: 100,
  },
  {
    header: "Date to",
    accessorKey: "finalDate",
    // size: 100,
  },
]);

export const KP_TO_DO_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 25,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "CO(..)№",
    accessorKey: "coNumber",
    size: 80,
    maxSize: 80,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
    // size: 100,
  },
  {
    header: "Notes",
    accessorKey: "note",
    size: 400,
    alignStart: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "D-day",
    accessorKey: "dueDate",
    size: 70,
    maxSize: 70,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
    isSortable: true,
    sortingFn: "sortDate",
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 50,
    maxSize: 50,
    // cell: status => (
    //   <div style={{fontStyle: "normal"}}>{status.getValue() === 1 ? "✓" : status.getValue() === 0 ? "⏰" : ""}</div>
    // ),
    cell: circleColor =>
      circleColor.getValue() === "done" ? (
        <div style={{fontStyle: "normal"}}>✓</div>
      ) : (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
          <AvailabilityStatusCIrcule color={circleColor.getValue()} />
        </div>
      ),
    // size: 60,
    isSortable: true,
  },
]);

export const INVOICE_CREATING_TABLE_COLUMNS = Object.freeze([
  {
    header: "Data",
    accessorKey: "data",
    size: 25,
  },
  {
    header: "Supplier requisites",
    accessorKey: "supplierReqs",
    size: 100,
  },
]);
