export const findIfBankTransactionHasNoClient = bankTransaction => {
  if (!bankTransaction) return false;

  const isCurrentTransactionDebit = Boolean(bankTransaction?.bankClientStatement?.isDebit);

  return (
    bankTransaction?.[isCurrentTransactionDebit ? "debitAccount" : "creditAccount"]?.accountNumber?.slice(11, 15) ===
    "2609"
  );
};
