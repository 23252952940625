import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchClients, selectAllClients} from "../../../../../../slices/PersonSlice";
import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../../../utilities/ToolTip";
import {TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";
import {fetchAllCounterpartyTypes, selectCounterpartyTypes} from "../../../../FinBlockSlice";

export const CLIENT_ACCOUNT_TYPE_ID = "2";

const CounterpartyAssociation = ({
  onSaveAssociationBtnClick = () => {},
  hasAssociation,
  isResidue,
  isAlignedResidue,
  isBankTransactionCompleted,
}) => {
  const dispatch = useDispatch();

  const {values, setFieldValue} = useFormikContext();

  const clients = useSelector(selectAllClients);
  const counterPartyTypes = useSelector(selectCounterpartyTypes);

  useEffect(() => {
    dispatch(fetchAllCounterpartyTypes());
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!clients) {
        dispatch(fetchClients());
      }
    }, 500);
  }, [clients?.length]);

  const counterpartyTypeOptions = counterPartyTypes?.map(({name, accountTypeId, transactionType}) => ({
    title: name,
    value: `${accountTypeId}`,
    transactionType,
  }));
  const clientNumberOptions = clients?.map(({clientId, clientNumber}) => ({title: clientNumber, value: clientId}));

  const onCounterpartyTypeChange = option => {
    setFieldValue("transactionType", option.transactionType);

    if (option.value !== CLIENT_ACCOUNT_TYPE_ID) {
      setFieldValue("clientId", null);
    }
  };

  return (
    <ToolTip title={hasAssociation && !isResidue ? "Already has association" : ""} className="w100">
      <div className="border padding0_5 row aic w100">
        <span className="header">{t("Counterparty Association")}</span>
        <Select
          name="counterpartyTypeId"
          label={t("Counterparty Type")}
          options={counterpartyTypeOptions}
          autolabel
          disabled={hasAssociation || isResidue}
          optionsAbove
          makeEffect={onCounterpartyTypeChange}
        />
        <Select
          name="clientId"
          label="№"
          options={clientNumberOptions}
          autolabel
          disabled={(hasAssociation && !isResidue) || values.counterpartyTypeId !== CLIENT_ACCOUNT_TYPE_ID}
          optionsAbove
        />
        <TextInput
          name="transactionType"
          label={t("Transaction type ")}
          // options={transactionTypeOptions}
          autolabel
          disabled
        />
        <FunctionButton
          name="Save association"
          onClick={onSaveAssociationBtnClick}
          autoWidth
          disabled={
            (hasAssociation && !isResidue) ||
            (isBankTransactionCompleted && !isResidue) ||
            isAlignedResidue ||
            !values.counterpartyTypeId ||
            (values.counterpartyTypeId === CLIENT_ACCOUNT_TYPE_ID && !values.clientId) ||
            values.counterpartyTypeId === "7"
          }
          tooltipMessage={
            values.counterpartyTypeId === "7"
              ? "No functional"
              : values.counterpartyTypeId === CLIENT_ACCOUNT_TYPE_ID && !values.clientId
              ? "Select client"
              : ""
          }
        />
      </div>
    </ToolTip>
  );
};

export default CounterpartyAssociation;
