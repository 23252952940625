import {useEffect, useMemo, useRef, useState} from "react";
import Carousel from "react-material-ui-carousel";

import {apiUrl} from "../../../../api/baseURL";
import prevIcon from "../../../../images/icons/Caret_Circle_Left.png";
import nextIcon from "../../../../images/icons/Caret_Circle_Right.png";

import "./ImgSlider.scss";

function ImgSlider({
  imgs,
  positionCheck = null,
  // className,
  width,
  height,
  showIndicators = true,
  currentPos = 0,
  onActiveImgChange = null,
}) {
  const [activePos, setActivePos] = useState(currentPos);
  const images = useMemo(() => {
    return imgs
      ? imgs.map(img => {
          return {url: `${apiUrl}/${img}`};
        })
      : [];
  }, [imgs]);

  useEffect(() => {
    if (positionCheck) {
      positionCheck(imgs[currentPos]);
    }
  }, []);

  const defSty = {
    buttonWrapper: {
      position: "absolute",
      height: "100px",
      backgroundColor: "transparent",
      top: "calc(50% - 70px)",
      "&:hover": {
        "& $button": {
          backgroundColor: "black",
          filter: "brightness(120%)",
          opacity: "0.4",
        },
      },
    },
    fullHeightHoverWrapper: {
      height: "100%",
      top: "0",
    },
    buttonVisible: {
      opacity: "1",
    },
    buttonHidden: {
      opacity: "0",
    },
    button: {
      margin: "0 10px",
      position: "relative",
      backgroundColor: "#494949",
      top: "calc(50% - 20px) !important",
      color: "white",
      fontSize: "30px",
      transition: "200ms",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.6 !important",
      },
    },
    // Applies to the "next" button wrapper
    next: {
      right: "100px",
    },
    // Applies to the "prev" button wrapper
    prev: {
      left: "100px",
    },
  };
  return (
    // <div className={`image_carusel_custom_style ${className}`}>
    <div style={{width: width, height: height}} className="image_carusel_custom_style">
      <Carousel
        classes={{item: "MuiCarouselItem-root"}}
        autoPlay={false}
        index={currentPos}
        height={height}
        sx={{height: "100%"}}
        // itemClass={className}
        indicators={showIndicators}
        navButtonsAlwaysVisible={true}
        // cycleNavigation={false}
        swipe={false}
        navButtonsAlwaysInvisible={imgs?.length === 0}
        onChange={(now, previous) => {
          setActivePos(now);
          if (onActiveImgChange) {
            onActiveImgChange(now);
          }
          if (positionCheck) {
            positionCheck(imgs[now]);
          }
        }}
        fullHeightHover={true}
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            background: "transparent",
            borderRadius: 0,
            // width: '100%',
            next: {
              background: "red !important",
              right: "100px",
              width: "10px",
            },
          },
        }}
        navButtonsWrapperProps={{
          style: {
            // bottom: '0',
            // top: 'unset'
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: "3px", // 1
            color: " #88A5CF", // 3
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: "#BFCDE2",
          },
        }}
        indicatorContainerProps={{
          style: {
            bottom: "0",
            position: "absolute",
            zIndex: 10,
          },
        }}
        // NextIcon={<img src={nextIcon} alt="next" style={{width: "1em"}} draggable="false" />}
        // PrevIcon={<img src={prevIcon} alt="prev" style={{width: "1em"}} draggable="false" />}
        NavButton={({onClick, className, style, next, prev}) => {
          // Other logic

          return (
            <img
              src={next ? nextIcon : prevIcon}
              alt="prev"
              style={{
                ...style,
                width: "2em",
                opacity: next && activePos < imgs?.length - 1 ? "1" : prev && activePos !== 0 ? "1" : "0.5",
              }}
              draggable="false"
              onClick={(next && activePos === imgs?.length - 1) || (prev && activePos === 0) ? () => {} : onClick}
              className={className}
            />
          );
        }}
      >
        {images.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
}

function Item(props) {
  return (
    <div className="caruselItem">
      <img src={props.item.url} alt="" draggable="false" />
    </div>
  );
}

export default ImgSlider;
