import {useState} from "react";
import {useFormikContext} from "formik";
import {t} from "i18next";

import AddIcon from "../../../../images/icons/add.png";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import AddLeadModal from "../AddLeadModal";
import {RECEIVER_TYPE_PART_TABLE_COLUMNS} from "../constants";

const ReceiverPart = ({currentReciver, setCurrentReciver}) => {
  const {setFieldValue} = useFormikContext();

  const [isModalActive, setModalActive] = useState(false);

  const columns = useColumns(RECEIVER_TYPE_PART_TABLE_COLUMNS);

  const onAddPayerBtnClick = () => {
    setModalActive(prev => !prev);
  };

  const onTableRowSelect = payer => {
    if (!payer) {
      setFieldValue("receiverType", "");
      setFieldValue("receiverName", "");
    }
    setCurrentReciver(payer);
  };

  return (
    <div className="fcCol gap fGrow aistr" style={{maxWidth: "32%"}}>
      <div className="row jcsb aife">
        <Select
          name="receiverType"
          label={t("Receiver")}
          options={[]}
          autolabel
          required
          placeholder={t("Receiver type")}
        />
        <TextInput name="receiverName" placeholder="Receiver" />
        <ImageButton src={AddIcon} onClick={onAddPayerBtnClick} width={1.2} height={1.2} />
      </div>

      <ReactTable
        columns={columns}
        defaultData={[]}
        className="scrollX scrollY fGrow"
        current={currentReciver}
        onSelect={onTableRowSelect}
      />
      <ModalForm
        label={t("Lead")}
        active={isModalActive}
        setModalActive={setModalActive}
        closable
        Component={AddLeadModal}
      />
    </div>
  );
};

export default ReceiverPart;
