import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {useFiltersContext} from "../../../conteiners/filters/useSessionFiltersContext";

const BankClientButtonRow = ({
  currentBankTransactionId,
  onAccountAssociationBtnClick,
  hasCurrentTranscationAssociation,
  isOperationBetweenOwnAccounts,
  onConductBetweenOwnBtnClick,
  isTransactionCompleted,
  onConductAfterAutoAssociationBtnClick,
}) => {
  const {filters: savedFilters = {}} = useFiltersContext();

  return (
    <div className="row asfs gap4x mt0_5">
      {isOperationBetweenOwnAccounts && (
        <FunctionButton
          name="Conduct without association"
          onClick={() => onConductBetweenOwnBtnClick({savedFilters})}
          autoWidth
          disabled={!currentBankTransactionId || isTransactionCompleted}
          tooltipMessage={isTransactionCompleted ? "Transaction completed" : null}
        />
      )}
      {!isOperationBetweenOwnAccounts && (
        <>
          {" "}
          <FunctionButton
            name="Account association"
            onClick={() => onAccountAssociationBtnClick({savedFilters})}
            autoWidth
            disabled={
              !currentBankTransactionId ||
              hasCurrentTranscationAssociation ||
              isOperationBetweenOwnAccounts ||
              isTransactionCompleted
            }
            tooltipMessage={
              hasCurrentTranscationAssociation
                ? "Transaction already has an association"
                : isTransactionCompleted
                ? "Transaction completed"
                : null
            }
          />
          <FunctionButton
            name="Conduct"
            onClick={() => onConductAfterAutoAssociationBtnClick({savedFilters})}
            autoWidth
            disabled={
              !currentBankTransactionId ||
              !hasCurrentTranscationAssociation ||
              isOperationBetweenOwnAccounts ||
              isTransactionCompleted
            }
            tooltipMessage={
              !hasCurrentTranscationAssociation
                ? "Transaction does not have an association"
                : isTransactionCompleted
                ? "Transaction completed"
                : null
            }
          />
        </>
      )}
    </div>
  );
};

export default BankClientButtonRow;
