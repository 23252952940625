import {useEffect} from "react";
import {useTranslation} from "react-i18next";

import useFileFolderPicker from "../../../hook/useFileFolderPicker";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import {swalCustom} from "../../../utilities/hooks/swalCustom";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import ReactTable from "../../conteiners/ReactTable";
import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import {DateInput, MoneyInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import BankClientButtonRow from "./parts/BankClientButtonRow";
import BankOperationList from "./tabs/BankOperationList/BankOperationList";
import BankStatementsList from "./tabs/BankStatementsList/BankStatementsList";
import useBankClient from "./useBankClient";

// const BankClientBody = () => {
//   const {loading, bankClientTableColumns, bankClientTableData} = useBankClient();

//   return (

//   );
// };

const BankClient = () => {
  const BANK_CLIENT_WINDOW_NAME = "BankClient";
  const {t} = useTranslation();

  const {
    bankOptions,
    currencyOptions,
    onBankClientFilterChange,
    onGetDataBtnClick,
    folderPickerRef,
    handleFolderUpload,
    bankClientTableColumns,
    bankClientTableData,
    loading,
  } = useBankClient();
  const {setTabs} = useTabsContext();

  useEffect(() => {
    setTabs(prev => [
      prev[0],
      {TabComponent: BankStatementsList, index: 1, title: "Bank-Client statements (register)"},
      {TabComponent: BankOperationList, index: 2, title: t("remains as of {{number}}", {number: ""})},
    ]);
  }, []);

  return (
    <div className="fcCol h100 w100 pb">
      <SessionFiltersContainer window={BANK_CLIENT_WINDOW_NAME} onFilterChange={onBankClientFilterChange}>
        <Filters>
          <div className="row aibl" style={{position: "absolute", top: "2.8em", left: 20, width: "50px"}}>
            <TextInput name="trId" placeholder={t("tr.id")} width="5.5rem" />
            <FunctionButton name="Get data" onClick={onGetDataBtnClick} autoWidth />
            <input
              type="file"
              style={{display: "none"}}
              webkitdirectory="true"
              ref={folderPickerRef}
              multiple
              onChange={handleFolderUpload}
            />
          </div>

          <div className="fcCol w100 gap0_5">
            <div className="row w100">
              <Select options={bankOptions} label={t("Bank")} name="bankId" autolabel />
              <TextInput label={t("Company")} name="companyName" autolabel />
              <TextInput name="clientNumber" label={t("Client №")} autolabel />
              <MoneyInput name={"sum"} label={t("Sum")} />
              <Select options={currencyOptions} label={t("Currency")} name="currencyId" autolabel />
              <DateInput label={t("From")} name="startDate" />
            </div>
            <div className="row w100 pl7x">
              <TextInput name="accountNumber" label={t("Acc. №")} autolabel />
              <TextInput name="prName" label={t("Name (P./R.)")} autolabel />
              <TextInput name="requisites" label={t("EDRPOU / TIN (P./R.)")} autolabel />
              <TextInput
                name="purpose"
                label={t("Purpose")}
                autolabel
                generalClassName="fGrow"
                className="fGrow"
                placeholder={`...${t("payment for")}`}
              />
              <DateInput label={t("To")} name="finalDate" />
            </div>
          </div>
        </Filters>
        <Divider />
        <div className="fcCol gap fGrow w100">
          <ReactTable
            columns={bankClientTableColumns}
            defaultData={bankClientTableData}
            //   current={currentDirectiveId}
            //   onSelect={onProjectDirectiveTableSelect}
            //   onRowDoubleClick={onDoubleClick}
            className="tableTac fGrow scrollX"
            tableClassName="fGrow"
            loading={loading}
          />
          <BankClientButtonRow />
        </div>
      </SessionFiltersContainer>
    </div>
  );
};

export default withTabs({isClosableTabs: true, label: "Bank operation register"})(BankClient);
