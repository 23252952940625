import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {selectAllUsers} from "../../../slices/PersonSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import {makeTableNameFromPersona} from "../../../utilities/makeNameFromPersona";
import {selectDirective} from "../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchProjectById, getCurrentProject} from "../ProjectSlice";

import {ORDER_VIEW_TABLE_COLUMNS, PROJECT_VIEW_TABLE_COLUMNS} from "./constants";

const useProjectView = () => {
  const {
    i18n: {language},
  } = useTranslation();
  const {setValues} = useFormikContext();
  const dispatch = useDispatch();

  const directive = useSelector(selectDirective);
  const users = useSelector(selectAllUsers);
  const project = useSelector(getCurrentProject);
  const projectId = project?.projectId;

  const projectManager = project?.projectManager;
  const managerCO = users?.find(user => user.userId === directive?.co?.managerCoId);

  useEffect(() => {
    if (directive?.directiveId) {
      dispatch(fetchProjectById(directive?.co?.project?.projectId));
    }
  }, [directive?.directiveId]);

  useEffect(() => {
    if (project && projectId) {
      const {projectNumber, butget, client} = project;

      setValues({projectNumber, projectBalance: butget, clientBalance: client.butget});
    }
  }, [projectId]);

  const isLoading = false;

  const projectTableColumns = useColumns(PROJECT_VIEW_TABLE_COLUMNS);
  const projectTableData =
    directive && project
      ? [
          {
            id: projectId,
            number: 1,
            dateFrom: dateFormat(project.createdAt),
            dateTo: "-",
            coNumber: directive.co.coNumber,
            coDescription: directive.co.note,
            coSum: directive.co.sum,
            sumN: directive.sumPlan,
            sumF: directive.sumFact,
            percentage: "-",
            approxSum: "-",
            dateToS: "-",
            managerP: makeTableNameFromPersona({persona: projectManager?.persona, language}),
            managerCO: makeTableNameFromPersona({persona: managerCO?.persona, language}),
          },
        ]
      : [];
  function onProjectTableRowSelect() {}

  // const [currentOrderId, setCurrentOrderId] = useState(null);
  const orderTableColumns = useColumns(ORDER_VIEW_TABLE_COLUMNS);
  const orderTableData = [];
  function onOrderTableRowSelect() {}

  return {
    projectTableColumns,
    projectTableData,
    onProjectTableRowSelect,
    isLoading,
    orderTableColumns,
    orderTableData,
    // currentOrderId,
    onOrderTableRowSelect,
    projectId,
  };
};

export default useProjectView;
