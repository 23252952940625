import AvailabilityStatusCIrcule from "../../../../../utilities/AvailabilityStatusCIrcule";

export const PF_CONTROL_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 40,
    // minWidth: 5,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 40,
    cell: circleColor => (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <AvailabilityStatusCIrcule color={circleColor.getValue()} />
      </div>
    ),
  },
  {
    header: "Pf №",
    accessorKey: "pfNumber",
    // size: 130,
  },
  {
    header: "Co №",
    accessorKey: "coNumber",
    // size: 15,
  },
  {
    header: "Request №",
    accessorKey: "requestNumber",
    // size: 15,
  },
  {
    header: "Manager FEA",
    accessorKey: "managerFEA",
    // size: 15,
  },
]);

export const DISAPPROVE_DETAILS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 15,
  },
  {
    header: "Reason",
    accessorKey: "reason",
    size: 15,
  },
  {
    header: "Description",
    accessorKey: "note",
    alignStart: true,
    size: 15,
  },
]);
