import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {MoneyInput, NumberInput, TextAreaInput, TextInput} from "../../../Inputs";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import CurencySelect from "../../../Project/ProjectDirectiveList/OrderDirectiveView/CurencySelect";
import useOrderDirectiveView from "../../../Project/ProjectDirectiveList/OrderDirectiveView/useOrderDirectiveView";
import Spinner from "../../../spinner/Spinner";

const CashdeskDirectiveOrder = ({orderId}) => {
  const location = useLocation();
  const readOnly = useMemo(() => location?.pathname?.includes("project"), []);
  const {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    currentTransactionId,
    onCreateOrder,
    currentOrderId,
    onRunOrder,
    runOrderBtnDisabled,
    onDeleteOrder,
    isOrderSaved,
    onCompleateOrder,
    isOrderCompleate,
    onTransactionSelect,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrderNumber,
    checkExchangeRate,
    loading,
  } = useOrderDirectiveView(orderId);
  const blockOkBtn = isOrderCompleate || isOrderSaved || upperTableData?.length > 0;
  const fileArray = [];
  return (
    <div className="fcCol h100 w100 pt5 aist">
      {/* {loading && <Spinner />} */}

      <div
        className="grid2ColFirstBigger fGrow pb fShrink maxH100"
        style={{gridTemplateColumns: "minmax(0px, 1fr) 30%"}}
      >
        <div className="fcCol fGrow2 gap">
          <div className={"fc nowrap g1"}>
            <CurencySelect
              name="paymentCurrency"
              title="Payment currency"
              required
              makeEffect={e => checkExchangeRate(e?.value, true)}
              readOnly={blockOkBtn}
            />
            <MoneyInput
              name="paymentSumm"
              width="7em"
              label={t("Payment Sum")}
              required
              disabled={false}
              currency={null}
              placeholder={""}
              readOnly={blockOkBtn}
            />
            <NumberInput
              name="exchangeRate"
              width="6em"
              label={t("exchange rate") + " VAL / EUR:"}
              autolabel
              readOnly={true}
            />
            {!blockOkBtn && (
              <FunctionButton name="Ok" onClick={onRunOrder} disabled={!runOrderBtnDisabled} width={"3em"} />
            )}
          </div>
          <div className=" w100  " style={{border: "1px solid #A3B9D9", padding: "1em"}}>
            <div className="fdr gap jcsb">
              <div className="fdr aic gap " style={{marginRight: "2em"}}>
                <TextInput
                  name="dt"
                  label={t("Dt")}
                  autolabel
                  style={{width: "30em"}}
                  // onBlur={(e, v) => defineBalance(v, "debit")}
                  readOnly={true}
                />
              </div>

              <div className="fdr aic gap" style={{marginLeft: "2em"}}>
                <TextInput
                  name="kt"
                  label={t("Kt")}
                  autolabel
                  style={{width: "30em"}}
                  readOnly={true}
                  // onBlur={(e, v) => defineBalance(v, "credit")}
                />
              </div>
            </div>
            <div className="fdr aic gap">
              <ToolTip title="Dt account name" className="fGrow">
                <TextAreaInput name="debitAccountName" height="2.5em" placeholder={t("Dt account name")} disabled />
              </ToolTip>
              <ToolTip title="Kt account name" className="fGrow">
                <TextAreaInput name="creditAccountName" height="2.5em" placeholder={t("Kt account name")} disabled />
              </ToolTip>
            </div>
            <Divider />
            <div className="fdr aic gap">
              <ToolTip title="Payment purpose" className="fGrow2">
                <TextAreaInput
                  name="paymentPurpose"
                  height="4.5em"
                  placeholder={t("Enter Recognition of payment") + "*"}
                  readOnly={true}
                />
              </ToolTip>
              <ToolTip title="Note (for the whole directive)" className="fGrow">
                <TextAreaInput
                  name="compleateNote"
                  height="4.5em"
                  placeholder={t("Add note")}
                  readOnly={isOrderCompleate}
                />
              </ToolTip>
            </div>
          </div>
          <div className="fGrow w100 mw100 h100">
            <ReactTable
              columns={upperTableColumns}
              defaultData={upperTableData}
              // selectable={false}
              current={currentTransactionId}
              onSelect={onTransactionSelect}
              className="mb scrollX"
            />
          </div>
          {!readOnly && (
            <div className={"fc nowrap  jcfe w100"}>
              <FunctionButton
                name="Create order"
                autoWidth
                disabled={!currentOrderId || upperTableData?.length === 0}
                onClick={onCreateOrder}
              />
            </div>
          )}
          <div className="fGrow w100 mw100 h100">
            <ReactTable
              columns={lowerTableColumns}
              defaultData={lowerTableData}
              current={currentTransactionId}
              onSelect={onTransactionSelect}
              className=" mb scrollX"
            />
          </div>
        </div>
        <div className="kpPreview__box pdfViewerContain w100 h100 fdc " style={{backgroundColor:"transparent"}}>
          <NewPdfViewer fileArray={fileArray ?? []} />
          <div className="row jcsb w100 mt">
            <FunctionButton
              onClick={onCompleateOrder}
              name="Run order"
              autoWidth
              disabled={!isOrderSaved}
              tooltipMessage={isOrderSaved ? null : "Order must be saved"}
            />
            <FunctionButton onClick={() => {}} name="Exit" autoWidth />
            <FunctionButton
              onClick={onPrintOrderBtnClick}
              name="Print an order"
              autoWidth
              disabled={!isOrderCompleate}
              tooltipMessage={isOrderCompleate ? null : "Order must be saved and completed"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashdeskDirectiveOrder;
