import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import {addNewLead, clearLead, selectLead} from "../../../slices/PersonSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {isValidEmail} from "../../../utilities/constants";
import {toaster} from "../../../utilities/hooks/toaster";
import {TextAreaInput, TextInput} from "../../Inputs";
import PhoneInput from "../../Inputs/PhoneInput";

const AddLeadModal = ({setModalActive}) => {
  const dispatch = useDispatch();
  const {initialValues, values, setValues} = useFormikContext();

  const lead = useSelector(selectLead);

  useEffect(() => {
    dispatch(clearLead());
  }, []);

  const onCreateLeadBtnClick = () => {
    const {firstName, lastName, patronym, telephone, email, note} = values;
    if (email && !email?.match(isValidEmail)) {
      toaster.error("Email is not valid");
      return;
    }
    dispatch(addNewLead({firstName, lastName, patronym, telephone, email, note})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };
  const onCancelBtnClick = () => {
    setValues(initialValues);
    dispatch(clearLead());
  };

  return (
    <div className="fcCol gap jcsa aic fGrow pt">
      <div className="fcCol gap aistr" style={{width: "25rem"}}>
        <TextInput name="firstName" label={t("Name")} autolabel required />
        <TextInput name="patronym" label={t("Patronymic Name")} autolabel generalClassName="fGrow" className="fGrow" />
        <TextInput name="lastName" label={t("Last Name")} autolabel />
      </div>
      <div className="fcCol gap aistr" style={{width: "25rem"}}>
        <PhoneInput label={t("Phone")} name="telephone" style={{flexGrow: 1}} />
        <TextInput
          type="email"
          name="email"
          label="email"
          autolabel
          onBlur={event => {
            if (!event.target.value.match(isValidEmail)) {
              toaster.error("Email is not valid");
              return;
            }
          }}
        />
        <TextAreaInput name="note" label={t("Notes on Lead")} />
      </div>
      <div className="row gap3x">
        <FunctionButton
          name="Create Lead"
          onClick={onCreateLeadBtnClick}
          autoWidth
          disabled={!values.firstName || !!lead}
        />
        <FunctionButton name="Cancel" onClick={onCancelBtnClick} autoWidth />
      </div>
    </div>
  );
};

export default withFormik({enableReinitialize: true})(AddLeadModal);
