import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {fetchClients, getClients} from "../../../../../slices/PersonSlice";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {dateFormat} from "../../../../../utilities/dateFormat";
import {swalCustom} from "../../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../../utilities/hooks/toaster";
import ReactTable from "../../../../conteiners/ReactTable";
import {Select} from "../../../../Inputs";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectBankTransaction} from "../../../FinBlockSlice";
import {
  addNewStatementDirective,
  cancelDirective,
  chooseDirectiveForStatement,
  deleteOrder,
  fetchAllDirectives,
  selectAllCurrency,
  selectAllDirectives,
} from "../../../FinSettings/FinSettingsSlice";

import {CLIENT_ORDERS_TABLE_COLUMNS} from "./constants";

const ClientOrderModal = ({setModalActive}) => {
  const dispatch = useDispatch();
  const {values, setFieldValue} = useFormikContext();

  const directives = useSelector(selectAllDirectives);
  const allCurrency = useSelector(selectAllCurrency);
  const bankTransaction = useSelector(selectBankTransaction);
  const clientId =
    bankTransaction?.counterpartyAccount?.owner?.clientId ||
    bankTransaction?.creditAccount?.owner?.clientId ||
    bankTransaction?.debitAccount?.owner?.clientId;
  const transactionId = bankTransaction?.transactionId;
  const clients = useSelector(getClients);

  useEffect(() => {
    if (clients?.length < 1) {
      dispatch(fetchClients());
    }
  }, []);

  const clientNumberOptions =
    clients?.map(({clientId, clientNumber}) => ({title: clientNumber, value: clientId})) ?? [];

  const [currentDirectiveId, setCurrentDirectiveId] = useState(null);
  const currentDirective = directives?.find(({directiveId}) => directiveId === currentDirectiveId);
  const isDirectiveWithNonFilledOrder =
    currentDirective?.orders?.length < 2 && currentDirective?.orders[0]?.status?.statusId < 2;

  const loading = false;
  const clientOrdersTableColumns = useColumns(CLIENT_ORDERS_TABLE_COLUMNS);
  const notCompletedDirectives = directives?.filter(({statusId}) => statusId === 2 || statusId === 3 || statusId === 5);
  const clientOrdersTableData =
    notCompletedDirectives?.map(
      ({directiveId, directiveNumber, sumPlan, sumFact, note, createdAt, currencyId}, index) => ({
        id: directiveId,
        number: ++index,
        date: dateFormat(createdAt),
        directiveNumber,
        sumPlan,
        sumFact,
        currency: allCurrency?.find(currency => currencyId === currency.currencyId)?.shortName ?? "",
        note,
      }),
    ) ?? [];

  useEffect(() => {
    return () => {
      setFieldValue("anotherClientId", null);
    };
  }, []);

  const onOpenBtnClick = () => {
    if (!currentDirectiveId || !bankTransaction) return;

    dispatch(chooseDirectiveForStatement({directiveId: currentDirectiveId, transactionId})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };

  const onCreateNewOrderBtnClick = ({anotherClientId}) => {
    if (!bankTransaction) return;

    dispatch(
      addNewStatementDirective({clientId: anotherClientId || clientId, transactionId: bankTransaction?.transactionId}),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        setModalActive(false);
      }
    });
  };

  const onCancelBtnClick = () => {
    setModalActive(false);
  };

  const onDeleteBtnClick = () => {
    if (!currentDirectiveId) return;

    swalCustom.confirm({
      confirmFunc: () => {
        if (!currentDirective?.orders) {
          dispatch(cancelDirective(currentDirectiveId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              toaster.success(t("directiveDeleted"));
              dispatch(
                fetchAllDirectives({
                  clientId:
                    bankTransaction?.counterpartyAccount?.owner?.clientId ||
                    bankTransaction?.creditAccount?.owner?.clientId,
                }),
              );
              setCurrentDirectiveId(null);
            }
          });
          return;
        }

        if (isDirectiveWithNonFilledOrder) {
          dispatch(deleteOrder(currentDirective?.orders[0]?.orderId)).then(res => {
            if (res.meta.requestStatus === "fulfilled") {
              dispatch(cancelDirective(currentDirectiveId)).then(resp => {
                if (resp.meta.requestStatus === "fulfilled") {
                  toaster.success(t("directiveDeleted"));
                  dispatch(
                    fetchAllDirectives({
                      clientId:
                        bankTransaction?.counterpartyAccount?.owner?.clientId ||
                        bankTransaction?.creditAccount?.owner?.clientId,
                    }),
                  );
                  setCurrentDirectiveId(null);
                }
              });
            }
          });
        }
      },
    });
  };

  const onClientOrdersTableRowSelect = id => {
    setCurrentDirectiveId(id);
  };

  return (
    <div className="fcCol jcsb fGrow padding gap2x">
      <div className="bold tac" style={{fontSize: "20px", width: "40%", lineHeight: "25px"}}>
        {notCompletedDirectives?.length > 0 ? t("orderHasAlreadyBeenCreated") : t("noCompletedDirectivesForClient")}
      </div>
      <ReactTable
        columns={clientOrdersTableColumns}
        defaultData={clientOrdersTableData}
        current={currentDirectiveId}
        onSelect={onClientOrdersTableRowSelect}
        className="tableTac fGrow scrollX"
        tableClassName="fGrow"
        loading={loading}
        style={{height: "10em", minHeight: "5em"}}
      />

      <div className="row w100 jcsa">
        <FunctionButton name="Yes, open!" autoWidth onClick={onOpenBtnClick} disabled={!currentDirectiveId} />
        <FunctionButton name="Create new " autoWidth onClick={onCreateNewOrderBtnClick} />
        <FunctionButton
          name="Delete"
          autoWidth
          onClick={onDeleteBtnClick}
          disabled={!currentDirectiveId || !isDirectiveWithNonFilledOrder}
          tooltipMessage={
            currentDirectiveId && !isDirectiveWithNonFilledOrder
              ? "Directive has filled orders and can't be deleted"
              : "Delete selected directive"
          }
        />
      </div>
      <div className="bold tac" style={{fontSize: "20px", width: "40%", lineHeight: "25px"}}>
        {t("createForAnotherClient")}
      </div>

      <div className="row gap w100">
        <Select label="Client №" name="anotherClientId" options={clientNumberOptions} optionsAbove />
        <FunctionButton
          name="Create new for another client"
          autoWidth
          onClick={() => onCreateNewOrderBtnClick({anotherClientId: values?.anotherClientId})}
          disabled={!values?.anotherClientId}
          tooltipMessage={!values?.anotherClientId ? "Select client" : null}
        />
        <FunctionButton name="Cancel" autoWidth onClick={onCancelBtnClick} tooltipMessage="Close modal" />
      </div>
    </div>
  );
};

export default ClientOrderModal;
