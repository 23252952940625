import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux/es/exports";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";

import {apiUrl} from "../../../api/baseURL";
import {fetchData} from "../../../hook/axios.hook";
import logoImg from "../../../images/icons/Logo_big.png";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import makeNameFromPersona from "../../../utilities/makeNameFromPersona";
import AccessBoundary from "../../AccessBoundary/AccessBoundary";
import PageContainer from "../../conteiners/PageContainer";
import ReadOnlyFields from "../../hoc/ReadOnlyFields";
import ControlCheck, {CONTROL_CHECK_TYPES} from "../../Inputs/controlCheck/ControlCheck";
import ModalForm from "../../Modal/FormModal";
import SkuList from "../SkuList";
import {createSkuNumder, deleteSku, deselectSku, dublicateSku, getCurrentSku, getSkuLoading} from "../SkuSlice";

import SkuFilters from "./Filters/SkuFilters";
import SkuPreviewForm from "./Preview/SkuPreview";
import SkuCreateForm from "./SkuVedForm/skuCreate/SkuCreateForm";
import SkuDuplicateForm from "./SkuVedForm/skuUpdate/SkuDuplicateForm";
import SkuUpdateForm from "./SkuVedForm/skuUpdate/SkuUpdateForm";
import SkuVedForm from "./SkuVedForm/SkuVedForm";

import "../sku.scss";

const SkuVed = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [formType, setFormType] = useState("CREATE");

  const dispatch = useDispatch();

  const types = useMemo(() => {
    return {
      create: "CREATE",
      update: "UPDATE",
      dublicate: "DUBLICATE",
    };
  }, []);
  useEffect(() => {
    fetchData(`/references/measurementQuantities/?field=area`, "get");
  }, []);

  const skuLoadingStatus = useSelector(getSkuLoading);
  const currentSku = useSelector(getCurrentSku);

  const preCreateSku = () => {
    Swal.fire({
      title: t("Create Sku Info"),
      text: ``,
      imageUrl: logoImg,
      imageWidth: 300,
      imageHeight: 200,
      showCancelButton: true,
      confirmButtonText: t("Creation confirm"),
      cancelButtonText: t("Cancel"),
    }).then(res => {
      if (res.isConfirmed) {
        dispatch(deselectSku());
        setFormType(types.create);
        dispatch(createSkuNumder()).then(() => {
          setShowModal(true);
        });
      }
    });
  };

  const onDublicateSku = () => {
    Swal.fire({
      title: t("Dublicate Sku info", {skuId: currentSku.article}),
      text: ``,
      imageUrl: currentSku?.images?.length > 0 ? `${apiUrl}/${currentSku.images[0]}` : logoImg,
      imageWidth: 300,
      imageHeight: 200,
      showCancelButton: true,
      confirmButtonText: t("Dublicate sku confirm"),
      cancelButtonText: t("Cancel"),
    }).then(res => {
      if (res.isConfirmed) {
        setFormType(types.dublicate);
        dispatch(dublicateSku(currentSku.skuId)).then(() => {
          setShowModal(true);
        });
      }
    });
  };

  const onEdit = () => {
    setFormType("UPDATE");
    setShowModal(true);
  };

  const formComponent = useMemo(() => {
    switch (formType) {
      case types.create:
        return SkuCreateForm;
      case types.update:
        return SkuUpdateForm;
      case types.dublicate:
        return SkuDuplicateForm;
      default:
        return SkuVedForm;
    }
  }, [formType]);

  const onDelete = () => {
    Swal.fire({
      title: "Точно видалити?",
      text: ``,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("No"),
    }).then(res => {
      if (res.isConfirmed) {
        dispatch(deleteSku(currentSku.skuId));
      }
    });
  };

  return (
    <PageContainer title={`SKU `} loading={!skuLoadingStatus === "complete"}>
      <div className="sku">
        <div className="project_form_wrapper">
          <div className="sku__top">
            <SkuFilters />
            <Divider />
            <SkuPreviewForm />
          </div>
          {/* <div style={{height: "90%", marginBottom: "0.5em"}}> */}
          <SkuList />
          {/* </div> */}
        </div>
        <div className="sku_buttons flex_container space-between nowrap gap2x " style={{width: "98%"}}>
          <Formik
            enableReinitialize
            initialValues={{
              skuControlBy: makeNameFromPersona({persona: currentSku?.creator?.persona, language}),
              skuControlDate: currentSku?.createdAt ?? "",
              skuChangedBy: makeNameFromPersona({persona: currentSku?.modifier?.persona, language}),
              skuChangeDate: currentSku?.updatedAt ?? "",
            }}
          >
            <Form className="row jcsb aibl gap w100">
              <ControlCheck name="skuControlBy" date="skuControlDate" initialChecked={false} />
              <ControlCheck
                name="skuChangedBy"
                date="skuChangeDate"
                initialChecked={false}
                type={CONTROL_CHECK_TYPES.CHANGED_BY}
              />
            </Form>
          </Formik>
          <div className="row">
            <AccessBoundary>
              <FunctionButton name={"Delete"} onClick={onDelete} disabled={!currentSku} />
            </AccessBoundary>
            <FunctionButton name={t("Create SKU")} onClick={preCreateSku} />
            <FunctionButton name={t("Edit SKU")} onClick={onEdit} disabled={!currentSku} />
            <FunctionButton name={t("Dublicate SKU")} onClick={onDublicateSku} disabled={!currentSku} />
            <FunctionButton name={"UnKit SKU"} disabled={true} />
          </div>
        </div>
      </div>
      <ModalForm
        active={showModal}
        Component={formComponent}
        setModalActive={setShowModal}
        label={formType === types.create ? t("Create SKU") : t("Edit SKU")}
        typeForm={formType}
      />
    </PageContainer>
  );
};

export default SkuVed;
