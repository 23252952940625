import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {Filters} from "../../../../conteiners/filters/Filter.hoc";
import {useFiltersContext} from "../../../../conteiners/filters/useSessionFiltersContext";
import HiddenContainer from "../../../../conteiners/hiddenContainer/HiddenContainer";
import {DateInput, MoneyInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";
import {fetchAllBankTransactions, fetchBankClientFilters, selectBankClientFilters} from "../../../FinBlockSlice";

const BankOperationFilters = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(selectBankClientFilters);
  const {filters: savedFilters = {}} = useFiltersContext();

  //!no clientNumber and operationTypes options in filters from backend
  const clientNumberOptions =
    filters?.clients?.map(({clientId, clientNumber}) => ({title: clientNumber, value: clientId})) ?? [];
  const operationTypeOptions = filters?.operationTypes?.map(bank => ({title: bank, value: bank})) ?? [];
  const currencyOptions =
    filters?.currencies?.map(({currencyId, shortName}) => ({title: shortName, value: currencyId})) ?? [];

  useEffect(() => {
    dispatch(fetchAllBankTransactions({isResidues: true, ...savedFilters}));
    dispatch(fetchBankClientFilters());
  }, []);

  return (
    <HiddenContainer tooltipMessageShow={"Show filters"} tooltipMessageHide={"Hide filters"} window="BANK_CLIENT">
      <Filters>
        <div className="row aibl" style={{position: "absolute", top: "3.3em", left: 15, width: "50px"}}>
          <TextInput
            label="trId"
            name="transactionUuid"
            placeholder="tr.id"
            width="5.5rem"
            labelStyles={{display: "none"}}
          />
        </div>

        <div className="w100 mb fcCol aistr gap">
          <div className="w100 fc nowrap jcsb">
            <Select name={"clientId"} label={t("Client №")} options={clientNumberOptions} />
            <Select name={"operationType"} label={t("Operation type")} options={operationTypeOptions} disabled />
            <MoneyInput name={"operationSum"} label={t("Sum")} />
            <Select name={"operationCurrencyId"} label={t("Operation currency")} options={currencyOptions} />
            <DateInput name={"dateStart"} label={t("Period From")} />
          </div>
          <div className="w100 fc nowrap jcsb">
            <TextInput name={"counterparty"} label={t("Name (P./R.)")} />
            <TextInput name={"ipn"} label={t("EDRPOU / ITN (P./R.)")} />
            <TextInput
              name={"paymentPurpose"}
              label={t("Purpose")}
              style={{width: "50em"}}
              placeholder={`...${t("payment for")}`}
            />
            <DateInput name={"dateEnd"} label={t("To")} />
          </div>
        </div>
      </Filters>
    </HiddenContainer>
  );
};

export default BankOperationFilters;
