import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {StatusRectangleBtn} from "../../../utilities/Buttons/StatusFunctionButton/StatusRectangleBtn";
import {STATUS_COLORS} from "../../../utilities/colorConstants";
import PageContainer from "../../conteiners/PageContainer";
import ModalForm from "../../Modal/FormModal";
import FullRequestFiltersForm from "../Filters/filtersVariety/FullRequestFilter";
import withRequestWithFilter from "../hoc/RequestWithFilterHOC";
import InProgressSector from "../RequestsInProgress/InProgressSector";
import {REQ_WINDOW_TYPES} from "../RequestsInProgress/utils/functions/requestsFiltersManager";
import useRequests from "../RequestsInProgress/utils/hooks/useRequests";
import {
  cancelAllAppointmentRequests,
  fetchRequests,
  getAppointRequests,
  getFirstStatusRequest,
  getLoadingRequest,
} from "../RequestsSlice";

import OrderingModal from "./ordering/Ordering";
import RequestTable from "./RequestTable/RequestTable";

import "./requestRegister.scss";

const RequestRegisterBase = ({updateFetch}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [orderingModalActive, setOrderingModalActive] = useState(false);
  const [buttonColor, setButtonColor] = useState(STATUS_COLORS.GREEN);

  const requestLoading = useSelector(getLoadingRequest),
    appointRequests = useSelector(getAppointRequests),
    firstSatusRequests = useSelector(getFirstStatusRequest);

  const {tableData, onRequestSelect} = useRequests();

  useEffect(() => {
    dispatch(fetchRequests({window: REQ_WINDOW_TYPES.INCOMING, showidenticalSku: true}));
  }, []);

  useEffect(() => {
    if (firstSatusRequests.length > 0) {
      setButtonColor(STATUS_COLORS.RED);
    } else {
      setButtonColor(STATUS_COLORS.GREEN);
    }
  }, [firstSatusRequests]);

  const onModalClose = useCallback(() => {
    if (appointRequests.length > 0) {
      dispatch(cancelAllAppointmentRequests()).then(() => {
        updateFetch();
        dispatch(fetchRequests({window: REQ_WINDOW_TYPES.INCOMING, showidenticalSku: true}));
      });
    }

    if (appointRequests.length === 0) {
      updateFetch();
      dispatch(fetchRequests({window: REQ_WINDOW_TYPES.INCOMING, showidenticalSku: true}));
    }
  }, [appointRequests]);

  return (
    <PageContainer title={t("Request register")}>
      <div className="form_wrapper">
        <div
          style={{
            paddingBottom: "1em",
            position: "relative",
          }}
        >
          <FullRequestFiltersForm window={REQ_WINDOW_TYPES.REGISTER} />
          <div style={{position: "absolute", top: "3.4em", left: "0.3em"}}>
            <StatusRectangleBtn
              onClick={() => {
                setOrderingModalActive(true);
              }}
              color={buttonColor}
            />
          </div>
        </div>
        <RequestTable
          defaultData={tableData}
          orderingModalActive={orderingModalActive}
          requestLoading={requestLoading}
          onSelect={onRequestSelect}
        />
        <InProgressSector />
      </div>

      <ModalForm
        closable={true}
        Component={OrderingModal}
        label={t("Order distribution")}
        active={orderingModalActive}
        setModalActive={setOrderingModalActive}
        onModalClose={onModalClose}
        modalInnerWrapperClassName="fcCol"
      />
    </PageContainer>
  );
};

const RequestRegister = withRequestWithFilter(RequestRegisterBase, REQ_WINDOW_TYPES.REGISTER);

export default RequestRegister;
