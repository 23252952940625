import React from "react";
import {t} from "i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../../../Inputs";

const DirectiveCreation = ({onCreateDirectiveBtnClick = () => {}, noCounterpartyAssociation}) => {
  return (
    <div className="border padding0_5 pa l0 t0 row gap fitContentWidth aist">
      <div className="row aic gap2x">
        <div className="header fitContentWidth ">{`${!noCounterpartyAssociation ? "2. " : ""}${t(
          "Crediting to the Client's balance",
        )}`}</div>
        <TextInput name="clientNumber" label="№" autolabel width="7rem" />
      </div>

      <FunctionButton
        name="Create directive"
        onClick={onCreateDirectiveBtnClick}
        width="9rem"
        style={{width: "9rem", height: "3rem", textAlign: "center"}}
        className="br"
      />
    </div>
  );
};

export default DirectiveCreation;
