import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {fetchAllCurrencies, selectAllCurrency} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {Select} from "../../../Inputs/SelectField";

const CurencySelect = ({name = "currency", title, ...props}) => {
  const currencies = useSelector(selectAllCurrency);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currencies?.length === 0) {
      dispatch(fetchAllCurrencies());
    }
  }, []);

  const options = useMemo(() => {
    if (currencies?.length < 1) {
      return [];
    }
    const hightPriorityId = [978, 840, 980];
    const sortedCurr = [...currencies]?.sort((a, b) => {
      const aPriority = hightPriorityId.includes(a.currencyId) ? hightPriorityId.indexOf(a.currencyId) : Infinity;
      const bPriority = hightPriorityId.includes(b.currencyId) ? hightPriorityId.indexOf(b.currencyId) : Infinity;
      return aPriority - bPriority;
    });
    return sortedCurr?.map(curr => ({title: curr.shortName, value: curr.currencyId})) || [];
  }, [currencies]);

  return <Select label={t(title)} options={options} name={name} width="5em" minFilterLength={1} {...props} />;
};

export default CurencySelect;
