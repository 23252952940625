import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../../hook/axios.hook";

//get
export const fetchAllStockPoolsNew = createAsyncThunk("logist/stockPools/fetchAllStockPools", async params =>
  fetchList(`/stockPools/getAll`, "get", {params}),
);

//!Outdated endpoint
export const fetchAllStockPoolsUpdated = createAsyncThunk("logist/fetchAllStockPoolsUpdated", async params =>
  fetchList(`/stockPools/getAllUpdate`, "get", {params}),
);
export const fetchStockPoolBySkuId = createAsyncThunk("logist/fetchStockPoolBySkuId", async skuRequestId =>
  fetchData(`/stockPools/get/${skuRequestId}`, "get"),
);

export const fetchStockPoolUpdated = createAsyncThunk("logist/fetchStockPoolUpdated", async ({stockPoolId}) =>
  fetchData(`stockPools/getUpdate/${stockPoolId}`, "get"),
);
export const fetchStockPoolFilters = createAsyncThunk("logist/fetchStockPoolFilters", async params =>
  fetchList(`/stockPools/getFilters`, "get", {params}),
);
// export const fetchStockPoolFiltersUpdated = createAsyncThunk("logist/fetchStockPoolFiltersUpdated", async params =>
//   fetchList(`/stockPools/getFiltersUpdate/`, "get", {params}),
// );

//*** new ***
export const fetchRequestsForLogistics = createAsyncThunk("logist/requests/fetchForLogistics", async params =>
  fetchList(`/requests/getForLogistic/`, "get", {params}),
);
export const fetchStockPoolBySkuRequestId = createAsyncThunk(
  "logist/stockPools/fetchStockPoolBySkuRequestId",
  async skuRequestId => fetchList(`/stockPools/get/${skuRequestId}`, "get"),
);
export const fetchFiltersForLogistics = createAsyncThunk("logistic/requests/getFilters", async params =>
  fetchList(`requests/logistic/getFilters`, "get", {params}),
);

//post
export const addNewStockPool = createAsyncThunk("logist/addNewStockPool", async ({warehouseId, params}) =>
  fetchData(`/stockPools/create/${warehouseId}`, "post", {...params}),
);

//patch
export const updateStockPool = createAsyncThunk("logist/updateStockPool", async ({stockPoolId, params}) =>
  fetchData(`/stockPools/update/${stockPoolId}`, "patch", params),
);

//put
export const addToStockPool = createAsyncThunk("logist/addToStockPool", async ({stockPoolId, params}) =>
  fetchData(`/stockPools/addToSP/${stockPoolId}`, "put", {...params}),
);
export const returnToStock = createAsyncThunk("logist/returnToStock", async params =>
  fetchData(`/stockPools/returnToStock`, "put", {...params}),
);
export const sendToLogistics = createAsyncThunk("logist/sendToLogistics", async stockPoolId =>
  fetchData(`/stockPools/sendToLogistics/${stockPoolId}`, "put"),
);
export const appointStockPool = createAsyncThunk("logist/appointStockPool", async ({managerLogistId, params}) =>
  fetchData(`/stockPools/appoint/${managerLogistId}`, "put", {...params}),
);
export const cancelStockPoolAppointment = createAsyncThunk("logist/cancelStockPoolAppointment", async stockPoolId =>
  fetchData(`/stockPools/cancelAppointment/${stockPoolId}`, "put"),
);
export const cancelAllStockPoolAppointments = createAsyncThunk("logist/cancelAllStockPoolAppointments", async () =>
  fetchData(`/stockPools/cancelAllAppointments`, "put"),
);
export const distributeStockPools = createAsyncThunk("logist/distributeStockPools", async () =>
  fetchData(`/stockPools/distribute`, "put"),
);

//delete
export const disbandStockPool = createAsyncThunk("logist/appointStockPool", async managerLogistId =>
  fetchData(`/stockPools/appoint/${managerLogistId}`, "put"),
);

////addToCargo
export const addSPToCargo = createAsyncThunk("logist/addSPToCargo", async ({cargoId, stockPoolIds}) =>
  fetchData(`/stockPools/addToCargo/${cargoId}`, "put", {stockPoolIds}),
);
/////Remove from Cargo
export const removeFromCargo = createAsyncThunk("logist/removeFromCargo", async stockPoolIds =>
  fetchData(`/stockPools/removeFromCargo`, "put", {stockPoolIds}),
);

//*** Cargos ***/
//get
// export const fetchAllCargos = createAsyncThunk("logist/fetchAllCargos", async factoryId =>
//   fetchList(`/cargos/getAll?factoryId=${factoryId}`, "get"),
// );

export const fetchAllCargos = createAsyncThunk("logist/fetchAllCargos", async params =>
  fetchList(`/cargos/getAll/`, "get", {params}),
);
export const fetchCargo = createAsyncThunk("logist/fetchCargo", async cargoId =>
  fetchData(`/cargos/get/${cargoId}`, "get"),
);
//post
export const addNewCargo = createAsyncThunk("logist/addNewCargo", async params =>
  fetchData(`/cargos/add`, "post", params),
);

//patch
export const takeTpToWork = createAsyncThunk("logist/takeTpToWork", async cargoId =>
  fetchList(`cargos/takeToWork/${cargoId}`, "patch"),
);

export const sendTP = createAsyncThunk("logist/sendTP", async ({cargoId, data}) =>
  fetchList(`/cargos/send/${cargoId}`, "patch", data),
);

export const acceptCargo = createAsyncThunk("logist/acceptCargo", async ({cargoId, warehouseId}) =>
  fetchList(`/cargos/accept/${cargoId}/${warehouseId}`, "patch"),
);

export const updateCargo = createAsyncThunk("logist/updateCargo", async ({cargoId, data}) =>
  fetchList(`/cargos/update/${cargoId}`, "patch", data),
);

//*** Cargos payments docs */

//*** Warehouses ***/

export const fetchAllWarehouses = createAsyncThunk("logist/fetchAllWarehouses", async params =>
  fetchList(`/warehouses/getAll`, "get", {params}),
);

export const addWarehouse = createAsyncThunk("logist/addWarehouse", async ({typeId, data}) =>
  fetchList(`/warehouses/add/${typeId}`, "post", data),
);
export const addTemporaryPlace = createAsyncThunk("logist/addWarehouse", async data =>
  fetchList(`/warehouses/addTemporary`, "post", data),
);

export const updateWarehouse = createAsyncThunk("logist/updateWarehouse", async ({warehouseId, data}) =>
  fetchList(`/warehouses/update/${warehouseId}`, "patch", data),
);

export const fetchWarehouseInfo = createAsyncThunk("logist/getWarehouseInfo", async warehouseId =>
  fetchList(`/warehouses/get/${warehouseId}`, "get"),
);

export const deleteWarehouse = createAsyncThunk("logist/deleteWarehouse", async warehouseId =>
  fetchList(`/warehouses/remove/${warehouseId}`, "delete"),
);

//* new
export const fetchWarehousesFilters = createAsyncThunk("logSettings/getWarehousesFilters", async params =>
  fetchList(`/warehouses/getFilters`, "get", params),
);

//** Warehouse Types **/
//get
export const fetchAllWarehouseTypes = createAsyncThunk("logist/fetchAllWarehouseTypes", async () =>
  fetchList(`/warehouses/types/getAll`, "get"),
);
//post
export const addNewWarehouseType = createAsyncThunk("logist/addNewWarehouseType", async params =>
  fetchData(`/warehouses/types/add`, "post", params),
);
//delete
export const deleteWarehouseType = createAsyncThunk("logist/deleteWarehouseType", async typeId =>
  fetchData(`/warehouses/types/remove/${typeId}`, "delete"),
);

//** Warehouse Contacts **/
//post
export const addNewWarehouseContact = createAsyncThunk("logist/addNewWarehouseContact", async ({warehouseId, params}) =>
  fetchData(`/warehouses/contacts/add/${warehouseId}`, "post", params),
);
//delete
export const deleteWarehouseContact = createAsyncThunk("logist/deleteWarehouseContact", async contactId =>
  fetchData(`/warehouses/contacts/remove/${contactId}`, "delete"),
);

//** Warehouse Accounts **/
//post
export const addNewWarehouseAccount = createAsyncThunk("logist/addNewWarehouseAccount", async ({warehouseId, params}) =>
  fetchData(`/warehouses/accounts/add/${warehouseId}`, "post", params),
);
//delete
export const deleteWarehouseAccount = createAsyncThunk("logist/deleteWarehouseAccount", async accountId =>
  fetchData(`/warehouses/accounts/remove/${accountId}`, "delete"),
);

//** Warehouse Docs **/
//post
export const addNewWarehouseDoc = createAsyncThunk("logist/addNewWarehouseDoc", async ({warehouseId, params}) =>
  fetchData(`/warehouses/docs/add/${warehouseId}`, "post", params),
);
//patch
export const updateWarehouseDoc = createAsyncThunk("logist/updateWarehouseDoc", async ({docId, params}) =>
  fetchData(`/warehouses/docs/update/${docId}`, "patch", params),
);
//delete
export const deleteWarehouseDoc = createAsyncThunk("logist/deleteWarehouseDoc", async docId =>
  fetchData(`/warehouses/docs/remove/${docId}`, "delete"),
);
//* Warehouse Docs Files */
//post
export const addNewWarehouseDocFile = createAsyncThunk("logist/addNewWb arehouseDocFile", async ({docId, doc}) =>
  fetchData(`/warehouses/docs/files/add/${docId}`, "post", doc),
);
//delete
export const deleteWarehouseDocFile = createAsyncThunk("logist/deleteWarehouseDocFile", async fileId =>
  fetchData(`/warehouses/docs/files/remove/${fileId}`, "delete"),
);

//***Carriers***/

export const fetchCarrierFilters = createAsyncThunk("logSettings/fetchCarrierFilters", async () =>
  fetchList(`/carriers/getFilters`, "get"),
);

export const fetchAllCarriers = createAsyncThunk("logist/fetchAllCarriers", async () =>
  fetchList(`/carriers/getAll`, "get"),
);

export const getCarrier = createAsyncThunk("logist/getCarrier", async carrierId =>
  fetchList(`/carriers/get/${carrierId}`, "get"),
);
export const addNewCarrier = createAsyncThunk("logist/addNewCarrier", async ({typeId, data}) =>
  fetchData(`/carriers/add/${typeId}`, "post", data),
);
export const updateCarrier = createAsyncThunk("logist/updateCarrier", async ({carrierId, data}) =>
  fetchData(`/carriers/add/${carrierId}`, "patch", data),
);
export const deleteCarrier = createAsyncThunk("logist/deleteCarrier", async carrierId =>
  fetchData(`/carriers/remove/${carrierId}`, "delete"),
);

// export const fetchAllCarrierDocs = createAsyncThunk("logist/fetchAllCarrierDocs", async cargoId =>
//   fetchData(`cargos/docs/getAll/${cargoId}`, "get"),
// );
// export const fetchAllCarrierDocs = createAsyncThunk("logist/fetchAllCarrierDocs", async cargoId =>
//   fetchData(`cargos/docs/getAll?cargoId=${cargoId}`, "get"),
// );
export const fetchAllCarrierDocs = createAsyncThunk("logist/fetchAllCarrierDocs", async () =>
  fetchData(`cargos/docs/getAll`, "get"),
);

export const fetchAllCarrierDocTypes = createAsyncThunk("logist/fetchAllCarrierDocTypes", async () =>
  fetchData(`cargos/docs/getAllTypes`, "get"),
);
//** Carrier Types **/
//get
export const fetchAllCarrierTypes = createAsyncThunk("logist/fetchAllCarrierTypes", async () =>
  fetchList(`/carriers/types/getAll`, "get"),
);
//post
export const addNewCarrierType = createAsyncThunk("logist/addNewCarrierType", async params =>
  fetchData(`/carriers/types/add`, "post", params),
);
//delete
export const deleteCarrierType = createAsyncThunk("logist/deleteCarrierType", async typeId =>
  fetchData(`/carriers/types/remove/${typeId}`, "delete"),
);
//** Carrier Contacts **/
//post
export const addNewCarrierContact = createAsyncThunk("logist/addNewCarrierContact", async ({carrierId, params}) =>
  fetchData(`/carriers/contacts/add/${carrierId}`, "post", params),
);
//delete
export const deleteCarrierContact = createAsyncThunk("logist/deleteCarrierContact", async contactId =>
  fetchData(`/carriers/contacts/remove/${contactId}`, "delete"),
);
//** Carrier Accounts **/
//post
export const addNewCarrierAccount = createAsyncThunk("logist/addNewCarrierAccount", async ({carrierId, params}) =>
  fetchData(`/carriers/accounts/add/${carrierId}`, "post", params),
);
//delete
export const deleteCarrierAccount = createAsyncThunk("logist/deleteCarrierAccount", async accountId =>
  fetchData(`/carriers/accounts/remove/${accountId}`, "delete"),
);
//** Carrier Docs **/
export const addCarrierDoc = createAsyncThunk("logist/addCarrierDoc", async ({carrierId, formData}) =>
  fetchData(`carriers/docs/add/${carrierId}`, "post", formData),
);
export const updateCarrierDoc = createAsyncThunk("logist/updateCarrierDoc", async ({docId, data}) =>
  fetchData(`carriers/docs/update/${docId}`, "patch", data),
);
export const deleteCarrierDoc = createAsyncThunk("logist/deleteCarrierDoc", async docId => {
  fetchData(`carriers/docs/remove/${docId}`, "delete");
});
export const addCarrierDocFile = createAsyncThunk("logist/addCarrierDocFile", async ({docId, doc}) =>
  fetchData(`carriers/docs/files/add/${docId}`, "post", doc),
);
export const deleteCarrierDocFile = createAsyncThunk("logist/deleteCarrierDocFile", async fileId =>
  fetchData(`carriers/docs/files/remove/${fileId}`, "delete"),
);

export const splitDefectedOrder = createAsyncThunk("logist/splitDefectedOrder", async skuRequestId =>
  fetchData(`/orders/split/${skuRequestId}`, "put"),
);

export const deleteSplitedOrder = createAsyncThunk("logist/deleteSplitedOrder", async orderId =>
  fetchData(`orders/remove/${orderId}`, "delete"),
);

export const updateSplitedOrder = createAsyncThunk("logist/updateSplitedOrder", async ({orderId, note}) =>
  fetchData(`orders/update/${orderId}`, "patch", {note}),
);

export const transferToOrder = createAsyncThunk("logist/transferToOrder", async ({orderId, params}) =>
  fetchData(`/skuRequest/transferToOrder/${orderId}`, "put", {...params}),
);

//Orders
export const updateOrder = createAsyncThunk("logist/orders/updateOrder", async ({orderId, data}) =>
  fetchData(`orders/update/${orderId}`, "patch", data),
);
