import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {withFormik} from "formik";
import {t} from "i18next";

import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import PageContainer from "../../conteiners/PageContainer";
import {clearCurrentTicket, fetchAllTickets, selectDevITFilters} from "../../DevIT/DevITSlice";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import DevTicketsListBody from "./DevTicketsLIstBody";

export const DEV_TICKETS_LIST_WINDOW_NAME = "DevTicketsList";

const DevTicketsList = () => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();

  const fetchedFilters = useSelector(selectDevITFilters);

  const typesFiltersOptions =
    fetchedFilters?.types?.map(({typeId, nameEng, nameUkr}) => ({
      value: typeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];

  const managersFiltersOptions =
    fetchedFilters?.creators?.map(({creatorId, name}) => ({
      value: creatorId,
      title: name,
    })) ?? [];

  const blockFiltersOptions =
    fetchedFilters?.blocks?.map(({blockId, nameEng, nameUkr}) => ({
      value: blockId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];

  const officeFiltersOptions = [];

  const onFilterChange = filters => {
    dispatch(clearCurrentTicket());

    if (!filters) {
      dispatch(fetchAllTickets({}));
      return;
    }

    dispatch(fetchAllTickets({params: filters}));
  };

  return (
    <PageContainer title={t("Tasks list")}>
      <SessionFiltersContainer window={DEV_TICKETS_LIST_WINDOW_NAME} onFilterChange={onFilterChange}>
        <Filters>
          <div className="fcCol w100 gap h100 aistr">
            <div className="row gap">
              <Select label={t("Appeal type")} options={typesFiltersOptions} name="typeId" />
              <Select label={t("Manager ")} options={managersFiltersOptions} name="creatorId" />
              <Select label={t("Appeal block")} options={blockFiltersOptions} name="blockId" />
              <DateInput label={t("Period From")} name="dateFrom" />
            </div>
            <div className="row gap">
              <Select label={t("Office ")} options={officeFiltersOptions} name="officeId" disabled />
              <TextInput label={t("Appeal text")} name="text" generalClassName="fGrow" />
              <DateInput label={t("To")} name="dateTo" />
            </div>
          </div>
        </Filters>
        <Divider />
        <DevTicketsListBody />
      </SessionFiltersContainer>
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true, mapPropsToValues: () => ({isDone: false})})(DevTicketsList);
