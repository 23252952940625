import {useTranslation} from "react-i18next";
import {withFormik} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import Divider from "../../../../utilities/Divider/Divider";
import ReactTable from "../../../conteiners/ReactTable";
import {DateInput, TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import NewPdfViewer from "../../../Project/Documents/DocumentView/NewPdfViewer";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {INVOICE_CREATING_TABLE_COLUMNS} from "../constants";

export const CreateInvoiceModalBase = () => {
  const {t} = useTranslation();

  const onOkBtnClick = () => {};

  const onDeleteBtnClick = () => {};
  const onSaveBtnClick = () => {};
  const onPrintBtnClick = () => {};
  const onCreateDirectiveBtnClick = () => {};

  const columns = useColumns(INVOICE_CREATING_TABLE_COLUMNS);

  return (
    <div className="pt">
      <div className="fdr">
        <div className="mr" style={{width: "70%"}}>
          <div className="fdr">
            <div className="fcCols mr gap w50">
              <Select label={t("Supplier TG") + "*"} name="supplierTg" options={[]} generalClassName="w100" />
              <Select label={t("Supplier") + "*"} name="supplier" options={[]} generalClassName="w100" />
              <div className="fdr gap w100 jcsb">
                <DateInput label={t("Account from") + "*:"} name="accountDate" />
                <FunctionButton name="Ok" onClick={onOkBtnClick} autoWidth className="noFunctional" />
              </div>
            </div>
            <ReactTable columns={columns} defaultData={[]} className="scrollX mb" style={{height: "15em"}} />
          </div>

          <TextInput label={t("Manager contacts")} name="mngContacts" readOnly style={{marginLeft: "12em"}} />

          <Divider />

          {t("Recipient second") + "*:"}
          <div style={{marginLeft: "12em"}} className="fcCols gap0_5 ">
            <TextInput label={t("Recipient Name second")} name="recipientName" readOnly />
            <TextInput label={t("Postal code")} name="postalCode" generalClassName="w100" readOnly />
            <TextInput label={t("Recipient address")} name="recipientAddress" generalClassName="w100" readOnly />
            <TextInput label={t("tel")} name="tel" generalClassName="w100" readOnly />
            <TextInput label="e-mail" name="email" generalClassName="w100" readOnly />
            <TextInput label={t("Payer Name")} name="payerName" generalClassName="w100" readOnly />
            <TextInput label={t("Payer`s EDRPOUO")} name="payerEdrpouo" generalClassName="w100" readOnly />
            <TextInput label={t("Payer`s account")} name="payersAccount" generalClassName="w100" readOnly />
          </div>

          <DateInput label={t("Pay to") + "*"} name="payTo" generalClassName="mt" />
        </div>

        <div className="kpPreview__box ml" style={{height: "inherit", width: "30%"}}>
          <NewPdfViewer fileArray={[]} hiding={true} />
        </div>
      </div>
      <Divider />
      <div className="fdr jcsb">
        <FunctionButton name="Delete" onClick={onDeleteBtnClick} autoWidth className="noFunctional" />
        <FunctionButton name="Save" onClick={onSaveBtnClick} autoWidth className="noFunctional" />
        <FunctionButton name="Print" onClick={onPrintBtnClick} autoWidth className="noFunctional" />
        <FunctionButton
          name="Create directive"
          onClick={onCreateDirectiveBtnClick}
          autoWidth
          className="noFunctional"
        />
      </div>
    </div>
  );
};

const CreateInvoiceModal = withFormik({
  mapPropsToValues: () => ({sum: "", ammount: ""}),
  enableReinitialize: true,
})(CreateInvoiceModalBase);

export default CreateInvoiceModal;
