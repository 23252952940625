// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordering_table__O5t-B {
  min-height: 120px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Requests/RequestRegister/ordering/ordering.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".table {\n  min-height: 120px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ordering_table__O5t-B`
};
export default ___CSS_LOADER_EXPORT___;
