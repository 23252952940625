export const SELLER_DETAILS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 20,
    maxSize: 20,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "PG",
    accessorKey: "productGroup",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Data",
    accessorKey: "data",
    alignStart: true,
    size: 100,
  },
  {
    header: "Seller Details",
    accessorKey: "sellerDetails",
    alignStart: true,
    size: 450,
  },
]);

export const PRODUCT_GROUP_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 40,
  },
  {
    header: "Product group(PG)",
    accessorKey: "productGroup",
    size: 350,
    alignStart: true,
    isSortable: true,
  },
]);

export const SELLERS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№ nn",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Code",
    accessorKey: "officeCode",
    size: 50,
    alignStart: true,
    isSortable: true,
  },
  {
    header: "Office name",
    accessorKey: "officeName",
    size: 300,
    alignStart: true,
    isSortable: true,
  },
]);
