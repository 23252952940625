import {t} from "i18next";

const UnderDevelopment = () => {
  return (
    <div className="flex_container jcc aic" style={{width: "100%", height: "100%"}}>
      <p> {t("Under development")}</p>
    </div>
  );
};

export default UnderDevelopment;
