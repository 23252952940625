import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual, set} from "lodash";

import editIcon from "../../../../../images/icons/edit.png";
import FunctionButton from "../../../../../utilities/Buttons/FunctionButton";
import {toaster} from "../../../../../utilities/hooks/toaster";
import {MoneyInput, NumberInput, PriceInput, TextInput} from "../../../../Inputs";
import FieldWithSample from "../../../../Inputs/FieldWithSample";
import MoneyReadInput from "../../../../Inputs/MoneyReadInput";
import {getCurrentSku, updateSku} from "../../../../SKU/SkuSlice";

const SumBlockUpdated = ({readOnly, initialLine, sumCouldEdit = true}) => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {values, setValues, resetForm, setFieldValue} = useFormikContext();
  const currentSku = useSelector(getCurrentSku);

  const [isSumEditingActive, setIsSumEditingActive] = useState(false);

  const {
    values: {amount, listPrice, retailPrice, discount, topDiscount, topDiscountCheck, discountCheck},
  } = useFormikContext();

  const [summ, setSumm] = useState(null),
    [total, setTotal] = useState(null),
    [toPayment, setToPayment] = useState(null);

  const initialValues = {
    listPrice: null,
    retailPrice: null,
    sku: {minimumPrice: null},
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isEqual(initialValues, values)) {
        setValues(initialLine);
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [initialValues, values]);

  useEffect(() => {
    var initPrice = retailPrice;
    //  || listPrice;
    var sum_ = +initPrice * (amount || 1);
    setSumm(sum_);
  }, [amount, retailPrice, listPrice]);

  useEffect(() => {
    setTotal(discountCheck && discount ? summ - (summ * discount) / 100 : summ);
  }, [summ, discount, discountCheck]);

  useEffect(() => {
    setToPayment(topDiscountCheck && topDiscount ? total - (total * topDiscount) / 100 : total);
  }, [summ, total, topDiscount, topDiscountCheck]);

  useEffect(() => {
    const translatedMeasurUnit =
      language === "en" ? values?.sku?.measurementUnit?.engName : values?.sku?.measurementUnit?.name;
    setFieldValue("measurUnit", translatedMeasurUnit);
  }, []);

  const [isUpdatingDiscount, setIsUpdatingDiscount] = useState(false);
  const [isUpdatingDiscountPrice, setIsUpdatingDiscountPrice] = useState(false);

  const handlePriceBlur = () => {
    if (values?.discountPrice && !isUpdatingDiscount) {
      setIsUpdatingDiscount(true);

      const calculatedDiscount = values?.listPrice
        ? ((values?.retailPrice - values?.discountPrice) / values?.retailPrice) * 100
        : 0;

      if (calculatedDiscount !== values?.discount) {
        //calculatedDiscount >= 0 &&
        setFieldValue("discount", calculatedDiscount.toFixed(2));
      } else {
        setFieldValue("discount", "0");
      }

      setIsUpdatingDiscount(false);
    }
  };

  useEffect(() => {
    if (values?.discount && !isUpdatingDiscountPrice) {
      setIsUpdatingDiscountPrice(true);

      const calculatedDiscountPrice = values?.retailPrice ? values?.retailPrice * (1 - values?.discount / 100) : 0;

      if (calculatedDiscountPrice !== values?.discountPrice) {
        setFieldValue("discountPrice", calculatedDiscountPrice.toFixed(2));
      }

      setIsUpdatingDiscountPrice(false);
    }
  }, [values?.discount]);

  const saleLessThanMin = +values?.retailPrice < +values?.sku?.minimumPrice;
  const saleBiggerThanMax = +values?.retailPrice > +values?.sku?.maxPrice;

  const specLessThanMin =
    values?.discountCheck && values?.discount !== null && +values?.discountPrice < +values?.sku?.minimumPrice;
  const specBiggerThanMax = values?.discountCheck && +values?.discountPrice > +values?.sku?.maxPrice;

  const onChangeSkuSumBtnClick = () => {
    if (!isSumEditingActive) {
      setIsSumEditingActive(true);
      return;
    }
    if (!currentSku?.skuId) return;

    dispatch(updateSku({skuId: currentSku?.skuId, sku: {listPrice}, noMessage: true, isHardUpdate: true})).then(() => {
      toaster.success(t("List price has been updated"));
      setIsSumEditingActive(false);
    });
  };

  return (
    <>
      <div className="summ-block_upd fcCol" style={{paddingRight: "1em"}}>
        <div className="summ-item aibl fdr jcsb">
          <div className="fdr gap aic">
            <PriceInput
              label="listPrice"
              name="listPrice"
              procent="100"
              // width="7em"
              readOnly={!isSumEditingActive}
              required
              autolabel
            />
            {sumCouldEdit && (
              <FunctionButton
                name=""
                style={{height: "2em"}}
                className="fc jcc aic"
                onClick={onChangeSkuSumBtnClick}
                autoWidth
                tooltipMessage="Update list price"
                disabled={
                  isSumEditingActive && Number.parseFloat(currentSku?.listPrice) === Number.parseFloat(listPrice)
                }
              >
                <div className="fdr aic jcc gap2">
                  {isSumEditingActive ? (
                    <div>Ok</div>
                  ) : (
                    <img src={editIcon} alt="pencil" draggable="false" width="17em" height="18em" />
                  )}
                </div>
              </FunctionButton>
            )}
          </div>

          <PriceInput
            label={t("RR price for client*")}
            name="retailPrice"
            // width="6em"
            procent={values?.retailPrice && values?.listPrice ? (values.retailPrice * 100) / values.listPrice : 0}
            readOnly={true}
            autolabel
          />

          <PriceInput
            label={t("Min price")}
            name="sku.minimumPrice"
            width="6em"
            procent={
              values?.sku?.minimumPrice && values?.listPrice ? (values?.sku?.minimumPrice * 100) / values.listPrice : 0
            }
            readOnly={true}
            autolabel
          />

          <PriceInput
            label={t("Max price")}
            name="sku.maxPrice"
            width="6em"
            procent={values?.sku?.maxPrice && values?.listPrice ? (values?.sku?.maxPrice * 100) / values.listPrice : 0}
            readOnly={true}
            autolabel
          />
        </div>

        <div className="summ-item fdr gap aibl jcsb">
          <div className="fdr gap aife">
            <MoneyInput label={t("Sale price")} name="retailPrice" autolabel readOnly={readOnly} />
            <div>{t("for")}</div>
            <TextInput label="" name="measurUnit" width="4em" min={1} disabled />
          </div>

          <div className="fdr">
            <TextInput
              label={t("Quantity")}
              name="amount"
              type="number"
              width="5em"
              min={1}
              readOnly={readOnly}
              autolabel
            />
            <TextInput label="" name="measurUnit" width="4em" min={1} disabled />
          </div>

          <MoneyReadInput label={t("Sum")} name="summ" rawValue={summ} readOnly={true} width="8em" autolabel />

          <FieldWithSample
            name="discountCheck"
            label={t("Special price")}
            style={{width: "unset"}}
            childreStyle={{alignItems: "flex-end"}}
            readOnly={readOnly}
          >
            <MoneyInput label="" name="discountPrice" width="6em" onBlur={handlePriceBlur} />
            <div>{t("for")}</div>
            <TextInput label="" name="measurUnit" width="4em" min={1} disabled />

            <div style={{width: "6em"}}>{t("or discount")}</div>
            <NumberInput
              name="discount"
              width="4.5em"
              className="procent"
              // onBlur={handleProcentBlur}
              readOnly={!discountCheck}
              maxNumberLength={2}
            />
          </FieldWithSample>

          <MoneyReadInput
            label={t("Special sum")}
            name="total"
            rawValue={values?.discountPrice && values?.discountCheck ? total : null}
            width="8em"
          />
        </div>
        {saleLessThanMin && <div style={{color: "#b72025"}}> {t("Sell price is less than minimum price")}</div>}
        {saleBiggerThanMax && <div style={{color: "#b72025"}}> {t("Sell price is higher than maximum price")}</div>}
        {specLessThanMin && <div style={{color: "#b72025"}}>{t("Special price is less than minimum price")}</div>}
        {specBiggerThanMax && <div style={{color: "#b72025"}}> {t("Special price is higher than maximum price")}</div>}
      </div>
    </>
  );
};

export default SumBlockUpdated;
