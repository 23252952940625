export const BALANCE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 20,
    maxSize: 20,
  },
  {
    header: "Balance №",
    accessorKey: "balanceSerialNumber",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Type",
    accessorKey: "type",
    size: 25,
    maxSize: 25,
    isSortable: true,
  },
  {
    header: "Account name and assignment",
    accessorKey: "nameAndPurpose",
    alignStart: true,
    size: 200,
  },
  {
    header: "Account operation description",
    accessorKey: "operationDescription",
    alignStart: true,
    size: 200,
  },
]);

export const ACCOUNT_OPENING_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    maxSize: 15,
  },
  {
    header: "Account №",
    accessorKey: "accountNumber",
    size: 30,
  },
  {
    header: "Type",
    accessorKey: "accountType",
    size: 20,
    maxSize: 20,
  },
  {
    header: "Account name",
    accessorKey: "accountName",
    alignStart: true,
    size: 200,
    maxSize: 200,
  },
  {
    header: "Account owner",
    accessorKey: "accountOwner",
    size: 70,
    maxSize: 70,
  },
]);

export const ACCOUNT_OWNERS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "Code of AO",
    accessorKey: "aoCode",
    size: 25,
    isSortable: true,
  },
  {
    header: "Account owner",
    accessorKey: "accountOwner",
    size: 25,
    isSortable: true,
  },
]);
