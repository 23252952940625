import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import formTerms from "../../../../../utilities/formTerms";
import {getSuccess} from "../../../../../utilities/toasts";
import {updateTermsForCondition} from "../../../redux/operations/FactoryOperations";
import {selectCurrentFactoryConditionById, selectTimeUnits} from "../../../redux/selectors/FactorySelectors";
import formControlTerms from "../../../VedSetiingsGeneral/ControlTermsSettings/formControlTerms";

import {DEFAULT_UNIT_ID, TIME_UNITS_INPUT_LIST_DATA} from "./constants";

const useTermsSettingsModalService = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const currentCondition = useSelector(selectCurrentFactoryConditionById);
  const timeUnits = useSelector(selectTimeUnits);

  const {controlTerms} = currentCondition ?? {};

  //data
  const areBtnsDisabled = !currentCondition;
  const measureUnitsList = useMemo(
    () =>
      timeUnits.map(({engName, name, multiplier, unitId}) => ({
        title: language === "en" ? engName : name,
        value: unitId,
        multiplier,
      })),
    [timeUnits.length],
  );
  const initialValues = {
    factoryName: currentCondition?.factory.name ?? "",
    defaultDate: formTerms({
      termsFrom: currentCondition?.termsFrom,
      termsTo: currentCondition?.termsTo,
      unitId: currentCondition?.unitId,
      language,
      timeUnits,
    }),
    controlTermsSummary: formControlTerms({controlTerms, timeUnits, language}),
  };
  TIME_UNITS_INPUT_LIST_DATA?.forEach((element, index) => {
    if (!controlTerms) {
      initialValues[element?.unitName] = DEFAULT_UNIT_ID;
      return;
    }
    initialValues[element.inputName] = controlTerms[index]?.calculatedOption;
    initialValues[element.unitName] = controlTerms[index]?.unitId;
  });

  //event handlers
  function onSaveTermsBtnClick(values) {
    if (!currentCondition) return;

    const data = [];
    TIME_UNITS_INPUT_LIST_DATA?.forEach((element, index) => {
      data.push({
        termId: controlTerms[index].termId,
        calculatedOption: Number.parseFloat(values[element.inputName]),
        unitId: values[element.unitName],
      });
    });

    dispatch(updateTermsForCondition({conditionId: currentCondition.conditionId, data})).then(response => {
      if (response.meta.requestStatus === "fulfilled") {
        getSuccess(t("Control terms updated successfully"));
      }
    });
  }
  function onDeleteTermsBtnClick() {}

  return {onSaveTermsBtnClick, onDeleteTermsBtnClick, initialValues, measureUnitsList, areBtnsDisabled};
};

export default useTermsSettingsModalService;
