export const TRANSACTION_PACKAGE_GROUPS_COLUMNS = Object.freeze([
  {
    header: "№ пп",
    accessorKey: "number",
    size: 40,
  },
  {
    header: "Group Code",
    accessorKey: "groupCode",
    size: 60,
  },
  {
    header: "Group",
    accessorKey: "group",
    size: 127,
    isSortable: true,
  },
]);

export const TRANSACTION_PACKAGE_COLUMNS = Object.freeze([
  {
    header: "№ пп",
    accessorKey: "number",
    size: 40,
    maxSize: 40,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 80,
    maxSize: 80,
  },
  {
    header: "Dt",
    accessorKey: "debitBalance",
    size: 60,
    maxSize: 60,
  },
  {
    header: "Type",
    accessorKey: "debitBalanceType",
    size: 217,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Ct",
    accessorKey: "creditBalance",
    size: 60,
  },
  {
    header: "Type",
    accessorKey: "creditBalanceType",
    size: 217,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 217,
    isSortable: true,
  },
  {
    header: "Dt. bal. name",
    accessorKey: "debitBalanceName",
    size: 117,
    isSortable: true,
  },
  {
    header: "Ct. bal. name",
    accessorKey: "creditBalanceName",
    size: 117,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 217,
    isSortable: true,
  },
]);

export const TRANSACTION_TAMPLATES_COLUMNS = Object.freeze([
  {
    header: "№ пп",
    accessorKey: "number",
    size: 40,
    maxSize: 40,
  },
  {
    header: "Package Name",
    accessorKey: "packageName",
    size: 700,
  },
  {
    header: "Package Code",
    accessorKey: "packageCode",
    size: 160,
    maxSize: 160,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 160,
    // maxSize: 100,
  },
  {
    header: "Dt",
    accessorKey: "debitBalance",
    size: 100,
    // maxSize: 100,
  },
  {
    header: "Type",
    accessorKey: "debitBalanceType",
    size: 70,
    // maxSize: 50,
    isSortable: true,
  },
  {
    header: "Ct",
    accessorKey: "creditBalance",
    size: 100,
    // maxSize: 100,
  },
  {
    header: "Type",
    accessorKey: "creditBalanceType",
    size: 70,
    // maxSize: 50,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 300,
    // maxSize: 120,
    isSortable: true,
  },

  {
    header: "Dt. bal. name",
    accessorKey: "debitBalanceName",
    size: 300,
    isSortable: true,
  },
  {
    header: "Ct. bal. name",
    accessorKey: "creditBalanceName",
    size: 300,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 120,
    isSortable: true,
  },
]);
