import {useEffect} from "react";
import {useFormikContext} from "formik";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {ProjectDocButton} from "../../../../utilities/Buttons/StatusFunctionButton/ProjectDocButton";
import Divider from "../../../../utilities/Divider/Divider";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import DirectiveFilters from "../DirectiveFilters";
import FinStatus from "../FinStatus";
import OrderTypeComposedPart, {ORDER_COMPOSED_PART_TYPES} from "../OrderTypeComposedPart/OrderTypeComposedPart";

import useMainDirective from "./useMainDirective";

export const MAIN_DIRECTIVE_TAB_TYPES = Object.freeze({FINBLOCK: "finblock", KP_VIEW: "kpView", KP_CREATE: "kpCreate"});

const MainDirective = ({type, directiveId}) => {
  const readOnly = type === MAIN_DIRECTIVE_TAB_TYPES.KP_VIEW;
  const {values} = useFormikContext();

  const {
    onCancelBtnClick,
    onOkBtnClick,
    isDirectiveCreated,
    onCancelDirectiveBtnClick,
    hasDirectivePayment,
    onTakeIntoWorkBtnClick,
    ordersTableColumns,
    ordersTableData,
    currentOrderId,
    onOrderSelect,
    onRowDoubleClick,
    createdDirective,
    onDocumentsOpen,
    onForImplementationBtnClick,
  } = useMainDirective(directiveId);

  let typeDependentPart;
  switch (type) {
    case MAIN_DIRECTIVE_TAB_TYPES.FINBLOCK:
      typeDependentPart = (
        <ToolTip title={!values?.finOrderTypeId || values?.finOrderTypeId === "default" ? "Choose fin order type" : ""}>
          <FunctionButton
            onClick={onTakeIntoWorkBtnClick}
            name={createdDirective?.orders?.length > 0 ? "Create a new order" : "Take into work"}
            autoWidth
            disabled={!!(!values?.finOrderTypeId || values?.finOrderTypeId === "default" || currentOrderId)}
          />
        </ToolTip>
      );
      break;
    case MAIN_DIRECTIVE_TAB_TYPES.KP_VIEW:
      typeDependentPart = (
        <>
          <ToolTip title={hasDirectivePayment ? "Directive has payment" : ""}>
            <FunctionButton
              onClick={onCancelDirectiveBtnClick}
              name="Cancel directive"
              autoWidth
              disabled={hasDirectivePayment}
            />
          </ToolTip>
          {createdDirective?.statusId === 1 && (
            <FunctionButton
              onClick={onForImplementationBtnClick}
              name="For implementation"
              autoWidth
              //  disabled={!isDirectiveCreated || !areAllFieldsFilledIn}
            />
          )}
        </>
      );
      break;
  }

  return (
    <div className="fcCol h100 w100 pb aist maxH100 scrollY">
      <div className="fc nowrap w100">
        <ProjectDocButton
          onClick={onDocumentsOpen}
          // disabled={}
          style={{marginRight: "50px"}}
          title={"Open documents"}
        />
        <DirectiveFilters readOnly />
      </div>
      <Divider />
      <div className="fc aistr jcsb gap2x w100 fGrow mb0_5 nowrap">
        <OrderTypeComposedPart
          onOkBtnClick={onOkBtnClick}
          type={
            readOnly || currentOrderId ? ORDER_COMPOSED_PART_TYPES.VIEW_FUll : ORDER_COMPOSED_PART_TYPES.FINBLOCK_DO
          }
        >
          <div className="row">
            {typeDependentPart}
            <FunctionButton
              onClick={onCancelBtnClick}
              name={readOnly || type === MAIN_DIRECTIVE_TAB_TYPES.FINBLOCK ? "Exit" : "Cancel"}
              autoWidth
              disabled={!isDirectiveCreated}
            />
          </div>
        </OrderTypeComposedPart>
        <div className="fcCol fGrow2" style={{maxWidth: "70%"}}>
          <FinStatus readOnly />
          <div className=" fGrow w100 h100">
            <ReactTable
              columns={ordersTableColumns}
              defaultData={ordersTableData}
              current={currentOrderId}
              onSelect={onOrderSelect}
              className="fGrow mb scrollX h100"
              style={{height: "100%"}}
              onRowDoubleClick={onRowDoubleClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDirective;
