export const ORDER_REGISTER_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 20,
    maxSize: 30,
  },
  {
    header: "Date To",
    accessorKey: "dateTo",
    size: 100,
    maxSize: 100,
    // isSortable: true,
  },
  {
    header: "Date (f)",
    accessorKey: "factDate",
    size: 100,
    maxSize: 100,
    isSortable: true,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",

    // isSortable: true,
  },
  {
    header: "Order number ",
    accessorKey: "orderNumber",
    // size: 70,
    // maxSize: 70,
    isSortable: true,
  },
  {
    header: "Dt",
    accessorKey: "dt",
    size: 250,
    maxSize: 250,
    // isSortable: true,
  },
  {
    header: "Kt",
    accessorKey: "kt",
    size: 250,
    maxSize: 250,
    // isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumF",
    size: 70,
    maxSize: 70,
    // isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 500,
    alignStart: true,
    // minSize: 300,
    // isSortable: true,
  },
  // {
  //   header: "Note",
  //   accessorKey: "note",
  //   size: 100,
  // },
]);
