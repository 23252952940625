import React from "react";
import {useTranslation} from "react-i18next";

import {Filters} from "../../../../conteiners/filters/Filter.hoc";
import {DateInput, MoneyInput, TextInput} from "../../../../Inputs";
import {Select} from "../../../../Inputs/SelectField";

const BankOperationFilters = () => {
  const {t} = useTranslation();
  return (
    <Filters>
      <div className="w100 mb">
        <div className="w100 fc nowrap jcsb">
          <Select name={"clientNumber"} label={t("Client №")} options={[]} />
          <Select name={"operationType"} label={t("Operation type")} options={[]} />
          <MoneyInput name={"sum"} label={t("Sum")} />
          <Select name={"operationCurrency"} label={t("Operation currency")} options={[]} />
          <DateInput name={"dateFrom"} label={t("Period From")} />
        </div>
        <div className="w100 fc nowrap jcsb">
          <TextInput name={"name"} label={t("Name (P./R.)")} />
          <TextInput name={"ipn"} label={t("EDRPOU / ITN (P./R.)")} />
          <TextInput
            name={"purpose"}
            label={t("Purpose")}
            style={{width: "50em"}}
            placeholder={`...${t("payment for")}`}
          />
          <DateInput name={"dateTo"} label={t("To")} />
        </div>
      </div>
    </Filters>
  );
};

export default BankOperationFilters;
