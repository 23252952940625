import Divider from "../../../../../utilities/Divider/Divider";
import ReactTable from "../../../../conteiners/ReactTable";

import ProductGroup from "./parts/ProductGroup";
import Seller from "./parts/Seller";
import SellerDetailsPart from "./parts/SellerDetailsPart";
import useSellerDetailsTab from "./useSellerDetailsTab";

const SellerDetailsTab = () => {
  const {
    sellerDetailsTableColumns,
    sellerDetailsTableData,
    current,
    setCurrent,
    loading,
    productGroupTableData,
    currentProductGroup,
    setCurrentProductGroup,
    onDeleteProductGroupBtnClick,
    onAddProductGroupBtnClick,
    sellerTableData,
    currentSeller,
    setCurrentSeller,
    onDeleteSellerBtnClick,
    onAddSellerBtnClick,
    onDeleteSellerDetailBtnClick,
    onAddSellerDetailBtnClick,
  } = useSellerDetailsTab();

  return (
    <div className="grid2Col h100 w100 pb gap">
      <div className="fcCol borderRight paddingRight">
        <ProductGroup
          productGroupTableData={productGroupTableData}
          currentProductGroup={currentProductGroup}
          setCurrentProductGroup={setCurrentProductGroup}
          onAddProductGroupBtnClick={onAddProductGroupBtnClick}
          onDeleteProductGroupBtnClick={onDeleteProductGroupBtnClick}
        />
        <Divider />
        <Seller
          sellerTableData={sellerTableData}
          currentSeller={currentSeller}
          setCurrentSeller={setCurrentSeller}
          onDeleteSellerBtnClick={onDeleteSellerBtnClick}
          onAddSellerBtnClick={onAddSellerBtnClick}
        />
        <Divider />
        <SellerDetailsPart
          onAddSellerDetailBtnClick={onAddSellerDetailBtnClick}
          onDeleteSellerDetailBtnClick={onDeleteSellerDetailBtnClick}
        />
      </div>
      <div className="fcCol">
        <ReactTable
          columns={sellerDetailsTableColumns}
          defaultData={sellerDetailsTableData}
          current={current}
          onSelect={setCurrent}
          enableColumnResizing={true}
          className="tableTac fGrow scrollX"
          tableClassName="fGrow"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SellerDetailsTab;
