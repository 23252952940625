import {useFormikContext} from "formik";
import {t} from "i18next";

import {numberFormat} from "../../../../../../utilities/moneyFormat";
import {MoneyInput, NumberInput} from "../../../../../Inputs";

const SumsWithAdvance = () => {
  const {values} = useFormikContext();
  const {apSumComposed, recipientCoBalanceComposed} = values ?? {};

  return (
    <div className="fcCol gap aistr w100">
      <MoneyInput name="coSumComposed" label={t("CO Sum")} autolabel width="10em" readOnly={true} />
      <div className="row aibl">
        <MoneyInput name="apSumComposed" label={t("Sum (ap)")} autolabel width="10em" readOnly={true} />
        <NumberInput name="advancePercentageComposed" label={t("%advance")} autolabel readOnly={true} />%
      </div>
      <div className="row aibl jcfs gap4x">
        <MoneyInput name="afSumComposed" label={t("Sum (af)")} autolabel width="10em" readOnly={true} />
        {apSumComposed &&
        recipientCoBalanceComposed &&
        !(Number.parseFloat(recipientCoBalanceComposed) > Number.parseFloat(apSumComposed)) ? (
          <span>{`(${t("Payment amount")}: ${numberFormat(apSumComposed - recipientCoBalanceComposed)})`}</span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SumsWithAdvance;
