import {useTranslation} from "react-i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../../../../conteiners/ReactTable";
import {TextInput} from "../../../../../Inputs";
import useColumns from "../../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {PRODUCT_GROUP_TABLE_COLUMNS} from "../constants";

const ProductGroup = ({
  productGroupTableData,
  currentProductGroup,
  setCurrentProductGroup,
  onDeleteProductGroupBtnClick,
  onAddProductGroupBtnClick,
}) => {
  const {t} = useTranslation();
  const columns = useColumns(PRODUCT_GROUP_TABLE_COLUMNS);

  return (
    <div className="grid2Col gap w100">
      <div className="fcCol jcsb aist gap">
        <div>{t("Product group (i):")}</div>
        <TextInput label="" name="productGroup" width="18rem" labelStyles={{display: "none"}} generalClassName="asfe" />
        <div className="row w100">
          <FunctionButton name="Delete" onClick={onDeleteProductGroupBtnClick} autoWidth />{" "}
          <FunctionButton name="Add" onClick={onAddProductGroupBtnClick} autoWidth />
        </div>
      </div>
      <ReactTable
        columns={columns}
        defaultData={productGroupTableData}
        current={currentProductGroup}
        onSelect={setCurrentProductGroup}
        enableColumnResizing={true}
        className="tableTac fGrow"
        tableClassName="fGrow"
        //   loading={loading}
      />
    </div>
  );
};

export default ProductGroup;
