export const APPEAL_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 10,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 100,
    maxSize: 120,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Appeal type",
    accessorKey: "appealType",
    size: 60,
    maxSize: 100,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Briefly about",
    accessorKey: "about",
    size: 350,
    maxSize: 350,
    isSortable: true,
    alignStart: true,
    cell: value => (
      <span className={`${value.row.original.isDone ? "strikethroughRow" : ""} `}>{value.getValue()}</span>
    ),
  },
  {
    header: "Block",
    accessorKey: "block",
    size: 70,
    maxSize: 70,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Manager ",
    accessorKey: "manager",
    size: 100,
    maxSize: 100,
    isSortable: true,
    alignStart: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 90,
    maxSize: 90,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "IT Manager",
    accessorKey: "managerIT",
    size: 100,
    maxSize: 100,
    isSortable: true,
    alignStart: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Start date",
    accessorKey: "startDate",
    size: 70,
    maxSize: 100,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Plan Date",
    accessorKey: "planDate",
    size: 70,
    maxSize: 100,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Дата Фініш",
    accessorKey: "finishDate",
    size: 100,
    maxSize: 100,
    isSortable: true,
    cell: value => <span className={value.row.original.isDone ? "strikethroughRow" : ""}>{value.getValue()}</span>,
  },
  {
    header: "Note IT",
    accessorKey: "noteIT",
    size: 350,
    maxSize: 350,
    isSortable: true,
    alignStart: true,
  },
]);
