import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Field, withFormik} from "formik";
import Swal from "sweetalert2";

import useContextMenu from "../../../hook/useContextMenu";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import {dateFormat, dateTimeFormat} from "../../../utilities/dateFormat";
import Divider from "../../../utilities/Divider/Divider";
import {getSuccess} from "../../../utilities/toasts";
import {SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import {useModal} from "../../Modal/ModalProvider";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {cancelDirective, fetchAllDirectives, selectAllDirectives} from "../FinSettings/FinSettingsSlice";

import CashdeskDirectiveOrder from "./cashdeskDirectiveOrderModals/CashdeskDirectiveOrder";
import CashdeskDirectiveFilters from "./parts/CashdeskDirectiveFilters";
import CashDirectibesContextMenu from "./CashDirectibesContextMenu";
import ComposedPart from "./ComposedPart";
import {CASHDESK_DIRECTIVE_TABLE_COLUMNS} from "./constants";
import useCashdeskDirective from "./useCashdeskDirective";

export const CASHDESK_DIRECTIVE_TYPES = Object.freeze({
  ADVENT: "ADVENT",
  EXPENCE: "EXPENCE",
  CURRENCY_EXCHANGE: "CURRENCY_EXCHANGE",
  TRANSIT: "TRANSIT",
});

const CashdeskDirectiveTableWithFilters = () => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const {openModal, isModalOpen} = useModal();
  const {handleContextMenu, menuVisible, menuPosition} = useContextMenu();
  const directives = useSelector(selectAllDirectives);

  const {filters} = useFiltersContext();
  useEffect(() => {
    if (!isModalOpen) dispatch(fetchAllDirectives({...checkEmptyFilterFields(filters), isCashDesk: true}));
  }, [filters, isModalOpen]);

  // const currentDirective = useSelector(selectCashdeskDirective);

  const [current, setCurrent] = useState(null);

  const columns = useColumns(CASHDESK_DIRECTIVE_TABLE_COLUMNS);
  const defaultData =
    directives?.map(
      (
        {
          directiveId,
          directiveNumber,
          createdAt,
          fulfilBy,
          sumFact,
          // sumPlan,
          operationType,
          payer,
          recipient,
          status,
          note,
        },
        index,
      ) => ({
        id: directiveId,
        number: ++index,
        status: language === "en" ? status?.nameEng : status?.nameUkr,
        createdAt: dateTimeFormat(createdAt),
        date: dateFormat(createdAt),
        dateTo: dateFormat(fulfilBy),
        // sumPlan: sumPlan ?? "0.00",
        sumFact: sumFact ?? "0.00",
        directiveNumber,
        operationType: language === "en" ? operationType?.nameEng : operationType?.nameUkr,
        payerType: payer?.firstName ? t("Lead") : t("Client"),
        payerName: payer?.owner
          ? payer?.owner?.clientNumber
          : payer?.clientNumber
          ? payer?.clientNumber
          : `${payer?.firstName}`,
        receiverType: recipient?.firstName ? t("Lead") : t("Client"),
        receiverName: recipient?.owner
          ? recipient?.owner?.clientNumber
          : recipient?.clientNumber
          ? recipient?.clientNumber
          : `${recipient?.firstName}`,
        note,
        // clientNumber: project?.client?.clientNumber ?? "-",
        // coNumber,
        // payerName: outsideParty?.name ?? "-",
        // payerEDRPOU: outsideParty?.edrpouCode ?? "-",
        // payerAccount: outsideParty?.currentAccount ?? "-",
      }),
    ) ?? [];

  const openOrderViewModal = (directiveID, viewOnly) => {
    const orders = directives?.find(directive => directive?.directiveId === (directiveID || current))?.orders;
    openModal(<CashdeskDirectiveOrder />, {
      label: t("Cashdesk order (View)"),
      style: {width: "90%", height: "85%"},
      className: "largeModal",
      orderId: orders?.[0]?.orderId,
      viewOnly: viewOnly,
    });
  };

  const onCancelDerictive = () => {
    const directive = directives?.find(directive => directive?.directiveId === current);
    Swal.fire({
      text: t("Delete directive question", {directiveNumber: directive?.directiveNumber}),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),
    }).then(value => {
      if (value.isConfirmed) {
        dispatch(cancelDirective(current)).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            getSuccess("Directive canceled");
            dispatch(fetchAllDirectives({...checkEmptyFilterFields(filters), isCashDesk: true}));
          }
        });
      }
    });
  };

  return (
    <>
      <ReactTable
        columns={columns}
        defaultData={defaultData}
        className="scrollX scrollY fGrow"
        onRowDoubleClick={row => openOrderViewModal(row?.id, true)}
        current={current}
        onSelect={setCurrent}
        selectOnRightBtn={true}
        handleContextMenu={handleContextMenu}
      />
      <CashDirectibesContextMenu
        onDelete={onCancelDerictive}
        onOpenDirective={() => openOrderViewModal(null, true)}
        onEdit={() => openOrderViewModal(null, false)}
        menuVisible={menuVisible}
        menuPosition={menuPosition}
      />
    </>
  );
};

const CashdeskDirective = () => {
  const {
    onAddNewDirectiveBtnClick,
    directiveType,
    currentDirectiveSubTypeId,
    setCurrentDirectiveSubTypeId,
    currentPayer,
    setCurrentPayer,
    currentReceiver,
    setCurrentReceiver,
  } = useCashdeskDirective();

  const {t} = useTranslation();

  return (
    <PageContainer title={t("Cashdesk directive")} id={"context-menu-container"}>
      <SessionFiltersContainer>
        <div className="row gap" style={{height: "30%"}}>
          <div className="fcCol jcsb gap3x">
            <div className="fcCol gap jcsb border aist padding">
              <label className="row aic gap">
                <Field type="radio" name="directiveType" id="Advent" value={CASHDESK_DIRECTIVE_TYPES.ADVENT} />
                {t(CASHDESK_DIRECTIVE_TYPES.ADVENT)}
              </label>
              <label className="row aic gap">
                <Field type="radio" name="directiveType" id="Expence" value={CASHDESK_DIRECTIVE_TYPES.EXPENCE} />
                {t(CASHDESK_DIRECTIVE_TYPES.EXPENCE)}
              </label>
              <label className="row aic gap noFunctional">
                <Field
                  type="radio"
                  name="directiveType"
                  id="currencyExchange"
                  value={CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE}
                  disabled
                />
                {t(CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE)}
              </label>
              <label className="row aic gap noFunctional">
                <Field
                  type="radio"
                  name="directiveType"
                  id="Transit"
                  value={CASHDESK_DIRECTIVE_TYPES.TRANSIT}
                  disabled
                />
                {t(CASHDESK_DIRECTIVE_TYPES.TRANSIT)}
              </label>
            </div>
            <FunctionButton
              name="New directive"
              autoWidth
              disabled={!currentDirectiveSubTypeId || !currentPayer || !currentReceiver}
              onClick={onAddNewDirectiveBtnClick}
            />
          </div>
          <ComposedPart
            directiveType={directiveType}
            currentDirectiveSubTypeId={currentDirectiveSubTypeId}
            setCurrentDirectiveSubTypeId={setCurrentDirectiveSubTypeId}
            currentPayer={currentPayer}
            setCurrentPayer={setCurrentPayer}
            currentReceiver={currentReceiver}
            setCurrentReceiver={setCurrentReceiver}
          />
        </div>

        <div className="fcCol fGrow pb" style={{height: "60%"}}>
          <Divider />
          <CashdeskDirectiveFilters />
          <Divider />
          <CashdeskDirectiveTableWithFilters />
        </div>
      </SessionFiltersContainer>
    </PageContainer>
  );
};

export default withFormik({
  mapPropsToValues: () => ({directiveType: CASHDESK_DIRECTIVE_TYPES.ADVENT}),
  enableReinitialize: true,
})(CashdeskDirective);
