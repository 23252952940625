import React from "react";
import {Route, Routes} from "react-router-dom";

import BankClient from "../../Components/FinBlock/BankClient/BankClient";
import Cashdesk from "../../Components/FinBlock/Cashdesk/CashdeskMainEl/Cashdesk";
import CashdeskDirective from "../../Components/FinBlock/CashdeskDirective/CashdeskDirective";
import CashdeskOrders from "../../Components/FinBlock/CashdeskOrders/CashdeskOrders";
import CurrencyRate from "../../Components/FinBlock/CurrencyRate/CurrencyRate";
import FinBlockDirectiveList from "../../Components/FinBlock/FinBlockDirectiveList/FinBlockDirectiveList";
import FinSettings from "../../Components/FinBlock/FinSettings/FinSettings";
import PaymentsToFactory from "../../Components/FinBlock/PaymentsForPf/PaymentsToFactory/PaymentsToFactory";
import ProjectDirectiveList from "../../Components/Project/ProjectDirectiveList/ProjectDirectiveList";
import UnderDevelopment from "../../utilities/UnderDevelopment/UnderDevelopment";

const FinBlock = () => {
  return (
    <Routes>
      <Route path="/paymentsregistr" element={<PaymentsToFactory />}></Route>
      <Route path="/fin_settings" element={<FinSettings />}></Route>
      {/* <Route path="/cashdesk" element={<Cashdesk />}></Route> */}
      <Route path="/cashdesk" element={<UnderDevelopment />}></Route>
      <Route path="/cashdeskDirective" element={<CashdeskDirective />}></Route>
      <Route path="/cashdeskOrders" element={<CashdeskOrders />}></Route>
      {/* <Route path="/finBlockDirectiveList" element={<FinBlockDirectiveList />}></Route> */}
      <Route path="/finBlockDirectiveList" element={<ProjectDirectiveList />}></Route>
      <Route path="/exchangeRates" element={<CurrencyRate />}></Route>
      <Route path="/bankClient" element={<BankClient />}></Route>
    </Routes>
  );
};

export default FinBlock;
