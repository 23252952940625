import {useState} from "react";
import {withFormik} from "formik";
import {t} from "i18next";

import ModalForm from "../../../../Modal/FormModal";
import ClientOrderModal from "../ClientOrderModal/ClientOrderModal";

import AssociatedTransaction from "./parts/AssociatedTransaction";
import CounterpartyAssociation from "./parts/CounterpartyAssociation";
import DirectiveAndOrder from "./parts/DirectiveAndOrder";
import RealTransaction from "./parts/RealTransaction";

const AccountAssociationAndTransaction = ({noCounterpartyAssociation = true}) => {
  const [isModalActive, setModalActive] = useState(false);

  const realTransactionTableData = [];
  const onRealTransactionSelect = () => {};

  const associatedTransactionTableData = [];
  const onAssociatiedTransactionSelect = () => {};

  const onSaveAssociationBtnClick = () => {};

  const onCreateDirectiveBtnClick = () => {
    setModalActive(true);
  };
  const onCurrencySumSubmitBtnClick = () => {};

  const onCompleteOrder = () => {};
  const onExitBtnClick = () => {};

  const orderTransactionsTableData = [];
  const onCreateOrder = () => {};
  const onDeleteOrder = () => {};
  const onPrintOrderBtnClick = () => {};

  const conductedOrderTransactionsTableData = [];

  return (
    <div className="fcCol w100 pt gap0_5">
      <RealTransaction
        realTransactionTableData={realTransactionTableData}
        onRealTransactionSelect={onRealTransactionSelect}
      />
      <AssociatedTransaction
        associatedTransactionTableData={associatedTransactionTableData}
        onAssociatiedTransactionSelect={onAssociatiedTransactionSelect}
      />
      {!noCounterpartyAssociation && <CounterpartyAssociation onSaveAssociationBtnClick={onSaveAssociationBtnClick} />}

      <DirectiveAndOrder
        onCreateDirectiveBtnClick={onCreateDirectiveBtnClick}
        onCurrencySumSubmitBtnClick={onCurrencySumSubmitBtnClick}
        onCompleteOrder={onCompleteOrder}
        onExitBtnClick={onExitBtnClick}
        orderTransactionsTableData={orderTransactionsTableData}
        onCreateOrder={onCreateOrder}
        onDeleteOrder={onDeleteOrder}
        onPrintOrderBtnClick={onPrintOrderBtnClick}
        conductedOrderTransactionsTableData={conductedOrderTransactionsTableData}
        noCounterpartyAssociation={noCounterpartyAssociation}
      />
      <ModalForm
        label={t("Client Order")}
        active={isModalActive}
        setModalActive={setModalActive}
        closable
        Component={ClientOrderModal}
        style={{width: "80rem"}}
      />
    </div>
  );
};

export default withFormik({enableReinitialize: true})(AccountAssociationAndTransaction);
