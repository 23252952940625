import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {dateFormat} from "../../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../../utilities/formatAccountNumber";
import {numberFormat} from "../../../../../utilities/moneyFormat";
import ReactTable from "../../../../conteiners/ReactTable";
import {useModal} from "../../../../Modal/ModalProvider";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchBankTransaction, selectBankTransactions, selectFinBlockLoading} from "../../../FinBlockSlice";
import {clearStatementDirective} from "../../../FinSettings/FinSettingsSlice";
import {BANK_CLIENT_TABLE_COLUMNS_2699} from "../../constants";
import ViewAccountAssociationAndTransaction from "../../modals/AccountAssociationAndTransaction/ViewAccountAssociationAndTransaction";

const BankOperationTable = ({handleContextMenu, currentBankTransactionId, setCurrentBankTransactionId}) => {
  const collumns = useColumns(BANK_CLIENT_TABLE_COLUMNS_2699);
  const {openModal} = useModal();
  const {t} = useTranslation();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchAllBankTransactions({isResidues: true}));
  // }, []);
  const loading = useSelector(selectFinBlockLoading);
  const bankTransactions = useSelector(selectBankTransactions);
  const bankClientTableData = useMemo(
    () =>
      bankTransactions?.map((transaction, index) => {
        const isDebit = Boolean(transaction?.bankClientStatement?.isDebit);
        return {
          id: transaction?.transactionId,
          number: ++index,
          trId: transaction?.bankClientStatement?.transactionUuid,
          counterpartyAccountNumber: formatAccountNumber(
            transaction?.[isDebit ? "creditAccount" : "debitAccount"]?.accountNumber,
          ),
          clientNumber:
            transaction?.creditAccount?.client?.clientNumber || transaction?.debitAccount?.client?.clientNumber || "-",
          accountNumber: formatAccountNumber(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.accountNumber),
          requisitesC: transaction?.bankClientStatement?.counterpartyEdrpou,
          counterpartyName: transaction?.bankClientStatement?.counterparty,
          commissionsAmmount: transaction?.bankClientStatement?.feesSum,
          counterparty: transaction?.bankClientStatement?.counterparty,
          date: dateFormat(transaction?.bankClientStatement?.dateAndTime),
          // status: formTransactionStatus(transaction),
          // operationType: "?",
          costsDt: isDebit ? numberFormat(transaction?.sum) : "",
          revenueCt: isDebit ? "" : numberFormat(transaction?.sum),
          currency:
            transaction?.creditAccount?.currency?.shortName || transaction?.debitAccount?.currency?.shortName || "-",
          paymentPurpose: transaction?.paymentPurpose,
          rate: transaction?.bankClientStatement?.rate,
          equivalent: transaction?.bankClientStatement?.nbuEquivalentSum,
          remainder: numberFormat(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
        };
      }) || [],
    [bankTransactions],
  );

  const onTableDoubleClick = transaction => {
    if (!transaction?.id) return;
    dispatch(fetchBankTransaction({id: transaction.id})).then(() => {
      openModal(<ViewAccountAssociationAndTransaction />, {
        label: t("View Account iban", {iban: transaction?.accountNumber}),
        closable: true,
        style: {height: "300px", minHeight: "300px"},
        onModalClose: () => {
          dispatch(clearStatementDirective());
        },
      });
    });
  };

  return (
    <div className="h100">
      <ReactTable
        columns={collumns}
        defaultData={bankClientTableData}
        className="fGrow scrollX scrollY fShrink"
        // enableColumnResizing={true}
        current={currentBankTransactionId}
        onSelect={setCurrentBankTransactionId}
        loading={loading}
        onRowDoubleClick={onTableDoubleClick}
        selectOnRightBtn={true}
        handleContextMenu={handleContextMenu}
      />
    </div>
  );
};

export default BankOperationTable;
