import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import calcStatusColor from "../../../../utilities/calcStatusColor";
import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import {dateFormat} from "../../../../utilities/dateFormat";
import {makeTableNameFromPersona} from "../../../../utilities/makeNameFromPersona";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput} from "../../../Inputs";
import {clearCurrentCO, fetchCO, getCOLoading, selectAllCOs, selectCurrentCO} from "../../../Project/ProjectSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {clearCurrentCoa, clearCurrentCof} from "../../kp-f/KpFinSlice";
import {KP_LIST_TABLE_COLUMNS} from "../constants";

const KpMainListAndForm = () => {
  const dispatch = useDispatch();
  const {setValues} = useFormikContext();
  const {
    i18n: {language},
  } = useTranslation();

  const allCos = useSelector(selectAllCOs);
  const currentCo = useSelector(selectCurrentCO);
  const coLoading = useSelector(getCOLoading);

  const [currentCoId, setCurrentCoId] = useState(currentCo?.coId ?? null);

  useEffect(() => {
    if (currentCoId) {
      dispatch(fetchCO({coId: currentCoId}));
    } else {
      dispatch(clearCurrentCO());
    }
  }, [currentCoId]);

  useEffect(() => {
    if (currentCo?.coId) {
      setValues(prev => ({...prev, coDecsNote: `${currentCo?.note ?? "-"}`}));
    } else {
      setValues(prev => ({...prev, coDecsNote: ""}));
    }
  }, [currentCo?.coId]);

  const kpListColumns = useColumns(KP_LIST_TABLE_COLUMNS);
  const kpListData =
    allCos?.map(({coId, coNumber, startDate, finalDate, note, managerCo, project, statusId}) => ({
      id: coId,
      status: statusId >= 3 ? STATUS_COLORS.GREY : calcStatusColor({startDate, finalDate}),
      coNumber,
      projectManager: makeTableNameFromPersona({persona: project?.projectManager?.persona, language}),
      coManager: makeTableNameFromPersona({persona: managerCo?.persona, language}),

      note,
      startDate: dateFormat(startDate),
      finalDate: dateFormat(finalDate),
    })) ?? [];

  function onKpListTableRowSelect(coId) {
    setCurrentCoId(coId);
    dispatch(clearCurrentCof());
    dispatch(clearCurrentCoa());
    setValues(prev => ({
      ...prev,
      cofNumber: "",
      cofNote: "",
      coSum: "",
      coaNumber: "",
      coaNote: "",
    }));
  }

  return (
    <>
      <ReactTable
        defaultData={kpListData ?? []}
        columns={kpListColumns}
        className={"project_table scrollX fGrow"}
        style={{height: "10em", minHeight: "5em"}}
        loading={coLoading}
        current={currentCoId}
        onSelect={onKpListTableRowSelect}
      />
      <TextAreaInput name="coDecsNote" height="4.5em" className="pt" readOnly />
    </>
  );
};

export default KpMainListAndForm;
