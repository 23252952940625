import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {getCurrentUser} from "../../../slices/UserSlice";
import {checkEmptyFilterFields} from "../../../utilities/checkEmptyFilterFields";
import {dateFormat, dateTimeFormat, dateTimeUnformatToUnix} from "../../../utilities/dateFormat";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import {
  fetchAllDirectives,
  fetchDirective,
  fetchDirectiveFilters,
  selectAllDirectives,
  selectAllStatuses,
  selectDirectiveFilters,
  selectFinSettingsLoading,
  setCreatedDirective,
  setFinOrder,
} from "../../FinBlock/FinSettings/FinSettingsSlice";
import {DateInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {ProjectView} from "../ProjectView/ProjectView";

import MainDirective, {MAIN_DIRECTIVE_TAB_TYPES} from "./MainDirective/MainDirective";
import OrderRegister from "./OrderRegister/OrderRegister";
import {PROJECT_DIRECTIVE_TABLE_COLUMNS} from "./constants";

import "./projectDirectiveLIstStyles.scss";

const useProjectDirectiveList = ({readOnly}) => {
  const dispatch = useDispatch();
  const {
    i18n: {language},
  } = useTranslation();

  const {addNewTabCustom, setTabs, setCurrentTabIndex} = useTabsContext();

  const loading = useSelector(selectFinSettingsLoading);
  const statuses = useSelector(selectAllStatuses);
  const allDirectives = useSelector(selectAllDirectives);
  const fetchedFilters = useSelector(selectDirectiveFilters);
  const currentUser = useSelector(getCurrentUser);
  const currentUserId = currentUser?.id;

  const [currentDirectiveId, setCurrentDirectiveId] = useState(null);
  const currentDirective = allDirectives?.find(directive => directive.directiveId === currentDirectiveId);

  const [filterFields, setFilterFields] = useState({});

  // useEffect(() => {
  //   if (!fetchedFilters || fetchedFilters.length === 0) {
  //     dispatch(fetchDirectiveFilters());
  //     dispatch(fetchAllFinStatuses());
  //     return;
  //   }
  //   dispatch(fetchAllDirectives());
  // }, [Object.keys(fetchedFilters ?? {}).length]);

  const isCurrentDirectiveCreatedByCurrentUser = currentDirective?.creatorId === currentUserId;

  const clientNumberOptions =
    fetchedFilters?.clients?.map(({clientNumber, clientId}) => ({title: clientNumber, value: clientId})) ?? [];
  const coNumberOptions = fetchedFilters?.cos?.map(({coNumber, coId}) => ({title: coNumber, value: coId})) ?? [];
  const operationTypeOptions =
    fetchedFilters?.operationTypes?.map(({operationTypeId, nameEng, nameUkr}) => ({
      value: operationTypeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const statusOptions =
    statuses?.map(({statusId, nameEng, nameUkr}) => ({
      value: statusId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const sumOptions = fetchedFilters?.sumPlans?.map(sum => ({title: sum, value: sum})) ?? [];
  const PAYER_DATA = Object.freeze({
    TITLE: "pName",
    EDRPOUO: "edrpouCode",
    P_ACCOUNT: "currentAccount",
  });
  const payerDataTypeOptions = [
    {title: t("Title"), value: PAYER_DATA.TITLE},
    {title: t("EDRPOUO"), value: PAYER_DATA.EDRPOUO},
    {title: t("p/acc"), value: PAYER_DATA.P_ACCOUNT},
  ];
  const [payerDataType, setPayerDataType] = useState(null);

  const payerDataOptions =
    payerDataType === PAYER_DATA.TITLE
      ? fetchedFilters?.payerNames?.map(name => ({title: name, value: name}))
      : payerDataType === PAYER_DATA.EDRPOUO
      ? fetchedFilters?.payerEdrpouCodes?.map(code => ({title: code, value: code}))
      : payerDataType === PAYER_DATA.P_ACCOUNT
      ? fetchedFilters?.payerAccounts?.map(accountName => ({title: accountName, value: accountName}))
      : [];

  // function onOpenCreateInnerDirectieveModalBtnClick() {
  //   addNewTabCustom({
  //     TabComponent: MainDirective,
  //     controlled: true,
  //     title: "Directive №",
  //     tabIdName: "createOrderTab",
  //   });
  // }

  const onMakeEffect = ({value, name}) => {
    const field = {[name]: value};
    setFilterFields(prev => ({...prev, ...field}));
  };
  const onFilterDelete = value => {
    if (!value) setFilterFields({});
    else setFilterFields(prev => ({...prev, [value]: ""}));
  };

  useEffect(() => {
    dispatch(fetchDirectiveFilters(checkEmptyFilterFields(filterFields)));
  }, [filterFields]);

  const filtersLayout = (
    <div className="projectDireciveListFiltersContainer">
      <TextInput
        name="directiveNumber"
        label={t("Directive №")}
        style={{
          justifySelf: "stretch",
          gridArea: "directiveNumber",
        }}
        autolabel
        onBlur={(_, value) => {
          onMakeEffect({value, name: "directiveNumber"});
        }}
      />
      <Select
        name="clientId"
        label={t("Client №")}
        options={clientNumberOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "clientId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "clientId"});
        }}
      />
      <Select
        name="coId"
        label={t("CO №")}
        options={coNumberOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "coId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "coId"});
        }}
      />
      <Select
        name="operationTypeId"
        label={t("Transaction type")}
        options={operationTypeOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "operationTypeId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "operationTypeId"});
        }}
      />
      <DateInput
        name="dateStart"
        style={{gridArea: "dateStart"}}
        label={t("Period From")}
        onDateChange={value => {
          onMakeEffect({value, name: "dateStart"});
        }}
      />
      <Select
        name="statusId"
        label={t("Status")}
        options={statusOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "statusId",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "statusId"});
        }}
      />
      <Select
        name="sumPlan"
        label={t("Sum (s/t)")}
        options={sumOptions}
        style={{
          justifySelf: "stretch",
          gridArea: "sumPlan",
        }}
        makeEffect={option => {
          onMakeEffect({value: option?.value, name: "sumPlan"});
        }}
      />
      <div className="row gap5 border" style={{gridArea: "payerData", justifySelf: "stretch", padding: "2px"}}>
        <Select
          name="payerDataType"
          label={t("Payer data")}
          options={payerDataTypeOptions}
          inputClassName="fGrow"
          className="fGrow"
          generalClassName="fGrow"
          makeEffect={option => {
            setPayerDataType(option?.value || null);
          }}
        />
        <Select
          name="payerData"
          label=" "
          options={payerDataOptions}
          style={{justifySelf: "stretch"}}
          makeEffect={option => {
            onMakeEffect({value: option?.value, name: formPayerDataParamName(payerDataType)});
          }}
        />
      </div>

      <DateInput
        name="dateEnd"
        label={t("To")}
        style={{justifyContent: "flex-end", gridArea: "dateEnd"}}
        makeEffect={value => {
          onMakeEffect({value, name: "dateEnd"});
        }}
      />
    </div>
  );

  function formPayerDataParamName(payerDataType) {
    switch (payerDataType) {
      case PAYER_DATA.TITLE:
        return "name";

      case PAYER_DATA.EDRPOUO:
        return "edrpouCode";

      case PAYER_DATA.P_ACCOUNT:
        return "currentAccount";
    }
  }

  function getFilterData(filters) {
    if (!filters) {
      dispatch(fetchAllDirectives());
      return;
    }

    const {
      directiveNumber,
      statusId,
      operationTypeId,
      sumPlan,
      clientId,
      dateStart,
      dateEnd,
      payerDataType,
      payerData,
      coId,
    } = filters;

    const payerDataParamName = formPayerDataParamName(payerDataType);

    dispatch(
      fetchAllDirectives({
        directiveNumber,
        coId,
        statusId,
        operationTypeId,
        sumPlan,
        clientId,
        dateStart,
        dateEnd,
        [payerDataParamName]: payerData,
      }),
    );
  }

  const projectDirectiveTableColumns = useColumns(PROJECT_DIRECTIVE_TABLE_COLUMNS);

  const [areDirectivesSorted, setDirectivesSorted] = useState(false);
  const [projectDirectiveTableData, setProjectDirectiveTableData] = useState([]);

  const mapedDirectives = useCallback(allDirectives => {
    return (
      allDirectives?.map(
        (
          {
            directiveId,
            directiveNumber,
            createdAt,
            fulfilBy,
            sumFact,
            sumPlan,
            co: {coNumber, project},
            operationType,
            outsideParty,
            status,
          },
          index,
        ) => ({
          id: directiveId,
          number: ++index,
          status: language === "en" ? status?.nameEng : status?.nameUkr,
          createdAt: dateTimeFormat(createdAt),
          date: dateFormat(createdAt),
          dateTo: dateFormat(fulfilBy),
          sumPlan: sumPlan ?? "-",
          sumFact: sumFact ?? "0.00",
          directiveNumber,
          operationType: language === "en" ? operationType?.nameEng : operationType?.nameUkr,
          clientNumber: project?.client?.clientNumber ?? "-",
          coNumber,
          payerName: outsideParty?.name ?? "-",
          payerEDRPOU: outsideParty?.edrpouCode ?? "-",
          payerAccount: outsideParty?.currentAccount ?? "-",
        }),
      ) ?? []
    );
  }, []);

  useEffect(() => {
    setProjectDirectiveTableData(mapedDirectives(allDirectives));
  }, [allDirectives]);

  const onMainDirectiveTabClose = tabConfig => {
    setTabs(tabs => tabs.filter(tab => !tab.tabIdName?.includes(tabConfig.tabIdName + " ")));
    setCurrentTabIndex(0);
  };

  function onProjectDirectiveTableSelect(id) {
    setCurrentDirectiveId(id);
  }

  function onOpenDirectiveBtnClick(id) {
    const directiveId = id || currentDirectiveId;
    if (!directiveId) return;

    addNewTabCustom({
      TabComponent: MainDirective,
      controlled: true,
      title: `Directive №`,
      tabIdName: `Directive №${directiveId}`,
      type: readOnly ? MAIN_DIRECTIVE_TAB_TYPES.KP_VIEW : MAIN_DIRECTIVE_TAB_TYPES.FINBLOCK,
      onTabClose: onMainDirectiveTabClose,
      directiveId: directiveId,
    });

    // dispatch(fetchDirective({})).then(resp => {
    //   const currentDirective = resp.payload;
    //   // const directiveHasOrders = currentDirective?.orders?.length > 0;

    //   // dispatch(setCreatedDirective(currentDirective));

    //   // if (directiveHasOrders) {
    //   //   dispatch(setFinOrder(null));
    //   //   addNewTabCustom({
    //   //     TabComponent: OrderRegister,
    //   //     title: "Directive`s Order register",
    //   //     tabIdName: `Directive №${directiveId} orders`,
    //   //     readOnly,
    //   //   });
    //   // }
    // });
  }

  const onDoubleClick = directive => {
    setCurrentDirectiveId(directive.id);
    onOpenDirectiveBtnClick(directive.id);
  };

  function onOpenProjectBtnClick() {
    dispatch(fetchDirective({directiveId: currentDirectiveId}));
    addNewTabCustom({
      TabComponent: ProjectView,
      title: "Project View",
    });
  }

  function onPrintReceiptBtnClick() {}

  function onCancelDirectiveBtnClick() {
    if (!isCurrentDirectiveCreatedByCurrentUser) return;
    dispatch(fetchDirective({directiveId: currentDirectiveId})).then(resp => {
      const currentDirective = resp.payload;

      dispatch(setCreatedDirective(currentDirective));

      addNewTabCustom({
        TabComponent: MainDirective,
        type: MAIN_DIRECTIVE_TAB_TYPES.KP_VIEW,
        title: "Directive №",
        tabIdName: "viewOrderTabOnCancel",
      });
    });
  }

  function onDoubleCheckIconClick() {
    if (!areDirectivesSorted) {
      setProjectDirectiveTableData(prev =>
        [...prev]
          .sort((a, b) => {
            return dateTimeUnformatToUnix(b.createdAt) - dateTimeUnformatToUnix(a.createdAt);
          })
          .map((item, index) => {
            item.number = index + 1;
            return item;
          }),
      );
      setDirectivesSorted(true);
    } else {
      setProjectDirectiveTableData(mapedDirectives(allDirectives));
      setDirectivesSorted(false);
    }
  }

  return {
    getFilterData,
    onFilterDelete,
    projectDirectiveTableColumns,
    projectDirectiveTableData,
    onProjectDirectiveTableSelect,
    onOpenDirectiveBtnClick,
    onOpenProjectBtnClick,
    onPrintReceiptBtnClick,
    onCancelDirectiveBtnClick,
    currentDirectiveId,
    loading,
    isCurrentDirectiveCreatedByCurrentUser,
    filtersLayout,
    formPayerDataParamName,
    setFilterFields,
    onDoubleCheckIconClick,
    areDirectivesSorted,

    onDoubleClick,
  };
};

export default useProjectDirectiveList;
