import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {t} from "i18next";
import moment from "moment";
import Swal from "sweetalert2";

import {deleteReq, fetchData, fetchList} from "../../hook/axios.hook";
import {dateFormat} from "../../utilities/dateFormat";
import {getError, getSuccess} from "../../utilities/toasts";

const initialState = {
  projects: [],
  projectsIdList: [],
  currentProjectManager: null,
  currentProject: null,
  statusList: [],
  projectsLoadingStatus: false,
  docsLoading: false,
  editedProject: null,
  contracts: null,
  filters: null,
  allCOs: [],
  currentCO: null,
  annexes: [],
  einfo: [],
  currentProjectDocument: null,
  projectDocumentsLoading: false,
  allTodos: [],
  currentTodo: null,
  toDoLoadingStatus: false,
  deliveryTerms: [],
  kpLoadingStatus: false,
};

export const fetchProjects = createAsyncThunk("projects/fetchProjects", async () => {
  const responce = fetchList("/projects/getForCurrent", "get");
  return responce;
});

export const fetchProjectsWithFilter = createAsyncThunk("projects/fetchProjectsWithFilter", async params => {
  const responce = fetchData(`/projects/getAll`, "get", {params});
  return responce;
});

export const fetchProjectById = createAsyncThunk("projects/fetchProjectById", async projectId => {
  const responce = fetchData(`/projects/get/${projectId}`, "get");
  return responce;
});

export const fetchProjectByUserId = createAsyncThunk("projects/fetchProjectByUserId", async userId => {
  const responce = fetchData(`/projects/getForUser/${userId}`, "get");
  return responce;
});

export const addProject = createAsyncThunk("projects/addProject", async clientId => {
  const responce = fetchData(`/projects/add/${clientId}`, "post");
  return responce;
});

export const deleteProject = createAsyncThunk("projects/deleteProject", async projectId => {
  return fetchData(`/projects/remove/${projectId}`, "delete");
});

export const updateProject = createAsyncThunk("projects/updateProject", async ({projectId, data}) => {
  const responce = fetchData(`/projects/update/${projectId}`, "patch", data);
  return responce;
});

export const updateProjectStatus = createAsyncThunk("projects/updateProjectStatus", async ({projectId, statusId}) => {
  const responce = await fetchData(`/projects/update/${projectId} `, "patch", {
    statusId,
  });
  return responce;
});

export const getProjectStatuses = createAsyncThunk("projects/getStatusList", async () => {
  const responce = fetchData(`/references/projectsStatuses`, "get");
  // const responce = fetchData(`/references/forSku`, "get");

  return responce;
});

////////contract

export const fetchProjectContracts = createAsyncThunk("projects/fetchProjectContracts", async params => {
  return fetchData(`/contracts/getAll/`, "get", {params});
});

export const addProjectContract = createAsyncThunk("projects/addProjectContract", async projectId => {
  const responce = fetchList(`/contracts/add/${projectId}`, "post");
  return responce;
});

export const addProjectContractFile = createAsyncThunk(
  "projects/addProjectContractFile",
  async ({contractId, formdata}) => {
    return fetchData(`/contracts/files/upload/${contractId}`, "post", formdata);
  },
);

export const delProjectContractFile = createAsyncThunk("projects/delProjectContractFile", async fileId => {
  const responce = deleteReq(`/contracts/files/remove/${fileId}`);
  return responce;
});

export const updateProjectContract = createAsyncThunk("projects/updateProjectContract", async ({contractId, data}) => {
  const responce = fetchData(`/contracts/update/${contractId}`, "patch", data);
  return responce;
});

export const delProjectContract = createAsyncThunk("projects/delProjectContract", async contractId => {
  const responce = deleteReq(`/contracts/remove/${contractId}`);
  return responce;
});

////////einfo
export const fetchProjectEinfos = createAsyncThunk("projects/fetchProjectEinfos", async params => {
  return fetchData(`/einfo/getAll/`, "get", {params});
});
export const addEinfo = createAsyncThunk("projects/addEinfo", async projectId => {
  const responce = fetchData(`/einfo/add/${projectId}`, "post");
  return responce;
});
export const updateEinfo = createAsyncThunk("projects/updateEinfo", async ({einfoId, data}) => {
  const responce = fetchData(`/einfo/update/${einfoId}`, "patch", data);
  return responce;
});
export const addEinfoFile = createAsyncThunk("projects/addEinfoFile", async ({einfoId, formdata}) => {
  const responce = fetchData(`/einfo/files/upload/${einfoId}`, "post", formdata);
  return responce;
});
export const delEinfoFile = createAsyncThunk("projects/delEinfoFile", async fileId => {
  const responce = deleteReq(`/einfo/files/remove/${fileId}`);
  return responce;
});
export const delEinfo = createAsyncThunk("projects/delEinfo", async einfoId => {
  return deleteReq(`/einfo/remove/${einfoId}`);
});

export const fetchProjectFilters = createAsyncThunk("projects/fetchFilters", async () => {
  return fetchData(`/projects/getFilters`, "get");
});

//*** COs ***/
export const fetchAllCOs = createAsyncThunk("projects/fetchAllCOs", async ({projectId, isCurrent}) =>
  fetchList("/cos/getAll", "get", {params: {projectId, isCurrent}}),
);
export const fetchCO = createAsyncThunk("projects/fetchCO", async ({coId}) => fetchData(`/cos/get/${coId}`, "get"));
export const addCO = createAsyncThunk("projects/addCO", async ({projectId, body}) =>
  fetchData(`/cos/add/${projectId}`, "post", body),
);
export const updateCO = createAsyncThunk("projects/updateCO", async ({coId, body}) =>
  fetchData(`/cos/update/${coId}`, "patch", body),
);
export const deleteCO = createAsyncThunk("projects/delCO", async ({coId}) =>
  fetchData(`/cos/remove/${coId}`, "delete"),
);

//*** TODO */
export const fetchAllTodos = createAsyncThunk("projects/fetchAllTodos", async ({projectId, coId}) =>
  fetchList("/todo/getAll", "get", {params: {projectId, coId}}),
);
export const fetchTodo = createAsyncThunk("projects/fetchTodo", async ({todoId}) =>
  fetchList(`/todo/get/${todoId}`, "get"),
);
export const addTodo = createAsyncThunk("projects/addTodo", async ({projectId, coId, body}) =>
  fetchData(`/todo/add`, "post", body, null, false, {projectId, coId}, true),
);
export const updateTodo = createAsyncThunk("projects/updateTodo", async ({todoId, body}) =>
  fetchData(`/todo/update/${todoId}`, "patch", body, null, false, null, true),
);
export const deleteTodo = createAsyncThunk("projects/deleteTodo", async ({todoId}) =>
  fetchData(`/todo/remove/${todoId}`, "delete"),
);

//*** Delivery terms */
export const fetchAllDeliveryTerms = createAsyncThunk("projects/fetchAllDeliveryTerms", async () =>
  fetchList("/projects/deliveryTerms/getAll", "get"),
);

/////annex
export const addAnnex = createAsyncThunk("projects/addAnnex", async ({coId}) => {
  return fetchData(`/annexes/add/${coId}`, "post");
});

export const deleteAnnex = createAsyncThunk("projects/deleteAnnex", async annexId => {
  return fetchData(`/annexes/remove/${annexId}`, "delete");
});

export const fetchAnnexes = createAsyncThunk("projects/fetchAnnexes", async params => {
  return fetchData(`/annexes/getAll`, "get", {params});
});

export const getAnnexInfo = createAsyncThunk("projects/getAnnexInfo", async annexId => {
  return fetchData(`/annexes/get/${annexId}`, "get");
});

export const updateAnnex = createAsyncThunk("projects/updateAnnex", async ({annexId, data}) => {
  return fetchData(`/annexes/update/${annexId}`, "patch", data);
});

// export const preCreateDirective = createAsyncThunk("finSettings/preCreateDirective", async ({cofId}) => {
//   return fetchData(`/finance/directives/preCreate/${cofId}`, "post");
// });

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    annexesFetched: (state, action) => {
      // state.filteredAnnexes = action.payload
    },
    setEditedProject: (state, action) => {
      state.editedProject = action.payload;
    },
    deselectProject: (state, action) => {
      state.currentProject = null;
      state.contracts = [];
      // state.annexes = [];
      state.einfo = [];
    },
    setCurrentProjectManager: (state, action) => {
      state.currentProjectManager = action.payload;
    },
    setCurrentProjectDocument: (state, action) => {
      state.currentProjectDocument = action.payload;
    },
    clearCurrentCO: state => {
      state.currentCO = null;
    },
    clearCurrentTodo: state => {
      state.currentTodo = null;
    },
    clearCOs: state => {
      state.allCOs = [];
    },
    clearTodos: state => {
      state.allTodos = [];
    },
    clearDeliveryTerms: state => {
      state.deliveryTerms = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.pending, state => {
        state.projectsLoadingStatus = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectsLoadingStatus = false;
        state.projects = action.payload;
        // .map((project)=>{
        //     var proj ={};
        //     for(var key in project){
        //         proj[key] = project[key] || ''
        //     }
        //     return proj
        // });
        state.projectsIdList = action.payload.map(project => {
          return {number: project.projectNumber, id: project.projectId};
        });
      })
      .addCase(fetchProjectsWithFilter.pending, (state, action) => {
        state.projectsLoadingStatus = true;
      })
      .addCase(fetchProjectsWithFilter.fulfilled, (state, action) => {
        state.projectsLoadingStatus = false;
        state.projects = action.payload;
      })
      .addCase(fetchProjectByUserId.pending, state => {
        state.projectsLoadingStatus = true;
      })
      .addCase(fetchProjectByUserId.fulfilled, (state, action) => {
        state.projectsLoadingStatus = false;
        state.projects = action.payload;
        state.projectsIdList = action.payload.map(project => {
          return {number: project.projectNumber, id: project.projectId};
        });
      })
      .addCase(fetchProjectById.pending, state => {
        state.projectsLoadingStatus = true;
      })
      .addCase(fetchProjectById.fulfilled, (state, action) => {
        state.currentProject = action.payload;
        state.projectsLoadingStatus = false;
        // var proj = {};
        // for (var key in action.payload.project) {
        //   proj[key] = action.payload.project[key] || "";
        // }

        // state.currentProject = {
        //   ...proj,
        //   userId: proj?.projectManager?.userId,
        //   userName: proj?.projectManager?.name,
        //   designerBonus: proj?.designer?.bonus || "",
        //   designerId: proj?.designer.designerId,
        //   designerName: proj?.designer.name,
        //   orgName: proj.orgStructure.shortName,
        //   orgStructureId: proj.orgStructure.orgStructureId,
        // };
        // var myproj = state.projects.find(project => project.projectKey === proj.projectKey);
        // if (!myproj) {
        //   state.projects.unshift(proj);
        // }
        // state.contracts = action.payload.project?.contracts;

        // state.annexes = [].concat(...action.payload.project.cos.map(co => co.annexes));

        // state.einfo = action.payload.project.einfo;
      })
      .addCase(addProject.fulfilled, (state, {payload}) => {
        // state.projects.unshift(payload.project);
        state.currentProject = payload.project;
      })
      .addCase(updateProject.fulfilled, (state, {payload}) => {
        const {project} = payload;
        state.currentProject = payload.project;
        state.projects = state.projects.map(item => {
          if (item.projectId === project.projectId) {
            return project;
          } else return item;
        });
      })
      .addCase(addProject.rejected, (state, {error}) => {
        getError(error);
      })
      .addCase(deleteProject.fulfilled, (state, {payload}) => {
        // state.projects.unshift(payload.project);
        state.currentProject = null;
        getSuccess(t("project deleted"));
      })
      .addCase(updateProjectStatus.fulfilled, (state, {payload, meta}) => {
        const updatedProject = payload.project;
        state.currentProject = {
          ...state.currentProject,
          ...updatedProject,
        };
      })
      .addCase(getProjectStatuses.fulfilled, (state, {payload}) => {
        state.statusList = payload;
      })
      ////contracts
      .addCase(fetchProjectContracts.pending, state => {
        state.projectDocumentsLoading = true;
      })
      .addCase(fetchProjectContracts.fulfilled, (state, {payload}) => {
        state.contracts = payload;
        state.projectDocumentsLoading = false;
      })
      .addCase(addProjectContract.fulfilled, (state, {payload}) => {
        state.contracts.push({
          ...payload.contract,
          deadline: moment().format("YYYY-MM-DD"),
          // contractDeadline: dateFormat(payload.contract.contractDeadline),
        });
      })
      .addCase(updateProjectContract.fulfilled, (state, {payload}) => {
        const {contract, message} = payload;
        state.contracts = state.contracts.map(item => {
          if (item.contractId === contract.contractId) {
            return {
              ...contract,
              contractDeadline: contract.contractDeadline,
            };
          } else return item;
        });
        console.log(payload);
        state.projects = state.projects.map(project => {
          if (project.projectId === state.currentProject.projectId) {
            return {...project, deadline: payload.contract?.deadline};
          } else return project;
        });
      })
      .addCase(addProjectContractFile.pending, state => {
        state.docsLoading = true;
      })
      .addCase(addProjectContractFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        // state.contracts = state.contracts.map(contract => {
        //   if (contract.contractId === meta.arg.contractId) {
        //     return {...contract, docsArray: payload.docsArray};
        //   } else {
        //     return contract;
        //   }
        // });
        state.docsLoading = false;
      })
      .addCase(delProjectContractFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        // state.contracts = state.contracts.map(contract => {
        //   if (contract.contractId === meta.arg.contractId) {
        //     return {...contract, docsArray: payload.docsArray};
        //   } else {
        //     return contract;
        //   }
        // });
      })
      .addCase(delProjectContract.fulfilled, (state, action) => {
        const {meta} = action;
        state.contracts = state.contracts.filter(contract => contract.contractId !== meta.arg);
      })

      // Einfo
      .addCase(fetchProjectEinfos.pending, state => {
        state.projectDocumentsLoading = true;
      })
      .addCase(fetchProjectEinfos.fulfilled, (state, {payload}) => {
        state.einfo = payload;
        state.projectDocumentsLoading = false;
      })
      .addCase(addEinfo.fulfilled, (state, {payload}) => {
        state.einfo.unshift({
          ...payload.einfo,
          dateFrom: payload.einfo.dateFrom,
        });
      })
      .addCase(updateEinfo.fulfilled, (state, {payload}) => {
        const {einfo, message} = payload;
        state.einfo = state.einfo.map(item => {
          if (item.einfoId === einfo.einfoId) {
            return {...einfo, dateFrom: einfo.dateFrom};
          } else return item;
        });
      })
      .addCase(addEinfoFile.pending, state => {
        state.docsLoading = true;
      })
      .addCase(addEinfoFile.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.einfo = state.einfo.map(ein => {
          if (ein.einfoId === meta.arg.einfoId) {
            return {...ein, docsArray: payload.docsArray};
          } else {
            return ein;
          }
        });
        state.docsLoading = false;
      })
      // .addCase(delEinfo.fulfilled, (state, action) => {
      //   const {payload, meta} = action;
      //   state.einfo = state.einfo.map(einfo => {
      //     if (einfo.einfoId === meta.arg.einfoId) {
      //       return {...einfo, docsArray: payload.docsArray};
      //     } else {
      //       return einfo;
      //     }
      //   });
      // })
      .addCase(delEinfo.fulfilled, (state, action) => {
        const {meta} = action;
        state.einfo = state.einfo.filter(einfo => einfo.einfoId !== meta.arg);
      })
      .addCase(fetchProjectFilters.pending, state => {
        state.projectsLoadingStatus = true;
      })
      .addCase(fetchProjectFilters.fulfilled, (state, {payload}) => {
        state.projectsLoadingStatus = false;
        state.filters = payload;
      })

      //*** COs ***/
      .addCase(fetchAllCOs.pending, (state, {payload}) => {
        state.kpLoadingStatus = true;
      })
      .addCase(fetchAllCOs.fulfilled, (state, {payload}) => {
        state.kpLoadingStatus = false;
        state.allCOs = payload;
      })
      .addCase(fetchCO.fulfilled, (state, {payload}) => {
        state.currentCO = payload;
      })
      .addCase(addCO.fulfilled, (state, {payload}) => {
        state.currentCO = payload.co;
      })
      .addCase(updateCO.fulfilled, (state, {payload}) => {
        state.currentCO = payload.co;
      })
      .addCase(deleteCO.fulfilled, state => {
        state.currentCO = null;
      })

      // .addCase(preCreateDirective.fulfilled, (state, {payload}) => {
      //   state.currentCO = payload.co;
      // })

      //// Annexes
      .addCase(fetchAnnexes.pending, state => {
        state.projectDocumentsLoading = true;
      })
      .addCase(fetchAnnexes.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.annexes = payload;
        state.projectDocumentsLoading = false;
      })
      .addCase(updateAnnex.fulfilled, (state, action) => {
        const {payload, meta} = action;
        state.annexes = state.annexes.map(annex => {
          if (annex.annexId === meta.arg.annexId) {
            return payload.annex;
          }
          return annex;
        });
      })

      .addCase(deleteAnnex.fulfilled, (state, action) => {
        const {meta} = action;
        state.annexes = state.annexes.filter(annexe => annexe.annexId !== meta.arg);
      })
      //*** TODO */
      .addCase(fetchAllTodos.pending, (state, {payload}) => {
        state.toDoLoadingStatus = true;
      })
      .addCase(fetchAllTodos.fulfilled, (state, {payload}) => {
        state.toDoLoadingStatus = false;
        state.allTodos = payload;
      })
      .addCase(fetchTodo.fulfilled, (state, {payload}) => {
        state.currentTodo = payload;
      })
      .addCase(addTodo.fulfilled, (state, {payload}) => {
        state.currentTodo = payload.todo;
      })
      .addCase(updateTodo.fulfilled, (state, {payload}) => {
        state.currentTodo = payload.todo;
      })
      .addCase(deleteTodo.fulfilled, state => {
        state.currentTodo = null;
      })

      //*** Delivery terms */
      .addCase(fetchAllDeliveryTerms.fulfilled, (state, {payload}) => {
        state.deliveryTerms = payload;
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = projectSlice;

export default reducer;
export const {
  selectProject,
  setEditedProject,
  deselectProject,
  annexesFetched,
  setCurrentProjectManager,
  setCurrentProjectDocument,
  clearCurrentCO,
  clearCOs,
  clearCurrentTodo,
  clearTodos,
  clearDeliveryTerms,
} = actions;

// export const filteredAnnexSelector = createSelector(
//   state => state.projects.annexes,
//   state => state.kp.currentKp,
//   (annexes, currentKp) => {
//     if (!currentKp) {
//       return annexes;
//     } else {
//       return annexes.filter(item => item.coId === currentKp.coId);
//     }
//   },
// );

export const getProjectContracts = state => state.projects.contracts;
export const getCurrentProject = state => state.projects.currentProject;
export const getProjects = state => state.projects.projects;
// export const getProjectAnnexes = state => state.projects.annexes;
export const getProjectEinfo = state => state.projects.einfo;
export const getDocsLoading = state => state.projects.docsLoading;
export const getProjectStatus = state => state.projects.statusList;
export const getCurrentProjectManager = state => state.projects.currentProjectManager;
export const getProjectFilters = state => state.projects.filters;
export const getProjectDocument = state => state.projects.currentProjectDocument;
export const selectAllCOs = state => state.projects.allCOs;
export const selectCurrentCO = state => state.projects.currentCO;
export const selectAllTodos = state => state.projects.allTodos;
export const selectCurrentTodo = state => state.projects.currentTodo;
export const selectDeliveryTerms = state => state.projects.deliveryTerms;

export const getCoAnnexes = state => state.projects.annexes;
export const getProjectLoading = state => state.projects.projectsLoadingStatus;
export const getCOLoading = state => state.projects.kpLoadingStatus;
export const getToDoLoading = state => state.projects.toDoLoadingStatus;
export const getProjectDocumentLoading = state => state.projects.projectDocumentsLoading;
