import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../hook/axios.hook";

const initialState = {
  cashdeskDirective: null,
  bankTransactions: [],
  bankClientStatements: [],
  loading: false,
  error: false,
};

//*** Operations ***

export const addNewCashdeskDirective = createAsyncThunk("finBlock/addNewCashdeskDirective", async body => {
  return fetchData(`/finance/directives/addForCashdesk`, "post", body);
});

///***  Bank Client */
export const uploadBankClientStatements = createAsyncThunk("finBlock/uploadBankClientStatements", async formdata => {
  return fetchData(`/finance/bankClient/upload`, "post", formdata);
});

export const fetchAllBankClientStatements = createAsyncThunk("finBlock/fetchAllBankClientStatements", async () => {
  return fetchList(`/finance/bankClient/statements/getAll`);
});

export const fetchAllBankTransactions = createAsyncThunk("finBlock/fetchAllBankTransactions", async () => {
  return fetchList(`/finance/bankClient/bankTransactions/getAll`);
});

export const FIN_BLOCK_STORE_NAME = "finBlock";

const FinBlockSlice = createSlice({
  name: FIN_BLOCK_STORE_NAME,
  initialState,
  reducers: {
    clearCashdeskDirective: state => {
      state.cashdeskDirective = null;
    },
  },
  extraReducers: builder => {
    builder
      //** Balance Sheet Accounts **
      .addCase(addNewCashdeskDirective.fulfilled, (state, {payload}) => {
        state.cashdeskDirective = payload.directive;
      })

      .addCase(uploadBankClientStatements.fulfilled, (state, {payload}) => {
        // state.cashdeskDirective = payload.directive;
      })

      .addCase(fetchAllBankTransactions.fulfilled, (state, {payload}) => {
        state.bankTransactions = payload;
      })

      .addCase(fetchAllBankClientStatements.fulfilled, (state, {payload}) => {
        state.bankClientStatements = payload;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(FIN_BLOCK_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(FIN_BLOCK_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(FIN_BLOCK_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.finSettingsLoading = false;
  state.error = false;
}

function handlePending(state) {
  state.finSettingsLoading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.finSettingsLoading = false;
  state.error = error;
}

const {actions, reducer} = FinBlockSlice;

export const {clearCashdeskDirective} = actions;

export default reducer;

// *** Selectors ***
export const selectCashdeskDirective = state => state[FIN_BLOCK_STORE_NAME].cashdeskDirective;

export const selectBankClientStatements = state => state[FIN_BLOCK_STORE_NAME].bankClientStatements;
export const selectBankTransactions = state => state[FIN_BLOCK_STORE_NAME].bankTransactions;
