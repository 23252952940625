import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";

import {deselectSku} from "../../../SKU/SkuSlice";
import {deselect, fetchSkuPlanListForKpf, getCurrentSkuPlan, updateSkuPlan} from "../KpFinSlice";

import KpFinForm from "./KpFinForm";

const KpUpdateForm = ({setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  const onSubmit = values => {
    dispatch(
      updateSkuPlan({
        skuPlanId: values.skuPlanId,
        skuPlan: {
          ...values,
          discount: values.discount === "" ? null : values.discount,
          topDiscount: values.topDiscount === "" ? null : values.topDiscount,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        // resetForm();
        setModalActive(false);
        dispatch(deselect());
      }
    });
  };
  const onCancel = () => {
    dispatch(deselect());
    dispatch(deselectSku());

    dispatch(fetchSkuPlanListForKpf(currentSkuPlan?.cof?.cofId));

    setModalActive(false);
  };

  return (
    <KpFinForm
      saveLabel={t("Save")}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isShowOverallView={true}
      toolTip="save and return kp upd"
    />
  );
};

KpUpdateForm.propTypes = {setModalActive: PropTypes.func};

export default KpUpdateForm;
