import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import ReactTable from "../../../conteiners/ReactTable";
import {Select} from "../../../Inputs/SelectField";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {selectOperationTypes} from "../../FinSettings/FinSettingsSlice";
import {CASHDESK_DIRECTIVE_TYPES} from "../CashdeskDirective";
import {DIRECTIVE_TYPE_PART_TABLE_COLUMNS} from "../constants";

const DirectiveTypePart = ({currentDirectiveSubTypeId, setCurrentDirectiveSubTypeId, directiveType}) => {
  const {
    i18n: {language},
  } = useTranslation();
  const {setFieldValue, values} = useFormikContext();

  const CASHDESK_OPERATION_TYPE_IDS = Object.freeze({
    [CASHDESK_DIRECTIVE_TYPES.ADVENT]: [1, 6],
    [CASHDESK_DIRECTIVE_TYPES.EXPENCE]: [4],
    [CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE]: [],
    [CASHDESK_DIRECTIVE_TYPES.TRANSIT]: [],
  });

  useEffect(() => {
    setFieldValue("directiveSubTypeId", currentDirectiveSubTypeId);
  }, [currentDirectiveSubTypeId]);

  useEffect(() => {
    setCurrentDirectiveSubTypeId(values.directiveSubTypeId);
  }, [values.directiveSubTypeId]);

  const operationTypesRow = useSelector(selectOperationTypes);
  const operationTypes = operationTypesRow.filter(type =>
    CASHDESK_OPERATION_TYPE_IDS[directiveType].includes(type.operationTypeId),
  );

  const directiveTypeOptions =
    operationTypes?.map(({operationTypeId, nameEng, nameUkr}) => ({
      value: operationTypeId,
      title: language === "en" ? nameEng : nameUkr,
    })) ?? [];
  const columns = useColumns(DIRECTIVE_TYPE_PART_TABLE_COLUMNS);
  const defaultData =
    operationTypes?.map(({operationTypeId, nameEng, nameUkr}, index) => ({
      id: operationTypeId,
      number: ++index,
      directiveType: language === "en" ? nameEng : nameUkr,
    })) ?? [];

  const onTableSelect = operationTypeId => {
    setCurrentDirectiveSubTypeId(operationTypeId);
  };

  return (
    <div className="fcCol gap fGrow aistr" style={{maxWidth: "32%"}}>
      <Select name="directiveSubTypeId" label={t("Directive type")} options={directiveTypeOptions} autolabel required />
      <ReactTable
        columns={columns}
        defaultData={defaultData}
        className="scrollX scrollY fGrow"
        current={currentDirectiveSubTypeId}
        onSelect={onTableSelect}
      />
    </div>
  );
};

export default DirectiveTypePart;
