import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import Swal from "sweetalert2";

import {fetchData} from "../../../../hook/axios.hook";
import {dateFormat} from "../../../../utilities/dateFormat";
import formatAccountNumber from "../../../../utilities/formatAccountNumber";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../utilities/hooks/toaster";
import {getError, getInfo} from "../../../../utilities/toasts";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {
  addNewTransaction,
  compleateOrder,
  deleteOrder,
  fetchFinOrder,
  runOrder,
  saveFinOrder,
  selectFinOrder,
  setFinOrder,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";

import {ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS, ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS} from "./constants";
import {generateDirectiveOrderPdf} from "./orderFileGenerate";

const useOrderDirectiveView = orderId => {
  const {
    i18n: {language},
    t,
  } = useTranslation();
  const dispatch = useDispatch();

  const {setValues, setFieldValue, values} = useFormikContext();
  const {currentTransactionId} = values || {};
  const {closeCurrentTab} = useTabsContext();

  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [printModalActive, setPrintModalActive] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const currentOrder = useSelector(selectFinOrder);
  const directive = currentOrder?.directive;
  const currentOrderId = currentOrder?.orderId;
  const isOrderSecondary = currentOrder?.typeId === 6;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!orderId) {
      setLoading(false);
      return;
    }
    dispatch(fetchFinOrder(orderId)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
      } else {
        getInfo(t("Order not found"));
        closeCurrentTab();
      }
    });
  }, []);

  useEffect(() => {
    setFieldValue("compleateNote", currentOrder?.directive?.note || "");
    setFieldValue("currentTransactionId", null);
  }, [currentOrder]);

  useEffect(() => {
    setValues(prev => {
      if (prev.paymentCurrency !== currentTransaction?.debitAccount.currencyId) {
        checkExchangeRate(currentTransaction?.debitAccount?.currencyId, false);
      }
      return {
        ...prev,
        dt: formatAccountNumber(currentTransaction?.debitAccount.accountNumber) || "",
        kt: formatAccountNumber(currentTransaction?.creditAccount.accountNumber) || "",
        debitAccountName: currentTransaction?.debitAccount.accountName || "",
        creditAccountName: currentTransaction?.creditAccount.accountName || "",
        paymentPurpose: currentTransaction?.paymentPurpose || "",
        paymentSumm: currentTransaction?.sum || "",
        paymentCurrency: currentTransaction?.debitAccount.currencyId,
        paymentNote: "",
      };
    });
  }, [currentTransaction?.transactionId, currentOrder?.orderId]);

  useEffect(() => {
    setCurrentTransaction(
      currentTransactionId
        ? currentOrder?.transactions?.find(transaction => transaction.transactionId === currentTransactionId)
        : null,
    );
  }, [currentTransactionId, currentOrder]);

  useEffect(() => {
    if (!directive?.directiveId || !currentOrderId || currentOrderId === orderId || currentOrder?.typeId === 6) {
      const {status, co, operationType, note, recipient, sumPlan, sumFact, fulfilBy, payer} = directive || {};
      const {project, account, coNumber, sum, prepayment} = co || {};
      const {loanTerms} = currentOrder || {};
      const outsideParty = currentOrder?.directive?.outsideParty || {};

      setValues(prev => ({
        ...prev,
        compleateNote: "",
        fulfilBy,
        directiveType: "",
        status: status ? (language === "en" ? status.nameEng : status.nameUkr) : "",
        coNumber,
        coBalance: account?.balance,
        clientBalance: project?.client?.account?.balance,
        clientProject: project?.butget,
        coSum: sum,
        advance: (sum && (sumPlan / sum) * 100) || "0",
        nSum: sumPlan,
        fact: (sum && (account?.balance / sum) * 100) || "0",
        operationTypeId: operationType?.operationTypeId,
        executorNote: note ?? t("No executor note"),
        finOrderTypeId: currentOrder?.type?.typeId ?? "",

        payerCoBalanceComposed: payer?.balance ?? "-",
        clientNumber: payer?.clientNumber ?? "-",
        payerClientNumberComposed: payer?.clientNumber ?? "-",
        payerClientBalanceComposed: payer?.balance ?? "-",
        payerCreditForCoComposed: payer?.balance ?? "-",

        recipientClientBalanceComposed: recipient?.balance ?? "-",
        recipientCoBalanceComposed: recipient?.balance ?? "-",
        recipientClientNumberComposed: recipient?.clientNumber ?? "-",
        recipientCreditForCoComposed: recipient?.balance ?? "-",

        creditTermComposed: loanTerms?.termUpTo ?? "-",
        perAnnumComposed: loanTerms?.annualInterestRate ?? "-",
        repaymentTermIdComposed:
          currentOrder?.loanTerms?.repaymentTerms?.[language === "en" ? "nameEng" : "nameUkr"] ||
          loanTerms?.repaymentTerms?.[language === "en" ? "nameEng" : "nameUkr"],
        repaymentTermsSumId:
          currentOrder?.loanTerms?.repaymentTermsSum?.[language === "en" ? "nameEng" : "nameUkr"] ||
          loanTerms?.repaymentTermsSum?.[language === "en" ? "nameEng" : "nameUkr"],

        creditSumComposed: currentOrder?.sum ?? "-",

        payerNameComposed: outsideParty?.name ?? "-",
        edrpouoComposed: outsideParty?.edrpouCode ?? "-",
        payerAccountComposed: outsideParty?.currentAccount ?? "-",

        sumComposed: currentOrder?.sum ?? "-",
        currencyIdComposed: currentOrder?.currencyId ?? "-",

        coSumComposed: sum ?? "-",
        fSumComposed: sumFact ?? "-",
        coSumAdditionalComposed: prepayment ?? "-",

        apSumComposed: sumPlan ?? "-",
        advancePercentageComposed: Number.parseFloat(co?.sum)
          ? ((Number.parseFloat(sumPlan) / Number.parseFloat(co?.sum)) * 100).toFixed(2)
          : "0",
        afSumComposed: sumFact ?? "-",
      }));
      if (currentOrder?.transactions?.length > 0) {
        onTransactionSelect(currentOrder?.transactions[0].transactionId);
      }
    }
  }, [currentOrder]);

  const upperTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_UPPER_TABLE_COLUMNS);
  const upperTableData =
    currentOrder?.status?.statusId === 1
      ? currentOrder?.transactions?.map(
          ({transactionId, creditAccount, debitAccount, paymentPurpose, sum, createdAt}, index) => ({
            id: transactionId,
            number: ++index,
            date: dateFormat(createdAt),
            sum,
            dt: formatAccountNumber(debitAccount.accountNumber),
            dtType: debitAccount.balanceSheet.type.balanceTypeName,
            kt: formatAccountNumber(creditAccount.accountNumber),
            ktType: creditAccount.balanceSheet.type.balanceTypeName,
            paymentPurpose,
            dtAccountName: debitAccount.accountName,
            ktAccountName: creditAccount.accountName,
            note: "",
          }),
        ) || []
      : [];

  const onTransactionSelect = id => {
    setFieldValue("currentTransactionId", id);
    const transaction = id ? currentOrder?.transactions?.find(transaction => transaction.transactionId === id) : null;
    setCurrentTransaction(transaction);
  };

  const lowerTableColumns = useColumns(ORDER_DIRECTIVE_VIEW_LOWER_TABLE_COLUMNS);
  const lowerTableData =
    currentOrder?.status?.statusId !== 1
      ? currentOrder?.transactions?.map(({transactionId, creditAccount, debitAccount, paymentPurpose, sum}, index) => ({
          id: transactionId,
          number: ++index,
          dateTo: "-",
          factDate: "-",
          directiveNumber: currentOrder?.directive?.directiveNumber,
          dt: formatAccountNumber(debitAccount.accountNumber),
          kt: formatAccountNumber(creditAccount.accountNumber),
          sumF: sum,
          paymentPurpose,
          note: "",
        })) || []
      : [];

  const onExitBtnClick = () => {
    swalCustom.confirm({
      confirmFunc: () => {
        closeCurrentTab();
      },
    });
  };

  useEffect(() => {
    if (!values?.debitAccountId) {
      setFieldValue(`debitAccountName`, "");
      setFieldValue(`dt`, "");
    }
  }, [values?.debitAccountId]);
  useEffect(() => {
    if (!values?.creditAccountId) {
      setFieldValue(`creditAccountName`, "");
      setFieldValue(`ct`, "");
    }
  }, [values?.creditAccountId]);

  const checkExchangeRate = (curr, showEror) => {
    if (curr === 978) {
      setFieldValue("exchangeRate", 1);
      return;
    }
    if (curr) {
      fetchData(`/finance/exchangeRates/get/${curr}`, "get", null, null, true)
        .then(res => {
          setFieldValue("exchangeRate", res?.saleRate);
        })
        .catch(() => {
          if (showEror) getError(t("Currency Rate for this currency not found"));
          setFieldValue("exchangeRate", "");
        });
    } else {
      setFieldValue("exchangeRate", "");
    }
  };

  const defineBalance = (accountNumber, type) => {
    if (!accountNumber || accountNumber === "") {
      setFieldValue(`${type}AccountId`, null);
      return;
    }
    fetchData(`/finance/accounts`, "get", {params: {accountNumber: accountNumber.trim()}}, null, true)
      .then(res => {
        if (res?.length > 0) {
          setFieldValue(`${type}AccountName`, res[0].accountName);
          setFieldValue(`${type}AccountId`, res[0].accountId);
        } else {
          getInfo("No such balance find");
          setFieldValue(`${type}AccountId`, null);
        }
      })
      .catch(e => {
        getInfo(e);
      });
  };

  const onCreateTransaction = () => {
    const {debitAccountId, creditAccountId, paymentNote, paymentPurpose, paymentSumm} = values;
    dispatch(
      addNewTransaction({
        orderId: currentOrderId,
        body: {
          debitAccountId,
          creditAccountId,
          sum: paymentSumm,
          paymentPurpose,
          note: paymentNote,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled")
        setValues(prev => ({
          ...prev,
          debitAccountId: "",
          creditAccountId: "",
          paymentNote: "",
          paymentPurpose: "",
          paymentSumm: "",
        }));
    });
  };

  const onCreateOrder = () => {
    dispatch(saveFinOrder({orderId: currentOrderId, note: values?.compleateNote}));
  };

  const onRunOrder = () => {
    dispatch(
      runOrder({
        orderId: currentOrderId,
        body: {
          currencyId: values?.paymentCurrency,
          sum: values?.paymentSumm,
        },
      }),
    )
      .then(res => {
        dispatch(setFinOrder({...currentOrder, ...res?.payload?.order}));
        setFieldValue("currentTransactionId", null);
        setValues(prev => ({
          ...prev,
          debitAccountId: "",
          creditAccountId: "",
          paymentNote: "",
          paymentPurpose: "",
          paymentSumm: "",
        }));
      })
      .catch(e => {
        console.error(e);
      });
  };

  const onPrintOrderBtnClick = () => {
    const data = {
      ...currentOrder,
      coNumber: currentOrder.directive.co?.coNumber,
      coManager: currentOrder.directive.co?.coManager?.name,
      operationType: currentOrder.directive?.operationType["nameUkr"],
    };
    const pdf = generateDirectiveOrderPdf(data);
    setPdfFile(pdf);
    setPrintModalActive(true);
    return pdf;
  };

  const onDeleteOrder = () => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: t("Sure"),
        text: t("Order will be removed", {orderNumber: currentOrder.orderNumber}),
        icon: "question",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),
      }).then(value => {
        if (value.isConfirmed) {
          dispatch(deleteOrder(currentOrderId))
            .then(res => {
              setFieldValue("paymentSumm", "");
              resolve(res);
            })
            .catch(err => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    });
  };

  const [isClientOverdraftModActive, setClientOverdraftModActive] = useState(false);
  const [isClientCreditingModActive, setClientCreditingModActive] = useState(false);
  const [modalData, setModalData] = useState(null);

  const onConduct = () => {
    return new Promise((resolve, reject) => {
      //after completing overdraft order - opening overdraft modal
      if (isOrderSecondary) {
        setModalData(prev => ({...prev, orderId: currentOrderId, note: values?.compleateNote}));
        setClientOverdraftModActive(true);
        resolve();
        return;
      }
      fetchData(`finance/directives/orders/complete/${currentOrderId}`, "patch", {
        note: `${directive?.note ? directive?.note?.trim() + "\n" : ""}  ${values?.compleateNote?.trim()}`,
      })
        .then(resp => {
          const {
            creditForShortage,
            order: {
              currency: {currencyId, shortName: currency},
            },
          } = resp ?? {};
          const {orderId, overdraftMessage, shortageSum, isOverdraft} = creditForShortage ?? {};

          setFieldValue("compleateNote", "");

          //fetching overdraft order => run => save
          if (orderId && isOverdraft) {
            toaster.info(overdraftMessage);
            setModalData({
              orderId,
              shortageSum,
              overdraftMessage,
              currency,
              note: `${directive?.note ? directive?.note?.trim() + "\n" : ""}  ${values?.compleateNote?.trim()}`,
            });
            fetchData(`/finance/directives/orders/get/${orderId}`, "get").then(overdraftOrder => {
              if (!overdraftOrder) return;
              dispatch(setFinOrder(overdraftOrder));
              dispatch(
                runOrder({
                  orderId,
                  body: {
                    currencyId,
                    sum: shortageSum,
                  },
                }),
              ).then(res => {
                if (res.meta.requestStatus === "fulfilled") {
                  dispatch(saveFinOrder({orderId, note: values?.compleateNote})).then(res => {
                    if (res.meta.requestStatus === "fulfilled") {
                      setModalData(prev => ({
                        ...prev,
                        orderId: res.payload?.order?.orderId,
                        note: values?.compleateNote,
                      }));
                      setClientOverdraftModActive(true);
                      resolve();

                      return;
                    }
                  });
                }
              });
            });
          } else {
            // if no credit or overdraft - closing modal and cleaning state
            toaster.success("Order compleated");
            resolve(resp);
          }
        })
        .catch(err => {
          console.error(err);
          reject(err);
        });
    });
  };

  const onCompleateOrder = prop => {
    const {noteWithoutMerge} = prop || {};
    dispatch(
      compleateOrder({
        orderId: currentOrderId,
        note: noteWithoutMerge
          ? values?.compleateNote
          : `${directive?.note ? directive?.note + " \n" : ""}  ${values?.compleateNote}`,
      }),
    ).then(({payload}) => {
      const {
        creditForShortage: {orderId, shortageSum, isRequired, isOverdraft, isCredit},
        order: {
          currency: {currencyId},
        },
      } = payload ?? {};
      dispatch(setFinOrder(payload.order));
      setFieldValue("compleateNote", "");

      //after completing overdraft order
      if (isPrimaryOrderCompleted && isOverdraft && !isCredit) {
        setClientOverdraftModActive(true);
        return;
      }
      if (!isRequired) return;

      //fetching overdraft order => run => save
      if (orderId) {
        setPrimaryOrderCompleted(true);
        fetchData(`/finance/directives/orders/get/${orderId}`, "get").then(overdraftOrder => {
          if (!overdraftOrder) return;
          dispatch(setFinOrder(overdraftOrder));
          dispatch(
            runOrder({
              orderId,
              body: {
                currencyId,
                sum: shortageSum,
              },
            }),
          ).then(res => {
            if (res.meta.requestStatus === "fulfilled") {
              dispatch(saveFinOrder({orderId, note: values?.compleateNote}));
            }
          });
        });
      }
    });
  };

  const runOrderBtnDisabled = values?.paymentSumm && values?.paymentCurrency && values?.exchangeRate;

  const isOrderSaved = currentOrder?.status?.statusId === 2;
  const isOrderCompleate = currentOrder?.status?.statusId === 3;
  const [isPrimaryOrderCompleted, setPrimaryOrderCompleted] = useState(false);

  const saveTransactionBtnDisabled =
    values?.paymentSumm &&
    values?.paymentSumm !== "" &&
    values?.debitAccountId &&
    values?.creditAccountId &&
    values?.debitAccountId !== "" &&
    values?.creditAccountId !== "" &&
    values?.paymentPurpose &&
    values?.paymentPurpose !== "";

  return {
    upperTableColumns,
    upperTableData,
    lowerTableColumns,
    lowerTableData,
    onPrintOrderBtnClick,
    onExitBtnClick,
    currentTransactionId,
    defineBalance,
    onCreateTransaction,
    saveTransactionBtnDisabled,
    onCreateOrder,
    currentOrderId,
    onRunOrder,
    runOrderBtnDisabled,
    onDeleteOrder,
    isOrderSaved,
    onCompleateOrder,
    isOrderCompleate,
    onTransactionSelect,
    printModalActive,
    setPrintModalActive,
    pdfFile,
    currentOrderNumber: currentOrder?.orderNumber || null,
    currentOrder,
    checkExchangeRate,
    loading,
    isPrimaryOrderCompleted,
    setPrimaryOrderCompleted,
    isClientOverdraftModActive,
    setClientOverdraftModActive,
    isClientCreditingModActive,
    setClientCreditingModActive,
    modalData,
    setModalData,
    isOrderSecondary,
    onConduct,
  };
};

export default useOrderDirectiveView;
