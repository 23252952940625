import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Divider} from "@mui/material";
import {useFormikContext} from "formik";
import moment from "moment";
import Swal from "sweetalert2";

import {fetchData} from "../../../../hook/axios.hook";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {
  addNewFinOrder,
  runOrder,
  saveFinOrder,
  selectCreatedDirective,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {DateInput, MoneyInput, Select, TextAreaInput, TextInput} from "../../../Inputs";
import withDirectiveWithDocsHOC from "../MainDirective/DirectiveWithDocsHOC";
import RecipientClientBalance from "../OrderChangingPart/Parts/bottom/RecipientClientBalance";

const CreditOrder = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const {values, setValues} = useFormikContext();
  const {changeCurrentTabName, closeCurrentTab} = useTabsContext();
  const [creditParams, setCreditParams] = useState([]);
  const {creditParametr} = values;
  const directive = useSelector(selectCreatedDirective);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(`/finance/creditParams/getAll`, "get", {params: {type: "credit"}})
      .then(resp => {
        setValues(prev => ({
          ...prev,
          finOrderTypeId: 5,
          creditSumCoAdvance: +directive?.sumPlan - +directive?.sumFact,
          creditForCoNumber: directive?.co?.coNumber,
        }));
        setCreditParams(resp);
        changeCurrentTabName(`${t("Directive №")} ${directive?.directiveNumber} (${t("Lending")})`);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (creditParametr) {
      const selectedCreditParams = creditParams?.find(param => param?.paramId === creditParametr);
      setValues(prev => ({
        ...prev,
        perAnnumComposed: selectedCreditParams?.annualInterestRate,
        repaymentTermIdComposed: selectedCreditParams?.repaymentTerms?.[language === "en" ? "nameEng" : "nameUkr"],
        repaymentTermsSumId: selectedCreditParams?.repaymentTermsSum?.[language === "en" ? "nameEng" : "nameUkr"],
      }));
    }
  }, [creditParametr, creditParams]);

  const creditOptions = useMemo(
    () =>
      creditParams?.map(param => ({
        value: param?.paramId,
        title: param?.name,
      })),
    [creditParams],
  );

  const onCreditRequest = () => {
    dispatch(
      addNewFinOrder({
        directiveId: directive?.directiveId,
        body: {
          typeId: values?.finOrderTypeId,
          sum: values?.creditSumCoAdvance,
          paramId: values?.creditParametr,
          termUpTo: values?.termUpTo,
        },
      }),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        const orderId = resp.payload.directive.orders[resp.payload?.directive.orders.length - 1].orderId;
        dispatch(
          runOrder({
            orderId: orderId,
            body: {
              currencyId: 978,
              sum: values?.creditSumCoAdvance,
            },
          }),
        ).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            dispatch(saveFinOrder({orderId, note: values?.compleateNote})).then(res => {
              if (res.meta.requestStatus === "fulfilled") {
                Swal.fire({
                  text: t("A loan request has been created"),
                  icon: "success",
                  confirmButtonText: t("Ок"),
                  showCancelButton: false,
                  customClass: {
                    popup: "zindex",
                    container: "zindex",
                    htmlContainer: "zindex",
                  },
                }).then(() => {
                  if (values?.compleateNote) {
                    fetchData(`finance/directives/update/${directive?.directiveId}`, "patch", {
                      note: `${directive?.note ? directive?.note?.trim() + "\n" : ""}${values?.compleateNote?.trim()}`,
                    })
                      .then(() => closeCurrentTab())
                      .catch(() => {});
                  }
                });
              }
            });
          }
        });
      }
    });
  };
  const onCancel = () => {
    closeCurrentTab();
  };

  return (
    <div className={`fcCol aistr gap jcsb fGrow`}>
      <>
        <Select
          name="finOrderTypeId"
          label={"Order type"}
          autolabel
          options={[{value: 5, title: t("Credit for CO")}]}
        />
        <DateInput name="fulfilBy" label={t("Term Until")} autolabel fromCurrentDate readOnly />
      </>

      <div className="fc   pt pb fGrow">
        <div className="fcCol jcsb aistr mt fGrow g1 w100">
          <div className="row w100 aibl">
            <span>{t("Payer")}</span>
            <TextInput name="creditForCoNumber" label={t("Credit for CO№")} autolabel readOnly width="8em" />
          </div>
          <Select name="creditParametr" label={"Credit"} autolabel required options={creditOptions} />
          <DateInput
            name="termUpTo"
            label={t("Credit Term*")}
            autolabel
            width="10em"
            minDate={moment().add(31, "day").format("DD-MM-YYYY")}
          />
          <TextInput name="perAnnumComposed" label={t("%%per annum per year")} autolabel readOnly />
          <TextInput name="repaymentTermIdComposed" label={t("repayment %%")} autolabel readOnly />
          <TextInput name="repaymentTermsSumId" label="repayment sum" autolabel readOnly />
          <MoneyInput name="creditSumCoAdvance" label={t("Credit Sum")} autolabel readOnly width="14em" />
        </div>
        <Divider />
        <RecipientClientBalance />
      </div>
      <TextAreaInput name="compleateNote" placeholder={"Add note"} />
      <TextAreaInput name="executorNote" placeholder={"Executant note"} readOnly />
      <div className="row">
        <FunctionButton
          onClick={onCreditRequest}
          name="Credit request"
          autoWidth
          disabled={!(values?.creditParametr && values?.termUpTo)}
        />
        <FunctionButton
          onClick={onCancel}
          name={"Cancel"}
          autoWidth
          // disabled={!isDirectiveCreated}
        />
      </div>
    </div>
  );
};

const CreditOrderRequestForm = withDirectiveWithDocsHOC(CreditOrder);
export default CreditOrderRequestForm;
