import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {getCurrentUserRoutes} from "../../../slices/UserSlice";

export const NAV_PATH_NAMES = Object.freeze({
  ADMIN: "ba3a/admin",
  PROJECT: "ba3a/project",
  KP: "ba3a/co",
  SKU: "ba3a/sku",
  VED: "ba3a/fea",
  LOGIST: "ba3a/logistician",
  FINBLOCK: "ba3a/fin-block",
  FIN_ACCOUNTING: "ba3a/fin-accounting",
  SERVICE: "ba3a/service",
  WAREHOUSE: "ba3a/warehouse",
  SETTINGS: "ba3a/settings",
  REFERENCE: "ba3a/references",
});

const NavItem = props => {
  const {dataName, name, children, multiUser} = props;
  const [open, setOpen] = useState();

  const location = useLocation();
  const pathName = location.pathname;
  const isCurrentLocation = pathName.includes(dataName);

  const navPath = dataName.replace("ba3a/", "");
  const allowedRoutes = useSelector(getCurrentUserRoutes);

  const ifAllowed = allowedRoutes?.filter(route => route.routeUrl.includes(navPath));
  if (!multiUser && ifAllowed?.length < 1) {
    return null;
  }
  const ifBos = ifAllowed?.some(route => route.routeUrl.includes("bos"));
  const visibleSubRoutes = ifBos
    ? children
    : React.Children.map(children, child => {
        if (child.props?.to?.includes("bos")) {
          return null;
        } else return child;
      });

  return (
    <div
      className={`nav-item ${isCurrentLocation ? "bold" : ""}`}
      onClick={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <span style={{...(props.uppercase ? {textTransform: "uppercase"} : {})}}>{name}</span>

      {open && <div className="dropdown">{visibleSubRoutes}</div>}
    </div>
  );
};

export default NavItem;
