// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiddenContainer {
  width: 100%;
}
.hiddenContainer .toggle-button {
  cursor: pointer;
  margin-top: 10px;
  transition: transform 0.3s;
  opacity: 0.5;
  background-color: rgb(168, 168, 168);
  color: rgb(168, 168, 168);
  height: 0.2em;
  width: 3em;
  display: flex;
  justify-content: center;
  font-size: 0.9em;
  border-radius: 2px;
}
.hiddenContainer .toggle-button span {
  margin-top: -1px;
}
.hiddenContainer .toggle-button:hover {
  transform: scale(1.1);
  opacity: 1;
}
.hiddenContainer .content {
  opacity: 0;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.hiddenContainer .content.show {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/conteiners/hiddenContainer/hiddenContent.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AASE;EACE,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,YAAA;EACA,oCAAA;EACA,yBAAA;EACA,aAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AAPJ;AASI;EACE,gBAAA;AAPN;AAWE;EACE,qBAAA;EACA,UAAA;AATJ;AAWE;EAEE,UAAA;EACA,iEAAA;AAVJ;AAaE;EAEE,UAAA;AAZJ","sourcesContent":[".hiddenContainer {\n  width: 100%;\n  // span {\n  //   font-size: 1.5em;\n  //   text-align: center;\n  //   cursor: pointer;\n  //   opacity: 0.5;\n  //   &:hover {\n  //     opacity: 1;\n  //   }\n  // }\n  .toggle-button {\n    cursor: pointer;\n    margin-top: 10px;\n    transition: transform 0.3s;\n    opacity: 0.5;\n    background-color: rgb(168, 168, 168);\n    color: rgb(168, 168, 168);\n    height: 0.2em;\n    width: 3em;\n    display: flex;\n    justify-content: center;\n    font-size: 0.9em;\n    border-radius: 2px;\n    // align-items: center;\n    span {\n      margin-top: -1px;\n    }\n  }\n\n  .toggle-button:hover {\n    transform: scale(1.1);\n    opacity: 1;\n  }\n  .content {\n    // max-height: 0;\n    opacity: 0;\n    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;\n  }\n\n  .content.show {\n    // max-height: 500px; /* Достаточно большое значение */\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
