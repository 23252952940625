import {useTranslation} from "react-i18next";
import moment from "moment";

import {DateInput, MoneyInput, TextInput} from "../../../../../Inputs";

const CreditData = ({readOnly}) => {
  const {t} = useTranslation();

  return (
    <div className="w100">
      <div className="fcCol aist gap mb3x">
        <DateInput
          name="creditTermComposed"
          label={t("Credit Term*")}
          autolabel
          readOnly={readOnly}
          width="10em"
          required
          minDate={moment().add(31, "day").format("DD-MM-YYYY")}
        />
        <TextInput
          name="perAnnumComposed"
          label={t("%%per annum per year")}
          autolabel
          readOnly={readOnly}
          width="10em"
        />
        <TextInput
          name="repaymentTermIdComposed"
          label={t("repayment %%")}
          readOnly={readOnly}
          generalClassName="w100"
        />
        <TextInput name="repaymentTermsSumId" label="repayment sum" autolabel readOnly generalClassName="w100" />
      </div>
      <MoneyInput
        name="creditSumComposed"
        label={t("Credit Sum")}
        autolabel
        width="14em"
        readOnly={readOnly}
        required
      />
    </div>
  );
};

export default CreditData;
