import React, {useEffect} from "react";

import Divider from "../../../../utilities/Divider/Divider";
import NewPdfViewer from "../../Documents/DocumentView/NewPdfViewer";
import ContractDocsScreenShotsTables from "../ContractDocsScreenShotsTables/ContractDocsScreenShotsTables";
import DirectiveFilters from "../DirectiveFilters";
import FinStatus from "../FinStatus";

import useMainDirective from "./useMainDirective";

const withDirectiveWithDocsHOC = WrappedComponent => {
  return function DirectiveWithDocs({directiveId, ...props}) {
    const {fileArray, setFileArray, contractTableDataRow, annexTableDataRow, einfoTableDataRow} = useMainDirective({
      directiveId,
    });

    return (
      <div className="fcCol h100 w100 pb aist maxH100 scrollY ">
        <DirectiveFilters readOnly />
        <Divider />
        <div className="grid2ColSecondMax gap w100 fGrow2 mb0_5" style={{gridTemplateColumns: "30% minmax(0px, 1fr)"}}>
          <WrappedComponent directiveId={directiveId} {...props} />
          <div className="fcCol fGrow2">
            <FinStatus readOnly />
            <div className="grid2ColFirstBigger fGrow">
              <ContractDocsScreenShotsTables
                setFileArray={setFileArray}
                contractTableDataRow={contractTableDataRow}
                annexTableDataRow={annexTableDataRow}
                einfoTableDataRow={einfoTableDataRow}
              />
              <div className="mb2x">
                <NewPdfViewer fileArray={fileArray || []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};

export default withDirectiveWithDocsHOC;
