import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {t} from "i18next";

import AddIcon from "../../../../images/icons/add.png";
import {
  fetchAllLeads,
  fetchClients,
  selectAllClients,
  selectAllLeads,
  selectLead,
} from "../../../../slices/PersonSlice";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../../conteiners/ReactTable";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import AddLeadModal from "../AddLeadModal";
import {CASHDESK_DIRECTIVE_TYPES} from "../CashdeskDirective";
import {PAYER_TYPE_PART_TABLE_COLUMNS, RECEIVER_TYPE_PART_TABLE_COLUMNS} from "../constants";

export const PAYER_RECEIVER_PART_TYPES = Object.freeze({PAYER: "PAYER", RECEIVER: "RECEIVER"});

const PayerReceiverPart = ({currentEntity, setCurrentEntity, type, directiveType}) => {
  const dispatch = useDispatch();

  const {values, setFieldValue} = useFormikContext();

  const entityTypeNaming = type === PAYER_RECEIVER_PART_TYPES.PAYER ? "payerType" : "receiverType";
  const isLeadEntityType = values[entityTypeNaming] === "Lead";
  const isOpposedEntityLead =
    values[type === PAYER_RECEIVER_PART_TYPES.PAYER ? "receiverType" : "payerType"] === "Lead";
  const entityNameNaming = type === PAYER_RECEIVER_PART_TYPES.PAYER ? "payerName" : "receiverName";
  const isPayerType = type === PAYER_RECEIVER_PART_TYPES.PAYER;

  const clients = useSelector(selectAllClients);
  const leads = useSelector(selectAllLeads);
  const lead = useSelector(selectLead);
  const currentLeadId = lead?.leadId;

  const [isModalActive, setModalActive] = useState(false);

  const [entityTypeOptions, setEntityTypeOptions] = useState([]);

  //in advent receiver must be a client, in expence payer and receiver must be clients,
  //can`t be 2 leads
  useEffect(() => {
    switch (type) {
      case PAYER_RECEIVER_PART_TYPES.PAYER:
        if (directiveType === CASHDESK_DIRECTIVE_TYPES.EXPENCE) {
          setEntityTypeOptions([{value: "Client", title: t("Client")}]);
          setTimeout(() => setFieldValue("payerType", "Client"));
        } else {
          setEntityTypeOptions(
            isOpposedEntityLead
              ? [{value: "Client", title: t("Client")}]
              : [
                  {value: "Client", title: t("Client")},
                  {value: "Lead", title: "Lead"},
                ],
          );
        }
        break;
      case PAYER_RECEIVER_PART_TYPES.RECEIVER:
        if (directiveType === CASHDESK_DIRECTIVE_TYPES.ADVENT || directiveType === CASHDESK_DIRECTIVE_TYPES.EXPENCE) {
          setEntityTypeOptions([{value: "Client", title: t("Client")}]);
          setTimeout(() => setFieldValue("receiverType", "Client"));
        } else {
          setEntityTypeOptions(
            isOpposedEntityLead
              ? [{value: "Client", title: t("Client")}]
              : [
                  {value: "Client", title: t("Client")},
                  {value: "Lead", title: "Lead"},
                ],
          );
        }
        break;
    }
  }, [type, directiveType, clients?.length, isOpposedEntityLead]);

  const currentEntityType = values[entityTypeNaming];

  const [defaultData, setDefaultData] = useState([]);
  const [tableData, setTableData] = useState(defaultData);

  const makeLeadName = ({firstName, patronym, lastName, note}) =>
    `${firstName} ${patronym ?? ""} ${lastName ?? ""} ${note ? ", " + note : ""}`;

  useEffect(() => {
    if (isLeadEntityType && currentLeadId) {
      setCurrentEntity(currentLeadId);
      const {firstName, patronym, lastName} = lead;
      setFieldValue(entityNameNaming, makeLeadName({firstName, patronym, lastName}));
    }
  }, [isLeadEntityType, currentLeadId]);

  useEffect(() => {
    setTableData(defaultData);
  }, [defaultData?.length]);

  useEffect(() => {
    setFieldValue(entityNameNaming);
    setCurrentEntity(null);

    if (!currentEntityType) {
      setDefaultData([]);
    }
    if (isLeadEntityType && (!leads || leads.length === 0)) {
      dispatch(fetchAllLeads());
    }
  }, [currentEntityType]);

  const makeTableDataFromClients = clients =>
    clients?.map(({clientId, clientNumber}, index) => ({
      id: clientId,
      number: ++index,

      [isPayerType ? "payerType" : "receiverType"]: t(currentEntityType),
      [isPayerType ? "payerName" : "receiverName"]: clientNumber,
    })) ?? [];

  const makeTableDataFromLeads = leads =>
    leads?.map(({leadId, firstName, lastName, patronym, note}, index) => ({
      id: leadId,
      number: ++index,
      [isPayerType ? "payerType" : "receiverType"]: t(currentEntityType),
      [isPayerType ? "payerName" : "receiverName"]: makeLeadName({
        firstName,
        lastName,
        patronym,
        note,
      }),
    })) ?? [];

  useEffect(() => {
    if (currentEntityType === "Client") {
      if (!clients) {
        dispatch(fetchClients()).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setDefaultData(makeTableDataFromClients(resp.payload));
          }
        });
      } else {
        setDefaultData(makeTableDataFromClients(clients));
      }
    }
    if (currentEntityType === "Lead") {
      if (leads || leads.length > 0) {
        setDefaultData(makeTableDataFromLeads(leads));
      }
    }
  }, [currentEntityType, leads?.length, clients?.length]);

  const columns = useColumns(
    type === PAYER_RECEIVER_PART_TYPES.PAYER ? PAYER_TYPE_PART_TABLE_COLUMNS : RECEIVER_TYPE_PART_TABLE_COLUMNS,
  );

  const onAddPayerBtnClick = () => {
    setModalActive(prev => !prev);
  };

  const onTableRowSelect = entityId => {
    setCurrentEntity(entityId);
    if (!entityId) {
      setFieldValue(entityNameNaming, "");
    } else {
      setFieldValue(entityNameNaming, (defaultData?.find(entity => entity.id === entityId) ?? {})[entityNameNaming]);
    }
  };

  const onEntityNameInput = event => {
    const entityName = event.target.value;
    setCurrentEntity(null);

    if (entityName) {
      setTableData(defaultData.filter(entity => entity.payerName?.includes(entityName)));
    } else {
      setTableData(defaultData);
    }
  };

  return (
    <div className="fcCol gap fGrow aistr" style={{maxWidth: "32%"}}>
      <div className="row jcsb">
        <Select
          name={entityTypeNaming}
          label={type === PAYER_RECEIVER_PART_TYPES.PAYER ? t("Payer") : t("Receiver")}
          options={entityTypeOptions}
          autolabel
          required
          placeholder={type === PAYER_RECEIVER_PART_TYPES.PAYER ? t("Payer type") : t("Receiver type")}
        />
        <TextInput
          name={entityNameNaming}
          placeholder={type === PAYER_RECEIVER_PART_TYPES.PAYER ? "Payer " : "Receiver"}
          handleInputChange={onEntityNameInput}
        />
        <ImageButton
          src={AddIcon}
          onClick={onAddPayerBtnClick}
          width={1.2}
          height={1.2}
          disabled={!isLeadEntityType}
          tooltipMessage={!isLeadEntityType ? t('Type is not "Lead"') : "Add new Lead"}
        />
      </div>

      <ReactTable
        columns={columns}
        defaultData={tableData}
        className="scrollX scrollY fGrow"
        current={currentEntity}
        onSelect={onTableRowSelect}
      />
      <ModalForm
        label={t("Lead")}
        active={isModalActive}
        setModalActive={setModalActive}
        closable
        Component={AddLeadModal}
      />
    </div>
  );
};

export default PayerReceiverPart;
