import {t} from "i18next";

import FunctionButton from "../../../../../../utilities/Buttons/FunctionButton";
import {TextAreaInput, TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

import AccountNumber from "./AccountNumber";

const PayerAccPart = ({onSavePayerAccBtnClick = () => {}}) => {
  const accOwnerOptions = [];
  const counterpartyTypeOptions = [];

  return (
    <div className="fcCol w100 aistr">
      <div className="row">
        <span className="mb">{t("Payer account number")}</span>
        <Select
          name="payerCounterpartyTypeId"
          label={t("Counterparty Type")}
          options={counterpartyTypeOptions}
          autolabel
        />
      </div>

      <div className="row gap">
        <AccountNumber disabled />
        <TextInput
          type="number"
          name="clientNumber"
          label={t("Client №")}
          autolabel
          inputClassName="inputTypeNumberNoArrows"
        />
      </div>
      <TextAreaInput name="externalAccNaming" placeholder={t("Payer account name")} height="2.5rem" className="mb0_5" />
      <div className="row w100 jcc">
        <FunctionButton name="Save" onClick={onSavePayerAccBtnClick} autoWidth />
      </div>
    </div>
  );
};

export default PayerAccPart;
