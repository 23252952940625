import {useContext} from "react";
import {t} from "i18next";

import {toaster} from "../../../utilities/hooks/toaster";

import {TabsContext} from "./tabs.context";

export const useTabsContext = () => {
  const tabsConfig = useContext(TabsContext);

  if (!tabsConfig) {
    throw new Error("TabsContext must be used within a Tabs container (withTabs hok)");
  }

  const {addNewTab, tabs, setTabs, currentTabIndex, closeTab, setCurrentTabIndex, closeByTabIdName} = tabsConfig;

  const tabsCount = tabs?.length;

  function addNewTabCustom({TabComponent, controlled = true, title, tabIdName, ...props}) {
    const tabName = tabIdName || title;
    if (tabs.find(tab => tab.tabIdName === tabName)) {
      toaster.error("The tab already exists!");
      return;
    }

    addNewTab({
      TabComponent,
      controlled,
      title: t(title),
      tabIdName: tabName,
      ...props,
    });
    setTimeout(() => {
      setCurrentTabIndex(tabsCount);
    }, 200);
  }

  function closeCurrentTab() {
    closeTab(currentTabIndex);
  }

  function closeTabs(indexes) {
    if (!indexes || indexes?.length === 0) return;

    const newTabs = tabs.filter((tab, index) => !indexes.includes(index));
    setTabs(newTabs);
    setCurrentTabIndex(newTabs?.length - 1);
  }

  const changeCurrentTabName = (name, tabIdName) => {
    if (!name) return;

    setTabs(prev => {
      if (prev?.length <= currentTabIndex) return prev;
      let newTabs = [...prev];
      newTabs[currentTabIndex].title = name;
      if (newTabs[currentTabIndex]?.tabIdName)
        newTabs[currentTabIndex].tabIdName = tabIdName || newTabs[currentTabIndex].tabIdName;
      return newTabs;
    });
  };

  const setCouldTabClose = isClosable => {
    setTabs(prev => {
      if (prev?.length <= currentTabIndex) return prev;

      let newTabs = [...prev];
      newTabs[currentTabIndex].controlled = isClosable;

      return newTabs;
    });
  };

  return {
    addNewTab,
    tabsCount,
    currentTabIndex,
    closeTab,
    tabs,
    setCurrentTabIndex,
    addNewTabCustom,
    closeCurrentTab,
    changeCurrentTabName,
    closeTabs,
    closeByTabIdName,
    setTabs,
    setCouldTabClose,
  };
};
