import {ContextMenu, ContextMenuOption} from "../../../hook/useContextMenu";
import {useFiltersContext} from "../../conteiners/filters/useSessionFiltersContext";

const BankClientContextMenu = ({
  onAccountAssociationBtnClick,
  // onToTheClientBalanceBtnClick,
  menuVisible,
  menuPosition,
  currentBankTransactionId,
  hasCurrentTranscationAssociation,
  isOperationBetweenOwnAccounts,
  onConductBetweenOwnBtnClick,
  isTransactionCompleted,
  onViewTransactionsBtnClick,
  onConductAfterAutoAssociationBtnClick,
}) => {
  const {filters: savedFilters = {}} = useFiltersContext();

  return (
    <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
      <ContextMenuOption
        text={"View Account"}
        onClick={onViewTransactionsBtnClick}
        disabled={!currentBankTransactionId}
      />
      {isOperationBetweenOwnAccounts && (
        <ContextMenuOption
          text={"Conduct without association"}
          onClick={onConductBetweenOwnBtnClick}
          disabled={isTransactionCompleted}
        />
      )}
      {!isOperationBetweenOwnAccounts && (
        <>
          <ContextMenuOption
            text={"Account association"}
            onClick={() => onAccountAssociationBtnClick({savedFilters})}
            disabled={
              !currentBankTransactionId ||
              hasCurrentTranscationAssociation ||
              isOperationBetweenOwnAccounts ||
              isTransactionCompleted
            }
          />
          <ContextMenuOption
            text={"Conduct"}
            onClick={() => onConductAfterAutoAssociationBtnClick({savedFilters})}
            disabled={
              !currentBankTransactionId ||
              !hasCurrentTranscationAssociation ||
              isOperationBetweenOwnAccounts ||
              isTransactionCompleted
            }
          />
          {/* <ContextMenuOption
            text={"To the Payer`s balance"}
            onClick={onToTheClientBalanceBtnClick}
            disabled={
              !currentBankTransactionId ||
              !hasCurrentTranscationAssociation ||
              isOperationBetweenOwnAccounts ||
              isTransactionCompleted
            }
          /> */}
        </>
      )}
    </ContextMenu>
  );
};

export default BankClientContextMenu;
