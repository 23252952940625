import React from "react";

import Divider from "../../../../../utilities/Divider/Divider";
import {SessionFiltersContainer} from "../../../../conteiners/filters/Filter.hoc";

import BankOperationFilters from "./BankOperationFilters";
import BankOperationTable from "./BankOperationTable";

const BankOperationList = () => {
  const onFilterChange = () => {};
  return (
    <SessionFiltersContainer window="BankOperationList" onFilterChange={onFilterChange}>
      <div className="form_wrapper" style={{paddingBottom: "10px"}}>
        <BankOperationFilters />
        <Divider />
        <BankOperationTable />
      </div>
    </SessionFiltersContainer>
  );
};

export default BankOperationList;
