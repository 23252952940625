export const SP_TABLE_STORAGE_ADD_SERVICES_MODAL = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Stock pool №",
    accessorKey: "stockPoolNumber",
  },
]);

export const WAREHOUSE_ADD_SERVICES_DOCUMENTS_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Document type",
    accessorKey: "docType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Document №",
    accessorKey: "docNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Document date",
    accessorKey: "onDocDate",
    size: 70,
    isSortable: true,
  },
  {
    header: "Stockpool №",
    accessorKey: "stockPoolNumber",
    size: 70,
    isSortable: true,
  },
  {
    header: "Load date",
    accessorKey: "docUploadDate",
    size: 100,
    isSortable: true,
  },
]);

export const WAREHOUSE_SERVICE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "Service type",
    accessorKey: "serviceType",
    size: 70,
    isSortable: true,
  },
  {
    header: "Date (n)",
    accessorKey: "date",
    size: 100,
    isSortable: true,
  },
  {
    header: "Price",
    accessorKey: "price",
    size: 70,
    isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sum",
    size: 70,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 100,
    isSortable: true,
  },
]);

export const WAREHOUSE_SHIPMENT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 50,
  },
  {
    header: "lwh (m)",
    accessorKey: "lwh",
    size: 70,
    isSortable: true,
  },
  {
    header: "volume (m3)",
    accessorKey: "volume",
    size: 70,
    isSortable: true,
  },
  {
    header: "weight (kg)",
    accessorKey: "weight",
    size: 100,
    isSortable: true,
  },
  {
    header: "Stockpool №",
    accessorKey: "stockpoolNumber",
    size: 100,
    isSortable: true,
  },
  {
    header: "Notes",
    accessorKey: "note",
    alignStart: true,
    size: 100,
  },
]);
