import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useFormikContext, withFormik} from "formik";

import {StatusRectangleBtn} from "../../../../utilities/Buttons/StatusFunctionButton/StatusRectangleBtn";
import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import {TextInput} from "../../../Inputs";
import {Select} from "../../../Inputs/SelectField";
import useLogistFilters from "../../common/LogistFilters/useLogistFilters";
import withLogistFilters from "../../common/LogistFilters/withLogistFilters";
import {fetchStockPoolFilters} from "../../redux/operations";

const BaseWarehouseFilters = ({disabled, setSelectedWarehouse}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [transferredSP, setTransferredSP] = useState(null);

  useEffect(() => {
    dispatch(fetchStockPoolFilters({window: "Warehouse"})).then(res => {
      setTransferredSP(res?.payload?.transferredSP);
    });
  }, []);

  const {
    coManagersOptions,
    invoiceNumberOptions,
    warehouseOptions,
    stockPoolNumberOptions,
    orderNumberOptions,
    filters,
  } = useLogistFilters();

  const {
    values: {warehouseId},
  } = useFormikContext();

  useEffect(() => {
    if (warehouseId && warehouseId !== null) {
      setSelectedWarehouse({
        title: warehouseOptions.find(item => item.value === warehouseId)?.title,
        value: warehouseId,
        address: filters?.warehouses?.find(item => item.warehouseId === warehouseId)?.address,
      });
    } else {
      setSelectedWarehouse(null);
    }
  }, [warehouseId, warehouseOptions]);

  return (
    <>
      <div className="f_block w100" style={{paddingTop: "0.7em", flexWrap: "nowrap"}}>
        <Select
          label={t("SP №") + ":"}
          name="stockPoolNumber"
          options={stockPoolNumberOptions}
          width="100%"
          disabled={disabled}
        />
        <Select
          label={t("Warehouse") + ":"}
          name="warehouseId"
          options={warehouseOptions}
          width="100%"
          disabled={disabled}
        />

        <TextInput label={t("Order № ") + ":"} name="orderNumber" width="100%" disabled={disabled} />
        <Select
          label={t("Invoice №") + ":"}
          name="invoiceNumber"
          options={invoiceNumberOptions}
          width="100%"
          disabled={disabled}
        />
        <Select
          label={t("Manager CO") + ":"}
          name="managerCoId"
          options={coManagersOptions}
          width="100%"
          disabled={disabled}
        />
        <StatusRectangleBtn
          onClick={() => {
            // setOrderingModalActive(true);
          }}
          title={t("button info warehouse")}
          color={transferredSP?.length > 0 ? STATUS_COLORS.RED : STATUS_COLORS.GREEN}
        />
      </div>
    </>
  );
};

const LogistFilters = withLogistFilters(BaseWarehouseFilters);

const WarehouseFilters = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: () => {},
  enableReinitialize: true,
})(LogistFilters);

export default WarehouseFilters;
