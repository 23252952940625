import {toast} from "react-toastify";

class ToastManager {
  constructor() {
    this.activeToasts = new Set(); // Хранит активные сообщения
  }

  showError(message, options = {}) {
    if (!this.activeToasts.has(message)) {
      this.activeToasts.add(message);

      toast.error(message, {
        ...options,
        onClose: () => {
          this.activeToasts.delete(message); // Убираем сообщение из активных при закрытии
        },
      });
    }
  }

  // Можно добавить и другие методы для удобства
  showSuccess(message, options = {}) {
    toast.success(message, options);
  }

  clearAll() {
    toast.dismiss(); // Закрывает все уведомления
    this.activeToasts.clear();
  }
}

const toastInstance = new ToastManager();

export default toastInstance;
