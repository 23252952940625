import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

import withTabs from "../../conteiners/tabs/Tabs.hoc";
import {useTabsContext} from "../../conteiners/tabs/useTabs";
import ToDoSection from "../../Project/ProjectSecondVersion/ProjectPageSections/ToDoSection";
import {fetchAllCOs, fetchCO, selectCurrentCO} from "../../Project/ProjectSlice";
import KpFin from "../kp-f/KpFin";
import {
  clearAllCoas,
  clearAllCofs,
  clearCurrentCoa,
  clearCurrentCof,
  fetchAllCOas,
  fetchAllCOfs,
  fetchCOf,
} from "../kp-f/KpFinSlice";

import CreateKpPageSection from "./KpPageSections/CreateKpPageSection";
import KpMainListAndForm from "./KpPageSections/KpMainListAndForm";
import KpPreviewAndMainButtonsSection from "./KpPageSections/KpPreviewAndMainButtonsSection";

export const CO_STATUSES = Object.freeze({
  CREATED: 1,
  ACTIVE: 2,
  DONE: 3,
  ONPAYMENT: 4,
  PAIDINADVANCED: 5,
  MAIN: 6,
  PAID: 7,
});

const KpSecondVersion = () => {
  const dispatch = useDispatch();

  const currentCo = useSelector(selectCurrentCO);
  const currentCoId = currentCo?.coId;

  const location = useLocation();
  const navigate = useNavigate();
  const {tabs, addNewTabCustom} = useTabsContext();
  const {t} = useTranslation();

  useEffect(() => {
    if (tabs?.length === 1 && location?.state?.coOpen) {
      dispatch(fetchCO({coId: location?.state?.coId})).then(res => {
        dispatch(fetchCOf({cofId: location?.state?.cofId})).then(res => {
          addNewTabCustom({
            TabComponent: KpFin,
            title: t(`${t("CO(f)")} ${res?.payload?.cofNumber || ""}`),
            controlled: true,
            tabIdName: "cof",
            cofId: location?.state?.cofId,
            onTabClose: () => {
              navigate(location.pathname, {state: {coOpen: false}});
            },
          });
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAllCOs({isCurrent: true}));
  }, []);

  useEffect(() => {
    if (currentCo?.coId) {
      dispatch(fetchAllCOfs({coId: currentCo?.coId}));
      dispatch(fetchAllCOas({coId: currentCo?.coId}));
    } else {
      dispatch(clearAllCofs());
      dispatch(clearAllCoas());
      dispatch(clearCurrentCof());
      dispatch(clearCurrentCoa());
    }
  }, [currentCo?.coId]);

  return (
    <div className="form_wrapper pt pb">
      <div className="two-column-page__container" style={{maxHeight: "100%"}}>
        <div className="left-side">
          <KpMainListAndForm />
          <CreateKpPageSection type={"f"} />
          <CreateKpPageSection type={"a"} />
        </div>
        <div className="right-side w100">
          <KpPreviewAndMainButtonsSection />
          <ToDoSection coId={currentCoId} noProject />
        </div>
      </div>
    </div>
  );
};

export default withTabs({isMainTabControlled: true, formInitialValues: {}, isClosableTabs: true, label: "CO"})(
  KpSecondVersion,
);
