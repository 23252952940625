import {useEffect, useRef, useState} from "react";

const useFileFolderPicker = () => {
  const folderPickerRef = useRef();

  const [files, setFiles] = useState([]);

  const handleFolderUpload = event => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  const clearFilesArray = () => {
    setFiles([]);
  };

  //   useEffect(() => {
  //     if (files?.length < 1) return;
  //     files.forEach(file => {
  //       console.log("file :>> ", file.name);
  //     });
  //   }, [files]);

  return {files, handleFolderUpload, folderPickerRef, clearFilesArray};
};

export default useFileFolderPicker;
