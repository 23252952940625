import {toast} from "react-toastify";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Swal from "sweetalert2";

import {fetchData, fetchList} from "../hook/axios.hook";

const token = localStorage.getItem("access_token");

export const getError = error => {
  toast.error(error);
};
export const getInfo = text => {
  toast.info(text);
};

const initialState = {
  user: null,
  isAuth: false,
  authLoadingStatus: "idle",
  token: token ? token : null,
  facsimile: null,
  systemLanguage: "en",
  accessRoutes: null,
};

export const login = createAsyncThunk("user/login", async body => {
  return fetchData("/auth/login", "post", body);
});

export const logout = createAsyncThunk("user/logout", async () => {
  return fetchData("/auth/logout", "post");
});

export const changePassword = createAsyncThunk("user/changePassword", async newPasw => {
  return fetchData("/users/changePass", "put", newPasw);
});

export const getUser = createAsyncThunk("user/getUser", async () => {
  return fetchData("/users/getCurrentUser", "get");
});

export const createFacsimile = createAsyncThunk("user/createFacsimile", async formData => {
  return fetchData("/facsimile/create", "post", formData);
});

export const getFacsimileForCurrentUser = createAsyncThunk("user/getFacsimileForCurrentUser", async () => {
  return fetchData("/facsimile/getForCurrent", "get");
});

export const fetchAccessRoutes = createAsyncThunk("person/fetchAccessRoutes", async user => {
  return fetchList("/routes/getAll");
});

export const registrUser = async user => {
  fetchData("/users/add", "post", user).then(res => {
    if (Object.prototype.hasOwnProperty.call(res, "persona")) {
      // getInfo(`Користувача ${res.persona.CA_FULL_NAME} успішно зареєстровано`);
      Swal.fire({
        title: `Користувача ${res.persona.DA_EMPLOYEE_NAME} успішно зареєстровано`,
        text: `Логін: ${res.persona.DA_LOGIN}`,
        icon: "info",
        confirmButtonText: "Ок",
      });
    }
  });
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setNotAuth: state => {
      localStorage.removeItem("access_token");
      state.token = null;
      state.user = null;
    },
    addChosenUser: (state, payload) => {
      state.chosenUser = payload;
    },
    addFacsimile: (state, {payload}) => {
      state.facsimile = payload;
    },
    setSystemLanguage: (state, {payload}) => {
      state.systemLanguage = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, {payload}) => {
        if (Object.prototype.hasOwnProperty.call(payload, "message")) {
          getInfo(payload.message);
          state.user = payload.userId;
          state.token = null;
          localStorage.removeItem("access_token");
        }
        if (Object.prototype.hasOwnProperty.call(payload, "token")) {
          state.token = payload.token;
          state.user = payload.user;
          localStorage.setItem("access_token", payload.token);
        }
      })
      .addCase(changePassword.fulfilled, (state, {payload}) => {
        state.token = payload.token;
        state.user = payload.user;
        localStorage.setItem("access_token", payload.token);
        getInfo("Пароль успішно змінено!");
      })
      .addCase(logout.fulfilled, state => {
        localStorage.removeItem("access_token");
        state.token = null;
        state.user = null;
      })
      .addCase(getUser.fulfilled, (state, {payload}) => {
        state.user = {
          login: payload.login,
          id: payload.userId,
          firstName: payload.persona.firstName,
          lastName: payload.persona.lastName,
          engName: payload.persona.engFirstName,
          engLastName: payload.persona.engLastName,
          orgId: payload.orgStructureId,
          orgStructure: payload.orgStructure,
          name: payload.name,
          position: payload.position,
        };
      })
      .addCase(createFacsimile.fulfilled, (state, {payload}) => {
        state.facsimile = payload;
      })
      .addCase(getFacsimileForCurrentUser.fulfilled, (state, {payload}) => {
        state.facsimile = payload;
      })
      .addCase(getUser.rejected, state => {
        state.token = null;
        state.user = null;
      })
      .addCase(fetchAccessRoutes.fulfilled, (state, {payload}) => {
        state.accessRoutes = payload;
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = userSlice;

export const {setNotAuth, addFacsimile, setSystemLanguage} = actions;

export default reducer;

export const getCurrentUser = state => state.user.user;
export const selectCurrentUserId = state => state.user?.user?.id;
export const getCurrentUserRole = state => state.user?.user?.position?.positionId || null;
export const getCurrentUserRoutes = state => state.user.user?.position?.routes || null;
export const getAccessRoutes = state => state.user.accessRoutes;

export const getToken = state => state.user.token;
export const selectFacsimile = state => state.user.facsimile;
