import {Field, useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import {Filters, SessionFiltersContainer} from "../../conteiners/filters/Filter.hoc";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ModalForm from "../../Modal/FormModal";
import OrderRegister from "../../Project/ProjectDirectiveList/OrderRegister/OrderRegister";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";

import CashdeskDirectiveOrder from "./cashdeskDirectiveOrderModals/CashdeskDirectiveOrder";
import ComposedPart from "./ComposedPart";
import {CASHDESK_DIRECTIVE_TABLE_COLUMNS} from "./constants";
import useCashdeskDirective from "./useCashdeskDirective";

export const CASHDESK_DIRECTIVE_TYPES = Object.freeze({
  ADVENT: "ADVENT",
  EXPENCE: "EXPENCE",
  CURRENCY_EXCHANGE: "CURRENCY_EXCHANGE",
  TRANSIT: "TRANSIT",
});

const CashdeskDirectiveTableWithFilters = ({setOrderViewModalActive}) => {
  const columns = useColumns(CASHDESK_DIRECTIVE_TABLE_COLUMNS);

  const onRowDoubleClick = () => {
    setOrderViewModalActive(true);
  };

  return (
    <>
      <ReactTable
        columns={columns}
        defaultData={[]}
        className="scrollX scrollY fGrow"
        onRowDoubleClick={onRowDoubleClick}
        current={null}
      />
    </>
  );
};

const CashdeskDirective = () => {
  const {
    onAddNewDirectiveBtnClick,
    directiveType,
    currentDirectiveSubType,
    setCurrentDirectiveSubType,
    currentPayer,
    setCurrentPayer,
    currentReciver,
    setCurrentReciver,
    isOrderCreateModalActive,
    setOrderCreateModalActive,
    isOrderViewModalActive,
    setOrderViewModalActive,
  } = useCashdeskDirective();

  return (
    <PageContainer title={t("Cashdesk directive")}>
      <div className="row gap">
        <div className="fcCol jcsb gap3x">
          <div className="fcCol gap jcsb border aist padding">
            <label className="row aic gap">
              <Field type="radio" name="directiveType" id="Advent" value={CASHDESK_DIRECTIVE_TYPES.ADVENT} />
              {t(CASHDESK_DIRECTIVE_TYPES.ADVENT)}
            </label>
            <label className="row aic gap">
              <Field type="radio" name="directiveType" id="Expence" value={CASHDESK_DIRECTIVE_TYPES.EXPENCE} />
              {t(CASHDESK_DIRECTIVE_TYPES.EXPENCE)}
            </label>
            <label className="row aic gap">
              <Field
                type="radio"
                name="directiveType"
                id="currencyExchange"
                value={CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE}
              />
              {t(CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE)}
            </label>
            <label className="row aic gap">
              <Field type="radio" name="directiveType" id="Transit" value={CASHDESK_DIRECTIVE_TYPES.TRANSIT} />
              {t(CASHDESK_DIRECTIVE_TYPES.TRANSIT)}
            </label>
          </div>
          <FunctionButton
            name="New directive"
            autoWidth
            // disabled={!currentDirectiveSubType || !currentPayer || !currentReciver}
            onClick={onAddNewDirectiveBtnClick}
          />
        </div>
        <ComposedPart
          directiveType={directiveType}
          currentDirectiveSubType={currentDirectiveSubType}
          setCurrentDirectiveSubType={setCurrentDirectiveSubType}
          currentPayer={currentPayer}
          setCurrentPayer={setCurrentPayer}
          currentReciver={currentReciver}
          setCurrentReciver={setCurrentReciver}
        />
      </div>
      <SessionFiltersContainer>
        <div className="fcCol fGrow">
          <Divider />
          <Filters>
            <div className="row"></div>
          </Filters>
          <Divider />
          <CashdeskDirectiveTableWithFilters setOrderViewModalActive={setOrderViewModalActive} />
        </div>
      </SessionFiltersContainer>
      {/* <ModalForm
        label={t("Cashdesk order (View)")}
        active={isOrderCreateModalActive}
        setModalActive={setOrderCreateModalActive}
        closable
        Component={}
      /> */}
      <ModalForm
        label={t("Cashdesk order")}
        active={isOrderCreateModalActive}
        setModalActive={setOrderCreateModalActive}
        closable
        Component={CashdeskDirectiveOrder}
        style={{width: "90%", height: "85%"}}
        className="largeModal"
      />
    </PageContainer>
  );
};

export default withFormik({
  mapPropsToValues: () => ({directiveType: CASHDESK_DIRECTIVE_TYPES.ADVENT}),
  enableReinitialize: true,
})(CashdeskDirective);
