import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik, useFormikContext} from "formik";
import Swal from "sweetalert2";

import {useTabsContext} from "../useTabs";

const ControlledTab = props => {
  const [initialValues, setInitialFormValues] = useState(props?.formInitialValues || null);
  const {currentTabIndex} = useTabsContext();

  useEffect(() => {
    setInitialFormValues(prev => ({...prev, ...props?.formInitialValues}));
  }, [props?.formInitialValues]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={values => {}}
      validationSchema={props?.validationSchema}
      validateOnBlur={props?.validateOnBlur}
    >
      <Form style={{height: props.tabIndex === currentTabIndex ? "100%" : "0px"}}>
        <Controlled {...props} setInitialFormValues={setInitialFormValues} />
      </Form>
    </Formik>
  );
};

const Controlled = ({
  Component,
  dirtyFormCondition = false,
  additionalCloseCondition = null,
  onTabClose = () => {},
  ...props
}) => {
  const {tabIndex, closeBtnRefs} = props;
  const {dirty} = useFormikContext();
  const {t} = useTranslation();

  const {currentTabIndex, closeTab, tabs} = useTabsContext();

  const handleClose = async () => {
    if (
      (!dirtyFormCondition && additionalCloseCondition === null) ||
      (additionalCloseCondition === null && dirtyFormCondition && !dirty)
    ) {
      closeTab(tabIndex);
      onTabClose({tabIdName: props?.tabIdName, tabIndex, tabs});
      return;
    }

    if (additionalCloseCondition !== null) {
      await additionalCloseCondition()
        .then(() => {
          if (dirtyFormCondition && dirty) {
            throw new Error();
          }
          closeTab(tabIndex);
          onTabClose({tabIdName: props?.tabIdName, tabIndex, tabs});
          return;
        })
        .catch(() => {
          Swal.fire({
            title: t("Close the tab?"),
            text: t("You have unsaved issues"),
            icon: "info",
            confirmButtonText: t("Close"),
            showCancelButton: true,
            cancelButtonText: t("Cancel"),

            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          }).then(answ => {
            if (answ.isConfirmed) {
              closeTab(tabIndex);
              onTabClose({tabIdName: props?.tabIdName, tabIndex, tabs});
              return;
            } else {
              return;
            }
          });
        });
    }
    if (dirtyFormCondition && dirty && additionalCloseCondition === null) {
      Swal.fire({
        title: t("Close the tab?"),
        text: t("You have unsaved issues"),
        icon: "info",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          closeTab(tabIndex);
          onTabClose({tabIdName: props?.tabIdName, tabIndex, tabs});
        } else {
          return;
        }
      });
    }
  };

  return (
    <>
      <span ref={element => (closeBtnRefs.current[tabIndex] = element)} onClick={handleClose}></span>
      {tabIndex === currentTabIndex && (
        <div className="page_container fcCol">
          <div className="overflow_form_modal hmax100 fGrow w100" style={{maxHeight: "100%"}}>
            <Component {...props} />
          </div>
        </div>
      )}
    </>
  );
};

export default ControlledTab;
