import React, {useEffect} from "react";
import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";

const PdfPreviewModal = ({pdfFile, setModalActive, fileName}) => {
  useEffect(() => {
    if (pdfFile) {
      pdfFile.getDataUrl(dataUrl => {
        const iframe = document.createElement("iframe");
        const parent = document.getElementById("pdfContainer");
        iframe.src = dataUrl;
        iframe.style.width = "100%";
        iframe.style.height = "500px";
        parent.appendChild(iframe);
      });
    }
  }, []);
  return (
    <div className="w100">
      <div className="w100" id={"pdfContainer"}></div>
      <div className="fc w100 nowrap jcc g2 mt">
        <FunctionButton onClick={() => setModalActive(false)} name="Cancel" autoWidth />
        <FunctionButton onClick={() => pdfFile.print()} name="Print" autoWidth />
        <FunctionButton
          onClick={() => {
            pdfFile.download(`order_${fileName}`);
            pdfFile.print();
          }}
          name={t("Print") + " + PDF"}
          autoWidth
        />
      </div>
    </div>
  );
};

export default PdfPreviewModal;
