import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useFormikContext} from "formik";

import saveImg from "../../../../images/icons/SaveDisket.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import {STATUS_COLORS} from "../../../../utilities/colorConstants";
import {dateFormat} from "../../../../utilities/dateFormat";
import Divider from "../../../../utilities/Divider/Divider";
import {swalCustom} from "../../../../utilities/hooks/swalCustom";
import {toaster} from "../../../../utilities/hooks/toaster";
import {makeTableNameFromPersona} from "../../../../utilities/makeNameFromPersona";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {useTabsContext} from "../../../conteiners/tabs/useTabs";
import {clearDirective, selectDirective} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {TextInput} from "../../../Inputs";
import {selectCurrentCO} from "../../../Project/ProjectSlice";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import TemplateRegister from "../../../Templates/TemplateRegistr/TemplateRegister";
import KpFin from "../../kp-f/KpFin";
import {
  addCOa,
  addCOf,
  clearCurrentCoa,
  clearCurrentCof,
  deleteCOa,
  deleteCOf,
  fetchAllCOas,
  fetchAllCOfs,
  fetchCOa,
  fetchCOf,
  getKpFLoading,
  selectAllCoas,
  selectAllCofs,
  selectCurrentCoa,
  selectCurrentCof,
  updateCOa,
  updateCOf,
} from "../../kp-f/KpFinSlice";
import {KP_A_LIST_TABLE_COLUMNS, KP_F_LIST_TABLE_COLUMNS} from "../constants";
import {CO_STATUSES} from "../KpSecondVersion";

const CreateKpPageSection = ({type}) => {
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const {addNewTabCustom} = useTabsContext();

  const navigate = useNavigate();

  // const allCos = useSelector(selectAllCOs);
  const currentCo = useSelector(selectCurrentCO);
  const currentCoId = currentCo?.coId;

  const allCofs = useSelector(selectAllCofs);
  const currentCof = useSelector(selectCurrentCof);

  const allCoas = useSelector(selectAllCoas);
  const currentCoa = useSelector(selectCurrentCoa);
  const cofLoading = useSelector(getKpFLoading);

  const directive = useSelector(selectDirective);
  const isDirectiveCreated = Boolean(directive);

  const [currentCofaId, setCurrentCofaId] = useState(null);

  useEffect(() => {
    dispatch(clearDirective());
  }, []);

  useEffect(() => {
    setCurrentCofaId(type === "f" ? currentCof?.cofId ?? null : currentCoa?.coaId ?? null);
  }, [currentCof?.cofId, currentCoa?.coaId]);

  useEffect(() => {
    if (currentCofaId) {
      if (type === "f") {
        dispatch(fetchCOf({cofId: currentCofaId})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            setValues(prev => ({
              ...prev,
              cofNumber: resp.payload.cofNumber,
              cofNote: resp.payload.note,
              coSum: resp.payload.co.sum,
            }));
          }
        });
      }
      if (type === "a") {
        // dispatch(fetchCOa({coaId: currentCofaId}));
        // setValues(prev => ({...prev, coaNumber: currentCoa?.coaNumber, coaNote: currentCoa?.note}));
      }
    } else {
      // dispatch(clearCurrentCof());
      // if (type === "f") {
      //
      // setValues(prev => ({
      //   ...prev,
      //   cofNumber: "",
      //   cofNote: "",
      //   coSum: "",

      // }));
      // }
      // if (type === "a") {
      //   console.log("dfdf AAAAAA:>> ");
      //   setValues(prev => ({...prev,  coaNumber: "", coaNote: ""}));
      // }

      setValues(prev => ({
        ...prev,
        cofNumber: "",
        cofNote: "",
        coSum: "",
        coaNumber: "",
        coaNote: "",
      }));
    }
  }, [currentCofaId]);

  const kpFListColumns = useColumns(KP_F_LIST_TABLE_COLUMNS);
  const kpFListData =
    allCofs?.map(({cofId, cofNumber, co, note, sumPlan}, index) => ({
      id: cofId,
      number: ++index,
      status: STATUS_COLORS.GREY,
      cofNumber,
      projectManager: makeTableNameFromPersona({persona: co?.project?.projectManager?.persona, language}) ?? "",
      coManager: makeTableNameFromPersona({persona: co?.managerCo?.persona, language}) ?? "",
      note: note ?? "-",
      // startDate: dateFormat(co?.startDate),
      // finalDate: dateFormat(co?.finalDate),
      cofSum: sumPlan ?? "",
    })) ?? [];
  const kpAListColumns = useColumns(KP_A_LIST_TABLE_COLUMNS);
  const kpAListData =
    allCoas?.map(({cofId, cofNumber, co, note}, index) => ({
      id: cofId,
      number: ++index,
      status: STATUS_COLORS.GREY,
      cofNumber,
      projectManager: co?.project?.projectManager?.name,
      coManager: co?.managerCo?.name ?? "",
      note: note ?? "-",
      startDate: dateFormat(co?.startDate),
      finalDate: dateFormat(co?.finalDate),
    })) ?? [];

  function onCreateCofaBtnClick() {
    if (!currentCoId) return;

    if (type === "f") {
      dispatch(addCOf({coId: currentCoId})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Co(f) created");
          setCurrentCofaId(resp.payload.cof.cofId);
          dispatch(fetchAllCOfs({coId: currentCoId}));
        }
      });
    }

    if (type === "a") {
      dispatch(addCOa({coId: currentCoId})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Co(a) created");
          setCurrentCofaId(resp.payload.coa.cofId);
        }
      });
    }
  }

  const onCreateCoFromTemplateBtnClick = () => {
    navigate("/ba3a/co/templateRegister");
  };

  function onDeleteCoBtnClick() {
    if (!currentCofaId || skuAdded) return;

    swalCustom.confirm({
      confirmFunc: () => {
        if (type === "f") {
          dispatch(deleteCOf({cofId: currentCofaId})).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              toaster.success("Co(f) deleted");
              // setCurrentCofaId(null);
              dispatch(fetchAllCOfs({coId: currentCoId}));
              dispatch(clearCurrentCof());
            }
          });
        }

        if (type === "a") {
          dispatch(deleteCOa({coaId: currentCofaId})).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              toaster.success("Co(a) deleted");
              // setCurrentCofaId(null);
              dispatch(fetchAllCOas({coId: currentCoId}));
              dispatch(clearCurrentCoa());
            }
          });
        }
      },
    });
  }

  function onAddFileToCoBtnClick() {}
  function onOpenAndEditCoBtnClick() {
    type === "f"
      ? addNewTabCustom({
          TabComponent: KpFin,
          title: t(`${t("CO(f)")} ${currentCof?.cofNumber || ""}`),
          controlled: true,
        })
      : addNewTabCustom({
          TabComponent: TemplateRegister,
          title: t("Template register"),
          controlled: true,
        });
  }

  function onKpFTableRowSelect(cofaId) {
    dispatch(clearDirective());
    setCurrentCofaId(cofaId);
    if (!cofaId) {
      type === "f" ? dispatch(clearCurrentCof()) : dispatch(clearCurrentCoa());
    }
  }

  function onSaveNoteBtnClick() {
    const note = type === "f" ? values.cofNote : values.coaNote;

    if (type === "f") {
      dispatch(updateCOf({cofId: currentCofaId, body: {note}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Co(f) updated");
          dispatch(fetchAllCOfs({coId: currentCoId}));
        }
      });
    }

    if (type === "a") {
      dispatch(updateCOa({coaId: currentCofaId, body: {note}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Co(a) updated");
          dispatch(fetchAllCOas({coId: currentCoId}));
        }
      });
    }
  }

  const noteChanged =
    type === "f" && values?.cofNote
      ? currentCof?.note === values.cofNote
      : values?.coaNote
      ? currentCoa?.note === values.coaNote
      : true;

  const noteType = type === "f" ? values?.cofNote : values?.coaNote;

  const skuAdded = type === "f" ? currentCof?.skuPlan?.length > 0 : currentCoa?.skuPlan?.length > 0;
  const isMain = type === "f" ? currentCof?.isMain : currentCoa?.isMain;
  const commonDisable = type === "a" || !currentCof;

  return (
    <div className={`${type === "a" ? "inactive" : ""}`}>
      <div className="fdr aic mb0_5 mt0_5">
        <div className="checkBox_label">{type === "f" ? "financial(f):" : "albumial(a):"}</div>
        <Divider />
      </div>
      <div className="fdr jcsb gap">
        <TextInput
          label={t(`CO(${type}) additional`)}
          name={type === "f" ? "cofNumber" : "coaNumber"}
          autolabel
          readOnly
        />
        <FunctionButton
          name={t(`Create CO(${type})`)}
          onClick={onCreateCofaBtnClick}
          disabled={
            !currentCoId || type === "a" || !!currentCofaId || currentCo?.status?.statusId >= CO_STATUSES.ONPAYMENT
          }
        />
        <FunctionButton
          name={t(`Create CO(${type}) from template`)}
          autoWidth
          onClick={onCreateCoFromTemplateBtnClick}
          disabled={
            !currentCoId || type === "a" || !!currentCofaId || currentCo?.status?.statusId >= CO_STATUSES.ONPAYMENT
          }
        />

        <ToolTip title={skuAdded ? "COf with added SKU or main COf can`t be deleted" : ""}>
          <FunctionButton
            name={t(`Delete CO(${type})`)}
            onClick={onDeleteCoBtnClick}
            disabled={commonDisable || skuAdded}
          />
        </ToolTip>
      </div>
      {/* {type === "f" && <TextInput label={t("CO amount") + "(f):"} name="coSum" width="10em" autolabel readOnly />} */}

      <div className="fdr mb0_5">
        <TextInput
          label={t("CO description") + `(${type}):`}
          name={type === "f" ? "cofNote" : "coaNote"}
          generalClassName="w100"
          autolabel
          disabled={commonDisable || isMain}
        />
        <ImageButton
          src={saveImg}
          width={1.4}
          height={1.4}
          disabled={commonDisable || noteChanged || noteType === ""}
          onClick={onSaveNoteBtnClick}
        />
      </div>
      <ReactTable
        defaultData={type === "f" ? kpFListData : kpAListData}
        columns={type === "f" ? kpFListColumns : kpAListColumns}
        className={"project_table mb scrollX"}
        loading={type === "f" ? cofLoading : false}
        style={{height: "10em"}}
        current={currentCofaId}
        onSelect={onKpFTableRowSelect}
      />
      <div className="fdr jcsb w100">
        {type === "f" && (
          <FunctionButton
            name={t("Add file to CO") + "(f)"}
            autoWidth
            onClick={onAddFileToCoBtnClick}
            disabled={true || commonDisable}
          />
        )}
        <div className="right_side_btn">
          <ToolTip
            title={
              isMain ? "Main COf/a can`t be edited" : isDirectiveCreated ? "Directive has already been created" : ""
            }
          >
            <FunctionButton
              name={t("Open and edit") + `(${type})`}
              className="right_side_btn"
              autoWidth
              onClick={onOpenAndEditCoBtnClick}
              disabled={
                commonDisable || isMain || currentCo?.status?.statusId >= CO_STATUSES.ONPAYMENT
              }
            />
          </ToolTip>
        </div>
      </div>
    </div>
  );
};

export default CreateKpPageSection;
