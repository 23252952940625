import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import useFileFolderPicker from "../../../hook/useFileFolderPicker";
import {dateFormat} from "../../../utilities/dateFormat";
import {numberFormat} from "../../../utilities/moneyFormat";
import useColumns from "../../Requests/OrderProcessing/utils/hooks/useColumns";
import {fetchAllBankTransactions, selectBankTransactions, uploadBankClientStatements} from "../FinBlockSlice";

import {swalCustom} from "./../../../utilities/hooks/swalCustom";
import {BANK_CLIENT_TABLE_COLUMNS} from "./constants";

const useBankClient = () => {
  const loading = false;
  const bankOptions = [];
  const currencyOptions = [];

  const {files, handleFolderUpload, folderPickerRef, clearFilesArray} = useFileFolderPicker();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const bankTransactions = useSelector(selectBankTransactions);
  const bankClientTableData = useMemo(
    () =>
      bankTransactions?.map(transaction => {
        const counterparty = {
          name: transaction?.bankClientStatement?.counterparty,
          iban: transaction?.bankClientStatement?.counterpartyIban,
          edrpou: transaction?.bankClientStatement?.counterpartyEdrpou,
        };
        const statement = {
          name: "ГРУПА КОМПАНIЙ ТЕХНО ПП",
          iban: transaction?.bankClientStatement?.iban,
          edrpou: transaction?.bankClientStatement?.edrpou,
        };
        const isDebit = Boolean(transaction?.bankClientStatement?.isDebit);
        const payer = isDebit ? statement : counterparty;
        const recipient = isDebit ? counterparty : statement;
        return {
          id: transaction?.id,
          number: transaction?.id,
          counterparty: transaction?.bankClientStatement?.counterparty,
          date: dateFormat(transaction?.bankClientStatement?.dateAndTime),
          status: "",
          operationType: "?",
          costsDt: isDebit ? "" : numberFormat(transaction?.sum),
          revenueCt: isDebit ? numberFormat(transaction?.sum) : "",
          currency: "",
          payerAccountNumber: transaction?.debitAccount?.accountNumber,
          requisitesP: payer?.edrpou,
          payerName: payer?.name,
          paymentPurpose: transaction?.paymentPurpose,
          recipientAccountNumber: transaction?.creditAccount?.accountNumber,
          requisitesR: recipient?.edrpou,
          recipientName: recipient?.name,
          rate: transaction?.bankClientStatement?.rate,
          equivalent: transaction?.bankClientStatement?.nbuEquivalentSum,
          remainder: numberFormat(transaction?.[isDebit ? "debitAccount" : "creditAccount"]?.balance),
        };
      }) || [],
    [bankTransactions],
  );

  const bankClientTableColumns = useColumns(BANK_CLIENT_TABLE_COLUMNS);

  useEffect(() => {
    dispatch(fetchAllBankTransactions());
  }, []);

  useEffect(() => {
    if (files?.length > 0) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append("file", file);
      });
      dispatch(uploadBankClientStatements(formData)).then(resp => {
        clearFilesArray();
        if (resp.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllBankTransactions());
        }
      });
    }
  }, [files]);

  const onBankClientFilterChange = () => {};
  const onBankStatementsListFilterChange = () => {};

  const onGetDataBtnClick = () => {
    swalCustom.info({
      text: `${t("bank client folder pick")}\n *${t("folder pick warning")} `,
      noTitle: true,
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmFunc: () => folderPickerRef.current.click(),
    });
  };

  return {
    bankOptions,
    currencyOptions,
    loading,
    onBankClientFilterChange,
    onBankStatementsListFilterChange,
    onGetDataBtnClick,
    bankClientTableColumns,
    bankClientTableData,
    folderPickerRef,
    handleFolderUpload,
  };
};

export default useBankClient;
