export const PROJECT_DIRECTIVE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 20,
    maxSize: 20,
  },
  {
    header: "Status",
    accessorKey: "status",
    size: 90,
    maxSize: 90,
    isSortable: true,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 40,
    maxSize: 45,
    isSortable: true,
  },
  {
    header: "Date To",
    accessorKey: "dateTo",
    size: 40,
    maxSize: 45,
    isSortable: true,
  },
  {
    header: "Sum (n)",
    accessorKey: "sumPlan",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumFact",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    size: 60,
    maxSize: 60,
    isSortable: true,
  },
  {
    header: "Directive type",
    accessorKey: "operationType",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Client №",
    accessorKey: "clientNumber",
    size: 40,
    maxSize: 40,
    isSortable: true,
  },
  {
    header: "CO №",
    accessorKey: "coNumber",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },

  {
    header: "Payer Name",
    accessorKey: "payerName",
    size: 100,
    isSortable: true,
  },

  {
    header: "Payer`s EDRPOUO",
    accessorKey: "payerEDRPOU",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Payer`s account",
    accessorKey: "payerAccount",
    size: 100,
    isSortable: true,
  },
  // {
  //   header: "Pending clarification",
  //   accessorKey: "pendingClarification",
  //   size: 70,
  //   maxSize: 70,
  //   isSortable: true,
  // },
]);

export const CREATE_INNER_DIRECTIVE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
    maxSize: 15,
  },
  {
    header: "Date To",
    accessorKey: "dateTo",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Date (f)",
    accessorKey: "factDate",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Directive №",
    accessorKey: "directiveNumber",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Dt",
    accessorKey: "dt",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Kt",
    accessorKey: "kt",
    size: 50,
    maxSize: 50,
    isSortable: true,
  },
  {
    header: "Sum (f)",
    accessorKey: "sumF",
    size: 70,
    maxSize: 70,
    isSortable: true,
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
    size: 100,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "note",
    alignStart: true,
    size: 100,
  },
]);
