import React from "react";

import ReactTable from "../../../../conteiners/ReactTable";
import useColumns from "../../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {BANK_CLIENT_TABLE_COLUMNS, BANK_OPERATIONS_TABLE_COLUMNS} from "../../constants";

import {BANK_OPERATIONS_LIST_TABLE_COLUMNS} from "./constants";

const BankOperationTable = () => {
  const collumns = useColumns(BANK_OPERATIONS_TABLE_COLUMNS);
  const tableData = [];
  const onOperationSelectSelect = () => {};
  return (
    <div className="h100">
      <ReactTable
        columns={collumns}
        defaultData={tableData}
        onSelect={onOperationSelectSelect}
        className="fGrow scrollX scrollY fShrink"
        current={null}
        loading={false}
      />
    </div>
  );
};

export default BankOperationTable;
