import {t} from "i18next";

import {Filters} from "../../../../../conteiners/filters/Filter.hoc";
import {DateInput, TextInput} from "../../../../../Inputs";
import {Select} from "../../../../../Inputs/SelectField";

const AccAssociationFilters = () => {
  const bankOptions = [];
  const counterpartyTypeOptions = [];

  const onFilterDelete = () => {};

  return (
    <Filters onFilterDelete={onFilterDelete}>
      <div className="fcCol gap w100">
        <div className="row w100 aibl">
          <div className="row border br padding0_5" style={{width: "85%"}}>
            <Select name="bankIdFilter" label={t("Bank")} options={bankOptions} autolabel />
            <TextInput
              type="number"
              name="mfiFilter"
              label={t("MFI")}
              autolabel
              inputClassName="inputTypeNumberNoArrows"
            />
            <TextInput
              type="number"
              name="extIntAccNumber"
              label={t("External/Internal acc. №")}
              autolabel
              inputClassName="inputTypeNumberNoArrows"
            />
            <TextInput name="externalAccNamingFilter" label={t("Account naming ")} autolabel />
          </div>
          <DateInput name="dateStart" label={t("Period From")} generalClassName="jcfe" />
        </div>
        <div className="row w100 aibl">
          <div className="row border br padding0_5" style={{width: "85%"}}>
            <Select
              name="counterpartyTypeId"
              label={t("Counterparty Type")}
              options={counterpartyTypeOptions}
              autolabel
            />
            <TextInput
              type="number"
              name="clientNumber"
              label={t("Client №")}
              autolabel
              inputClassName="inputTypeNumberNoArrows"
            />
            <TextInput
              type="number"
              name="payerAccNumber"
              label={t("Payer account number")}
              autolabel
              inputClassName="inputTypeNumberNoArrows"
            />
            <TextInput name="internalAccNamingFilter" label={t("Account naming ")} autolabel />
          </div>
          <DateInput name="dateEnd" label={t("To")} generalClassName="jcfe" />
        </div>
      </div>
    </Filters>
  );
};

export default AccAssociationFilters;
