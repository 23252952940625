import {useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import {
  fetchFinOrderTypes,
  fetchOperationTypes,
  selectCreatedDirective,
  selectFinOrderTypes,
  selectOperationTypes,
} from "../../../FinBlock/FinSettings/FinSettingsSlice";
import {ORDER_CHANGING_PART_TYPES} from "../OrderChangingPart/OrderChangingPart";

import {
  DIRECTIVE_CREATE_PART_DEPENDENCY,
  DIRECTIVE_PART_DEPENDENCY,
  ORDER_COMPOSED_PART_TYPES,
} from "./OrderTypeComposedPart";

const useOrderTypeComposedPart = ({isForCreate, type, coId}) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const {values, setFieldValue} = useFormikContext();
  const {finOrderTypeId, operationTypeId, fulfilBy} = values || {};
  const isOkBtnDisabled =
    !fulfilBy ||
    (!isForCreate && !finOrderTypeId) ||
    !operationTypeId ||
    (!isForCreate && finOrderTypeId === "default") ||
    operationTypeId === "default";

  const operationTypes = useSelector(selectOperationTypes);
  const finOrderTypes = useSelector(selectFinOrderTypes);
  const createdDirective = useSelector(selectCreatedDirective);
  const isDirectiveCreated = createdDirective?.directiveId;

  useEffect(() => {
    const isCurrentFinOrderinFetched = finOrderTypes.find(({typeId}) => typeId === finOrderTypeId);
    if (!isCurrentFinOrderinFetched && finOrderTypeId !== "default") {
      setTimeout(() => setFieldValue("finOrderTypeId", "default"));
    }
  }, [finOrderTypes?.length]);

  useEffect(() => {
    const isCurrentOperationinFetched = operationTypes.find(
      operationType => operationType.operationTypeId === operationTypeId,
    );
    if (!isCurrentOperationinFetched && operationTypeId !== "default") {
      setTimeout(() => setFieldValue("operationTypeId", "default"), 100);
    }
  }, [operationTypes?.length]);

  useEffect(() => {
    dispatch(fetchFinOrderTypes({}));
    dispatch(fetchOperationTypes({...(isForCreate ? {fromCo: isForCreate, coId} : {})}));
  }, []);

  useEffect(() => {
    if (finOrderTypeId && finOrderTypeId !== "default") {
      dispatch(fetchOperationTypes({typeId: finOrderTypeId, ...(isForCreate ? {fromCo: isForCreate, coId} : {})}));
    }
  }, [finOrderTypeId]);

  useEffect(() => {
    if (operationTypeId && operationTypeId !== "default") {
      dispatch(fetchFinOrderTypes({operationTypeId}));
    }
  }, [operationTypeId]);

  const finOrderTypeOptions =
    finOrderTypes?.map(({typeId, nameEng, nameUkr}) => ({
      title: language === "en" ? nameEng : nameUkr,
      value: typeId,
      disabled: !(typeId === 2 || typeId === 3),
    })) ?? [];
  // const finOrderTypeOptions = [
  //   {title: t("Cash"), value: 2},
  //   {title: t("Cashless"), value: 3},
  // ];

  // ?.filter(type => type?.value !== 5)
  const operationTypeOptions =
    operationTypes
      ?.filter(({isAvailable}) => isAvailable)
      ?.map(({operationTypeId, nameEng, nameUkr}) => ({
        title: language === "en" ? nameEng : nameUkr,
        value: operationTypeId,
      })) ?? [];

  const changingPartType = useMemo(() => {
    let result;
    switch (type) {
      case ORDER_COMPOSED_PART_TYPES.KP_CREATE:
      case ORDER_COMPOSED_PART_TYPES.VIEW_OPERATION_TYPE:
        result = isDirectiveCreated && DIRECTIVE_CREATE_PART_DEPENDENCY[operationTypeId];
        break;

      case ORDER_COMPOSED_PART_TYPES.VIEW_FUll:
      case ORDER_COMPOSED_PART_TYPES.FINBLOCK_DO:
        result = DIRECTIVE_PART_DEPENDENCY[`${operationTypeId}.${finOrderTypeId}`];
        break;

      default:
        result = isDirectiveCreated && DIRECTIVE_PART_DEPENDENCY[`${operationTypeId}.${finOrderTypeId}`];
        break;
    }
    return result;
  }, [isOkBtnDisabled, operationTypeId, finOrderTypeId, isDirectiveCreated]);

  useEffect(() => {
    if (
      changingPartType === ORDER_CHANGING_PART_TYPES.ON_CLIENT_BALANCE_GENERAL ||
      changingPartType === ORDER_CHANGING_PART_TYPES.CLIENT_REFUND_GENERAL ||
      changingPartType === ORDER_CHANGING_PART_TYPES.ON_CREDIT_REPAYMENT_GENERAL
    ) {
      setTimeout(() => {
        setFieldValue("areAllFieldsFilledIn", null);
      });
    }
  }, [changingPartType]);

  function onDefaultFinOrderTypeSelect() {
    dispatch(fetchFinOrderTypes({}));
  }

  function onDefaultOperationTypeSelect() {
    dispatch(fetchOperationTypes({...(isForCreate ? {fromCo: isForCreate, coId} : {})}));
  }
  return {
    finOrderTypeOptions,
    onDefaultFinOrderTypeSelect,
    operationTypeOptions,
    onDefaultOperationTypeSelect,
    isDirectiveCreated,
    isOkBtnDisabled,
    changingPartType,
    createdDirective,
  };
};

export default useOrderTypeComposedPart;
