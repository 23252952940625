export const BANK_OPERATIONS_LIST_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 10,
  },
  {
    header: "Date",
    accessorKey: "date",
  },
  {
    header: "Status",
    accessorKey: "status",
  },
  {
    header: "Operation type",
    accessorKey: "operationType",
  },
  {
    header: "Expenses (Dt)",
    accessorKey: "debitExpenses",
  },
  {
    header: "Expenses (Ct)",
    accessorKey: "creditExpenses",
  },
  {
    header: "Payer's account no",
    accessorKey: "payerAccNumber",
  },
  {
    header: "EDRPOU / ITN (P.)",
    accessorKey: "payerIpn",
  },
  {
    header: "Payer Name",
    accessorKey: "payerName",
  },
  {
    header: "Payment purpose",
    accessorKey: "paymentPurpose",
  },
  {
    header: "recipient's account no",
    accessorKey: "recipientAccNumber",
  },
  {
    header: "EDRPOU / ITN (R.)",
    accessorKey: "recipientIpn",
  },
  {
    header: "Recipient Name",
    accessorKey: "recipientName",
  },
  {
    header: "Bank (R.)",
    accessorKey: "recipientBank",
  },
  {
    header: "BIC (R.)",
    accessorKey: "recipientBIC",
  },
  {
    header: "Rate",
    accessorKey: "rate",
  },
  {
    header: "Equivalent",
    accessorKey: "equivalent",
  },
  {
    header: "Commissions sum",
    accessorKey: "commissionsSum",
  },
  {
    header: "Remaining funds",
    accessorKey: "remainingFunds",
  },
  {
    header: "Reference i",
    accessorKey: "reference",
  },
  {
    header: "Operation code",
    accessorKey: "operationCode",
  },
]);
