import ConnectIcon from "../../../../../../images/icons/link.png";
import DisconnectIcon from "../../../../../../images/icons/linkBreak.png";
import ImageButton from "../../../../../../utilities/Buttons/ImageBtn";

import ExtIntAccPart from "./ExtIntAccPart";
import PayerAccPart from "./PayerAccPart";

const AccAssociationAccounts = () => {
  const onConnectAccBtnClick = () => {};
  const onDisconnectAccBtnClick = () => {};

  const onSaveExternalAccBtnClick = () => {};
  const onSavePayerAccBtnClick = () => {};

  return (
    <div className="row gap w100 mb">
      <ExtIntAccPart onSaveExternalAccBtnClick={onSaveExternalAccBtnClick} />
      <div className="fcCol jcc gap">
        <ImageButton src={ConnectIcon} onClick={onConnectAccBtnClick} width={2.3} height={2.3} />
        <ImageButton src={DisconnectIcon} onClick={onDisconnectAccBtnClick} width={2.3} height={2.3} />
      </div>
      <PayerAccPart onSavePayerAccBtnClick={onSavePayerAccBtnClick} />
    </div>
  );
};

export default AccAssociationAccounts;
