import {useEffect} from "react";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";

import Divider from "../../../../../utilities/Divider/Divider";
import ModalForm from "../../../../Modal/FormModal";
import ClientOrderModal from "../ClientOrderModal/ClientOrderModal";

import AssociatedTransaction from "./parts/AssociatedTransaction";
import CounterpartyAssociation from "./parts/CounterpartyAssociation";
import CounterpartyInfo from "./parts/CounterpartyInfo";
import DirectiveAndOrder from "./parts/DirectiveAndOrder";
import RealTransaction from "./parts/RealTransaction";
import useAccAssotiation from "./useAccAssotiation";

const AccountAssociationAndTransaction = ({hasAssociation, isResidue, savedFilters}) => {
  const {
    isModalActive,
    currentOrderId,
    onCreateDirectiveBtnClick,
    onSaveAssociationBtnClick,
    associatedTransactionTableData,
    realTransactionTableData,
    bankTransaction,
    statementDirective,
    setModalActive,
    isBankTransactionCompleted,
    hasInternal,
    isAlignedResidue,
  } = useAccAssotiation({isResidue, savedFilters});

  const {values, setValues} = useFormikContext();
  const hasDirective = bankTransaction?.directive;

  useEffect(() => {
    setValues(prev => ({
      ...prev,
      counterpartyType: hasInternal || isResidue ? "client" : "",
      counterpartyTypeId: isResidue ? "2" : "",
      clientId: hasInternal ? bankTransaction?.counterpartyAccount?.owner?.clientId : "",
      transactionType: isResidue ? "На Баланс Платника" : "",
      clientNumber: hasInternal ? bankTransaction?.counterpartyAccount?.owner?.clientNumber : "",
      counterpartyNaming: bankTransaction?.directive?.outsideParty?.name,
      counterpartyEdrpou: bankTransaction?.directive?.outsideParty?.edrpouCode,
      counterpartyIban: bankTransaction?.directive?.outsideParty?.currentAccount,
      counterpartyDirectiveNumber: bankTransaction?.directive?.directiveNumber || "-",
      paymentCurrency: bankTransaction?.currency?.currencyId,
    }));
  }, [bankTransaction?.transactionId, hasInternal]);

  return (
    <div className="fcCol w100 pt gap0_5 fGrow">
      <RealTransaction realTransactionTableData={realTransactionTableData} />
      <AssociatedTransaction associatedTransactionTableData={associatedTransactionTableData} />
      {!hasAssociation && (
        <>
          <Divider margin="5px" />
          <CounterpartyAssociation
            onSaveAssociationBtnClick={() => onSaveAssociationBtnClick({isResidue, values, savedFilters})}
            hasAssociation={hasInternal}
            isResidue={isResidue}
            isAlignedResidue={isAlignedResidue}
            isBankTransactionCompleted={isBankTransactionCompleted}
          />
        </>
      )}
      {hasDirective && <CounterpartyInfo />}
      {hasAssociation && (
        <>
          <Divider margin="5px" />
          <DirectiveAndOrder
            orderId={currentOrderId}
            onCreateDirectiveBtnClick={onCreateDirectiveBtnClick}
            noCounterpartyAssociation={hasAssociation}
            hasAssociation={hasInternal}
            isDirectiveCreated={!!statementDirective}
            isBankTransactionCompleted={isBankTransactionCompleted}
            savedFilters={savedFilters}
          />
        </>
      )}

      <ModalForm
        label={t("Client Order")}
        active={isModalActive}
        setModalActive={setModalActive}
        closable
        Component={ClientOrderModal}
        style={{width: "80rem"}}
      />
    </div>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    paymentCurrency: 980,
  }),
})(AccountAssociationAndTransaction);
