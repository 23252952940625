import {Route, Routes} from "react-router-dom";

import DevTicketsList from "../Components/Reference/DevTicketsList/DevTicketsList";

const ReferencePage = () => {
  return (
    <Routes>
      <Route path="/" element={<DevTicketsList />} />
    </Routes>
  );
};

export default ReferencePage;
