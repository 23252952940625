import React from "react";

import DirectiveTypePart from "./parts/DirectiveTypePart";
import PayerPart from "./parts/PayerPart";
import ReceiverPart from "./parts/ReceiverPart";
import {CASHDESK_DIRECTIVE_TYPES} from "./CashdeskDirective";

const ComposedPart = ({
  directiveType,
  currentDirectiveSubType,
  setCurrentDirectiveSubType,
  currentPayer,
  setCurrentPayer,
  currentReciver,
  setCurrentReciver,
}) => {
  switch (directiveType) {
    case CASHDESK_DIRECTIVE_TYPES.ADVENT:
    case CASHDESK_DIRECTIVE_TYPES.EXPENCE:
      return (
        <div className="row fGrow gap border padding">
          <DirectiveTypePart
            currentDirectiveSubType={currentDirectiveSubType}
            setCurrentDirectiveSubType={setCurrentDirectiveSubType}
          />
          <PayerPart currentPayer={currentPayer} setCurrentPayer={setCurrentPayer} />
          <ReceiverPart currentReciver={currentReciver} setCurrentReciver={setCurrentReciver} />
        </div>
      );
    case CASHDESK_DIRECTIVE_TYPES.CURRENCY_EXCHANGE:
      return (
        <div className="row fGrow gap border padding">
          <DirectiveTypePart
            currentDirectiveSubType={currentDirectiveSubType}
            setCurrentDirectiveSubType={setCurrentDirectiveSubType}
          />
        </div>
      );
    case CASHDESK_DIRECTIVE_TYPES.TRANSIT:
      return (
        <div className="row fGrow gap border padding">
          <DirectiveTypePart
            currentDirectiveSubType={currentDirectiveSubType}
            setCurrentDirectiveSubType={setCurrentDirectiveSubType}
          />
          <PayerPart currentPayer={currentPayer} setCurrentPayer={setCurrentPayer} />
          <ReceiverPart currentReciver={currentReciver} setCurrentReciver={setCurrentReciver} />
        </div>
      );
    default:
      return <div className="row fGrow gap border"></div>;
  }
};

export default ComposedPart;
