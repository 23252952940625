import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import PageContainer from "../../conteiners/PageContainer";
import ModalForm from "../../Modal/FormModal";
import SkuList from "../../SKU/SkuList";
import SkuReactTableList from "../../SKU/SkuReactTableList";
import {fetchSkuCatalogs, getCurrentSku, getSkuInfo} from "../../SKU/SkuSlice";
import SkuFilters from "../../SKU/VED/Filters/SkuFilters";
import CreateSkuBtn from "../../SKU/VED/SkuVedForm/skuCreate/CreateSkuBtn";

import KpCreateForm from "./kpFinForm/KpCreateForm";
import KpUpdateForm from "./kpFinForm/KpUpdateForm";
import KpFinTable from "./kpFinTable/KpFinTable";
import {
  addSkuToKpF,
  delSkuPlan,
  fetchSkuPlanListForKpf,
  getCurrentSkuPlan,
  getPlanSkuList,
  selectCurrentCof,
} from "./KpFinSlice";

const KpFin = ({isTab = true}) => {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  // const {addNewTabCustom} = useTabsContext();

  //get current COf code from slice
  // const currentSubKp = useSelector(getCurrentSubKp);
  const currentSubKp = useSelector(selectCurrentCof);
  const planSkuList = useSelector(getPlanSkuList);
  const currentSkuPlan = useSelector(getCurrentSkuPlan);

  //get current SKU from slice
  const currentSku = useSelector(getCurrentSku);

  const [currentKpF, setCurrentKpF] = useState();

  const [showAddSkuModal, setShowAddSkuModal] = useState(false);

  // get current COf code and sets it to local storage, get SKU list for this COf
  useEffect(() => {
    if (currentSubKp) {
      const currentSubKpObj = JSON.stringify(currentSubKp);
      localStorage.setItem("kpf", currentSubKpObj);
      setCurrentKpF(currentSubKp);

      dispatch(fetchSkuPlanListForKpf(currentSubKp.cofId));
    } else {
      const storedSubKpObj = localStorage.getItem("kpf");
      const currentKpFfromLS = JSON.parse(storedSubKpObj);
      setCurrentKpF(currentKpFfromLS);
      dispatch(fetchSkuPlanListForKpf(currentKpFfromLS.cofId));
    }
  }, []);

  const [formType, setFormType] = useState("CREATE");

  const formComponent = useMemo(() => {
    switch (formType) {
      case "CREATE":
        return KpCreateForm;
      case "UPDATE":
        return KpUpdateForm;
    }
  }, [formType]);

  useEffect(() => {
    dispatch(fetchSkuCatalogs());
  }, []);

  const initialValues = useMemo(() => {
    return {
      skuId: "",
      name: "",
      skuNameCheck: false,
      nameEng: "",
      nameEngCheck: false,
      productSeries: "",
      productSeriesCheck: false,
      material: "",
      materialCheck: false,
      color: "",
      colorCheck: false,
      complectDesc: "",
      complectDescCheck: false,
      materialType: "",
      materialTypeCheck: false,
      colorType: "",
      colorTypeCheck: false,
      batch: "",
      batchCheck: false,
      unitIdCheck: false,
      volumeWeightChar: "",
      volumeWeightCharCheck: false,
      firstImage: "",
      secondImage: null,
      amount: 1,
      listPrice: "",
      retailPriceCheck: false,
      retailPrice: "",
      discount: "",
      discountCheck: false,
      topDiscount: "",
      topDiscountCheck: false,
      note: "",
      allChar: false,
    };
  }, []);

  function filterSkuPlanObj(obj1, obj2) {
    const commonKeys = Object.keys(obj2);
    return Object.fromEntries(commonKeys.map(key => [key, obj1[key] || obj2[key]]));
  }

  //delete selected sku plan using delete button
  const onDelete = currSkuPlan => {
    Swal.fire({
      title: "",
      text: `${currSkuPlan?.brand?.prefix + "-" + currSkuPlan?.article} ${t("Will be removed from")} ${t("CO №")} ${
        currentKpF.cofNumber
      }`,
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(delSkuPlan(currSkuPlan.skuPlanId));
      }
    });
  };

  //check if currSKU is already in plan SKU list and open modal for update and add, is it is not
  const onAddSkuToKpf = currSku => {
    setFormType("CREATE");

    const matchPlanSKU = planSkuList.find(item => item.sku.skuId === currSku.skuId);

    if (matchPlanSKU) {
      Swal.fire({
        title: "",
        text: `${currSku?.brand?.prefix + "-" + currSku?.article} ${t("Already in")} ${t("CO №")} ${
          currentKpF.cofNumber
        } `,
        icon: "error",
      });
      return;
    }

    var newSkuPlan = filterSkuPlanObj(currSku, initialValues);
    dispatch(
      addSkuToKpF({
        cofId: currentKpF.cofId,
        idealSkuId: currentSku.skuId,
        skuPlan: {
          ...newSkuPlan,
          productSeries: currSku.name,
        },
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setShowAddSkuModal(true);
      }
    });
  };

  const onEditSkuInKpf = currSkuPlan => {
    setFormType("UPDATE");

    const matchPlanSKU = planSkuList.find(item => item.skuPlanId === currSkuPlan.skuPlanId);
    if (matchPlanSKU) {
      dispatch(getSkuInfo(currSkuPlan.sku.skuId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setShowAddSkuModal(true);
          // addNewTabCustom({
          //   TabComponent: KpUpdateForm,
          //   controlled: true,
          //   title: `${t("Edit SKU")} ${currentSkuPlan?.brand?.prefix + "-" + currentSkuPlan?.article} ${t("in")} ${t(
          //     "CO №",
          //   )} ${currentKpF?.cofNumber}`,
          //   tabIdName: "EditSku",
          // });
        }
      });
    }
  };

  return isTab ? (
    <div className="form_wrapper" style={{height: "100%"}}>
      <div className="">
        <div>
          <SkuFilters />
          <div style={{height: "12em"}}>
            <SkuReactTableList onAddSkuToKpf={onAddSkuToKpf} />
          </div>
        </div>
        <div className="flex_container space-between ">
          <FunctionButton name={t("Add common string")} style={{width: "200px", margin: "1em"}} disabled={true} />

          <div style={{display: "flex", gap: "20px"}}>
            <FunctionButton
              name={t("Change and add to CO(f)")}
              autoWidth
              onClick={() => {
                onAddSkuToKpf(currentSku);
              }}
              disabled={!currentSku}
            />

            <FunctionButton
              name={t("Delete from CO(f)")}
              onClick={() => {
                onDelete(currentSkuPlan);
              }}
              disabled={!currentSkuPlan}
            />

            <FunctionButton
              name={t("Edit in CO(f)")}
              onClick={() => {
                onEditSkuInKpf(currentSkuPlan);
              }}
              disabled={!currentSkuPlan}
            />
          </div>
          {/* creting Ideal Sku */}
          <CreateSkuBtn />
        </div>

        <ModalForm
          active={showAddSkuModal}
          Component={formComponent}
          setModalActive={setShowAddSkuModal}
          label={
            formType === "CREATE"
              ? `${t("Add SKU")} ${currentSku?.brand?.prefix + "-" + currentSku?.article} ${t("to")} ${
                  currentKpF?.cofNumber
                }`
              : `${t("Edit SKU")} ${currentSkuPlan?.brand?.prefix + "-" + currentSkuPlan?.article} ${t("in")} ${t(
                  "CO №",
                )} ${currentKpF?.cofNumber}`
          }
          typeForm={formType}
        />
      </div>

      <KpFinTable currentKpF={currentKpF} kpFin={true} />
    </div>
  ) : (
    <PageContainer title={`${t("CO(f)")} ${currentKpF?.cofNumber || ""}`} loading={false}>
      <div className="form_wrapper" style={{height: "100%"}}>
        <div className="">
          <div>
            <SkuFilters />
            <div style={{height: "12em"}}>
              <SkuReactTableList onAddSkuToKpf={onAddSkuToKpf} />
            </div>
          </div>
          <div className="flex_container space-between ">
            <FunctionButton name={t("Add common string")} style={{width: "200px", margin: "1em"}} disabled={true} />

            <div style={{display: "flex", gap: "20px"}}>
              <FunctionButton
                name={t("Change and add to CO(f)")}
                autoWidth
                onClick={() => {
                  onAddSkuToKpf(currentSku);
                }}
                disabled={!currentSku}
              />

              <FunctionButton
                name={t("Delete from CO(f)")}
                onClick={() => {
                  onDelete(currentSkuPlan);
                }}
                disabled={!currentSkuPlan}
              />

              <FunctionButton
                name={t("Edit in CO(f)")}
                onClick={() => {
                  onEditSkuInKpf(currentSkuPlan);
                }}
                disabled={!currentSkuPlan}
              />
            </div>
            {/* creting Ideal Sku */}
            <CreateSkuBtn />
          </div>

          <ModalForm
            active={showAddSkuModal}
            Component={formComponent}
            setModalActive={setShowAddSkuModal}
            label={
              formType === "CREATE"
                ? `${t("Add SKU")} ${currentSku?.brand?.prefix + "-" + currentSku?.article} ${t("to")} ${
                    currentKpF?.cofNumber
                  }`
                : `${t("Edit SKU")} ${currentSkuPlan?.brand?.prefix + "-" + currentSkuPlan?.article} ${t("in")} ${t(
                    "CO №",
                  )} ${currentKpF?.cofNumber}`
            }
            typeForm={formType}
          />
        </div>

        <KpFinTable currentKpF={currentKpF} kpFin={true} />
      </div>
    </PageContainer>
  );
};

export default KpFin;
