import {useEffect, useRef, useState} from "react";
import CurrencyInput from "react-currency-input-field";
import {useField} from "formik";
import {t} from "i18next";
import PropTypes from "prop-types";

import QuestionItem from "../../utilities/QuestionItem";

const MoneyInput = ({
  label,
  width = "5em",
  height,
  question = null,
  autolabel,
  labelStyles,
  generalStyles,
  disabled = false,
  currency = "EUR",
  placeholder = "€",
  inputClassName = "",
  required = false,
  readOnly = false,
  setref = null,
  // func = null,
  onBlur = () => {},
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState("");

  const {setValue: setRawValue, setError} = helpers;

  const inputRef = useRef(null);

  const setInputRef = ref => {
    if (ref) {
      inputRef.current = ref;
      if (setref) setref(ref);
    }
  };

  const handleFocus = () => {
    inputRef?.current.select();
  };

  const [className, setClassName] = useState("moneyInp");

  const validateValue = val => {
    const rawValue = val === undefined || !val ? null : val;
    setValue(rawValue);

    setRawValue(rawValue);

    if (Number.isNaN(Number(val))) {
      setError("не валідна сумма");
    }
  };

  // useEffect(() => {
  //   if (field?.value === meta.initialValue) return;
  //   validateValue(field?.value);
  // }, [field?.value, meta.initialValue]);

  useEffect(() => {
    validateValue(field?.value);
  }, [field?.value]);

  return (
    <div className={"project_input money_input"} style={generalStyles}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {typeof label === "string" ? `${t(label)}${required ? "*" : ""}` : t(label)}
          {/* {label} */}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className}>
          <CurrencyInput
            // id={`validation-example-${props.name}-field`}
            placeholder={placeholder}
            allowDecimals={true}
            readOnly={readOnly}
            className={`form-control ${className} ${inputClassName}`}
            onValueChange={validateValue}
            groupSeparator={"'"}
            decimalSeparator={"."}
            decimalsLimit={2}
            decimalScale={2}
            maxLength={10}
            allowNegativeValue={false}
            prefix={currency === "UAH" ? "₴" : ""}
            step={10}
            autoComplete="off"
            value={value || ""}
            ref={setInputRef}
            intlConfig={{
              locale: "en-US",
              currency: currency,
            }}
            onFocus={() => {
              !readOnly && handleFocus();
            }}
            disabled={disabled}
            onBlur={e => {
              // setRawValue(value);
              onBlur(e);
            }}
            style={{
              height: height,
              cursor: readOnly ? "default" : "text",
              ...(readOnly ? {cursor: "default"} : {background: "white"}),
            }}
          />
        </div>
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} /> : null}
    </div>
  );
};
MoneyInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
export default MoneyInput;
