import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../../Inputs";
import TextAreaInput from "../../../Inputs/TextAreaInput";
import {getRequestSkuInfo} from "../../../Requests/RequestsSlice";
import {getColorImages, getDecorImages, getDrawings, getSkuImages} from "../../../SKU/SkuSlice";
import {getCurrentSkuPlan, updateSkuPlan} from "../KpFinSlice";
import AddSkuToTable from "../kpFinTable/AddSkuToTable";

import AllCheckBlock from "./kpFinFormBlocks/AllCheckBlock";
import CharDeskBlock from "./kpFinFormBlocks/CharDeskBlock";
import DecorColorBlock from "./kpFinFormBlocks/DecorColorBlock";
import ImageBlock from "./kpFinFormBlocks/ImagesBlock";
import NameBlock from "./kpFinFormBlocks/NameBlock";
import OverallView from "./kpFinFormBlocks/OverallView";
import SeriesBatchBlock from "./kpFinFormBlocks/SeriesBatchBlock";
import SummBlock from "./kpFinFormBlocks/SummBlock";
import UpdateControl from "./kpFinFormBlocks/UpdateControl";
import VolumeWeightCharacteristicsKpf from "./kpFinFormBlocks/VolumeWeightCharacteristicsKpf";

import "./kpFinForm.scss";

const KpFinForm = ({forcedUpdate, saveLabel, onSubmit, onCancel, isShowOverallView, toolTip}) => {
  const {t} = useTranslation();

  const currentSkuPlan = useSelector(getCurrentSkuPlan);
  const currentRequestSku = useSelector(getRequestSkuInfo);

  const dispatch = useDispatch();
  const initialValues = useMemo(() => {
    return {
      skuId: "",
      name: "",
      nameCheck: false,
      nameEng: "",
      nameEngCheck: false,
      productSeries: "",
      productSeriesCheck: false,
      material: "",
      materialCheck: false,
      color: "",
      colorCheck: false,
      complectDesc: "",
      complectDescCheck: false,
      materialType: "",
      materialTypeCheck: false,
      colorType: "",
      colorTypeCheck: false,
      batch: "",
      batchCheck: false,
      unitIdCheck: false,

      brand: "",
      brandName: "",

      amount: 1,
      listPrice: "",
      retailPriceCheck: false,
      retailPrice: "",
      discount: null,
      discountCheck: false,
      topDiscount: null,
      topDiscountCheck: false,
      minimumPrice: "",
      note: "",
      allChar: false,
      overallSizeCheck: false,
      areaCheck: false,
      volumeCheck: false,
      weightCheck: false,
      palletCheck: false,

      addedImages: [],
      addedDecorImages: null,
      addedColorImages: null,
      characteristicsText: false,
      charCheck: false,

      overallSizeUnitId: "",
      areaUnitId: "",
      volumeUnitId: "",
      weightUnitId: "",
      overallSizeUnit: "",
      areaUnit: "",
      volumeUnit: "",
      weightUnit: "",
    };
  }, []);

  const onUpdate = values => {
    if (currentSkuPlan) {
      dispatch(
        updateSkuPlan({
          skuPlanId: values.skuPlanId,
          skuPlan: values,
        }),
      );
    }
  };
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Formik
        initialValues={currentSkuPlan || currentRequestSku || initialValues}
        validationSchema={Yup.object().shape({
          topDiscount: Yup.number().max(100).min(0).integer().nullable(true),
        })}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        // onSubmit={onSubmit}
      >
        {({values, setFieldValue}) => {
          return (
            <div className="main-form" onKeyDown={handleKeyDown}>
              <div className="fields_check form-part ">
                <UpdateControl forcedUpdate={forcedUpdate} updFunc={onUpdate} />

                <div className="upper-block">
                  <div className="part-with-img">
                    <div className="left-part-p">
                      <AllCheckBlock />
                      <NameBlock />
                      <CharDeskBlock />
                    </div>

                    <div className="image-block-three">
                      {isShowOverallView && <OverallView />}
                      <ImageBlock label={"Product view"} selectorType={getSkuImages} imageType={"Common"} />
                      <ImageBlock label={"Drawings"} selectorType={getDrawings} imageType={"Drawings"} />
                    </div>

                    <div className="image-block-two">
                      <ImageBlock label={"Finishing"} selectorType={getDecorImages} imageType={"Decor"} />
                      <ImageBlock label={"Color"} selectorType={getColorImages} imageType={"Color"} />
                    </div>
                    <DecorColorBlock />
                  </div>
                  <div className="lower-part">
                    <SeriesBatchBlock />
                    <div className="vwchar">
                      <label className="label">{t("VWC")}</label>
                      <VolumeWeightCharacteristicsKpf readonly={false} />
                    </div>
                  </div>
                </div>

                <div className="border"></div>
              </div>

              {/* <div className=" w100 fcCol"> */}
              <SummBlock />
              <div className="border border_gap"></div>
              {/* </div> */}
              <div id="add-toKpF_btn">
                <div className="lower-block">
                  <TextInput
                    label="SKU*"
                    name="article"
                    question="curr sku"
                    labelStyles={{width: "3em"}}
                    readOnly={true}
                  />
                  <div className="kpf_button_wrapper">
                    {/* <KpFinForm onSubmit={onSubmit} isShowOverallView={isShowOverallView}> */}
                    <FunctionButton
                      name={saveLabel}
                      tooltipMessage={toolTip}
                      onClick={() => onSubmit(values)}
                      // type="submit"
                      style={{width: "15em"}}
                    />
                    <FunctionButton
                      name={t("Cancel")}
                      onClick={onCancel}
                      style={{marginLeft: "2em", position: "initial", width: "11em"}}
                    />
                    {/* </KpFinForm> */}
                  </div>

                  <TextAreaInput name="note" width="30em" height="3em" placeholder={t("Add header string")} />
                </div>
              </div>

              <AddSkuToTable
                values={values}
                setFieldValue={setFieldValue}
                overallRender={isShowOverallView}
                className={"kpf_table_table_width"}
                chengeable={true}
              />
            </div>
          );
        }}
      </Formik>
    </>
  );
};

KpFinForm.propTypes = {
  forcedUpdate: PropTypes.bool,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  isShowOverallView: PropTypes.bool,
};

export default KpFinForm;
