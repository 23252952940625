import {useState} from "react";
import PropTypes from "prop-types";

import questionImg from "../images/icons/question.png";

import ToolTip from "./ToolTip";

const QuestionItem = ({title, tooltipShown = false, className}) => {
  const [show, setShow] = useState(false);
  return (
    <ToolTip title={title} open={tooltipShown || show} className={className}>
      <img
        src={questionImg}
        id="questionImg"
        alt=""
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
    </ToolTip>
  );
};
QuestionItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
};

export default QuestionItem;
