import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../hook/axios.hook";

const initialState = {
  cashdeskDirective: null,
  bankTransactions: [],
  bankClientStatements: [],
  bankTransaction: null,
  bankClientFilters: [],
  counterpartyTypes: [],

  loading: false,
  error: false,
};

//*** Operations ***

export const addNewCashdeskDirective = createAsyncThunk("finBlock/addNewCashdeskDirective", async body => {
  return fetchData(`/finance/directives/addForCashdesk`, "post", body);
});

///***  Bank Client */
export const uploadBankClientStatements = createAsyncThunk("finBlock/uploadBankClientStatements", async formdata => {
  return fetchData(`/finance/bankClient/upload`, "post", formdata);
});

export const fetchAllBankClientStatements = createAsyncThunk("finBlock/fetchAllBankClientStatements", async params => {
  return fetchList(`/finance/bankClient/statements/getAll`, "get", {params: {...params}});
});

export const fetchAllBankTransactions = createAsyncThunk("finBlock/fetchAllBankTransactions", async params => {
  return fetchList(`/finance/bankClient/bankTransactions/getAll`, "get", {params: {...params}});
});

export const fetchBankClientFilters = createAsyncThunk("finBlock/fetchBankClientFilters", async () => {
  return fetchData(`/finance/bankClient/getFilters`, "get");
});

export const fetchAllCounterpartyTypes = createAsyncThunk("finBlock/fetchAllCounterpartyTypes", async () =>
  fetchList(`/finance/bankClient/counterpartyTypes/getAll`, "get"),
);

export const fetchBankTransaction = createAsyncThunk("finBlock/fetchBankTransaction", async ({id}) => {
  return fetchList(`/finance/bankClient/bankTransactions/${id}`);
});

export const alignCounterpartyAcc = createAsyncThunk(
  "finBlock/alignCounterpartyAcc",
  async ({id, clientId, accountTypeId}) => {
    return fetchData(`/finance/bankClient/bankTransactions/align/${id}`, "patch", {clientId, accountTypeId});
  },
);

export const alignUntilFurtherNotice = createAsyncThunk("finBlock/alignUntilFurtherNotice", async ({id, clientId}) => {
  return fetchData(`/finance/bankClient/bankTransactions/alignUntilFurtherNotice/${id}`, "post", {clientId});
});

export const completeBankTransaction = createAsyncThunk("finBlock/completeBankTransaction", async id =>
  fetchData(`/finance/bankClient/bankTransactions/complete/${id}`, "post"),
);

export const FIN_BLOCK_STORE_NAME = "finBlock";

const FinBlockSlice = createSlice({
  name: FIN_BLOCK_STORE_NAME,
  initialState,
  reducers: {
    clearCashdeskDirective: state => {
      state.cashdeskDirective = null;
    },
  },
  extraReducers: builder => {
    builder
      //** Balance Sheet Accounts **
      .addCase(addNewCashdeskDirective.fulfilled, (state, {payload}) => {
        state.cashdeskDirective = payload.directive;
      })

      .addCase(uploadBankClientStatements.fulfilled, (state, {payload}) => {
        // state.cashdeskDirective = payload.directive;
      })

      .addCase(fetchAllBankTransactions.fulfilled, (state, {payload}) => {
        state.bankTransactions = payload;
      })
      .addCase(fetchBankClientFilters.fulfilled, (state, {payload}) => {
        state.bankClientFilters = payload;
      })
      .addCase(fetchBankTransaction.fulfilled, (state, {payload}) => {
        state.bankTransaction = payload;
      })
      .addCase(alignCounterpartyAcc.fulfilled, (state, {payload}) => {
        state.bankTransaction = payload;
      })
      .addCase(alignUntilFurtherNotice.fulfilled, (state, {payload}) => {
        state.bankTransaction = payload;
      })

      .addCase(fetchAllBankClientStatements.fulfilled, (state, {payload}) => {
        state.bankClientStatements = payload;
      })
      .addCase(fetchAllCounterpartyTypes.fulfilled, (state, {payload}) => {
        state.counterpartyTypes = payload;
      })

      .addCase(completeBankTransaction.fulfilled, (state, {payload}) => {
        state.bankTransaction = payload;
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith(FIN_BLOCK_STORE_NAME) && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith(FIN_BLOCK_STORE_NAME) && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith(FIN_BLOCK_STORE_NAME) && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )
      //default
      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.loading = false;
  state.error = false;
}

function handlePending(state) {
  state.loading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.loading = false;
  state.error = error;
}

const {actions, reducer} = FinBlockSlice;

export const {clearCashdeskDirective} = actions;

export default reducer;

// *** Selectors ***
export const selectCashdeskDirective = state => state[FIN_BLOCK_STORE_NAME].cashdeskDirective;

export const selectBankClientStatements = state => state[FIN_BLOCK_STORE_NAME].bankClientStatements;
export const selectBankTransactions = state => state[FIN_BLOCK_STORE_NAME].bankTransactions;
export const selectBankTransaction = state => state[FIN_BLOCK_STORE_NAME].bankTransaction;
export const selectBankClientFilters = state => state[FIN_BLOCK_STORE_NAME].bankClientFilters;
export const selectFinBlockLoading = state => state[FIN_BLOCK_STORE_NAME].loading;
export const selectCounterpartyTypes = state => state[FIN_BLOCK_STORE_NAME].counterpartyTypes;
