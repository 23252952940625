import {t} from "i18next";

import ReactTable from "../../../conteiners/ReactTable";
import {Select} from "../../../Inputs/SelectField";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {DIRECTIVE_TYPE_PART_TABLE_COLUMNS} from "../constants";

const DirectiveTypePart = ({currentDirectiveSubType, setCurrentDirectiveSubType}) => {
  const columns = useColumns(DIRECTIVE_TYPE_PART_TABLE_COLUMNS);

  const onTableSelect = subType => {
    setCurrentDirectiveSubType(subType);
  };

  return (
    <div className="fcCol gap fGrow aistr" style={{maxWidth: "32%"}}>
      <Select name="directiveType" label={t("Directive type")} options={[]} autolabel required />
      <ReactTable
        columns={columns}
        defaultData={[]}
        className="scrollX scrollY fGrow"
        current={currentDirectiveSubType}
        onSelect={onTableSelect}
      />
    </div>
  );
};

export default DirectiveTypePart;
