import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import saveIcon from "../../../../images/icons/SaveDisket.png";
import {
  addMainTemplateListItem,
  addSelectedTemplateListItem,
  deselectSelectedTemplate,
  deselectTemplate,
  fetchTemplatesWithFilter,
  getCurrentSelectedTemplate,
  getCurrentTemplate,
  getSelectedTemplates,
  getTemplateBrandsLoading,
  getTemplateInfo,
  getTemplateLoading,
  getTemplates,
  removeMainTemplateListItem,
  removeSelectedTemplateListItem,
  selectSelectedTemplate,
  updateTemplate,
} from "../../../../slices/KPSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import UpDownButtons from "../../../../utilities/Buttons/UpDownButtons";
import {dateFormat} from "../../../../utilities/dateFormat";
import makeNameFromPersona from "../../../../utilities/makeNameFromPersona";
import QuestionItem from "../../../../utilities/QuestionItem";
import {useFiltersContext} from "../../../conteiners/filters/useSessionFiltersContext";
import ReactTable from "../../../conteiners/ReactTable";
import {MoneyInput, TextAreaInput} from "../../../Inputs";
import ModalForm from "../../../Modal/FormModal";
import useColumns from "../../../Requests/OrderProcessing/utils/hooks/useColumns";
import {BRAND_TEMPLATE_TABLE_COLUMNS, T_KP_TABLE_COLUMNS} from "../../constants";
import ChooseCoModal from "../../CoTemplates/ChooseCoModal/ChooseCoModal";

const TemplateMainInfo = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const dispatch = useDispatch();

  const templates = useSelector(getTemplates),
    currentTemplate = useSelector(getCurrentTemplate),
    selectedTemplates = useSelector(getSelectedTemplates),
    currentSelectedTemplate = useSelector(getCurrentSelectedTemplate),
    templateLoadingStatus = useSelector(getTemplateLoading),
    templateBrandsLoadingStatus = useSelector(getTemplateBrandsLoading);

  const [chooseCoModalFromMainActive, setChooseCoModalFromMainActive] = useState(false);
  const [disabledTemplates, setDisabledTemplates] = useState([]);

  const {setFieldValue, values, setValues} = useFormikContext();

  const templateCoColumns = useColumns(T_KP_TABLE_COLUMNS);

  const brandTemplateColumns = useColumns(BRAND_TEMPLATE_TABLE_COLUMNS);

  const {filters} = useFiltersContext();

  useEffect(() => {
    fetchTemplates(filters);
  }, [filters]);

  const fetchTemplates = params => {
    dispatch(fetchTemplatesWithFilter(params));
  };
  const templatesTableData = useMemo(
    () =>
      templates?.map(({cofTemplateId, templateNumber, note, createdAt, cof}, index) => ({
        number: index + 1,
        id: cofTemplateId,
        templateNumber,
        managerP: makeNameFromPersona({persona: cof?.co?.project?.projectManager?.persona, language}),
        managerCO: makeNameFromPersona({persona: cof?.co?.managerCo?.persona, language}),
        note,
        templateDate: dateFormat(createdAt),
      })) || [],
    [templates],
  );

  const selectedTemplatesTableData = useMemo(
    () =>
      selectedTemplates?.map(({cofTemplateId, templateNumber, note, createdAt, cof}, index) => ({
        number: index + 1,
        id: cofTemplateId,
        templateNumber,
        managerP: makeNameFromPersona({persona: cof?.co?.project?.projectManager?.persona, language}),
        managerCO: makeNameFromPersona({persona: cof?.co?.managerCo?.persona, language}),
        note,
        templateDate: dateFormat(createdAt),
      })) || [],
    [selectedTemplates],
  );

  const onDownClick = () => {
    const neededTemplate = templates?.find(temp => temp?.cofTemplateId === currentTemplate?.cofTemplateId);
    if (neededTemplate) {
      // dispatch(removeMainTemplateListItem(neededTemplate?.cofTemplateId));
      setDisabledTemplates(prevState => [...prevState, neededTemplate.cofTemplateId]);
      dispatch(addSelectedTemplateListItem(neededTemplate));
      dispatch(deselectSelectedTemplate());
      dispatch(deselectTemplate());
    }
  };

  const onUpClick = () => {
    const neededTemplate = selectedTemplates?.find(
      temp => temp?.cofTemplateId === currentSelectedTemplate?.cofTemplateId,
    );
    if (neededTemplate) {
      dispatch(removeSelectedTemplateListItem(neededTemplate?.cofTemplateId));
      setDisabledTemplates(prevState => prevState.filter(id => id !== neededTemplate.cofTemplateId));
      // dispatch(addMainTemplateListItem(neededTemplate));
      dispatch(deselectSelectedTemplate());
      dispatch(deselectTemplate());
    }
  };

  const brandsTableData = useMemo(
    () =>
      currentTemplate?.brands?.map(({brandId, name}, index) => ({
        number: index + 1,
        id: brandId,
        country: "-",
        brand: name,
      })) || [],
    [currentTemplate],
  );

  const onTemplateSelect = value => {
    if (!value) {
      dispatch(deselectTemplate());
      return;
    }
    dispatch(getTemplateInfo(value));
  };

  const onSelectedTemplateSelect = value => {
    if (!value) {
      dispatch(deselectSelectedTemplate());
      return;
    }
    const neddedTemp = selectedTemplates?.find(temp => temp.cofTemplateId === value);
    dispatch(selectSelectedTemplate(neddedTemp));
  };

  useEffect(() => {
    setValues({tCoSum: currentTemplate?.sum, tCoNote: currentTemplate?.note});
  }, [currentTemplate]);

  const onSaveTemplateNoteClick = () => {
    dispatch(updateTemplate({cofTemplateId: currentTemplate.cofTemplateId, note: {note: values.tCoNote}})).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        fetchTemplates(filters);
        setFieldValue("tCoSum", currentTemplate?.sum);
        dispatch(deselectTemplate());
      }
    });
  };

  const selectedTemplatesIds = selectedTemplates?.map(temp => temp.cofTemplateId);

  const noteBtnDisable = values?.tCoNote ? currentTemplate?.note === values?.tCoNote : true;

  return (
    <div className="left-side">
      <ReactTable
        defaultData={templatesTableData}
        columns={templateCoColumns}
        current={currentTemplate?.cofTemplateId || null}
        onSelect={onTemplateSelect}
        className="scrollX mb"
        style={{maxHeight: "12em"}}
        loading={templateLoadingStatus}
        disabledRowIds={disabledTemplates}
      />
      <div className="fdr aic jcsb mb">
        <div>{t("Notes on tCO") + ":"}</div>
        <div className="fdr jcfe gap2x" style={{width: "80%"}}>
          <TextAreaInput name="tCoNote" height="2.5em" disabled={!currentTemplate} />
          <ImageButton
            src={saveIcon}
            alt="save"
            width={1.4}
            height={1.4}
            onClick={onSaveTemplateNoteClick}
            disabled={!currentTemplate || values.tCoNote === "" || noteBtnDisable}
          />
        </div>
      </div>
      <div className="fdr jcsb mb">
        <div className="fcCol jcc" style={{gap: "8em"}}>
          <div className="mt2x">{t("Brand list tCO") + ":"}</div>
          <div className="fc nowrap gap0_5">
            <QuestionItem title={t("The summ is calculated based on the catalog price")} />
            <MoneyInput label={t("tCO sum")} name="tCoSum" readOnly autolabel />
          </div>
        </div>
        <ReactTable
          defaultData={brandsTableData}
          columns={brandTemplateColumns}
          className="scrollX "
          style={{width: "80%", height: "13em"}}
          loading={templateBrandsLoadingStatus}
        />
      </div>
      <div className="fdr aic jcfs mb" style={{gap: "20em"}}>
        <FunctionButton
          name={t("Create CO(f)")}
          onClick={() => setChooseCoModalFromMainActive(true)}
          autoWidth
          disabled={!currentTemplate || selectedTemplates?.length !== 0}
        />
        <UpDownButtons
          onDownClick={onDownClick}
          onUpClick={onUpClick}
          isDownBtnDisabled={!currentTemplate || templates.length === 0}
          isUpBtnDisabled={!currentSelectedTemplate || selectedTemplates.length === 0}
          tooltipMessage={""}
        />
      </div>
      <ReactTable
        defaultData={selectedTemplatesTableData}
        columns={templateCoColumns}
        current={currentSelectedTemplate?.cofTemplateId || null}
        onSelect={onSelectedTemplateSelect}
        className="scrollX mb"
        style={{maxHeight: "12em"}}
        loading={false}
      />
      <FunctionButton
        name={t("Unite and create CO(f)")}
        onClick={() => setChooseCoModalFromMainActive(true)}
        disabled={selectedTemplates?.length === 0}
        className="right_side_btn bottom"
        autoWidth
      />
      <ModalForm
        Component={ChooseCoModal}
        label={t("Creating CO(f) from template")}
        active={chooseCoModalFromMainActive}
        setModalActive={setChooseCoModalFromMainActive}
        className="largeModal"
        templateIds={selectedTemplates.length > 0 ? selectedTemplatesIds : [currentTemplate?.cofTemplateId]}
        setDisabledTemplates={setDisabledTemplates}
      />
    </div>
  );
};

export default TemplateMainInfo;
