export const ECB_RATE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 5,
  },
  {
    header: "Date",
    accessorKey: "date",
    size: 70,
    isSortable: true,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    size: 70,
  },
  {
    header: "code",
    accessorKey: "code",
    size: 5,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 200,
  },
]);
export const OFFICE_RATE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 5,
  },
  {
    header: "Date, time",
    accessorKey: "dateTime",
    size: 70,
    isSortable: true,
  },
  {
    header: "cc",
    accessorKey: "buyingRate",
    size: 70,
  },
  {
    header: "co",
    accessorKey: "salesRate",
    size: 70,
  },
  {
    header: "code",
    accessorKey: "code",
    size: 5,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 200,
  },
]);

export const TT_RATE_TABLE_COLUMNS = Object.freeze([
  {
    header: "№nn",
    accessorKey: "number",
    size: 5,
  },
  {
    header: "Date, time",
    accessorKey: "dateTime",
    size: 70,
    isSortable: true,
  },
  {
    header: "Rate",
    accessorKey: "rate",
    size: 70,
  },
  {
    header: "code",
    accessorKey: "code",
    size: 5,
  },
  {
    header: "Currency",
    accessorKey: "currency",
    size: 200,
  },
]);
